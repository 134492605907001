import React, {useState, useEffect} from 'react'
import {WebhookForm} from './WebhookForm'
import {WebhookList} from './WebhookList'
import {clearError, clearSuccess, fetchWebhooks} from 'actions'
import {useSelector, useDispatch} from 'react-redux'
import {WebhookLogs} from './WebhookLogs'
import ArrowIcon from 'res/icons/ArrowIcon'
import PlanUtils from 'utils/plan'
import Subsection from 'components/partials/headers/Subsection'

const Webhooks = () => {
  const webhooksState = useSelector(state => state.webhooks)

  const dispatch = useDispatch()

  const [webhookSelected, selectWebhook] = useState(null)
  const [webhooks, setWebhooks] = useState([])
  const [webhookComponentState, setWebhookComponentState] = useState('list')

  const planUtils = PlanUtils()

  useEffect(() => {
    if (!planUtils.hasPlanRestriction()) {
      dispatch(fetchWebhooks())
    }
  }, [])

  useEffect(() => {
    if (webhooksState) {
      dispatch(clearSuccess())
      dispatch(clearError())

      setWebhooks(webhooksState)
    }
  }, [webhooksState])

  const getWebhookContent = () => {
    if (planUtils.hasPlanRestriction()) {
      return <WebhookList webhooks={[]} />
    }

    switch (webhookComponentState) {
      case 'list':
        return (
          <WebhookList
            webhooks={webhooks}
            selectWebhook={selectWebhook}
            setWebhookComponentState={setWebhookComponentState}
          />
        )

      case 'details':
        return (
          <WebhookForm
            webhook={webhookSelected}
            webhookSelected={webhookSelected}
            selectWebhook={selectWebhook}
            webhookComponentState={webhookComponentState}
            setWebhookComponentState={setWebhookComponentState}
          />
        )

      case 'add':
        return (
          <WebhookForm
            selectWebhook={selectWebhook}
            webhookComponentState={webhookComponentState}
            setWebhookComponentState={setWebhookComponentState}
          />
        )
    }
  }

  return (
    <Subsection
      title={
        <div className="flex">
          {(webhookComponentState === 'details' ||
            webhookComponentState === 'add') && (
            <figure
              className="cursor-pointer pr-4"
              onClick={() => {
                setWebhookComponentState('list')
                selectWebhook(null)
              }}>
              <ArrowIcon color="#528CD4" width="16" direction="left" />
            </figure>
          )}
          <div>Webhooks</div>
        </div>
      }
      loading={planUtils.hasPlanRestriction() ? false : !webhooksState}>
      <div className="flex flex-wrap border border-sideral-50 p-6">
        {getWebhookContent()}
      </div>

      {webhookSelected && (
        <div className="rounded-md border border-sideral-50 mt-8">
          <WebhookLogs webhook={webhookSelected} />
        </div>
      )}
    </Subsection>
  )
}

export default Webhooks
