import React, {useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  checkCheckoutSession,
  clearAction,
  fetchFeaturesCount,
  fetchUser,
} from 'actions'

import PlanUtils from 'utils/plan'
import Spinner from 'components/partials/Spinner'
import CheckoutWrapper from './checkout'
import ProcessingPayment from './ProcessingPayment'
import Update from './update'

export default function CheckoutRedirect() {
  const dispatch = useDispatch()
  const checkoutIntervalRef = useRef(null)
  const userIntervalRef = useRef(null)

  const checkChekoutSessionState = useSelector(
    state => state.checkChekoutSession
  )
  const csrfState = useSelector(state => state.csrf)
  const userState = useSelector(state => state.user)

  const planUtils = PlanUtils()

  const params = new URLSearchParams(window.location.search)
  const sessionId = params.get('sessionId')

  useEffect(() => {
    dispatch(clearAction('checkChekoutSession'))

    dispatch(fetchFeaturesCount())

    if (sessionId) {
      dispatch(
        checkCheckoutSession({
          sessionId,
          _csrf: csrfState,
        })
      )

      checkoutIntervalRef.current = setInterval(() => {
        dispatch(
          checkCheckoutSession({
            sessionId,
            _csrf: csrfState,
          })
        )
      }, 5000)
    }
  }, [])

  useEffect(() => {
    if (checkChekoutSessionState?.status === 'complete') {
      clearInterval(checkoutIntervalRef.current)

      userIntervalRef.current = setInterval(() => {
        dispatch(fetchUser())
      }, 5000)
    }
  }, [checkChekoutSessionState])

  useEffect(() => {
    if (userState.subscription) {
      clearInterval(userIntervalRef.current)
    }
  }, [userState])

  if (planUtils.userHasPremiumPlan()) return <Update />

  if (!sessionId) return <CheckoutWrapper />

  if (!checkChekoutSessionState) return <Spinner />

  if (
    checkChekoutSessionState?.status === 'complete' &&
    userState.subscription
  ) {
    return <Update />
  }

  return <ProcessingPayment />
}
