import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import i18next from 'i18next'
import {Trans} from 'react-i18next'
import {Link} from 'react-router-dom'
import {DefaultModal} from 'components/partials/DefaultModal'

export default function LimitRestrictionModal({
  productId,
  prevQty,
  newQty,
  setModal,
  source,
}) {
  const featuresCountState = useSelector(state => state.featuresCount)

  let i18nKey = ''
  let currentQty = ''
  let redirectUrl = ''
  let hideSecondParagraph = false

  switch (productId.toLowerCase()) {
    case 'domains monitoring':
    case 'domains':
      i18nKey = 'subscription.limitRestrictionModal.domains'
      currentQty = featuresCountState.domains
      redirectUrl = '/dns#domains-table'
      break

    case 'cloud accounts monitoring':
    case 'cloudintegrations':
      i18nKey = 'subscription.limitRestrictionModal.cloudIntegrations'
      currentQty = featuresCountState.cloudIntegrations
      redirectUrl = '/cloud#cloud-integrations'
      break

    case 'identity providers monitoring':
    case 'idpintegrations':
      i18nKey = 'subscription.limitRestrictionModal.idpIntegrations'
      currentQty = featuresCountState.idpIntegrations
      redirectUrl = '/people/integrations'
      break

    case 'api access':
    case 'apikeys':
      i18nKey = 'subscription.limitRestrictionModal.apiKeys'
      currentQty = featuresCountState.apiKeys
      redirectUrl = '/settings/api-keys'
      break

    case 'suppliers score monitoring':
    case 'monitoredsuppliers':
      i18nKey = 'subscription.limitRestrictionModal.monitoredSuppliers'
      currentQty = featuresCountState.monitoredSuppliers
      redirectUrl = '/suppliers'
      break

    case 'suppliers questionnaires management':
    case 'securityquestionnaires':
      i18nKey = 'subscription.limitRestrictionModal.securityQuestionnaires'
      currentQty = featuresCountState.securityQuestionnaires
      hideSecondParagraph = true
      break
  }

  return (
    <DefaultModal
      id="limitRestrictionModal"
      title={i18next.t('subscription.limitRestrictionModal.title')}
      body={
        <div className="text-sm text-gray-700 leading-6">
          <div className="mb-4">
            {
              <Trans
                i18nKey={`${i18nKey}.firstParagraph`}
                values={{
                  excerpt:
                    source === 'checkout'
                      ? i18next.t(
                          'subscription.limitRestrictionModal.quantityExcerpt'
                        )
                      : i18next.t(
                          'subscription.limitRestrictionModal.subscriptionExcerpt'
                        ),
                  prevQty,
                  newQty,
                  currentQty,
                }}
                components={{
                  a: (
                    <a
                      href="mailto: support@unxpose.com"
                      className="text-sky-900 font-medium"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            }
          </div>

          {!hideSecondParagraph && (
            <div>
              {
                <Trans
                  i18nKey={`${i18nKey}.secondParagraph`}
                  components={{
                    externalLink: (
                      <Link
                        to={redirectUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="text-sky-900 underline hover:cursor-pointer"
                      />
                    ),
                    a: (
                      <a
                        href="mailto: support@unxpose.com"
                        className="text-sky-900 font-medium"
                        rel="noreferrer"
                      />
                    ),
                    reload: (
                      <Link
                        onClick={() => window.location.reload()}
                        className="text-sky-900 underline hover:cursor-pointer"
                        rel="noreferrer"
                      />
                    ),
                    i: (
                      <i className="icofont-external-link text-sky-900 ml-1" />
                    ),
                  }}
                />
              }
            </div>
          )}
        </div>
      }
      primaryBtnText={i18next.t('buttons.close')}
      onClickPrimaryBtn={() => setModal({showModal: false})}
      onCloseModal={() => setModal({showModal: false})}
      disableBackdropClose
      width="max-w-xl"
    />
  )
}

LimitRestrictionModal.propTypes = {
  productId: PropTypes.string,
  prevQty: PropTypes.number,
  newQty: PropTypes.number,
  setModal: PropTypes.func,
  source: PropTypes.string,
}
