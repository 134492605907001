import React, {useState, useEffect} from 'react'
import i18next from 'i18next'
import {Trans} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {companyRenewal, showAutoSubscriptionModal} from 'actions'
import ReactMarkdown from 'react-markdown'
import {Page} from './partials/Page'
import PaperForm from './partials/PaperForm'
import PlanUtils from 'utils/plan'

import images from 'res'
import Logo from 'res/brand/logo-mini.svg'
import PrimaryButton from './partials/buttons/PrimaryButton'
import {linearGradientStyle} from 'constant'

const WhyUnxpose = () => {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)

  const [scroll, setScroll] = useState(1)
  const [showRightCallToAction, setShowRightCallToAction] = useState(false)

  const [showPaperForm, setShowPaperform] = useState(false)

  const isPremiumPlan = PlanUtils().userHasPremiumPlan()

  const onClickNavigatorItem = id =>
    document.getElementById(id.toString()).scrollIntoView({behavior: 'smooth'})

  const handleTalkToSales = () => {
    setShowPaperform(true)

    dispatch(
      companyRenewal({
        _csrf: csrfState,
        companyId: userState.company.id,
        element: 'talkToSales',
        source: 'why-unxpose-page',
      })
    )
  }

  const handleStartForFree = () => {
    dispatch(showAutoSubscriptionModal(true))
  }

  const onScroll = () => {
    const totalHeight = document.body.scrollHeight
    const visibleHeight = window.innerHeight
    const scrollPosition = window.scrollY

    const isNearBottom = totalHeight - scrollPosition <= visibleHeight + 200

    if (isNearBottom) setShowRightCallToAction(false)
    else setShowRightCallToAction(true)

    document.querySelectorAll('.item-content').forEach(e => {
      if (e.getBoundingClientRect().top < 300)
        setScroll(parseInt(e.attributes.id.value))
    })
  }

  useEffect(() => window.addEventListener('scroll', onScroll), [])

  useEffect(() => {
    document.addEventListener('mouseup', e => {
      const paperformModal = document.querySelector('#paperformModal')
      if (e.target === paperformModal) setShowPaperform(false)
    })
  }, [])

  return (
    <Page pageTitle={i18next.t('pageTitles.whyUnxpose')} hideHeader>
      <div className="relative bg-white p-8 rounded-md border mt-8 md:mt-0">
        <div id="header" className="border-b pb-8 mb-12 mt-4">
          <img src={Logo} alt="Unxpose logo" className="w-8 mb-3 m-auto" />
          <h2 className="text-4xl font-medium text-center text-black">
            <Trans
              i18nKey="whyUnxpose.mainTitle"
              components={{
                span: <span className={linearGradientStyle} />,
              }}
            />
          </h2>
        </div>

        <div id="main" className="grid grid-cols-1 xxl:grid-cols-4 gap-16">
          <div className="col-span-3">
            {pageContent.map((item, i) => (
              <div
                key={item.id}
                id={item.id}
                className="item-content group mb-24 last:mb-0 pt-6">
                <div className="flex items-center mb-8 text-black group-even:justify-end">
                  <span className="relative">
                    <img src={images.enumerator} className="w-[95px]" />
                    <div
                      className={`${linearGradientStyle} bg-gradient-to-b absolute -top-[14px] left-[34px] text-5xl font-bold`}>
                      {item.id}
                    </div>
                  </span>
                  <h3 className="text-2xl font-medium ml-4">{item.title}</h3>
                </div>

                <div className="leading-9 indent-20 text-justify text-lg">
                  {item.paragraphs.map((paragraph, i) => (
                    <ReactMarkdown
                      key={i}
                      components={{
                        strong: obj => (
                          <span
                            className={`${linearGradientStyle} font-medium`}>
                            {obj.children}
                          </span>
                        ),
                      }}>
                      {paragraph}
                    </ReactMarkdown>
                  ))}
                </div>

                {item.testimonial && (
                  <div id="testimonial" className="relative max-w-3xl ml-auto">
                    <i
                      className={`absolute -top-[32px] group-odd:-left-[34px] group-even:-right-[32px] text-[#EFEFEF] text-8xl ${
                        i % 2 === 0
                          ? 'icofont-quote-left'
                          : 'icofont-quote-right'
                      }`}
                    />

                    <div className="flex items-center group-even:flex-row-reverse border rounded-md p-5 my-8 backdrop-blur-sm">
                      <div className="group-odd:mr-6 group-even:ml-6 basis-1/6">
                        <img src={item.testimonial.authorImage} />
                      </div>
                      <div className="basis-5/6">
                        <p className="text-sm italic leading-6">
                          {item.testimonial.text}
                        </p>
                        <div className="flex items-center justify-between group-even:flex-row-reverse mt-3">
                          <div className="group-even:text-right">
                            <div className="text-sm font-medium">
                              {item.testimonial.authorName}
                            </div>
                            <div className="text-xs italic">
                              {item.testimonial.authorRole}
                            </div>
                          </div>
                          <div>
                            <img
                              src={item.testimonial.companyLogo}
                              className="w-20"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}

            <div className="pt-10">
              <div className="bg-white border rounded-md w-full px-10 py-8">
                <h3 className="text-2xl font-medium text-black">
                  <Trans
                    i18nKey="whyUnxpose.bottom.title"
                    components={{
                      span: <span className={linearGradientStyle} />,
                    }}
                  />
                </h3>
                <div className="mt-2 mb-4">
                  {i18next.t('whyUnxpose.bottom.subtitle')}
                </div>
                <div className="w-64">
                  <PrimaryButton
                    text={i18next.t('sales.talkToSalesBtn')}
                    size="full"
                    margin="mb-2"
                    onClick={handleTalkToSales}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="hidden xxl:flex flex-col justify-between">
            <div className="sticky top-0 pt-6">
              <ul className="border-l pl-8">
                {pageContent.map(item => (
                  <li
                    key={item.id}
                    className="mb-4 last:mb-0"
                    onClick={() => onClickNavigatorItem(item.id)}>
                    <a
                      className={`hover:cursor-pointer block text-sm ${
                        scroll === item.id
                          ? linearGradientStyle
                          : 'hover:text-black'
                      }`}>
                      <span className="font-bold text-xl pr-2">{item.id}.</span>
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {showRightCallToAction && (
              <div className="sticky bottom-[25px] hidden xxl:block">
                <div className="bg-white border rounded-md w-full p-6">
                  <PrimaryButton
                    text={i18next.t('buttons.startForFree')}
                    size="full"
                    margin="mb-4"
                    onClick={handleStartForFree}
                  />
                  <div className="text-xs text-gray-600 text-">
                    {i18next.t('sales.stripeFooter')}
                  </div>
                </div>
              </div>
            )}

            {showPaperForm && (
              <div
                id="paperformModal"
                className="modal-bg fixed top-0 left-0 w-screen h-screen z-50">
                <div className="fixed bg-white border rounded-md bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 max-w-lg w-full p-8">
                  <PaperForm
                    classes="relative"
                    title={
                      isPremiumPlan
                        ? i18next.t('planInfo.paid.paperformTitle')
                        : i18next.t('planInfo.free.paperformTitle')
                    }
                    onClickBack={() => setShowPaperform(false)}
                    useCloseIcon
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  )
}

const pageContent = [
  {
    id: 1,
    title: i18next.t('whyUnxpose.firstItem.title'),
    paragraphs: [i18next.t('whyUnxpose.firstItem.paragraph_1')],
    testimonial: {
      text: i18next.t('whyUnxpose.firstItem.testimonial.text'),
      authorName: i18next.t('whyUnxpose.firstItem.testimonial.authorName'),
      authorRole: i18next.t('whyUnxpose.firstItem.testimonial.authorRole'),
      authorImage: images.mateus,
      companyLogo: images.seedzLogo,
    },
  },
  {
    id: 2,
    title: i18next.t('whyUnxpose.secondItem.title'),
    paragraphs: [i18next.t('whyUnxpose.secondItem.paragraph_1')],
    testimonial: {
      text: i18next.t('whyUnxpose.secondItem.testimonial.text'),
      authorName: i18next.t('whyUnxpose.secondItem.testimonial.authorName'),
      authorRole: i18next.t('whyUnxpose.secondItem.testimonial.authorRole'),
      authorImage: images.denis,
      companyLogo: images.cafLogo,
    },
  },
  {
    id: 3,
    title: i18next.t('whyUnxpose.thirdItem.title'),
    paragraphs: [i18next.t('whyUnxpose.thirdItem.paragraph_1')],
  },
  {
    id: 4,
    title: i18next.t('whyUnxpose.fourthItem.title'),
    paragraphs: [
      i18next.t('whyUnxpose.fourthItem.paragraph_1'),
      i18next.t('whyUnxpose.fourthItem.paragraph_2'),
    ],
  },
  {
    id: 5,
    title: i18next.t('whyUnxpose.fifthItem.title'),
    paragraphs: [i18next.t('whyUnxpose.fifthItem.paragraph_1')],
  },
  {
    id: 6,
    title: i18next.t('whyUnxpose.sixthItem.title'),
    paragraphs: [i18next.t('whyUnxpose.sixthItem.paragraph_1')],
  },
  {
    id: 7,
    title: i18next.t('whyUnxpose.seventhItem.title'),
    paragraphs: [i18next.t('whyUnxpose.seventhItem.paragraph_1')],
  },
  {
    id: 8,
    title: i18next.t('whyUnxpose.eighthItem.title'),
    paragraphs: [i18next.t('whyUnxpose.eighthItem.paragraph_1')],
  },
  {
    id: 9,
    title: i18next.t('whyUnxpose.ninthItem.title'),
    paragraphs: [i18next.t('whyUnxpose.ninthItem.paragraph_1')],
    testimonial: {
      text: i18next.t('whyUnxpose.ninthItem.testimonial.text'),
      authorName: i18next.t('whyUnxpose.ninthItem.testimonial.authorName'),
      authorRole: i18next.t('whyUnxpose.ninthItem.testimonial.authorRole'),
      authorImage: images.cristiano,
      companyLogo: images.tempestLogo,
    },
  },
]

export default WhyUnxpose
