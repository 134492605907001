import Company from './Company'
import Members from './members/Members'
import Notifications from './Notifications'
import Password from './Password'
import Profile from './Profile'
import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import Webhooks from './Webhooks'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'

import {clearFilters} from 'actions'
import {i18next} from 'translate/i18n'
import {activeBtn, notActiveBtn} from 'constant'
import {Page} from 'components/partials/Page'
import AuditLogs from './AuditLogs'
import ApiKeys from './apiKeys/ApiKeys'
import {restrictedRoles} from 'utils'

const TabContent = props => {
  switch (props.tab) {
    case '/settings/profile':
      return <Profile />
    case '/settings/members':
      return <Members />
    case '/settings/company':
      return <Company />
    case '/settings/password':
      return <Password />
    case '/settings/notifications':
      return <Notifications />
    case '/settings/webhooks':
      return <Webhooks />
    case '/settings/api-keys':
      return <ApiKeys />
    case '/settings/audit-logs':
      return <AuditLogs />
    default:
      return <Company />
  }
}

const Settings = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const userState = useSelector(state => state.user)

  const [currentTab, setCurrentTab] = useState('/settings/company')

  const handleClickTab = name => {
    setCurrentTab(name)
    navigate(name)
  }

  useEffect(() => {
    setCurrentTab(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    dispatch(clearFilters())
  }, [])

  const tabItems = [
    {
      path: '/settings/company',
      label: i18next.t('settings.tabs.company'),
      hide: false,
    },
    {
      path: '/settings/members',
      label: i18next.t('settings.tabs.members'),
      hide: false,
    },
    {
      path: '/settings/profile',
      label: i18next.t('settings.tabs.profile'),
      hide: false,
    },
    {
      path: '/settings/password',
      label: i18next.t('settings.tabs.security'),
      hide: false,
    },
    {
      path: '/settings/notifications',
      label: i18next.t('settings.tabs.notifications'),
      hide: restrictedRoles.some(role => userState.role === role.name),
    },
    {
      path: '/settings/webhooks',
      label: 'Webhooks',
      hide: restrictedRoles.some(role => userState.role === role.name),
    },
    {
      path: '/settings/api-keys',
      label: i18next.t('settings.tabs.apiKeys'),
      hide: restrictedRoles.some(role => userState.role === role.name),
    },
    {
      path: '/settings/audit-logs',
      label: i18next.t('settings.tabs.auditLogs'),
      hide: restrictedRoles.some(role => userState.role === role.name),
    },
  ]

  return (
    <Page pageTitle={i18next.t('pageTitles.settings')}>
      <ul
        data-testid="settings-tabs"
        className="flex border border-b-0 border-sideral-100 rounded-t-lg overflow-auto">
        {tabItems.map(
          tabItem =>
            !tabItem.hide && (
              <li
                key={tabItem.path}
                className="dm-mono w-full whitespace-nowrap">
                <button
                  className={
                    currentTab === tabItem.path ? activeBtn : notActiveBtn
                  }
                  onClick={() => handleClickTab(tabItem.path)}>
                  {tabItem.label}
                </button>
              </li>
            )
        )}
      </ul>

      <div className="bg-white border border-sideral-100 rounded-b-lg p-6">
        <TabContent tab={currentTab} />
      </div>
    </Page>
  )
}

TabContent.propTypes = {
  tab: PropTypes.string,
}

Settings.propTypes = {
  props: PropTypes.object,
  location: PropTypes.object,
}

export default Settings
