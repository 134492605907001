import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import i18next from 'i18next'

import Timestamp from 'components/partials/Timestamp'
import Subsection from 'components/partials/headers/Subsection'
import SecurityShield from 'res/icons/securityShield'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import CancelSubscriptionModal from './CancelSubscriptionModal'
import {mars700, sky900} from 'constant'
import PlanUtils from 'utils/plan'
import ClockIcon from 'res/icons/clockIcon'
import SecurityShieldBad from 'res/icons/securityShieldBad'

export default function PlanSubsection() {
  const userState = useSelector(state => state.user)

  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false)

  const planUtils = PlanUtils()

  return (
    <Subsection
      title={i18next.t('subscription.update.planSubsection.title')}
      margin="mb-10">
      <div className="flex justify-between items-center p-6">
        <div className="flex items-center gap-10">
          <div className="flex items-center">
            <SecurityShield width="35" height="35" color={sky900} />
            <div className="border-l pl-4 ml-3">
              <div className="font-medium text-sm">
                {i18next.t('subscription.update.planSubsection.creation')}
              </div>
              <div className="text-xs">
                <Timestamp
                  date={userState.subscription?.startDate}
                  format="LL"
                />
              </div>
            </div>
          </div>

          {userState.subscription?.endDate && (
            <>
              {!planUtils.planHasExpired() && (
                <div className="flex items-center">
                  <ClockIcon width="35" height="35" color={mars700} />
                  <div className="border-l pl-4 ml-3">
                    <div className="font-medium text-sm">
                      {i18next.t(
                        'subscription.update.planSubsection.expiration'
                      )}
                    </div>
                    <div className="text-xs">
                      <Timestamp
                        date={
                          userState.futureSubscription
                            ? userState.futureSubscription.endDate
                            : userState.subscription.endDate
                        }
                        format="LL"
                      />
                    </div>
                  </div>
                </div>
              )}

              {planUtils.planHasExpired() && (
                <div className="flex items-center">
                  <SecurityShieldBad width="35" height="35" color={mars700} />
                  <div className="border-l pl-4 ml-3">
                    <div className="font-medium text-sm">
                      {i18next.t(
                        'subscription.update.planSubsection.expirated'
                      )}
                    </div>
                    <div className="text-xs">
                      <Timestamp
                        date={userState.subscription.endDate}
                        format="LL"
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <SecondaryButton
          text={i18next.t(
            'subscription.update.planSubsection.cancelSubscriptionBtn'
          )}
          size="sm"
          onClick={() => setShowCancelSubscriptionModal(true)}
        />
      </div>

      {showCancelSubscriptionModal && (
        <CancelSubscriptionModal setModal={setShowCancelSubscriptionModal} />
      )}
    </Subsection>
  )
}
