import React, {useState} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {DefaultModal} from 'components/partials/DefaultModal'
import {useCheckout} from '@stripe/react-stripe-js'

export default function RemoveLastItemModal({modal, setModal}) {
  const {updateLineItemQuantity} = useCheckout()

  const [loading, setLoading] = useState(false)

  const onCloseModal = () => {
    setModal({itemId: '', showModal: false})
  }

  const handleRemoveItem = () => {
    setLoading(true)

    updateLineItemQuantity({lineItem: modal.itemId, quantity: 0}).then(() => {
      setLoading
      onCloseModal()
    })
  }

  return (
    <DefaultModal
      id="remove-last-item-modal"
      title={i18next.t('subscription.checkout.removeModal.title')}
      body={
        <div className="text-sm text-gray-700 leading-6">
          {i18next.t('subscription.checkout.removeModal.body')}
        </div>
      }
      primaryBtnText={i18next.t('buttons.remove')}
      onClickPrimaryBtn={handleRemoveItem}
      secondayBtnText={i18next.t('buttons.cancel')}
      onClickSecondaryBtn={onCloseModal}
      onCloseModal={onCloseModal}
      primaryButtonLoading={loading}
      disableBackdropClose
    />
  )
}

RemoveLastItemModal.propTypes = {
  modal: PropTypes.object,
  setModal: PropTypes.func,
}
