import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

export default function PrimaryButton({
  testId,
  text,
  type,
  onClick,
  loading,
  disabled,
  margin,
  redirect,
  openNewTab,
  iconClass,
  icon,
  size,
  theme,
  miniature,
  rightContent,
  tooltip,
  iconDirection = 'left',
}) {
  const isDisabled = disabled ? 'pointer-events-none opacity-50' : ''
  const font = `dm-mono font-light uppercase leading-none`

  function setSize(size) {
    switch (size) {
      case 'sm':
        return `w-36 md:min-w-56 h-10 text-xs`
      case 'md':
        return `w-48 md:min-w-56 text-md`
      case 'lg':
        return `w-56 md:min-w-56 text-lg`
      case 'full':
        return `w-full text-sm`
      case 'full sm':
        return `w-full h-10 text-xs`
      default:
        return `text-sm`
    }
  }

  function setTheme(theme) {
    switch (theme) {
      case 'blue':
        return `text-white border-sky-900 bg-sky-900 hover:bg-sky-800`
      case 'blue-outline':
        return 'text-sky-900 border-sky-900 bg-transparent hover:bg-gray-100 hover:font-medium'
      case 'red':
        return 'text-white border-red-700 bg-red-700 hover:bg-red-800'
      case 'red-outline':
        return 'text-red-700 border-red-700 hover:bg-red-100'
      case 'icon-only':
        return 'border-0 !px-0'
      default:
        return 'text-white border-sky-900 bg-sky-900 hover:bg-sky-800'
    }
  }

  function setLoading(theme) {
    switch (theme) {
      case 'blue':
        return `cursor-wait bg-sky-800 border-sky-800 text-white`
      case 'blue-outline':
        return 'cursor-wait bg-gray-100'
      case 'red':
        return `cursor-wait bg-red-700 border-red-700 text-white`
      case 'red-outline':
        return 'cursor-wait border-red-700 text-red-700'
      default:
        return 'cursor-wait bg-sky-800 border-sky-800 text-white'
    }
  }

  const buttonJsx = (
    <button
      data-testid={testId}
      type={type || 'button'}
      onClick={onClick || null}
      disabled={loading || disabled ? 'disabled' : ''}
      className={`
      ${setSize(size)} 
      ${font} 
      ${isDisabled} 
      ${miniature ? 'text-xs py-2 px-3' : 'px-4 h-10'} 
      ${loading ? setLoading(theme) : setTheme(theme)} 
      flex items-center justify-center rounded-sm border`}>
      {iconDirection === 'left' && (
        <>
          {iconClass && <i className={`${iconClass} mr-2`} />}
          {icon && <div className="pr-2">{icon}</div>}
        </>
      )}

      <span className={loading ? ' loading' : ''}>
        {loading ? '. . .' : text}
      </span>

      {iconDirection === 'right' && (
        <>
          {iconClass && <i className={`${iconClass} ml-2`} />}
          {icon && <div className="pl-2">{icon}</div>}
        </>
      )}

      {rightContent && <div className="ml-2">{rightContent}</div>}
    </button>
  )

  return (
    <div className={`${margin} ${tooltip && 'relative tooltip'}`}>
      {redirect ? (
        <Link
          to={redirect}
          target={openNewTab ? '_blank' : '_self'}
          rel="noopener noreferrer">
          {buttonJsx}
        </Link>
      ) : (
        buttonJsx
      )}

      {tooltip}
    </div>
  )
}

PrimaryButton.propTypes = {
  testId: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  redirect: PropTypes.string,
  openNewTab: PropTypes.bool,
  iconClass: PropTypes.string,
  icon: PropTypes.object,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'full']),
  theme: PropTypes.oneOf([
    'blue',
    'blue-outline',
    'red',
    'red-outline',
    'icon-only',
  ]),
  miniature: PropTypes.bool,
  rightContent: PropTypes.object,
  iconDirection: PropTypes.string,
  tooltip: PropTypes.object,
}
