import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {Trans} from 'react-i18next'
import {DefaultModal} from 'components/partials/DefaultModal'

export default function CancelSubscriptionModal({setModal}) {
  return (
    <DefaultModal
      id="cancel-subscription-modal"
      title={i18next.t(
        'subscription.update.planSubsection.cancelSubscriptionModal.title'
      )}
      body={
        <div className="text-sm text-gray-700 leading-6">
          <Trans
            i18nKey="subscription.update.planSubsection.cancelSubscriptionModal.body"
            components={{
              a: (
                <a
                  href="mailto: support@unxpose.com"
                  className="text-sky-900 font-medium"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </div>
      }
      onCloseModal={() => setModal(false)}
    />
  )
}

CancelSubscriptionModal.propTypes = {
  setModal: PropTypes.func,
}
