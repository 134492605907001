import {useDispatch, useSelector} from 'react-redux'
import React, {useState, useEffect} from 'react'
import i18next from 'i18next'
import {useNavigate} from 'react-router-dom'

import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import Spinner from 'components/partials/Spinner'
import TableWrapper from 'components/partials/tables/TableWrapper'
import Tag from 'components/partials/Tag'
import {Page} from 'components/partials/Page'
import {fetchProducts, loading} from 'actions'

export default function Marketplace() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loading({products: true}))
    dispatch(fetchProducts())
  }, [])

  return (
    <Page
      pageTitle={i18next.t('pageTitles.marketplace')}
      helpPath="marketplace">
      <TableWrapper
        id="partners-table"
        title={i18next.t('marketplace.services')}
        loaded={true}
        table={<Partners />}
      />
    </Page>
  )
}

function Partners() {
  const pentestId = '5d821a5e-8b02-4e9d-9600-9c71f1df55cd'

  const [categories, setCategories] = useState([])
  const [products, setProducts] = useState([])
  const [selectedCategory, selectCategory] = useState(pentestId)

  const navigate = useNavigate()

  const productState = useSelector(state => state.products)
  const loadingState = useSelector(state => state.loading)

  useEffect(() => {
    if (productState) {
      setCategories(productState.categories)
      setProducts(productState.products)
    }
  }, [productState])

  if (loadingState.products) {
    return <Spinner />
  }

  return (
    <>
      <div className="flex flex-wrap mb-4 justify-center items-center">
        {categories.map((category, index) => (
          <button
            key={`${category.id} ${index}`}
            className={
              'px-3 py-1 rounded-full m-1' +
              (category.id === selectedCategory
                ? ' bg-sky-900 text-white'
                : ' bg-gray-300 text-gray-800')
            }
            onClick={() => selectCategory(category.id)}>
            {category.name}
          </button>
        ))}
      </div>

      {selectedCategory &&
      !products.some(product => product.category.id === selectedCategory) ? (
        <div className="text-center text-gray-700 mt-8 mb-6">
          {i18next.t('marketplace.noServicesFound')}
        </div>
      ) : null}

      {products.map((product, index) => {
        if (product.category.id !== selectedCategory) {
          return null
        }

        return (
          <div
            key={product.id + index}
            className="relative border rounded shadow-sm my-6 md:mx-4">
            <div className="p-4">
              <div className="flex justify-between">
                <div>
                  <h2 className="text-xl font-medium">{product.name}</h2>
                  <h3 className="font-thin text-sm mb-4">
                    {i18next.t('marketplace.by')} {product.partner.name}
                  </h3>
                </div>
                <div>
                  <img src={product.partner.logo} className="w-32 m-2" />
                </div>
              </div>

              <div className="w-10/12">
                <p className="mt-2 text-gray-700 font-light">
                  {product.description}
                </p>

                <h4 className="font-medium mt-4">
                  {i18next.t('marketplace.about')}
                </h4>
                <p className="mt-2 text-gray-700 font-light">
                  {product.partner.description}
                </p>
              </div>

              <div className="mt-4">
                <Tag content={product.category.name} />
              </div>
            </div>

            <div className="bg-gray-100 px-4 py-2 border-t">
              <div className="flex justify-between items-center">
                <div className="font-thin text-sm text-gray-700 w-6/12">
                  {i18next.t('marketplace.startingAt')}
                  <span className="font-normal"> {product.startingPrice}</span>
                </div>

                <SecondaryButton
                  text={i18next.t('marketplace.hire')}
                  direction="right"
                  size="sm"
                  onClick={() => {
                    navigate(`/marketplace/products/${product.id}`)
                  }}
                />
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}
