import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {Trans} from 'react-i18next'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {useCheckout} from '@stripe/react-stripe-js'
import {
  getProductInfo,
  convertToCurrency,
  getFeatureCountProp,
} from '../subscriptionUtils'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import LimitRestrictionModal from '../LimitRestrictionModal'
import RemoveLastItemModal from './RemoveLastItemModal'
import {skeletonLoadingStyle} from 'constant'
import ProductLeftSide from './partials/ProductLeftSide'

export default function CheckoutItem({item}) {
  const {currency, updateLineItemQuantity} = useCheckout()

  const featuresCountState = useSelector(state => state.featuresCount)

  const productInfo = getProductInfo(item.name)
  const featureCount = featuresCountState[getFeatureCountProp(productInfo.id)]

  const [isCurrentItemUpdating, setIsCurrentItemUpdating] = useState(false)
  const [itemIdUpdating, setItemIdUpdating] = useState(null)
  const [tempValue, setTempValue] = useState({
    itemId: item.id,
    quantity: item.quantity,
  })
  const [removeLastItemModal, setRemoveLastItemModal] = useState({
    itemId: '',
    showModal: false,
  })
  const [limitRestrictionModal, setLimitRestrictionModal] = useState({
    newQty: 0,
    showModal: false,
  })

  const updateQty = (itemId, newQty) => {
    setItemIdUpdating(itemId)

    updateLineItemQuantity({
      lineItem: itemId,
      quantity: newQty,
    }).then(result => {
      setItemIdUpdating(null)

      if (result.type === 'error') {
        toast.error(i18next.t('subscription.checkout.messages.removeItemError'))
      }
    })
  }

  const handleChange = (itemId, newQty, autoConfirm = true) => {
    if (newQty < featureCount) {
      return setLimitRestrictionModal({newQty, showModal: true})
    }

    if (newQty === 0 && autoConfirm) {
      return setRemoveLastItemModal({itemId, showModal: true})
    }

    setTempValue({itemId, quantity: isNaN(newQty) ? 0 : newQty})

    if (autoConfirm) updateQty(itemId, newQty)
  }

  useEffect(() => {
    setIsCurrentItemUpdating(itemIdUpdating === item.id)
  }, [itemIdUpdating])

  useEffect(() => {
    if (
      featureCount &&
      featureCount > 1 &&
      getFeatureCountProp(productInfo.id) !== 'apiKeys'
    ) {
      updateQty(item.id, featureCount)
      setTempValue({itemId: item.id, quantity: featureCount})
    }
  }, [featureCount])

  return (
    <>
      <div key={item.id} className="lg:flex items-center mb-8 pb-8 border-b">
        <ProductLeftSide product={productInfo} />

        <div id="right-content" className="basis-1/2 mt-6 lg:mt-0">
          <div className="xs:flex gap-10 items-start justify-between">
            {productInfo.isOnOffItem ? (
              <div className="flex basis-1/3 text-center justify-between xs:block shrink-0">
                <div className="font-medium text-sm xs:mb-8">
                  {i18next.t('subscription.checkout.cartItem.include')}
                </div>
                <div className="flex justify-center">
                  <input
                    id="include-item-checkbox"
                    type="checkbox"
                    className="switch switch--shadow"
                    onChange={() => handleChange(tempValue.itemId, 0)}
                    checked
                  />
                  <label htmlFor="include-item-checkbox" />
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between xs:block text-end xs:text-center basis-1/3 shrink-0">
                <div className="font-medium text-sm mb-4">
                  {i18next.t('subscription.checkout.cartItem.quantity')}
                </div>
                <div>
                  <div className="flex items-baseline justify-center">
                    <button
                      onClick={() =>
                        handleChange(tempValue.itemId, tempValue.quantity - 1)
                      }
                      disabled={
                        tempValue.quantity <= 0 || isCurrentItemUpdating
                      }>
                      <i className="icofont-minus text-lg" />
                    </button>
                    <div className="w-16 mx-3">
                      <PrimaryInput
                        name="cartQty"
                        type="number"
                        value={tempValue.quantity}
                        onChange={e =>
                          handleChange(
                            tempValue.itemId,
                            e.target.valueAsNumber,
                            false
                          )
                        }
                        onBlur={() =>
                          handleChange(tempValue.itemId, tempValue.quantity)
                        }
                        onKeyDown={e =>
                          e.key === 'Enter' &&
                          handleChange(tempValue.itemId, tempValue.quantity)
                        }
                        onFocus={e => e.target.select()}
                        className="text-center"
                        disabled={isCurrentItemUpdating}
                      />
                    </div>
                    <button
                      onClick={() =>
                        handleChange(tempValue.itemId, tempValue.quantity + 1)
                      }
                      disabled={isCurrentItemUpdating}>
                      <i className="icofont-plus text-xs" />
                    </button>
                  </div>

                  {productInfo.subQuantity && (
                    <div className="text-xs inline-block bg-astral-100 border rounded px-1 text-nowrap">
                      {
                        <Trans
                          i18nKey={productInfo.i18nLimitKey}
                          values={{
                            limit: productInfo.subQuantity * item.quantity,
                          }}
                        />
                      }
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="flex items-center justify-between my-5 xs:my-0 xs:block text-center basis-1/3">
              <div className="font-medium text-sm">
                {i18next.t('subscription.checkout.cartItem.value')}
              </div>
              {isCurrentItemUpdating ? (
                <div
                  className={`${skeletonLoadingStyle} w-20 h-10 mt-6 mx-auto`}
                />
              ) : (
                <div className="xs:mt-8">
                  {convertToCurrency(item.unitAmount / 100, currency)}
                </div>
              )}
            </div>

            <div className="flex items-center justify-between xs:block text-center max-w-xl basis-1/3">
              <div className="font-medium text-sm">
                {i18next.t('subscription.checkout.cartItem.total')}
              </div>
              {isCurrentItemUpdating ? (
                <div
                  className={`${skeletonLoadingStyle} w-20 h-10 mt-6 mx-auto`}
                />
              ) : (
                <div className="xs:mt-8">
                  {convertToCurrency(
                    (item.unitAmount / 100) * item.quantity,
                    currency
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {removeLastItemModal.showModal && (
        <RemoveLastItemModal
          modal={removeLastItemModal}
          setModal={setRemoveLastItemModal}
        />
      )}

      {limitRestrictionModal.showModal && (
        <LimitRestrictionModal
          productId={productInfo.id}
          prevQty={item.quantity}
          newQty={limitRestrictionModal.newQty}
          setModal={setLimitRestrictionModal}
          source="checkout"
        />
      )}
    </>
  )
}

CheckoutItem.propTypes = {
  item: PropTypes.object,
}
