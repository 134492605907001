import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import {i18next} from 'translate/i18n'
import {useSelector} from 'react-redux'
import {useLocation, useNavigate, Link} from 'react-router-dom'

import {hasMultipleCompanies, isLoggedIn, getFeatureSubscription} from 'utils'
import {sky900} from 'constant'
import images from 'res'

import HelpMenu from 'components/help/HelpMenu'
import PlanInfoMenu from './PlanInfo'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import PlanUtils from 'utils/plan'
import CompanyLogo from 'components/partials/CompanyLogo'

const Menu = props => {
  const userState = useSelector(state => state.user)
  const redirectFeedbackState = useSelector(state => state.redirectFeedback)

  const location = useLocation()
  const navigate = useNavigate()

  const [page, setPage] = useState('')
  const [showHelp, setShowHelp] = useState(false)

  const planUtils = PlanUtils()

  function isFullPage() {
    return (
      window.location.pathname === '/login' ||
      window.location.pathname === '/mfa' ||
      window.location.pathname.match(/\/register/) ||
      window.location.pathname.match(/\/forgot/)
    )
  }

  const getMenuItems = () => {
    switch (userState.role) {
      case 'questionnaires-only':
        return (
          <MenuItem
            icon={<i className="icofont-list text-lg text-white" />}
            id="questionnaire-menu"
            testId="questionnaire-menu"
            isActive={page.startsWith('/questionnaires')}
            name={i18next.t('pageTitles.questionnaires')}
            path="/questionnaires"
          />
        )

      case 'suppliers-questionnaires':
        return (
          <>
            <MenuItem
              icon={
                <images.SuppliersIcon width="20" height="20" color="white" />
              }
              id="suppliers-menu"
              testId="suppliers-menu"
              isActive={page.startsWith('/suppliers')}
              name={i18next.t('pageTitles.suppliers')}
              path="/suppliers"
            />
            <MenuItem
              icon={<i className="icofont-list text-lg text-white" />}
              id="questionnaire-menu"
              testId="questionnaire-menu"
              isActive={page.startsWith('/questionnaires')}
              name={i18next.t('pageTitles.questionnaires')}
              path="/questionnaires"
            />
          </>
        )

      default:
        return (
          <>
            <MenuItem
              icon={
                <images.DashboardIcon width="20" height="20" color="white" />
              }
              isActive={page === '/'}
              name={i18next.t('pageTitles.overview')}
              path="/"
            />
            <div className="categories-menu">
              <>
                <MenuItem
                  icon={
                    <images.InternetExposureIcon
                      width="20"
                      height="20"
                      color="white"
                    />
                  }
                  id="internet-exposure-menu"
                  testId="internet-exposure-menu"
                  isActive={page.startsWith('/internet-exposure')}
                  name="Internet exposure"
                  path="/internet-exposure"
                />

                <div className="relative lg:ml-5 border-collapse">
                  <div className="absolute hidden lg:block bg-white opacity-25 w-px h-[102px] left-0 top-0 -mt-1 ml-4" />

                  <MenuItem
                    icon={
                      <images.DnsIcon width="20" height="20" color="white" />
                    }
                    id="dns-security-menu"
                    testId="dns-security-menu"
                    isActive={page.startsWith('/dns')}
                    isSubItem
                    name="DNS"
                    path="/dns"
                  />

                  <MenuItem
                    icon={
                      <images.NetworkIcon
                        width="20"
                        height="20"
                        color="white"
                      />
                    }
                    id="network-exposure-menu"
                    testId="network-exposure-menu"
                    isActive={page.startsWith('/network')}
                    isSubItem
                    name="Network"
                    path="/network"
                  />

                  <MenuItem
                    icon={
                      <images.WebIcon width="20" height="20" color="white" />
                    }
                    id="web-security-menu"
                    testId="web-security-menu"
                    isActive={page.startsWith('/web')}
                    isSubItem
                    name="Web"
                    path="/web"
                  />
                </div>
              </>

              <MenuItem
                icon={<images.CloudIcon width="20" height="20" color="white" />}
                id="cloud-security-menu"
                testId="cloud-security-menu"
                isActive={
                  page.startsWith('/cloud') ||
                  page.startsWith('/aws/finding') ||
                  page.startsWith('/gcp/finding') ||
                  page.startsWith('/azure/finding')
                }
                name="Cloud"
                path="/cloud"
              />

              {getFeatureSubscription(userState, 'cloudIntegrations')
                .hasAccess && (
                <div className="relative lg:ml-5 border-collapse">
                  <div className="absolute hidden lg:block bg-white opacity-25 w-px h-[22px] left-0 top-0 -mt-1 ml-4" />
                  <MenuItem
                    icon={
                      <images.LgpdIcon width="20" height="20" color="white" />
                    }
                    id="compliance-menu"
                    testId="compliance-menu"
                    isActive={page.startsWith('/compliance')}
                    isSubItem
                    name="Compliance"
                    path="/compliance"
                  />
                </div>
              )}

              <MenuItem
                icon={
                  <images.PeopleIcon width="20" height="20" color="white" />
                }
                id="people-exposure-menu"
                testId="people-exposure-menu"
                isActive={page.startsWith('/people')}
                name="People"
                path="/people"
              />

              <span className="bg-sky-800 block w-11/12 h-px mx-auto my-2" />

              <MenuItem
                icon={
                  <images.ManagementIcon width="20" height="20" color="white" />
                }
                id="management-menu"
                testId="management-menu"
                isActive={page.startsWith('/management')}
                name={i18next.t('pageTitles.allFindings')}
                path="/management"
              />

              <MenuItem
                icon={
                  <images.ReportIcon width="20" height="20" color="white" />
                }
                id="reports-menu"
                testId="reports-menu"
                isActive={page.startsWith('/reports')}
                name={i18next.t('pageTitles.reports')}
                path="/reports"
              />

              <span className="bg-sky-800 block w-11/12 h-px mx-auto my-2" />
              <MenuItem
                icon={
                  <images.MarketplaceIcon
                    width="20"
                    height="20"
                    color="white"
                  />
                }
                id="marketplace-menu"
                testId="marketplace-menu"
                isActive={page.startsWith('/marketplace')}
                name={i18next.t('pageTitles.marketplace')}
                path="/marketplace"
              />

              <span className="bg-sky-800 block w-11/12 h-px mx-auto my-2" />

              <MenuItem
                icon={
                  <images.SuppliersIcon width="20" height="20" color="white" />
                }
                id="suppliers-menu"
                testId="suppliers-menu"
                isActive={page.startsWith('/suppliers')}
                name={i18next.t('pageTitles.suppliers')}
                path="/suppliers"
              />

              <MenuItem
                icon={<i className="icofont-list text-lg text-white" />}
                id="questionnaire-menu"
                testId="questionnaire-menu"
                isActive={page.startsWith('/questionnaires')}
                name={i18next.t('pageTitles.questionnaires')}
                path="/questionnaires"
              />

              <MenuItem
                icon={<i className="icofont-ui-folder text-lg text-white" />}
                id="documents-menu"
                testId="documents-menu"
                isActive={page.startsWith('/documents')}
                name={i18next.t('pageTitles.documents')}
                path="/documents"
              />

              <span className="bg-sky-800 block w-11/12 h-px mx-auto mt-2" />
              <li
                id="help-menu"
                data-testid="help-menu"
                className="group dm-mono uppercase text-sm flex items-center lg:justify-start justify-center cursor-pointer rounded hover:bg-sky-800 py-3 px-4 lg:px-1 lg:mx-4 mt-1"
                onClick={() => setShowHelp(true)}>
                <figure className="opacity-50 group-hover:opacity-100 px-2">
                  <images.HelpIcon width="20" height="20" color="white" />
                </figure>
                <span className="hidden lg:block leading-none text-xs">
                  {i18next.t('pageTitles.help')}
                </span>
              </li>
            </div>
          </>
        )
    }
  }

  useEffect(() => {
    setPage(location.pathname)
    document.documentElement.scrollTop = 0
  }, [location.pathname])

  useEffect(() => {
    if (redirectFeedbackState && redirectFeedbackState.path) {
      navigate(redirectFeedbackState.path)
    }
  }, [redirectFeedbackState])

  if (!isLoggedIn(userState) || isFullPage()) return ''

  return (
    <nav className="text-sm md:block fixed top-0 inset-x-0 shadow z-50">
      <div className="sidebar-menu overflow-y-auto overflow-x-hidden hidden md:block fixed top-0 left-0 h-full text-white font-light bg-sky-900 md:w-20 lg:w-64 z-50">
        {/* SIDEBAR MENU */}
        <div className="flex items-center justify-center h-16 lg:w-64 bg-white md:bg-sky-900 lg:-mb-4">
          <div onClick={props.hideDropdown}>
            <a href="/">
              <img
                src={images.WhiteLogo}
                alt="Unxpose"
                className="cursor-pointer w-40 hidden lg:block md:mx-0"
              />
              <img
                src={images.BlackLogo}
                alt="Unxpose"
                className="cursor-pointer w-40 md:hidden md:mx-0"
              />
              <img
                src={images.Favicon}
                alt="Unxpose"
                className="hidden md:block lg:hidden w-20"
              />
            </a>
          </div>
        </div>

        <div
          data-testid="menu-dropdown"
          className="flex flex-wrap items-center truncate border border-sky-800 rounded-lg cursor-pointer z-40 m-4 p-2 "
          onClick={props.toggleDropdown}>
          {userState && userState.company ? (
            <>
              <CompanyLogo
                logoUrl={userState.company.logo}
                logoClasses="w-8 h-8 lg:w-12 lg:h-12 bg-white rounded"
              />

              <div className="hidden relative lg:flex flex-wrap flex-1 w-8/12 px-4 text-sm">
                <span className="w-full truncate uppercase font-normal">
                  {userState.company.name}
                </span>
                <span className="w-full text-sm">{userState.firstName}</span>

                <figure className="hidden lg:flex absolute top-0 bottom-0 right-0 items-center w-4">
                  <images.Chevron
                    width="10"
                    direction={props.dropdown ? 'down' : 'up'}
                  />
                </figure>
              </div>
            </>
          ) : (
            <i
              className={`${
                props.dropdown ? 'icofont-curved-down' : 'icofont-curved-up'
              }  mx-auto w-4`}></i>
          )}

          <div
            className={`${props.dropdown} lg:w-full bg-sky-900 fixed left-0 top-0 lg:relative mt-16 lg:mt-0 lg:ml-0 ml-20`}>
            <p className="text-sm font-normal truncate px-4 border-b border-sky-800 py-2 lg:mt-2 cursor-default">
              {userState.email}
            </p>
            <Link
              data-testid="settings-menu"
              to="/settings/company"
              className="uppercase flex items-center group border-b border-sky-800 py-1">
              <button
                type="button"
                className="w-full text-left font-light f rounded group-hover:bg-sky-800 py-1 pl-4"
                onClick={props.hideDropdown}>
                {i18next.t('pageTitles.settings')}
              </button>
            </Link>
            <Link
              data-testid="subscription-menu"
              to="/subscription"
              className="uppercase flex items-center group border-b border-sky-800 py-1">
              <button
                type="button"
                className="w-full text-left font-light f rounded group-hover:bg-sky-800 py-1 pl-4"
                onClick={props.hideDropdown}>
                {planUtils.userHasPremiumPlan()
                  ? i18next.t('dropdownMenu.subscriptionPremium')
                  : i18next.t('dropdownMenu.subscriptionFree')}
              </button>
            </Link>

            {hasMultipleCompanies(userState) && (
              <Link
                to="/switch-company"
                className="uppercase flex items-center group border-b border-sky-800 py-1">
                <button
                  type="button"
                  className="w-full text-left font-light f rounded group-hover:bg-sky-800 py-1 pl-4"
                  onClick={props.hideDropdown}>
                  {i18next.t('dropdownMenu.switchCompany')}
                </button>
              </Link>
            )}
            <Link
              to="/logout"
              className="flex items-center rounded hover:bg-sky-800 py-1 mt-1 pl-4">
              <button
                type="button"
                className="font-light md:my-1 lg:my-0"
                onClick={props.hideDropdown}>
                {i18next.t('dropdownMenu.logout')}
              </button>
            </Link>
          </div>
        </div>

        <ul id="menu-items" className="w-full menu leading-none">
          {getMenuItems()}
        </ul>

        <PlanInfoMenu />
      </div>
      <HelpMenu
        showHelp={showHelp}
        setShowHelp={setShowHelp}
        setShowIntro={props.setShowIntro}
      />
      <div className="fixed md:hidden top-0 left-0 w-full bg-sky-900 z-100">
        {/* RESPONSIVE MENU */}
        <div className="block md:hidden">
          <div className="flex justify-between items-center px-4 pt-6 pb-4">
            <img
              src={images.WhiteLogo}
              alt="Unxpose"
              className="cursor-pointer w-32"
            />
            <button
              type="button"
              className="text-white mb-2"
              onClick={props.toggleDropdown}>
              <svg
                className="fill-current h-4 w-4"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            className={
              'fixed top-0 left-0 mt-16 w-full h-screen overflow-y-auto bg-white pb-32' +
              (props.dropdown ? ' hidden' : '')
            }>
            <div className="p-4 text-gray-700 text-lg">
              <div className="flex justify-between mb-5">
                <SecondaryButton
                  text={
                    planUtils.userHasPremiumPlan()
                      ? i18next.t('dropdownMenu.subscriptionPremium')
                      : i18next.t('dropdownMenu.subscriptionFree')
                  }
                  onClick={() => navigate('/subscription')}
                  margin="mb-2"
                />

                {hasMultipleCompanies(userState) && (
                  <div className="flex justify-end">
                    <SecondaryButton
                      text={i18next.t('dropdownMenu.switchCompany')}
                      onClick={() => navigate('/switch-company')}
                    />
                  </div>
                )}
              </div>

              <div className="pb-6 pt-3">
                <div className="font-semibold text-sky-900 mb-2 text-sm uppercase">
                  {i18next.t('dropdownMenu.categories')}
                </div>
                <Link
                  to="/"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <images.DashboardIcon width="22" height="22" color={sky900} />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    {i18next.t('pageTitles.overview')}
                  </button>
                </Link>
                <Link
                  to="/internet-exposure"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <images.InternetExposureIcon
                    width="22"
                    height="22"
                    color={sky900}
                  />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    Internet exposure
                  </button>
                </Link>
                <Link
                  to="/dns"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <images.DnsIcon width="22" height="22" color={sky900} />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    DNS
                  </button>
                </Link>
                <Link
                  to="/network"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <images.NetworkIcon width="22" height="22" color={sky900} />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    Network
                  </button>
                </Link>
                <Link
                  to="/web"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <images.WebIcon width="22" height="22" color={sky900} />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    Web
                  </button>
                </Link>
                <Link
                  to="/cloud"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <images.CloudIcon width="22" height="22" color={sky900} />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    Cloud
                  </button>
                </Link>

                <Link
                  to="/people"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <images.PeopleIcon width="22" height="22" color={sky900} />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    People
                  </button>
                </Link>

                <Link
                  to="/management"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <images.ManagementIcon
                    width="22"
                    height="22"
                    color={sky900}
                  />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    {i18next.t('pageTitles.allFindings')}
                  </button>
                </Link>

                <Link
                  to="/reports"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <images.ReportIcon width="22" height="22" color={sky900} />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    {i18next.t('pageTitles.reports')}
                  </button>
                </Link>

                <Link
                  to="/marketplace"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <images.MarketplaceIcon
                    width="22"
                    height="22"
                    color={sky900}
                  />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    {i18next.t('pageTitles.marketplace')}
                  </button>
                </Link>

                <Link
                  to="/suppliers"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <images.SuppliersIcon width="22" height="22" color={sky900} />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    {i18next.t('pageTitles.suppliers')}
                  </button>
                </Link>

                <Link
                  to="/questionnaires"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <i className="icofont-list text-lg text-sky-900" />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    {i18next.t('pageTitles.questionnaires')}
                  </button>
                </Link>

                <Link
                  to="/documents"
                  className="flex items-center hover:text-sky-900 px-2 py-4">
                  <i className="icofont-ui-folder text-lg text-sky-900" />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    {i18next.t('pageTitles.documents')}
                  </button>
                </Link>
              </div>

              <div className="pb-6 pt-3">
                <div className="font-semibold text-sky-900 mb-2 text-sm uppercase">
                  {i18next.t('pageTitles.settings')}
                </div>
                <Link
                  to="/settings/profile"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <i className="icofont-user text-sky-900" />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    {i18next.t('dropdownMenu.profile')}
                  </button>
                </Link>
                <Link
                  to="/settings/company"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <i className="icofont-settings-alt text-sky-900" />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    {i18next.t('dropdownMenu.companySettings')}
                  </button>
                </Link>
                <Link
                  to="/settings/security"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <i className="icofont-ssl-security text-sky-900" />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    {i18next.t('dropdownMenu.security')}
                  </button>
                </Link>
                <Link
                  to="/settings/notifications"
                  className="flex items-center hover:text-sky-900 px-2 py-4 border-b">
                  <i className="icofont-notification text-sky-900" />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    {i18next.t('dropdownMenu.notifications')}
                  </button>
                </Link>

                <Link
                  to="/settings/webhooks"
                  className="flex items-center hover:text-sky-900 px-2 py-4">
                  <i className="icofont-user text-sky-900" />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    Webhooks
                  </button>
                </Link>

                <Link
                  to="/logout"
                  className="flex items-center hover:hover:text-sky-900 px-2 py-4">
                  <i className="icofont-exit text-sky-900" />
                  <button
                    type="button"
                    className="ml-4"
                    onClick={props.hideDropdown}>
                    {i18next.t('dropdownMenu.logout')}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

const MenuItem = ({icon, id, testId, isActive, isSubItem, name, path}) => {
  const listItemContainerStyle =
    'group dm-mono uppercase text-xs flex items-center lg:justify-start justify-center relative rounded py-2 px-3 lg:px-1 lg:mx-4 mb-1'

  return (
    <Link to={path} id={id} data-testid={testId}>
      <li
        className={
          listItemContainerStyle + (isActive ? ' active' : ' hover:bg-sky-800')
        }>
        {isSubItem && (
          <div className="absolute hidden lg:block left-0 bg-white w-2 opacity-25 border-white border-opacity-25 h-px ml-px " />
        )}

        <figure
          className={
            'opacity-50 group-hover:opacity-100 px-2' +
            (isActive ? ' active' : '')
          }>
          {icon}
        </figure>
        <span className="hidden lg:block">{name}</span>
      </li>
    </Link>
  )
}

MenuItem.propTypes = {
  id: PropTypes.string,
  testId: PropTypes.string,
  icon: PropTypes.object,
  isActive: PropTypes.bool,
  isSubItem: PropTypes.bool,
  name: PropTypes.string,
  path: PropTypes.string,
}

Menu.propTypes = {
  dropdown: PropTypes.string,
  hideDropdown: PropTypes.func,
  location: PropTypes.object,
  pathname: PropTypes.object,
  props: PropTypes.object,
  toggleDropdown: PropTypes.func,
  setShowIntro: PropTypes.func,
}

export default Menu
