import React from 'react'
import i18next from 'i18next'
import {
  getSeverityColor,
  getSeverityLabel,
  getSeverityString,
} from 'utils/score'
import {getPropByLocale} from 'utils'
import {statusList} from 'constant'
import Timestamp from 'components/partials/Timestamp'

const getCustomTimestamp = value => {
  return value && value !== 'N/A' && value !== 'not_supported' ? (
    <Timestamp date={new Date(value)} fromNow />
  ) : (
    '—'
  )
}

export const findingsColumns = [
  {
    header: i18next.t('tables.title'),
    accessorKey: 'title',
    meta: {align: 'left'},
    minSize: 800,
    cell: cell => (
      <div className="flex items-baseline">
        <div
          className={`${getSeverityColor(
            getSeverityString(cell.row.original.severity)
          )} severity-indicator inline-flex flex-shrink-0 mr-3`}
        />
        <div>{getPropByLocale(cell.row.original.title)}</div>
      </div>
    ),
  },
  {
    header: 'Resource ID',
    accessorKey: 'resourceId',
    maxSize: 200,
    cell: cell => <div className="w-92 truncate">{cell.getValue()}</div>,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: cell => statusList[cell.getValue()],
  },
  {
    header: i18next.t('tables.severity'),
    accessorKey: 'severity',
    cell: cell => getSeverityLabel(getSeverityString(cell.getValue())),
  },
  {
    header: i18next.t('tables.seen'),
    accessorKey: 'id',
    cell: cell => {
      const moment =
        cell.row.original.lastNmapScan ||
        cell.row.original.checkedAt ||
        cell.row.original.updatedAt

      return <Timestamp date={new Date(moment)} fromNow />
    },
    sortingFn: (a, b) => {
      return new Date(a.original.updatedAt) - new Date(b.original.updatedAt)
    },
  },
]

export const subscriptionsColumns = [
  {
    header: 'ID',
    accessorKey: 'resourceId',
  },
  {
    header: 'Display name',
    accessorKey: 'data.displayName',
  },
  {
    header: 'Tenant',
    accessorKey: 'data.tenantId',
  },
  {
    header: 'Status',
    accessorKey: 'data.state',
  },
]

export const mysqlServersColumns = [
  {
    header: 'Name',
    accessorKey: 'data.name',
  },
  {
    header: 'FQDN',
    accessorKey: 'data.fullyQualifiedDomainName',
  },
  {
    header: 'Resource group',
    accessorKey: 'resourceGroup',
  },
  {
    header: 'Location',
    accessorKey: 'data.location',
  },
  {
    header: 'Storage (MB)',
    accessorKey: 'data.storageProfile.storageMB',
  },
]

export const postgresqlServersColumns = [
  {
    header: 'Name',
    accessorKey: 'data.name',
  },
  {
    header: 'FQDN',
    accessorKey: 'data.fullyQualifiedDomainName',
  },
  {
    header: 'Resource group',
    accessorKey: 'resourceGroup',
  },
  {
    header: 'Location',
    accessorKey: 'data.location',
  },
  {
    header: 'Storage (MB)',
    accessorKey: 'data.storageProfile.storageMB',
  },
]

export const resourceGroupsColumns = [
  {
    header: 'Name',
    accessorKey: 'data.name',
  },
  {
    header: 'Location',
    accessorKey: 'data.location',
  },
  {
    header: 'Subscription',
    accessorKey: 'subscriptionId',
  },
]

export const sqlDatabasesColumns = [
  {
    header: 'Name',
    accessorKey: 'data.name',
  },
  {
    header: 'Resource Group',
    accessorKey: 'resourceGroup',
  },
  {
    header: 'Server name',
    accessorKey: 'data.serverName',
  },
]

export const sqlServersColumns = [
  {
    header: 'Name',
    accessorKey: 'data.name',
  },
  {
    header: 'Resource Group',
    accessorKey: 'resourceGroup',
  },
  {
    header: 'Location',
    accessorKey: 'data.location',
  },
  {
    header: 'Type',
    accessorKey: 'data.type',
  },
]

export const storageAccountsColumns = [
  {
    header: 'Name',
    accessorKey: 'data.name',
  },
  {
    header: 'Resource Group',
    accessorKey: 'resourceGroup',
  },
  {
    header: 'Kind',
    accessorKey: 'data.kind',
  },
  {
    header: 'Status',
    accessorKey: 'data.statusOfPrimary',
  },
  {
    header: 'Created at',
    accessorKey: 'data.creationTime',
    cell: cell => getCustomTimestamp(cell.getValue()),
  },
]

export const blobContainersColumns = [
  {
    header: 'Name',
    accessorKey: 'data.name',
  },
  {
    header: 'Resource Group',
    accessorKey: 'resourceGroup',
  },
  {
    header: 'Status',
    accessorKey: 'data.leaseState',
  },
  {
    header: 'Last modified time',
    accessorKey: 'data.lastModifiedTime',
    cell: cell => getCustomTimestamp(cell.getValue()),
  },
]

export const vmInstancesColumns = [
  {
    header: 'Name',
    accessorKey: 'data.name',
  },
  {
    header: 'Resource Group',
    accessorKey: 'resourceGroup',
  },
  {
    header: 'OS Type',
    accessorKey: 'data.storageProfile.osDisk.osType',
  },
  {
    header: 'SKU',
    accessorKey: 'data.storageProfile.imageReference.sku',
  },
  {
    header: 'VM Size',
    accessorKey: 'data.hardwareProfile.vmSize',
  },
]

export const disksColumns = [
  {
    header: 'Name',
    accessorKey: 'data.sku.name',
  },
  {
    header: 'OS type',
    accessorKey: 'data.osType',
  },
  {
    header: 'Disk state',
    accessorKey: 'data.diskState',
  },
  {
    header: 'Disk size (GB)',
    accessorKey: 'data.diskSizeGB',
  },
]
