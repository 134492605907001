import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {i18next} from 'translate/i18n'
import {useNavigate} from 'react-router-dom'
import {statusList} from 'constant'
import Timestamp from 'components/partials/Timestamp'
import FindingIcon from 'res/icons/findingIcon'

import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import {
  getSeverityColor,
  getSeverityLabel,
  getSeverityString,
} from 'utils/score'

export default function FindingsTable({data, domainId}) {
  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      {
        header: i18next.t('tables.title'),
        accessorKey: 'title',
        cell: cell => (
          <div className="flex items-center justify-center">
            <div
              className={`${getSeverityColor(
                getSeverityString(cell.row.original.severity)
              )} severity-indicator flex-shrink-0 mr-2`}
            />
            <div>{cell.getValue()}</div>
          </div>
        ),
      },

      {
        header: 'Status',
        accessorKey: 'status',
        width: 60,
        cell: cell => statusList[cell.getValue()],
      },
      {
        header: i18next.t('tables.severity'),
        accessorKey: 'severity',
        width: 50,
        cell: cell => getSeverityLabel(getSeverityString(cell.getValue())),
      },
      {
        header: i18next.t('tables.seen'),
        accessorKey: 'checkedAt',
        width: 50,
        cell: cell => {
          const moment =
            cell.row.original.lastNmapScan ||
            cell.row.original.checkedAt ||
            cell.row.original.updatedAt

          return <Timestamp date={new Date(moment)} fromNow />
        },
        sortingFn: (a, b) => {
          return new Date(a.original.updatedAt) - new Date(b.original.updatedAt)
        },
      },
    ],
    []
  )

  return (
    <>
      <PrimaryTableV8
        data={data}
        columns={columns}
        detailPath={row => `/dns/finding/${row.original.id}`}
        emptyStateWithoutFilter={
          <GenericEmptyState
            icon={<FindingIcon width="55" color="#90A4AE" />}
            body={i18next.t('dns.noFindings')}
            buttonText={i18next.t('buttons.details')}
            linkPath={`/dns/domain/${domainId}`}
            bgColor="bg-astral-50"
            margin="m-2"
          />
        }
        hideSearch
        hidePagination
      />
      <SecondaryButton
        text={i18next.t('buttons.details')}
        size="sm"
        onClick={() => {
          navigate(`/dns/domain/${domainId}`)
        }}
        margin="mt-4 text-center"
        direction="right"
      />
    </>
  )
}

FindingsTable.propTypes = {
  data: PropTypes.array,
  domainId: PropTypes.string,
}
