import React, {useEffect, useState} from 'react'
import i18next from 'i18next'
import {useDispatch, useSelector} from 'react-redux'
import config from 'config'

import {Page} from 'components/partials/Page'
import BackButton from 'components/partials/buttons/BackButton'
import {CheckoutProvider} from '@stripe/react-stripe-js'
import Subsection from 'components/partials/headers/Subsection'
import {createCheckoutSession} from 'actions'
import Checkout from './Checkout'
import CheckoutLoader from './loadingState/CheckoutLoader'

export default function CheckoutWrapper() {
  const dispatch = useDispatch()

  const checkoutSessionState = useSelector(state => state.stripeCheckoutSession)
  const csrfState = useSelector(state => state.csrf)

  const [stripeInstance, setStripeInstance] = useState(null)
  const [checkoutReady, setCheckoutReady] = useState(false)

  useEffect(() => {
    import('@stripe/stripe-js').then(({loadStripe}) => {
      loadStripe(config.stripePublishableKey, {
        betas: ['custom_checkout_beta_5'],
      }).then(instance => setStripeInstance(instance))
    })
  }, [])

  useEffect(() => {
    dispatch(
      createCheckoutSession({
        _csrf: csrfState,
      })
    )
  }, [])

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (document.getElementById('stripe-checkout-container')) {
        setCheckoutReady(true)
        observer.disconnect()
      }
    })

    observer.observe(document.body, {childList: true, subtree: true})

    return () => observer.disconnect()
  }, [])

  return (
    <Page pageTitle={i18next.t('pageTitles.subscription')}>
      <BackButton />

      <Subsection
        title={i18next.t('subscription.checkout.title')}
        margin="mt-4">
        {checkoutSessionState ? (
          <CheckoutProvider
            options={{
              clientSecret: checkoutSessionState.clientSecret,
            }}
            stripe={stripeInstance}>
            <Checkout />
          </CheckoutProvider>
        ) : (
          <CheckoutLoader />
        )}

        {checkoutSessionState && !checkoutReady && <CheckoutLoader />}
      </Subsection>
    </Page>
  )
}
