import {
  checkCheckoutSessionDone,
  createCheckoutSessionDone,
  fetchUser,
  genericError,
  success,
  updateSubscriptionDone,
} from 'actions'
import {handler} from './handler'
import config from 'config'
import i18next from 'i18next'

export function* handleCreateCheckoutSession(action) {
  yield handler({
    handler: _createCheckoutSession,
    error: genericError,
    success: createCheckoutSessionDone,
    action,
  })
}

const _createCheckoutSession = function (body) {
  return fetch(config.SERVER_URL + '/stripe/checkout', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleCheckCheckoutSession(action) {
  yield handler({
    handler: _checkCheckoutSession,
    error: genericError,
    success: checkCheckoutSessionDone,
    action,
  })
}

const _checkCheckoutSession = function (body) {
  return fetch(
    `${config.SERVER_URL}/stripe/checkout/sessions/${body.sessionId}`,
    {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdateSubscription(action) {
  yield handler({
    handler: _updateSubscription,
    error: genericError,
    success: [
      () =>
        success({
          message: i18next.t('subscription.update.messages.updateSuccess'),
        }),
      updateSubscriptionDone,
      fetchUser,
    ],
    action,
  })
}

const _updateSubscription = function (body) {
  return fetch(`${config.SERVER_URL}/subscriptions/${body.subscriptionId}`, {
    method: 'PATCH',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}
