import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {i18next} from 'translate/i18n'
import {useSelector, useDispatch} from 'react-redux'

import {
  fetchBreaches,
  fetchCompanyStats,
  fetchGoogleWorkspaceIntegrations,
  fetchMicrosoft365Integrations,
  loading,
} from 'actions'
import config from 'config'
import {getFeatureSubscription, isLoading} from 'utils'

import Accounts from 'components/people/Accounts'
import BreachesSummary from './breaches/BreachesSummary'
import ScoreBox from 'components/partials/ScoreBox'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import Spinner from 'components/partials/Spinner'
import StatsBox from 'components/partials/StatsBox'
import PeopleIntro from './PeopleIntro'
import {Page} from 'components/partials/Page'
import PlanUtils from 'utils/plan'
import PeopleEmptyState from './PeopleEmptyState'

const People = () => {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const loadingState = useSelector(state => state.loading)
  const googleWorkspaceIntegrationsState = useSelector(
    state => state.googleWorkspaceIntegrations
  )
  const microsoft365IntegrationsState = useSelector(
    state => state.microsoft365Integrations
  )
  const companyEmailsState = useSelector(state => state.companyEmails)

  const planUtils = PlanUtils()

  const integrationsDataWasFetched =
    googleWorkspaceIntegrationsState &&
    microsoft365IntegrationsState &&
    loadingState &&
    !isLoading(loadingState.googleWorkspaceIntegrations) &&
    !isLoading(loadingState.microsoft365Integrations)

  const companyEmailsDataWasFetched = !isLoading(loadingState.companyEmails)

  const hasCompanyEmails = () => companyEmailsState?.data.length > 0

  const hasActiveIntegration = () => {
    if (
      (googleWorkspaceIntegrationsState?.length &&
        googleWorkspaceIntegrationsState.find(i => i.status === 'active')) ||
      (microsoft365IntegrationsState?.length &&
        microsoft365IntegrationsState.find(i => i.status === 'active'))
    )
      return true

    return false
  }

  const hasIntegration = () =>
    googleWorkspaceIntegrationsState?.length ||
    microsoft365IntegrationsState?.length

  const hasAccessToFeature = () =>
    planUtils.userHasPremiumPlan() &&
    getFeatureSubscription(userState, 'idpIntegrations').hasAccess

  useEffect(() => {
    dispatch(
      fetchCompanyStats({companyId: userState.company.id, type: 'people'})
    )

    if (userState && userState.company && !planUtils.hasPlanRestriction()) {
      dispatch(fetchBreaches())
    }
  }, [dispatch, userState])

  useEffect(() => {
    dispatch(
      loading({
        googleWorkspaceIntegrations: true,
        microsoft365Integrations: true,
        companyEmails: true,
      })
    )
    dispatch(fetchGoogleWorkspaceIntegrations())
    dispatch(fetchMicrosoft365Integrations())
  }, [])

  const HeaderButton = () => {
    let buttonId, redirectUrl, buttonLabel, crownIcon
    if (hasIntegration()) {
      buttonId = 'manage-integration-button'
      redirectUrl = '/people/integrations'
      buttonLabel = i18next.t('googleWorkspace.manageIntegrations')
      crownIcon = false
    } else {
      buttonId = 'new-integration-button'
      redirectUrl = '/people/integrations/new'
      buttonLabel = i18next.t('googleWorkspace.newIntegration')
      crownIcon = !hasAccessToFeature()
    }

    return (
      <SecondaryButton
        id={buttonId}
        redirect={redirectUrl}
        text={buttonLabel}
        direction="right"
        crownIcon={crownIcon}
      />
    )
  }

  const renderPeopleContent = () => {
    if (!hasAccessToFeature()) {
      return <PeopleEmptyState />
    }

    if (!integrationsDataWasFetched) return <Spinner />

    return (
      <>
        {!hasActiveIntegration() && <PeopleEmptyState />}
        <Accounts />
        <BreachesSummary />
        {companyEmailsDataWasFetched && (
          <PeopleIntro
            hasCompanyEmails={hasCompanyEmails()}
            hasActiveIntegration={hasActiveIntegration()}
          />
        )}
      </>
    )
  }

  return (
    <Page
      pageTitle={i18next.t('pageTitles.people')}
      button={integrationsDataWasFetched && <HeaderButton />}
      helpPath="people">
      <>
        <PeopleStats displayMessage={hasAccessToFeature} />

        {renderPeopleContent()}
      </>
    </Page>
  )
}

const PeopleStats = ({displayMessage}) => {
  const statsPeopleState = useSelector(state => state.statsPeople)

  return (
    <div className="lg:flex lg:flex-wrap w-full mb-8">
      {displayMessage() && <StatusMessage />}
      <div className="grid md:grid-cols-4 xl:grid-cols-7 gap-4 w-full">
        <StatsBox
          classContainer="md:col-span-4 xl:col-span-2"
          slots={1}
          title={`/${i18next.t('people.accounts')}`}
          data={statsPeopleState && statsPeopleState.totalAccounts}
          size="xs"
        />

        <StatsBox
          classContainer="md:col-span-3 xl:col-span-3"
          slots={2}
          title={`/${i18next.t('misc.findings')}`}
          label={i18next.t('people.breaches')}
          data={statsPeopleState && statsPeopleState.affectedUsers}
          secondaryLabel={i18next.t('people.tooltips.mfaDisabled')}
          secondaryData={statsPeopleState && statsPeopleState.totalMfaDisabled}
        />

        <ScoreBox
          classContainer="xl:col-span-2"
          score={statsPeopleState && statsPeopleState.scores}
        />
      </div>
    </div>
  )
}

const StatusMessage = () => {
  const state = useSelector(state => state)

  const statusOptions = {
    failedIntegration: {
      highlight: i18next.t(
        'googleWorkspace.failedIntegration.failedIntegration'
      ),
      redirectLink: `${config.CLIENT_URL}/people/integrations/`,
      text: i18next.t('googleWorkspace.failedIntegration.seeErrorDetails'),

      textColor: 'text-red-700',
      bgColor: 'bg-red-100',
      borderColor: 'border-red-400',
    },
    updateRequired: {
      highlight: i18next.t('googleWorkspace.updateRequired'),
      redirectLink: `${config.CLIENT_URL}/people/integrations/`,
      text: i18next.t('googleWorkspace.clickHereToManageUpdates'),

      textColor: 'text-purple-700',
      bgColor: 'bg-purple-100',
      borderColor: 'border-purple-400',
    },
    startedIntegration: {
      highlight: i18next.t(
        'googleWorkspace.startedIntegration.integrationInProgress'
      ),
      redirectLink: `${config.CLIENT_URL}/people/integrations/`,
      text: i18next.t('googleWorkspace.startedIntegration.comeBackInMinutes'),

      textColor: 'text-teal-500',
      bgColor: 'bg-teal-100',
      borderColor: 'border-teal-400',
    },
  }

  const Toast = ({content}) => {
    const [visibility, setVisibility] = useState('block')

    return (
      <div
        className={`${content.textColor} ${content.bgColor} ${content.borderColor} ${visibility} flex relative border rounded text-xs mb-4 px-4 py-3`}
        role="alert">
        <a href={content.redirectLink} className="flex">
          <strong className="font-bold">{content.highlight}</strong>
          &nbsp;
          <span className="block sm:inline">{content.text}</span>
        </a>
        <figure className="ml-auto" onClick={() => setVisibility('hidden')}>
          <svg
            className={`fill-current h-5 w-5 ${content.textColor}`}
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20">
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </figure>
      </div>
    )
  }

  return (
    state.googleWorkspaceIntegrations && (
      <div className="w-full">
        {state.googleWorkspaceIntegrations.find(
          item => item.shouldUpdate && item.status === 'active'
        ) && <Toast content={statusOptions.updateRequired} />}

        {state.googleWorkspaceIntegrations.find(
          item => item.status === 'started'
        ) && <Toast content={statusOptions.startedIntegration} />}

        {state.googleWorkspaceIntegrations.find(
          item => item.status === 'failed'
        ) && <Toast content={statusOptions.failedIntegration} />}
      </div>
    )
  )
}

export default People

PeopleStats.propTypes = {
  hasActiveIntegration: PropTypes.bool,
  displayMessage: PropTypes.func,
}

StatusMessage.propTypes = {
  content: PropTypes.string,
}
