import React, {useEffect, useState} from 'react'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import images from 'res'

import SectionAdvantages from 'components/partials/SectionAdvantages'
import PlanUtils from 'utils/plan'
import PaperForm from 'components/partials/PaperForm'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {useDispatch} from 'react-redux'
import {showAutoSubscriptionModal} from 'actions'
import {sky900} from 'constant'

export default function SuppliersEmptyState({handleAddSupplier}) {
  const dispatch = useDispatch()

  const [showPaperForm, setShowPaperform] = useState(false)

  const planUtils = PlanUtils()

  const isPremiumPlan = planUtils.userHasPremiumPlan()

  useEffect(() => {
    document.addEventListener('mouseup', e => {
      const modal = document.querySelector('#suppliersSalesModal')
      if (e.target === modal) setShowPaperform(false)
    })
  }, [])

  return (
    <>
      <div className="w-full flex-wrap bg-white text-center border border-sideral-100 font-medium rounded-md p-4 my-8">
        <SectionAdvantages
          headerIcon={
            <images.SuppliersIcon width="40" height="40" color={sky900} />
          }
          headerTitle={
            isPremiumPlan
              ? i18next.t('suppliers.noResults')
              : i18next.t('suppliers.unavailable')
          }
          title={i18next.t('suppliers.addSupplierBtn.title')}
          advantagesItems={[
            i18next.t('suppliers.addSupplierBtn.listItems.first'),
            i18next.t('suppliers.addSupplierBtn.listItems.second'),
            i18next.t('suppliers.addSupplierBtn.listItems.third'),
          ]}
          actionButtons={
            <PrimaryButton
              text={
                isPremiumPlan
                  ? i18next.t('suppliers.addNewBtn')
                  : i18next.t('buttons.startForFree')
              }
              onClick={() =>
                isPremiumPlan
                  ? handleAddSupplier()
                  : dispatch(showAutoSubscriptionModal(true))
              }
            />
          }
          rightSideImage={
            <img
              className="max-w-lg m-auto"
              src={images.suppliersPlaceholder}
            />
          }
        />
      </div>

      {showPaperForm && (
        <div
          id="suppliersSalesModal"
          className="modal-bg fixed top-0 left-0 w-screen h-screen z-50">
          <div
            className={`fixed bg-white border rounded-md bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 max-w-lg w-full p-8`}>
            <PaperForm
              classes="relative"
              title={
                isPremiumPlan
                  ? i18next.t('planInfo.paid.paperformTitle')
                  : i18next.t('planInfo.free.paperformTitle')
              }
              onClickBack={() => setShowPaperform(false)}
              useCloseIcon
            />
          </div>
        </div>
      )}
    </>
  )
}

SuppliersEmptyState.propTypes = {
  handleAddSupplier: PropTypes.func,
}
