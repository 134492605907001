import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {DefaultModal} from 'components/partials/DefaultModal'
import {Trans} from 'react-i18next'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {companyRenewal} from 'actions'
import PaperForm from 'components/partials/PaperForm'

export default function BillingProviderModal({setModal}) {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)

  const [showPaperform, setShowPaperform] = useState(false)

  return (
    <DefaultModal
      id="billing-provider-modal"
      title={i18next.t(
        'subscription.update.products.billingProviderModal.title'
      )}
      body={
        showPaperform ? (
          <PaperForm
            classes="relative"
            title={i18next.t(
              'subscription.update.products.billingProviderModal.paperformTitle'
            )}
            onClickBack={() => setShowPaperform(false)}
            hideButtons
          />
        ) : (
          <div className="text-sm text-gray-700 leading-6">
            <Trans
              i18nKey="subscription.update.products.billingProviderModal.body"
              components={{
                a: (
                  <a
                    href="mailto: sales@unxpose.com"
                    className="text-sky-900 font-medium"
                    rel="noreferrer"
                  />
                ),
              }}
            />

            <div className="w-full mt-6">
              <PrimaryButton
                text={i18next.t(
                  'subscription.update.products.billingProviderModal.talkToSalesBtn'
                )}
                type="submit"
                size="full"
                margin="mb-2"
                onClick={() => {
                  setShowPaperform(true)
                  dispatch(
                    companyRenewal({
                      _csrf: csrfState,
                      companyId: userState.company.id,
                      element: 'talkToSales',
                      source: 'subscriptionUpgradeModal',
                    })
                  )
                }}
              />
            </div>
          </div>
        )
      }
      onCloseModal={() => setModal(false)}
      width="max-w-xl"
    />
  )
}

BillingProviderModal.propTypes = {
  setModal: PropTypes.func,
}
