import React from 'react'
import PropTypes from 'prop-types'

export default function SecurityShield({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      fill={color || '#05852E'}
      viewBox="0 0 19 23"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M8.25313 15.5825L14.9625 9.085L13.2703 7.44625L8.25313 12.305L5.75938 9.89L4.06719 11.5288L8.25313 15.5825ZM9.5 23C6.74896 22.3292 4.47786 20.8006 2.68672 18.4144C0.895573 16.0281 0 13.3783 0 10.465V3.45L9.5 0L19 3.45V10.465C19 13.3783 18.1044 16.0281 16.3133 18.4144C14.5221 20.8006 12.251 22.3292 9.5 23Z" />
    </svg>
  )
}

SecurityShield.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
