import config from '../config'
import {handler} from './handler'
import i18next from 'i18next'
import {
  fetchCloudIntegrations,
  fetchGcpBucketsDone,
  fetchGcpFinding,
  fetchGcpFindingActivity,
  fetchGcpFindingActivityDone,
  fetchGcpFindingDone,
  fetchGcpFindingsByAssetDone,
  fetchGcpFindingsDone,
  fetchGcpInstancesDone,
  fetchGcpIntegrationDone,
  fetchGcpIntegrationsDone,
  fetchGcpKmsDone,
  fetchGcpMostCriticalDone,
  fetchGcpApiKeysDone,
  fetchGcpCloudRunServicesDone,
  fetchGcpPrincipalsDone,
  fetchGcpServiceAccountKeysDone,
  fetchGcpServiceAccountsDone,
  fetchGcpSqlInstancesDone,
  fetchGcpStatsDone,
  genericError,
  redirectFeedback,
  stopGcpIntegrationDone,
  success,
  updateGcpIntegrationVersionDone,
  retryGcpIntegrationDone,
  loading,
  toggleRestrictionModal,
} from '../actions'

export function* handleIntegrateGcp(action) {
  yield handler({
    handler: integrateGcp,
    error: [
      () => loading({gcpIntegration: false}),
      res => {
        switch (res.code) {
          case 'SUBSCRIPTION_LIMIT':
            return toggleRestrictionModal({
              contentId: 'cloudIntegrationTutorialBtn',
              showModal: true,
            })
        }
        return genericError({message: res.message})
      },
    ],
    success: [
      success,
      () => {
        setTimeout(() => {
          window.location.href = '/cloud'
        }, 2000)
      },
    ],
    action,
  })
}

const integrateGcp = function (body) {
  return fetch(config.SERVER_URL + '/gcp/integrate', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleStopGcpIntegration(action) {
  yield handler({
    handler: stopGcpIntegration,
    error: genericError,
    success: [
      () => {
        // Avoid unnecessary redirect flag setting to prevent status message display on a different component.
        if (
          window.location.pathname === '/cloud' ||
          window.location.pathname === '/cloud/'
        )
          return success({message: i18next.t('cloud.gcp.integrationRemoved')})

        return redirectFeedback({
          path: '/cloud',
          status: 'success',
          message: i18next.t('cloud.gcp.integrationRemoved'),
        })
      },
      stopGcpIntegrationDone,
      fetchCloudIntegrations,
    ],
    action,
  })
}

const stopGcpIntegration = function (body) {
  return fetch(`${config.SERVER_URL}/gcp/${body.integrationId}`, {
    method: 'DELETE',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpStats(action) {
  yield handler({
    handler: fetchGcpStats,
    error: genericError,
    success: fetchGcpStatsDone,
    action,
  })
}

const fetchGcpStats = function (projectId) {
  const url = projectId
    ? `${config.SERVER_URL}/gcp/${projectId}/stats`
    : `${config.SERVER_URL}/gcp/stats`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpBuckets(action) {
  yield handler({
    handler: fetchGcpBuckets,
    error: genericError,
    success: fetchGcpBucketsDone,
    action,
  })
}

const fetchGcpBuckets = function (projectId) {
  return fetch(`${config.SERVER_URL}/gcp/${projectId}/assets/buckets`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpApiKeys(action) {
  yield handler({
    handler: fetchGcpApiKeys,
    error: genericError,
    success: fetchGcpApiKeysDone,
    action,
  })
}

const fetchGcpApiKeys = function (projectId) {
  return fetch(`${config.SERVER_URL}/gcp/${projectId}/assets/apiKeys`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpCloudRunServices(action) {
  yield handler({
    handler: fetchGcpCloudRunServices,
    error: genericError,
    success: fetchGcpCloudRunServicesDone,
    action,
  })
}

const fetchGcpCloudRunServices = function (projectId) {
  return fetch(
    `${config.SERVER_URL}/gcp/${projectId}/assets/cloudRunServices`,
    {
      method: 'GET',
      credentials: 'include',
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpPrincipals(action) {
  yield handler({
    handler: fetchGcpPrincipals,
    error: genericError,
    success: fetchGcpPrincipalsDone,
    action,
  })
}

const fetchGcpPrincipals = function (projectId) {
  return fetch(`${config.SERVER_URL}/gcp/${projectId}/assets/principals`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpFetchServiceAccounts(action) {
  yield handler({
    handler: fetchGcpServiceAccounts,
    error: genericError,
    success: fetchGcpServiceAccountsDone,
    action,
  })
}

const fetchGcpServiceAccounts = function (projectId) {
  return fetch(`${config.SERVER_URL}/gcp/${projectId}/assets/serviceAccounts`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpServiceAccountKeys(action) {
  yield handler({
    handler: fetchGcpServiceAccountKeys,
    error: genericError,
    success: fetchGcpServiceAccountKeysDone,
    action,
  })
}

const fetchGcpServiceAccountKeys = function (projectId) {
  return fetch(
    `${config.SERVER_URL}/gcp/${projectId}/assets/serviceAccountKeys`,
    {
      method: 'GET',
      credentials: 'include',
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpFinding(action) {
  yield handler({
    action,
    error: genericError,
    handler: _fetchGcpFinding,
    success: fetchGcpFindingDone,
  })
}

const _fetchGcpFinding = findingId => {
  return fetch(`${config.SERVER_URL}/gcp/finding/${findingId}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  })
}

export function* handleFetchGcpFindings(action) {
  yield handler({
    handler: fetchGcpFindings,
    error: genericError,
    success: fetchGcpFindingsDone,
    action,
  })
}

const fetchGcpFindings = function (projectId) {
  return fetch(`${config.SERVER_URL}/gcp/${projectId}/findings`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpFindingsByAsset(action) {
  yield handler({
    handler: _fetchGcpFindingsByAsset,
    success: fetchGcpFindingsByAssetDone,
    action,
  })
}

const _fetchGcpFindingsByAsset = ({projectId, asset}) => {
  const url = `${config.SERVER_URL}/gcp/${projectId}/${asset}/findings`
  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpMostCritical(action) {
  yield handler({
    handler: fetchGcpMostCritical,
    error: genericError,
    success: fetchGcpMostCriticalDone,
    action,
  })
}

const fetchGcpMostCritical = function (projectId) {
  return fetch(`${config.SERVER_URL}/gcp/${projectId}/findings/mostCritical`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpInstances(action) {
  yield handler({
    handler: fetchGcpInstances,
    error: genericError,
    success: fetchGcpInstancesDone,
    action,
  })
}

const fetchGcpInstances = function (projectId) {
  return fetch(`${config.SERVER_URL}/gcp/${projectId}/assets/instances`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpKms(action) {
  yield handler({
    handler: fetchGcpKms,
    error: genericError,
    success: fetchGcpKmsDone,
    action,
  })
}

const fetchGcpKms = function (projectId) {
  return fetch(`${config.SERVER_URL}/gcp/${projectId}/assets/cryptoKeys`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpIntegration(action) {
  yield handler({
    handler: _fetchGcpIntegration,
    error: () => {
      window.location.href = '/404'
    },
    success: fetchGcpIntegrationDone,
    action,
  })
}

const _fetchGcpIntegration = function (integrationId) {
  return fetch(`${config.SERVER_URL}/gcp/integrations/${integrationId}`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpIntegrations(action) {
  yield handler({
    handler: _fetchGcpIntegrations,
    error: genericError,
    success: fetchGcpIntegrationsDone,
    action,
  })
}

const _fetchGcpIntegrations = function () {
  return fetch(config.SERVER_URL + '/gcp/integrations', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdateGcpFinding(action) {
  const {updateStatusMessage, findingId} = action.payload

  yield handler({
    action,
    error: genericError,
    handler: _updateGcpFinding,
    success: [
      () =>
        success({
          message: updateStatusMessage,
        }),
      () => fetchGcpFinding(findingId),
      () => fetchGcpFindingActivity(findingId),
    ],
  })
}

const _updateGcpFinding = change => {
  return fetch(
    `${config.SERVER_URL}/gcp/finding/${change.findingId}/${change.status}`,
    {
      body: JSON.stringify(change),
      credentials: 'include',
      headers: {'Content-Type': 'application/json'},
      method: 'PATCH',
    }
  )
}

export function* handleFetchGcpFindingActivity(action) {
  yield handler({
    handler: _fetchGcpFindingActivity,
    success: fetchGcpFindingActivityDone,
    error: genericError,
    action,
  })
}

const _fetchGcpFindingActivity = findingId => {
  const url = `${config.SERVER_URL}/gcp/finding/${findingId}/activity`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGcpSqlInstances(action) {
  yield handler({
    handler: fetchGcpSqlInstances,
    error: genericError,
    success: fetchGcpSqlInstancesDone,
    action,
  })
}

const fetchGcpSqlInstances = function (projectId) {
  return fetch(`${config.SERVER_URL}/gcp/${projectId}/assets/sqlInstances`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdateGcpIntegrationVersion(action) {
  yield handler({
    handler: _updateGcpIntegrationVersion,
    error: genericError,
    success: updateGcpIntegrationVersionDone,
    action,
  })
}

const _updateGcpIntegrationVersion = function (body) {
  return fetch(`${config.SERVER_URL}/gcp/integrations/${body.integrationId}`, {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'PATCH',
  }).catch(err => {
    console.trace(err)
  })
}

const retryGcpIntegration = function (body) {
  return fetch(`${config.SERVER_URL}/gcp/integrations/retry`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleRetryGcpIntegration(action) {
  yield handler({
    handler: retryGcpIntegration,
    error: genericError,
    success: [
      () => {
        // Avoid unnecessary redirect flag setting to prevent status message display on a different component.
        if (
          window.location.pathname === '/cloud' ||
          window.location.pathname === '/cloud/'
        )
          return success({message: i18next.t('cloud.gcp.integrationRetried')})

        return redirectFeedback({
          path: '/cloud',
          status: 'success',
          message: i18next.t('cloud.gcp.integrationRetried'),
        })
      },
      retryGcpIntegrationDone,
      fetchCloudIntegrations,
    ],
    action,
  })
}
