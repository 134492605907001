import React from 'react'
import PropTypes from 'prop-types'

export default function CollapseContentIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960">
      <path d="M440-440v240h-80v-160H200v-80h240Zm160-320v160h160v80H520v-240h80Z" />
    </svg>
  )
}

CollapseContentIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
