import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import {DefaultModal} from 'components/partials/DefaultModal'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import {useDispatch, useSelector} from 'react-redux'
import {addSupplier, loading} from 'actions'
import {isLoading} from 'utils'

export default function SupplierAddModal({modal, setModal}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)
  const addSupplierDoneState = useSelector(state => state.addSupplierDone)

  const initialFormState = {
    domain: modal.domain,
  }

  const [form, setForm] = useState(initialFormState)

  const handleInputChange = e => {
    setForm({...form, domain: e.target.value})
  }

  const handleSubmit = e => {
    e.preventDefault()

    dispatch(loading({addSupplier: true}))
    dispatch(
      addSupplier({
        domain: form.domain,
        _csrf: csrfState,
      })
    )
  }

  useEffect(() => {
    if (addSupplierDoneState)
      setModal({
        domain: null,
        showModal: false,
      })
  }, [addSupplierDoneState])

  return (
    <form onSubmit={handleSubmit}>
      <DefaultModal
        id="addSupplierModal"
        title={i18next.t('suppliers.addNew.title')}
        body={
          <div className="text-sm text-gray-700 leading-6 mb-8">
            <PrimaryInput
              text={i18next.t('misc.domain')}
              name="domain"
              type="text"
              value={form.domain}
              onChange={handleInputChange}
              autofocus
              required
            />
          </div>
        }
        primaryBtnText={i18next.t('buttons.add')}
        secondayBtnText={i18next.t('buttons.cancel')}
        onClickSecondaryBtn={() =>
          setModal({
            showModal: false,
          })
        }
        primaryButtonType="submit"
        primaryButtonLoading={isLoading(loadingState.addSupplier, false)}
      />
    </form>
  )
}

SupplierAddModal.propTypes = {
  modal: PropTypes.object,
  setModal: PropTypes.func,
}
