import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from './buttons/PrimaryButton'
import Spinner from './Spinner'

export const DefaultModal = ({
  id,
  title,
  body,
  bodyClasses,
  primaryBtnText,
  secondayBtnText,
  onClickPrimaryBtn,
  onClickSecondaryBtn,
  primaryBtnTooltip,
  secondaryBtnTooltip,
  disablePrimaryBtn,
  disableSecondaryBtn,
  primaryButtonTheme,
  primaryButtonType,
  primaryButtonLoading,
  primaryButtonIcon,
  loadingContent,
  onCloseModal,
  disableBackdropClose,
  width,
}) => {
  useEffect(() => {
    if (!onCloseModal || disableBackdropClose) return

    document.addEventListener('mouseup', e => {
      const modal = document.querySelector(`#${id}`)
      if (e.target === modal) onCloseModal()
    })
  }, [])

  return (
    <div
      id={id}
      className="modal-bg fixed top-0 left-0 w-screen h-screen z-[90]">
      <div
        className={`fixed bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 bg-white rounded-md shadow-md p-6 w-full ${
          width || 'max-w-lg'
        }`}>
        <div className="relative">
          <div className="flex justify-between items-start">
            {title && (
              <div
                id="modal-header"
                className="text-sideral-900 leading-none border-b border-sideral-50 text-lg pb-6 font-medium w-full">
                {title}
              </div>
            )}
            {onCloseModal && (
              <div className="z-50 ml-auto">
                <button type="button" onClick={() => onCloseModal()}>
                  <i className="icofont-close text-xl" />
                </button>
              </div>
            )}
          </div>

          {!loadingContent ? (
            <div className="mt-6">
              <div id="modal-body" className={bodyClasses}>
                {body}
              </div>

              {primaryBtnText && (
                <div
                  id="modal-footer"
                  className={`xs:flex gap-2 w-full mt-6 ${
                    !secondayBtnText && 'justify-end'
                  }`}>
                  <div className={`${secondayBtnText && 'w-full'}`}>
                    <PrimaryButton
                      text={primaryBtnText}
                      size="full"
                      theme={primaryButtonTheme}
                      onClick={onClickPrimaryBtn}
                      disabled={disablePrimaryBtn}
                      type={primaryButtonType}
                      loading={primaryButtonLoading}
                      iconClass={primaryButtonIcon?.iconClass}
                      iconDirection={primaryButtonIcon?.direction}
                      tooltip={primaryBtnTooltip}
                    />
                  </div>
                  {secondayBtnText && (
                    <div className="w-full mt-1 xs:mt-0">
                      <PrimaryButton
                        text={secondayBtnText}
                        size="full"
                        theme="blue-outline"
                        onClick={onClickSecondaryBtn}
                        disabled={disableSecondaryBtn}
                        tooltip={secondaryBtnTooltip}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </div>
  )
}

DefaultModal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.object,
  bodyClasses: PropTypes.string,
  primaryBtnText: PropTypes.string,
  primaryButtonTheme: PropTypes.string,
  secondayBtnText: PropTypes.string,
  onClickPrimaryBtn: PropTypes.func,
  onClickSecondaryBtn: PropTypes.func,
  primaryBtnTooltip: PropTypes.object,
  secondaryBtnTooltip: PropTypes.object,
  disablePrimaryBtn: PropTypes.bool,
  disableSecondaryBtn: PropTypes.bool,
  primaryButtonType: PropTypes.string,
  primaryButtonLoading: PropTypes.bool,
  primaryButtonIcon: PropTypes.object,
  loadingContent: PropTypes.bool,
  onCloseModal: PropTypes.func,
  disableBackdropClose: PropTypes.bool,
  width: PropTypes.string,
}
