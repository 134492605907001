import React, {useEffect, useState} from 'react'
import i18next from 'i18next'
import moment from 'moment'
import {PaymentElement, useCheckout} from '@stripe/react-stripe-js'
import {useDispatch, useSelector} from 'react-redux'
import {Trans} from 'react-i18next'
import {toast} from 'react-toastify'
import {convertToCurrency} from '../subscriptionUtils'

import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'

import {updateCompany} from 'actions'
import CardIcon from 'res/icons/cardIcon'

export default function CheckoutPayment() {
  const dispatch = useDispatch()
  const {confirm, currency, recurring} = useCheckout()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)
  const companyHasUpdatedState = useSelector(state => state.companyHasUpdated)

  const [form, setForm] = useState({email: '', taxId: ''})
  const [paymentLoading, setPaymentLoading] = useState(false)

  const amountSubtotal = recurring.dueNext.amountSubtotal / 100
  const amountDiscount = recurring.dueNext.amountDiscount / 100
  const amountTotal = amountSubtotal - amountDiscount

  const handleClick = e => {
    e.preventDefault()

    setPaymentLoading(true)

    dispatch(
      updateCompany({
        _csrf: csrfState,
        taxId: form.taxId,
      })
    )
  }

  useEffect(() => {
    if (companyHasUpdatedState) {
      confirm({
        email: form.email,
      }).then(result => {
        if (result.type === 'error') {
          let errorMsg = ''

          switch (result.error.code) {
            case 'checkout_has_active_subscriptions':
              errorMsg = i18next.t(
                'subscription.checkout.messages.activeSubscriptions'
              )
              break

            case 'card_declined':
              errorMsg = i18next.t(
                'subscription.checkout.messages.cardDeclined'
              )
              break

            default:
              errorMsg = result.error.message
          }

          toast.error(errorMsg)
        }

        setPaymentLoading(false)
      })
    }
  }, [companyHasUpdatedState])

  const handleInputChange = event => {
    const {name, value} = event.target
    setForm({...form, [name]: value})
  }

  return (
    <form onSubmit={handleClick}>
      <div id="payment-body" className="my-10">
        <div className="sm:flex xxl:block gap-4">
          <div className="w-full">
            <PrimaryInput
              name="email"
              text={i18next.t('subscription.checkout.emailInput')}
              value={form.email}
              onChange={handleInputChange}
              type="email"
              autoComplete="email"
              className="w-full"
              required
            />
          </div>

          <div className="w-full">
            <PrimaryInput
              name="taxId"
              text={i18next.t('subscription.checkout.taxIdInput')}
              value={form.taxId}
              onChange={handleInputChange}
              margin="mb-8"
              required
            />
          </div>
        </div>

        <PaymentElement
          options={{
            layout: 'accordion',
          }}
        />
      </div>

      <div id="payment-footer" className="mt-auto">
        <div className="ml-auto xxl:ml-0 w-full md:w-64 xxl:w-full">
          <PrimaryButton
            text={i18next.t('subscription.checkout.subscribeBtn')}
            size="full"
            margin="mb-5"
            icon={<CardIcon width="22" color="#fff" />}
            loading={paymentLoading}
            type="submit"
          />
        </div>

        {!userState.company.stripeTrialCompleted && (
          <div className="text-xs text-end xxl:text-left">
            <Trans
              i18nKey="subscription.checkout.footer"
              values={{
                total: convertToCurrency(amountTotal, currency),
                date: moment(new Date()).add(7, 'days').format('L'),
              }}
              shouldUnescape
            />
          </div>
        )}
      </div>
    </form>
  )
}
