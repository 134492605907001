import PropTypes from 'prop-types'
import i18next from 'i18next'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import ProgressBar from 'components/overview/ProgressBar'

const ScoreBox = ({classContainer, score}) => {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  let color

  switch (score && score.grade) {
    case 'A':
      color = 'bg-teal-500'
      break
    case 'B':
      color = 'bg-green-500'
      break
    case 'C':
      color = 'bg-yellow-500'
      break
    case 'D':
      color = 'bg-orange-500'
      break
    case 'E':
      color = 'bg-red-500'
      break
    case 'F':
      color = 'bg-red-700'
      break
    default:
      color = 'bg-teal-500'
      break
  }

  useEffect(() => {
    if (score) {
      setLoading(false)
    }
  }, [score])

  const ScoreInfo = () => {
    return (
      <div
        className="cursor-pointer"
        onClick={() => {
          navigate('#/help/scoring')
        }}>
        <i className="icofont-question-square opacity-0 absolute right-0 top-0 m-2 score-info"></i>
      </div>
    )
  }

  return (
    <div
      className={`${classContainer} ${color} h-48 w-full security-score relative flex flex-wrap rounded-lg text-white px-6`}>
      <ScoreInfo />
      <div
        className={`relative flex flex-wrap justify-between h-full w-full rounded-lg font-normal text-white pb-4`}>
        <p className="text-lg w-full mt-4">
          /{i18next.t('titles.securityScore').toUpperCase()}
        </p>
        {loading ? (
          <span
            className={`animate-pulse rounded-md bg-blue-600 block text-5xl w-16 h-12 relative mb-2`}
          />
        ) : (
          <>
            <span className="text-5xl relative mt-1">
              {score && score.grade ? score.grade : '-'}
            </span>
          </>
        )}
        <ProgressBar
          completed={score && score.progress ? score.progress : 0}
          overallScore={score && score.grade ? score.grade : '-'}
          margin="mb-4"
        />
      </div>
    </div>
  )
}

ScoreBox.propTypes = {
  classContainer: PropTypes.string,
  score: PropTypes.object,
}

export default ScoreBox
