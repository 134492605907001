import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import moment from 'moment'

export default function Timestamp({key, className, date, format, fromNow}) {
  useEffect(() => {
    moment.locale(i18next.language)
  }, [])

  if (!date)
    return (
      <span key={key} className={className}>
        -
      </span>
    )

  return (
    <span key={key} className={className}>
      {fromNow ? moment(date).fromNow() : defaultDatetime(date, format)}
    </span>
  )
}

const defaultDatetime = (date, format) => {
  let dateStyle, timeStyle

  switch (format) {
    case 'll':
      dateStyle = 'short'
      timeStyle = undefined
      break

    case 'LL':
      dateStyle = 'long'
      timeStyle = undefined
      break

    case 'LLL':
      dateStyle = 'long'
      timeStyle = 'short'
      break

    case 'LT':
      dateStyle = undefined
      timeStyle = 'short'
      break

    default:
      dateStyle = 'long'
      timeStyle = undefined
  }

  return new Intl.DateTimeFormat(i18next.language, {
    dateStyle,
    timeStyle,
  }).format(new Date(date))
}

Timestamp.propTypes = {
  key: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.DateTimeFormat]),
  format: PropTypes.string,
  fromNow: PropTypes.bool,
}
