import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import CopyToClipboard from 'components/partials/CopyToClipboard'
import ArticleFooter from './ArticleFooter'
import gfm from 'remark-gfm'

export default function Article({content, updatedAt}) {
  return (
    <div className="max-w-3xl mx-auto p-6 mt-5">
      <div className="template-markdown help-article">
        <ReactMarkdown
          remarkPlugins={[gfm]}
          components={{
            code: code => (
              <CopyToClipboard
                content={code.children}
                classes="mt-2 mb-6"
                onClick={() => navigator.clipboard.writeText(code.children)}
              />
            ),
          }}>
          {content}
        </ReactMarkdown>
      </div>

      <ArticleFooter updatedAt={updatedAt} />
    </div>
  )
}

Article.propTypes = {
  content: PropTypes.string,
  updatedAt: PropTypes.string,
}
