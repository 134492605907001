import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {i18next} from 'translate/i18n'
import {v4 as uuidv4} from 'uuid'
import {
  fetchCompany,
  memberGetMemberModal,
  showAutoSubscriptionModal,
  updateCompany,
} from '../../actions'
import {useDispatch, useSelector} from 'react-redux'
import {mars700, nEmployees, sky900, tooltipStyle} from 'constant'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import ClockIcon from 'res/icons/clockIcon'
import Spinner from 'components/partials/Spinner'
import PlanUtils from 'utils/plan'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import images from 'res'
import SecurityShield from 'res/icons/securityShield'
import SecurityShieldBad from 'res/icons/securityShieldBad'
import Subsection from 'components/partials/headers/Subsection'
import {Trans} from 'react-i18next'
import Timestamp from 'components/partials/Timestamp'

export default function Company() {
  const planUtils = PlanUtils()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)
  const companyState = useSelector(state => state.company)

  const [company, setCompany] = useState({
    nEmployees: null,
    settings: {cloudflare: false, aws: false},
    loading: true,
  })

  const handleSelectChange = event => {
    const {value} = event
    setCompany({...company, nEmployees: parseInt(value, 10)})
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (userState?.role === 'read-only') return

    dispatch(updateCompany({...company, _csrf: csrfState}))
  }

  const handleChangeCheckbox = event => {
    const {name, checked} = event.target

    setCompany({
      ...company,
      settings: {
        ...company.settings,
        [name]: checked,
      },
    })
  }

  const getCompanySizeRange = n => {
    let range

    if (n <= 10) range = 10
    if (n > 10 && n <= 50) range = 50
    if (n > 50 && n <= 200) range = 200
    if (n > 200 && n <= 500) range = 500
    if (n > 500 && n <= 1000) range = 1000
    if (n > 1000 && n <= 5000) range = 5000
    if (n > 5000) range = 10000

    return range
  }

  useEffect(() => {
    dispatch(fetchCompany(userState.company.id))
  }, [dispatch])

  useEffect(() => {
    if (companyState) setCompany({...companyState, loading: false})
  }, [companyState])

  const isStripeProvider = userState.subscription?.billingProvider === 'STRIPE'
  const isPaymentFailed = isStripeProvider && userState.company.paymentFailed

  if (company.loading) return <Spinner />

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Subsection
          title={i18next.t('settings.tabs.company')}
          margin="mb-10"
          content={
            <div id="company-details">
              <div className="flex border-b border-sideral-50 pt-8 pb-6 px-6">
                <div className="hidden sm:block w-4/12">
                  <p className="text-sm text-sideral-900">
                    {i18next.t('titles.companyDetails')}*
                  </p>
                  <p className="text-light text-xs text-sideral-300 pt-1">
                    {i18next.t('subtitles.companyDetails')}
                  </p>
                </div>
                <div className="w-full sm:w-6/12">
                  <PrimaryInput
                    text="Company name"
                    name="name"
                    type="text"
                    value={company.name || ''}
                    disabled={true}
                  />
                </div>
              </div>
              {company.cnpjList && company.cnpjList.length ? (
                <div className="flex border-b border-sideral-50 pt-8 pb-6 px-6">
                  <div className="hidden sm:block w-4/12">
                    <p className="text-sm text-sideral-900">
                      {i18next.t('titles.taxIdentification')}
                    </p>
                    <p className="text-light text-xs text-sideral-300 pt-1">
                      {i18next.t('subtitles.taxIdentification')}
                    </p>
                  </div>
                  <div className="w-full sm:w-6/12">
                    {company.cnpjList.map(cnpj => {
                      return (
                        <PrimaryInput
                          text={i18next.t('labels.identificationNumber')}
                          type="text"
                          value={cnpj || ''}
                          disabled={true}
                          key={uuidv4()}
                        />
                      )
                    })}
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="flex border-b border-sideral-50 pt-8 pb-6 px-6">
                <div className="hidden sm:block w-4/12">
                  <p className="text-sm text-sideral-900">
                    {i18next.t('titles.companySize')}*
                  </p>
                  <p className="text-light text-xs text-sideral-300 pt-1">
                    {i18next.t('subtitles.companySize')}
                  </p>
                </div>
                <div className="w-full sm:w-6/12">
                  <PrimarySelect
                    placeholder={i18next.t(
                      'settings.company.placeholders.select'
                    )}
                    text={i18next.t('labels.numberOfEmployees')}
                    name="nEmployees"
                    value={{
                      value: company.nEmployees,
                      label:
                        nEmployees[getCompanySizeRange(company.nEmployees)],
                    }}
                    options={
                      nEmployees &&
                      Object.keys(nEmployees).map(key => ({
                        value: key,
                        label: nEmployees[key],
                      }))
                    }
                    onChange={handleSelectChange}
                    disabled={userState?.role === 'read-only'}
                  />
                </div>
              </div>

              <div className="flex border-b border-sideral-50 pt-8 pb-6 px-6">
                <div className="hidden sm:block w-4/12">
                  <p className="text-sm text-sideral-900">
                    {i18next.t('titles.plan')}*
                  </p>
                  <p className="text-light text-xs text-sideral-300 pt-1">
                    {i18next.t('subtitles.plan')}
                  </p>
                </div>
                <div className="text-sm rounded-md bg-sky-900 w-full md:w-8/12 xl:w-1/2">
                  <div className="text-white px-3 py-2">
                    {i18next.t('titles.yourPlan')}
                  </div>

                  <div className="xxl:flex flex-wrap bg-white border border-sky-900 rounded-b-md">
                    {isPaymentFailed && (
                      <div className="w-full bg-red-200 text-red-900 text-center p-2">
                        <i className="icofont-info-circle text-red-800 text-lg mr-2" />
                        {i18next.t('settings.company.paymentFailed')}
                      </div>
                    )}

                    {planUtils.isTrial() && (
                      <div className="w-full bg-orange-200 text-orange-900 text-center p-2">
                        {userState.subscription?.endDate &&
                        planUtils.planHasExpired()
                          ? i18next.t('settings.company.trialUserExpired')
                          : i18next.t('settings.company.trialUser')}
                      </div>
                    )}

                    {!planUtils.isTrial() &&
                      planUtils.showSubscriptionAlert() && (
                        <div className="w-full bg-orange-200 text-orange-900 text-center p-2">
                          {i18next.t('settings.company.inactiveSubscription')}
                        </div>
                      )}

                    <div className="w-full p-4">
                      <div className="text-sm font-light pr-2 basis-1/2">
                        {planUtils.userHasPremiumPlan() &&
                          !planUtils.showSubscriptionAlert() && (
                            <div className="inline-flex items-start rounded-full p-2 mr-2">
                              <SecurityShield
                                width="18"
                                height="18"
                                color={sky900}
                              />
                              <span className="font-normal px-2">
                                {i18next.t('misc.created')}
                              </span>
                              <div>
                                {i18next.t('misc.at').toLowerCase()}
                                <Timestamp
                                  className="pl-1"
                                  date={userState.subscription?.startDate}
                                  format="LL"
                                />
                              </div>
                            </div>
                          )}

                        {userState.subscription?.endDate ? (
                          <>
                            {!planUtils.planHasExpired() && (
                              <div className="inline-flex items-center text-sideral-700 py-1 px-2">
                                <ClockIcon
                                  width="20"
                                  height="20"
                                  color={mars700}
                                />
                                <span className="font-normal px-1">
                                  {i18next.t('misc.expires')}
                                </span>
                                {i18next.t('misc.at').toLowerCase()}
                                <Timestamp
                                  className="pl-1"
                                  date={
                                    userState.futureSubscription
                                      ? userState.futureSubscription.endDate
                                      : userState.subscription.endDate
                                  }
                                  format="LL"
                                />
                              </div>
                            )}

                            {planUtils.planHasExpired() && (
                              <div className="inline-flex items-center text-sideral-700 py-1 px-2 mb-4 bg-red-100 border border-red-200 rounded-lg">
                                <SecurityShieldBad
                                  width="20"
                                  height="20"
                                  color={mars700}
                                />
                                <span className="font-normal px-1">
                                  {i18next.t('misc.expired')}
                                </span>
                                {i18next.t('misc.at').toLowerCase()}
                                <Timestamp
                                  className="pl-1"
                                  date={userState.subscription.endDate}
                                  format="LL"
                                />
                              </div>
                            )}
                          </>
                        ) : null}
                      </div>

                      {planUtils.userHasPremiumPlan() && (
                        <div className="mt-5">
                          <div className="flex text-sm pl-2 mt-4 xxl:mt-0 basis-1/2 mb-5">
                            <ul className="list-disc list-inside">
                              {SubscriptionFeaturesBreakdown(
                                userState.subscription?.features
                              )}
                            </ul>
                          </div>

                          <div className="w-full bg-astral-50 text-sm rounded-md border p-4">
                            <div className="xl:flex justify-between items-center border-b pb-6 mb-6">
                              <div className="xl:flex items-center justify-center text-center mb-4 xl:mb-0">
                                <i className="icofont-credit-card text-sky-900 text-4xl mr-3" />
                                <div>
                                  <Trans
                                    i18nKey={
                                      'settings.company.premiumAdvantages.subscriptionManagement'
                                    }
                                    shouldUnescape
                                  />
                                </div>
                              </div>

                              <PrimaryButton
                                text={i18next.t('buttons.mySubscription')}
                                onClick={() => navigate('/subscription')}
                                size="full"
                                miniature
                              />
                            </div>

                            <div className="flex items-center">
                              <div className="w-fit h-fit">
                                <img src={images.giftIcon} className="w-10" />
                              </div>
                              <div className="ml-4">
                                {i18next.t('memberGetMember.body')}
                                <SecondaryButton
                                  text={i18next.t('memberGetMember.button')}
                                  size="sm"
                                  margin="mt-2 xl:mt-0"
                                  onClick={() => {
                                    dispatch(
                                      memberGetMemberModal({showModal: true})
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {!planUtils.userHasPremiumPlan() && (
                        <div className="lg:flex items-center w-full mx-auto text-center bg-astral-50 text-sm rounded-md border p-4">
                          <div className="flex items-center justify-center mb-4 lg:mb-0 lg:mr-6">
                            <div className="bg-[#FFB743]/[.15] p-4 rounded-full">
                              <images.crownIcon width="42" height="42" />
                            </div>
                          </div>

                          <div>
                            <Trans
                              i18nKey="settings.company.premiumAdvantages.tryForFree"
                              shouldUnescape
                            />

                            <div className="sm:flex items-center justify-center gap-4 lg:justify-start w-full mt-3">
                              <PrimaryButton
                                text={i18next.t('buttons.startForFree')}
                                onClick={() =>
                                  dispatch(showAutoSubscriptionModal(true))
                                }
                                size="full"
                                margin="mb-2 sm:mb-0"
                                miniature
                              />
                              <SecondaryButton
                                text={i18next.t(
                                  'settings.company.premiumAdvantages.callToAction'
                                )}
                                size="sm"
                                onClick={() => navigate('/why-unxpose')}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />

        <Subsection
          title={i18next.t('settings.advanced.title')}
          content={
            <div id="advanced">
              <div className="flex border-b border-sideral-50 pt-8 pb-6 px-6">
                <div className="hidden sm:block w-4/12">
                  <p className="text-sm text-sideral-900">
                    {i18next.t('settings.advanced.monitoring.title')}*
                  </p>
                  <p className="text-light text-xs text-sideral-300 pt-1">
                    {i18next.t('settings.advanced.monitoring.subtitle')}
                  </p>
                </div>
                <div className="w-full sm:w-6/12">
                  <div className="flex items-start w-full border border-sideral-100 rounded-md p-4 mb-5">
                    <div className="tooltip">
                      <input
                        name="cloudflare"
                        id="ignore-cloudflare"
                        className="switch switch--shadow"
                        type="checkbox"
                        checked={company.settings?.cloudflare}
                        onChange={handleChangeCheckbox}
                        disabled={
                          planUtils.hasPlanRestriction() ||
                          userState?.role === 'read-only'
                        }
                      />
                      <label htmlFor="ignore-cloudflare" />
                      {planUtils.hasPlanRestriction() && (
                        <span
                          className={`${tooltipStyle.default} ml-5 -mt-12 text-xs`}>
                          {i18next.t('sales.notAvailable')}
                        </span>
                      )}
                    </div>
                    <div className="w-10/12 text-sideral-700 px-6">
                      <p className="font-normal leading-none">
                        {i18next.t(
                          'settings.advanced.monitoring.cloudflare.label'
                        )}
                      </p>
                      <p className="text-xs font-light pt-2">
                        {i18next.t(
                          'settings.advanced.monitoring.cloudflare.description'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />

        <div className="flex justify-end pt-6">
          <PrimaryButton
            text={i18next.t('buttons.saveChanges')}
            disabled={userState?.role === 'read-only'}
            onClick={handleSubmit}
            theme="blue"
            size="md"
            type="submit"
          />
        </div>
      </form>
    </>
  )
}

function SubscriptionFeaturesBreakdown(features) {
  const toList = ['cloudIntegrations', 'domains', 'idpIntegrations']

  if (!features || !Object.keys(features)) return null

  return Object.keys(features).map(feature => {
    if (!toList.includes(feature)) {
      return null
    }

    return (
      <li key={uuidv4()} className="pb-1">
        {features[feature] === 'UNLIMITED' ? (
          <>
            <span className="font-light mx-2 -ml-1">
              {i18next.t(`settings.subscription.unlimited.${feature}`)}
            </span>
          </>
        ) : (
          <>
            <span className="font-light mx-2 -ml-1">
              {i18next.t(`settings.subscription.${feature}`)}:
              <span className="font-medium text-base">
                {' '}
                {features[feature]}
              </span>
            </span>
          </>
        )}
      </li>
    )
  })
}
