import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import {DefaultModal} from 'components/partials/DefaultModal'
import {useDispatch, useSelector} from 'react-redux'
import {loading, monitorSupplier} from 'actions'
import {getFeatureSubscription, isLoading} from 'utils'
import {Trans} from 'react-i18next'

export default function StartMonitoringModal({supplierDetails, setModal}) {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)
  const suppliersStatsState = useSelector(state => state.suppliersStats)
  const startSupplierMonitoringDoneState = useSelector(
    state => state.startSupplierMonitoringDone
  )

  const handleConfirm = e => {
    e.preventDefault()

    dispatch(loading({startSupplierMonitoring: true}))
    dispatch(
      monitorSupplier({
        supplierId: supplierDetails.id,
        _csrf: csrfState,
      })
    )
  }

  const closeModal = () => {
    setModal({
      showModal: false,
    })
  }

  useEffect(() => {
    if (startSupplierMonitoringDoneState)
      setModal({
        supplierDetails: null,
        showModal: false,
      })
  }, [startSupplierMonitoringDoneState])

  const monitoredSuppliersSubscription = getFeatureSubscription(
    userState,
    'monitoredSuppliers'
  )

  return (
    <DefaultModal
      id="startMonitoringModal"
      title={i18next.t('suppliers.startMonitoringModal.title')}
      body={
        <div className="text-sm text-gray-700 leading-6 mb-8">
          <Trans
            i18nKey={
              monitoredSuppliersSubscription.isUnlimited
                ? 'suppliers.startMonitoringModal.bodyUnlimited'
                : 'suppliers.startMonitoringModal.body'
            }
            values={{
              count: suppliersStatsState?.monitoredCount,
              currentLimit: monitoredSuppliersSubscription.limit,
              currentMonitoring: suppliersStatsState?.monitoredCount,
              supplierName: supplierDetails.name,
            }}
            shouldUnescape
          />
        </div>
      }
      primaryBtnText={i18next.t('buttons.confirm')}
      secondayBtnText={i18next.t('buttons.cancel')}
      onClickPrimaryBtn={handleConfirm}
      onClickSecondaryBtn={closeModal}
      primaryButtonLoading={isLoading(
        loadingState.startSupplierMonitoring,
        false
      )}
      onCloseModal={closeModal}
    />
  )
}

StartMonitoringModal.propTypes = {
  supplierDetails: PropTypes.object,
  setModal: PropTypes.func,
}
