import Chart from 'react-apexcharts'
import i18next from 'i18next'
import React, {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {
  fetchCompanyScores,
  fetchDomainsBreakdown,
  fetchDomainsByRegistrar,
  fetchHostsByAsOrgs,
  fetchHostsByCountry,
  fetchWebAppsByProtocol,
  loading,
} from 'actions'

import config from 'config'
import {astral900, sideral300} from 'constant'
import images from 'res'

import CleanScoreBox from 'components/partials/CleanScoreBox'
import DomainsTable from './DomainsTable'
import Subsection from 'components/partials/headers/Subsection'
import Spinner from 'components/partials/Spinner'
import TableWrapper from 'components/partials/tables/TableWrapper'
import ChoroplethMap from './choroplethMap/ChoroplethMap'
import {Page} from 'components/partials/Page'
import VerticalEmptyState from 'components/partials/VerticalEmptyState'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'

export default function Dashboard() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const domainsBreakdown = useSelector(state => state.domainsBreakdown)
  const domainsByRegistrar = useSelector(state => state.domainsByRegistrar)
  const hostsByAsOrgs = useSelector(state => state.hostsByAsOrgs)
  const hostsByCountry = useSelector(state => state.hostsByCountry)
  const scores = useSelector(state => state.scores)
  const webAppsByProtocol = useSelector(state => state.webAppsByProtocol)

  const loadingHostsByAsOrgs = useSelector(state => state.loading.hostsByAsOrgs)
  const loadingWebAppsByProtocol = useSelector(
    state => state.loading.webAppsByProtocol
  )
  const loadingDomainsByRegistrar = useSelector(
    state => state.loading.domainsByRegistrar
  )

  const labels = ['HTTPS', 'HTTP']
  const pageIndex = 0
  const pageSize = 3

  const hostsByAsOrgsColumns = useMemo(
    () => [
      {
        header: 'AS Organization',
        accessorKey: 'asOrg',
        meta: {align: 'left'},
      },
      {
        header: 'Total',
        accessorKey: 'total',
        size: 50,
      },
    ],
    []
  )

  const domainsByRegistrarColumns = useMemo(
    () => [
      {
        header: i18next.t('internetExposure.tables.registrar'),
        accessorKey: 'registrar',
        meta: {align: 'left'},
        hasCustomNoValue: true,
        cell: cell =>
          cell.getValue() || i18next.t('internetExposure.registrarNotDetected'),
      },
      {
        header: 'Total',
        accessorKey: 'total',
        size: 50,
      },
    ],
    []
  )

  const domainsColumns = useMemo(
    () => [
      {
        id: '1',
        columns: [
          {
            Header: 'Domains',
            accessor: 'hostname',
            hidden: true,
          },
        ],
      },
    ],
    []
  )

  useEffect(() => {
    if (!domainsByRegistrar) {
      dispatch(fetchDomainsByRegistrar())
      dispatch(loading({domainsByRegistrar: true}))
    }

    if (!scores) {
      dispatch(fetchCompanyScores())
    }

    if (!hostsByAsOrgs) {
      dispatch(fetchHostsByAsOrgs())
      dispatch(loading({hostsByAsOrgs: true}))
    }

    if (!hostsByCountry) {
      dispatch(fetchHostsByCountry())
    }

    if (!webAppsByProtocol) {
      dispatch(fetchWebAppsByProtocol())
      dispatch(loading({webAppsByProtocol: true}))
    }

    if (!domainsBreakdown) {
      dispatch(fetchDomainsBreakdown({pageIndex, limit: pageSize}))
    }
  }, [])

  const sortRegistrars = registrars => {
    /*
      Sorting rules:
        1 - Move object with null registrar to the end
        2 - Sort by "total" in descending order
        3 - If "total" values are equal, sort by "registrar" alphabetically
    */
    registrars.sort((a, b) => {
      if (a.registrar === null && b.registrar !== null) {
        return 1
      } else if (a.registrar !== null && b.registrar === null) {
        return -1
      } else {
        const totalComparison = b.total - a.total

        if (totalComparison === 0) {
          return a.registrar.localeCompare(b.registrar)
        }

        return totalComparison
      }
    })

    return registrars
  }

  return (
    <Page pageTitle={i18next.t('pageTitles.internetExposure')}>
      <div className="flex flex-wrap">
        {scores ? (
          <div className="flex flex-wrap justify-center lg:flex-nowrap gap-4 w-full mb-8">
            <CleanScoreBox
              overallScore={scores.dns.grade}
              percentage={scores.dns.progress}
              titleLink={`${config.CLIENT_URL}/dns`}
              title="DNS score"
            />
            <CleanScoreBox
              overallScore={scores.network.grade}
              percentage={scores.network.progress}
              titleLink={`${config.CLIENT_URL}/network`}
              title="Network score"
            />
            <CleanScoreBox
              overallScore={scores.web.grade}
              percentage={scores.web.progress}
              titleLink={`${config.CLIENT_URL}/web`}
              title="Web score"
            />
          </div>
        ) : (
          <Spinner />
        )}

        <div className="flex flex-wrap lg:flex-nowrap w-full gap-4">
          <Subsection
            title={i18next.t('internetExposure.tables.domainsByRegistrar')}
            content={
              <div className="flex justify-center flex-wrap bg-white min-w-[300px] min-h-[220px]">
                {loadingDomainsByRegistrar ? (
                  <div className="self-center">
                    <Spinner />
                  </div>
                ) : domainsByRegistrar?.length ? (
                  <div className="w-full max-h-[220px] menu-scrollbar overflow-y-auto">
                    <PrimaryTableV8
                      columns={domainsByRegistrarColumns}
                      data={sortRegistrars(domainsByRegistrar)}
                      pageSize={1000}
                      detailPath={row =>
                        `/dns?registrar=${row.original.registrar || 'unknown'}`
                      }
                      hideSearch
                      hidePagination
                    />
                  </div>
                ) : (
                  <VerticalEmptyState
                    icon={<images.NetworkIcon width="55" color={sideral300} />}
                  />
                )}
              </div>
            }
          />
          <Subsection
            title={i18next.t('internetExposure.tables.hostsByAsOrg')}
            content={
              <div className="flex justify-center flex-wrap bg-white min-w-[300px] min-h-[220px]">
                {loadingHostsByAsOrgs ? (
                  <div className="self-center">
                    <Spinner />
                  </div>
                ) : hostsByAsOrgs?.length ? (
                  <div className="w-full max-h-[220px] menu-scrollbar overflow-y-auto">
                    <PrimaryTableV8
                      columns={hostsByAsOrgsColumns}
                      data={hostsByAsOrgs}
                      pageSize={1000}
                      detailPath={row => `/network?asOrg=${row.original.asOrg}`}
                      hideSearch
                      hidePagination
                    />
                  </div>
                ) : (
                  <VerticalEmptyState
                    icon={<images.NetworkIcon width="55" color={sideral300} />}
                  />
                )}
              </div>
            }
          />
          <Subsection
            title={i18next.t('internetExposure.tables.webAppsByProtocol')}
            content={
              <div className="flex justify-center flex-wrap bg-white min-w-[300px] max-h-[210px]">
                {loadingWebAppsByProtocol ? (
                  <div className="self-center">
                    <Spinner />
                  </div>
                ) : webAppsByProtocol?.http || webAppsByProtocol?.https ? (
                  <div className="w-full my-2">
                    <Chart
                      type="donut"
                      options={{
                        plotOptions: {
                          pie: {
                            donut: {
                              size: '65%',
                              labels: {
                                show: false,
                                total: {
                                  show: false,
                                  label: 'Total',
                                  fontSize: '16px',
                                  color: astral900,
                                  fontWeight: 600,
                                },
                              },
                            },
                          },
                        },
                        chart: {
                          events: {
                            legendClick: function (_, seriesIndex) {
                              navigate(
                                `/web?protocol=${labels[
                                  seriesIndex
                                ].toLowerCase()}://`
                              )
                            },
                          },
                          fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                        },
                        legend: {
                          show: true,
                          position: 'bottom',
                          formatter: (name, opts) => {
                            return [
                              name,
                              `(${opts.w.globals.series[opts.seriesIndex]})`,
                            ]
                          },

                          markers: {
                            width: 16,
                            height: 16,
                            radius: 3,
                          },
                        },
                        labels,
                        colors: ['#38a169', '#dd6b20'],
                        dataLabels: {
                          enabled: false,
                        },
                        responsive: [
                          {
                            breakpoint: 480,
                            options: {
                              chart: {
                                width: 200,
                              },
                            },
                          },
                        ],
                      }}
                      series={[
                        webAppsByProtocol.https || 0,
                        webAppsByProtocol.http || 0,
                      ]}
                      height={200 + Math.floor(Math.random() * 2) + 1} // trick to avoid a bug triggered by window re-render
                    />
                  </div>
                ) : (
                  <VerticalEmptyState
                    icon={<images.NetworkIcon width="55" color={sideral300} />}
                  />
                )}
              </div>
            }
          />
        </div>
      </div>

      <Subsection
        title={
          <div>
            <p>{i18next.t('internetExposure.hostsByCountry.title')}</p>
            {/* 
              <div className="tooltip ml-2">
                <images.InfoIcon width="20" />
                <div className={`${tooltipStyle.default} normal-case text-center leading-5 right-0 top-0 transform -translate-y-full`}>
                  {i18next.t('internetExposure.hostsByCountry.info')}
                </div>
              </div> 
            */}
          </div>
        }
        margin="mt-8"
        content={
          hostsByCountry ? (
            <ChoroplethMap data={hostsByCountry} />
          ) : (
            <div className="self-center">
              <Spinner />
            </div>
          )
        }
      />

      <TableWrapper
        title={i18next.t('internetExposure.domainsOverview')}
        loaded={domainsBreakdown && domainsBreakdown.data}
        table={
          <DomainsTable
            columns={domainsColumns}
            data={domainsBreakdown && domainsBreakdown.data}
            pageNumber={0}
            fetchData={props =>
              dispatch(
                fetchDomainsBreakdown({
                  ...props,
                  limit: pageSize,
                })
              )
            }
            pageCount={domainsBreakdown && domainsBreakdown.pages}
            pageIndex={pageIndex}
            pageSize={pageSize}
            total={domainsBreakdown && domainsBreakdown.total}
          />
        }
        margin="mt-8"
      />
    </Page>
  )
}
