import React, {useEffect, useState} from 'react'
import {i18next} from 'translate/i18n'
import {useSelector, useDispatch, batch} from 'react-redux'
import {useParams, Link} from 'react-router-dom'

import {
  clearAwsAssetsData,
  clearFilters,
  fetchAwsFindings,
  fetchAwsIntegrations,
  fetchAwsMostCritical,
  fetchAwsStats,
} from 'actions'

import EmptyState from '../EmptyState'
import WhatsImportant from '../WhatsImportant'
import Spinner from 'components/partials/Spinner'
import IntegrationStats from 'components/cloud/IntegrationStats'
import AssetsItems from './AssetsItems'
import {Page} from 'components/partials/Page'
import {getAwsIntegrationUpdatePath} from '../cloudUtils'
import PlanUtils from 'utils/plan'
import Subsection from 'components/partials/headers/Subsection'

export default function Dashboard() {
  const dispatch = useDispatch()
  const params = useParams()

  const awsStatsState = useSelector(state => state.awsStats)
  const awsIntegrationsState = useSelector(state => state.awsIntegrations)
  const awsMostCriticalState = useSelector(state => state.awsMostCritical)

  const planUtils = PlanUtils()

  const [fetchingFeed, setFetchingFeed] = useState(true)

  const [currentIntegration, setCurrentIntegration] = useState({})
  const [findingsByAsset, setFindingsByAsset] = useState([])
  const [visibility, setVisibility] = useState('')

  const configsAreObsolete = integration => {
    if (!integration) return

    return (
      awsStatsState &&
      awsStatsState[integration.accountId] &&
      awsStatsState[integration.accountId].isPolicyObsolete
    )
  }

  const hasAtLeastOneResource = integrationId => {
    return (
      awsStatsState &&
      awsStatsState[integrationId] &&
      Object.values(awsStatsState[integrationId]).find(value => !!value)
    )
  }

  const assetsNames = {
    cloudtrailTrails: 'CloudTrail trails',
    s3Buckets: 'S3 buckets',
    iamPolicies: 'IAM policies',
    iamUsers: 'IAM users',
    kmsKeys: 'KMS keys',
    rdsInstances: 'RDS instances',
    rdsSnapshots: 'RDS snapshots',
    ec2Amis: 'EC2 AMIs',
    ec2Instances: 'EC2 instances',
    ec2SecurityGroups: 'EC2 security groups',
    ec2Vpcs: 'EC2 VPCs',
    efs: 'Elastic File Systems',
    ebsVolumes: 'EBS volumes',
    route53Domains: 'Route 53 domains',
    route53HostedZones: 'Route 53 hosted zones',
  }

  const mapFindingsByAsset = awsFindingsByAsset => {
    const assets = Object.entries(awsFindingsByAsset).map(entry => {
      const [awsAsset, {findings}] = entry
      return {awsAsset, findings, name: assetsNames[awsAsset]}
    })

    return assets.sort((a, b) => a.name.localeCompare(b.name))
  }

  const assetsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            accessor: 'name',
          },
          {
            accessor: 'findings',
          },
          {
            accessor: 'chevron',
          },
        ],
      },
    ],
    []
  )

  useEffect(() => {
    if (currentIntegration && currentIntegration.accountId) {
      batch(() => {
        dispatch(fetchAwsStats(currentIntegration.accountId))
        dispatch(fetchAwsFindings(currentIntegration.accountId))

        if (!planUtils.hasPlanRestriction()) {
          dispatch(fetchAwsMostCritical(currentIntegration.accountId))
        }
      })
    }
  }, [currentIntegration])

  useEffect(() => {
    if (awsIntegrationsState) {
      const account = awsIntegrationsState.find(
        integration => integration.accountId === params.id
      )
      setCurrentIntegration(account)
    }
  }, [awsIntegrationsState])

  useEffect(() => {
    if (awsMostCriticalState && fetchingFeed) {
      setFetchingFeed(false)
    }
  }, [awsMostCriticalState])

  useEffect(() => {
    if (
      !awsStatsState ||
      !currentIntegration ||
      !awsStatsState[currentIntegration.accountId] ||
      !awsStatsState[currentIntegration.accountId].assets
    )
      return

    const arr = mapFindingsByAsset(
      awsStatsState[currentIntegration.accountId].assets
    )
    setFindingsByAsset(arr)
  }, [awsStatsState, currentIntegration])

  useEffect(() => {
    batch(() => {
      dispatch(fetchAwsIntegrations())
      dispatch(clearFilters({except: 'aws'}))
      dispatch(clearAwsAssetsData())
    })
  }, [])

  return (
    <Page pageTitle={i18next.t('pageTitles.cloud')}>
      {currentIntegration &&
      currentIntegration.status === 'active' &&
      configsAreObsolete(currentIntegration) &&
      !currentIntegration.logs?.test ? (
        <div className={visibility}>
          <div
            className="bg-purple-100 border border-purple-400 text-purple-700 px-4 py-3 mb-6 rounded relative text-xs"
            role="alert">
            <Link
              to={getAwsIntegrationUpdatePath(
                currentIntegration.config?.method,
                currentIntegration.config?.externalId
              )}>
              <strong className="font-bold">
                {i18next.t('cloud.updateAvailable')}
              </strong>
              &nbsp;
              <span className="block sm:inline">
                {i18next.t('cloud.followOurGuide')}
              </span>
            </Link>
            <span
              className="absolute top-0 bottom-0 right-0 p-3"
              onClick={() => {
                setVisibility('hidden')
              }}>
              <svg
                className="fill-current h-5 w-5 text-purple-500"
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20">
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>
        </div>
      ) : (
        ''
      )}

      {!currentIntegration ||
      !currentIntegration.accountId ||
      !awsStatsState ? (
        <Spinner />
      ) : (
        <>
          {currentIntegration &&
          currentIntegration.status === 'active' &&
          hasAtLeastOneResource(currentIntegration.accountId) ? (
            <>
              {awsStatsState && awsStatsState[currentIntegration.accountId] && (
                <IntegrationStats
                  provider="aws"
                  integration={currentIntegration}
                  integrationId={currentIntegration.accountId}
                  nAssets={
                    awsStatsState[currentIntegration.accountId]
                      .totalResources || 0
                  }
                  nFindings={
                    awsStatsState[currentIntegration.accountId].findings || 0
                  }
                  score={
                    awsStatsState[currentIntegration.accountId].score.grade ||
                    '-'
                  }
                />
              )}
              <WhatsImportant
                provider="aws"
                feed={awsMostCriticalState || []}
                cloudAccount={currentIntegration.accountId}
                fetching={fetchingFeed}
              />
              <Subsection
                title={i18next.t('misc.assets')}
                loaded={findingsByAsset}>
                <div className="p-4">
                  <AssetsItems
                    data={findingsByAsset}
                    columns={assetsColumns}
                    integrationId={currentIntegration.accountId}
                    currentIntegration={currentIntegration}
                    pageNumber={0}
                  />
                </div>
              </Subsection>
            </>
          ) : (
            <>
              <div>
                <div className="bg-white rounded-sm pt-2 pl-2 pb-1 relative">
                  <i className="icofont-clouds ml-2 mt-2 text-2xl"></i>
                  <span className="text-xs absolute top-0 mt-3 mx-4 uppercase">
                    AMAZON WEB SERVICES
                  </span>
                </div>
              </div>
              <EmptyState />
            </>
          )}
        </>
      )}
    </Page>
  )
}
