import React, {useEffect, useMemo, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import i18next from 'i18next'
import {useParams} from 'react-router-dom'
import Timestamp from 'components/partials/Timestamp'

import {
  borderStyle,
  sideral300,
  sky500,
  statusList,
  tooltipStyle,
} from 'constant'
import config from 'config'
import {formatDnsRecords} from '../utils'
import PlanUtils from 'utils/plan'
import images from 'res'

import AddDomainModal from '../AddDomainModal'
import AlternateActionButton from 'components/partials/buttons/AlternateActionButton'
import BackButton from 'components/partials/buttons/BackButton'
import FindingIcon from 'res/icons/findingIcon'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import ScoreBadge from 'components/partials/ScoreBadge'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import Spinner from 'components/partials/Spinner'
import TableWrapper from 'components/partials/tables/TableWrapper'

// table used for subdomains/records
import TableServerSide from '../TableServerSide'

import {
  fetchDomainFindings,
  fetchDomainRecords,
  fetchDomainSubdomains,
  fetchDomain,
  toggleRestrictionModal,
  clearSuccess,
} from 'actions'
import {Page} from 'components/partials/Page'
import RemoveDomainModal from '../RemoveDomainModal'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import {
  getSeverityColor,
  getSeverityLabel,
  getSeverityString,
} from 'utils/score'

export default function DomainDetails() {
  const domainState = useSelector(state => state.domain)
  const domainSubdomainsState = useSelector(state => state.domainSubdomains)
  const domainRecordsState = useSelector(state => state.domainRecords)
  const domainFindingsState = useSelector(state => state.domainFindings)

  const dispatch = useDispatch()
  const params = useParams()

  const [domain, setDomain] = useState(null)
  const [subdomains, setSubdomains] = useState([])
  const [records, setRecords] = useState([])
  const [findings, setFindings] = useState([])
  const [modalVisibility, setModalVisibility] = useState('hidden')
  const [removeDomainModal, setRemoveDomainModal] = useState({
    domainId: '',
    hostname: '',
    showModal: false,
  })
  const [csvFilteringProps, setCsvFilteringProps] = useState({
    filter: '',
    sortBy: '',
    order: '',
  })

  const planUtils = PlanUtils()

  const pageIndex = 0
  const pageSize = 5

  const subdomainsColumns = useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.name').toUpperCase(),
            accessor: 'name',
          },
          {
            Header: i18next.t('tables.value').toUpperCase(),
            accessor: 'data',
          },
          {
            Header: i18next.t('tables.seen').toUpperCase(),
            accessor: 'lastResolution',
            width: 50,
            sortType: (a, b) => {
              return (
                new Date(a.original.lastResolution) -
                new Date(b.original.lastResolution)
              )
            },
          },
        ],
      },
    ],
    []
  )

  const recordsColumns = useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.name').toUpperCase(),
            accessor: 'name',
          },
          {
            Header: i18next.t('tables.type').toUpperCase(),
            accessor: 'type',
            width: 50,
          },
          {
            Header: i18next.t('tables.value').toUpperCase(),
            accessor: 'data',
          },
          {
            Header: i18next.t('tables.seen').toUpperCase(),
            accessor: 'lastResolution',
            width: 50,
            sortType: (a, b) => {
              return (
                new Date(a.original.lastResolution) -
                new Date(b.original.lastResolution)
              )
            },
          },
        ],
      },
    ],
    []
  )

  const findingsColumns = useMemo(
    () => [
      {
        header: i18next.t('tables.title'),
        accessorKey: 'title',
        cell: cell => (
          <div className="flex items-center">
            <div
              className={`${getSeverityColor(
                getSeverityString(cell.row.original.severity)
              )} severity-indicator inline-flex flex-shrink-0 mr-2`}
            />
            {cell.getValue()}
          </div>
        ),
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 60,
        cell: cell => statusList[cell.getValue()],
      },
      {
        header: i18next.t('tables.severity'),
        accessorKey: 'severity',
        size: 60,
        cell: cell => {
          const value = cell.getValue()

          if (value && typeof value === 'number') {
            return getSeverityLabel(getSeverityString(value))
          }
        },
      },
      {
        header: i18next.t('tables.seen'),
        accessorKey: 'checkedAt',
        size: 60,
        cell: cell => <Timestamp date={new Date(cell.getValue())} fromNow />,
        sortingFn: (a, b) => {
          return new Date(a.original.checkedAt) - new Date(b.original.checkedAt)
        },
      },
    ],

    []
  )

  function handleRemoveDomain() {
    dispatch(clearSuccess())
    setRemoveDomainModal({
      domainId: domain.id,
      hostname: domain.hostname,
      showModal: true,
    })
  }

  function toggleModal() {
    if (planUtils.hasPlanRestriction()) {
      return dispatch(
        toggleRestrictionModal({contentId: 'addDomainBtn', showModal: true})
      )
    }

    modalVisibility === 'hidden'
      ? setModalVisibility('block')
      : setModalVisibility('hidden')
  }

  function closeModal(e) {
    const inviteSectionModal = document.querySelector('#add-domain-modal')
    if (e.target === inviteSectionModal) {
      setModalVisibility('hidden')
    }
  }

  const handleGenerateSubdomainsCSV = csvFilteringProps => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'downloadCsvBtn', showModal: true})
      )

    if (!domain || !domain.id) return

    const searchParams = new URLSearchParams()
    const {filter, order, sortBy} = csvFilteringProps
    let downloadUrl = `${config.SERVER_URL}/dns/domains/${domain.id}/subdomains`

    searchParams.append('format', 'csv')

    if (filter) searchParams.append('filter', filter)
    if (order) searchParams.append('order', order)
    if (sortBy) searchParams.append('sortBy', sortBy)

    downloadUrl += '?' + searchParams.toString()

    window.location.href = downloadUrl
  }

  useEffect(() => {
    if (!domainSubdomainsState) return

    const key = Object.keys(domainSubdomainsState).find(
      key => key === params.id
    )
    const currentSubdomains = domainSubdomainsState[key]

    if (!currentSubdomains) return

    const formattedData = formatDnsRecords(currentSubdomains.data.subdomains)

    currentSubdomains.data.subdomains = formattedData

    setSubdomains(currentSubdomains)
  }, [domainSubdomainsState])

  useEffect(() => {
    if (!domainRecordsState) return

    const key = Object.keys(domainRecordsState).find(key => key === params.id)
    try {
      const currentRecords = JSON.parse(JSON.stringify(domainRecordsState[key]))
      const formattedData = formatDnsRecords(currentRecords.data.records)

      currentRecords.data.records = formattedData

      setRecords(currentRecords)
    } catch (err) {
      setRecords({data: {records: []}})
    }
  }, [domainRecordsState])

  useEffect(() => {
    dispatch(fetchDomain(params.id))
  }, [])

  useEffect(() => {
    if (domainState) {
      setDomain(domainState)
    }
  }, [domainState])

  useEffect(() => {
    if (domainFindingsState && domainFindingsState[params.id]) {
      setFindings(domainFindingsState[params.id])
    }
  }, [domainFindingsState])

  useEffect(() => {
    if (!params.id) return

    if (!planUtils.hasPlanRestriction()) {
      dispatch(
        fetchDomainFindings({
          domainId: params.id,
          pagination: {pageIndex, pageSize},
        })
      )
      dispatch(
        fetchDomainRecords({
          domainId: params.id,
          pagination: {pageIndex, pageSize},
        })
      )
      dispatch(
        fetchDomainSubdomains({
          domainId: params.id,
          pagination: {
            pageIndex,
            pageSize,
          },
        })
      )
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mouseup', e => closeModal(e))
    return () => {
      document.removeEventListener('mouseup', e => closeModal(e))
    }
  }, [])

  if (!domain) {
    return <Spinner />
  }

  return (
    <Page
      pageTitle={i18next.t('pageTitles.dns')}
      button={
        <SecondaryButton
          text={i18next.t('buttons.addNewDomain')}
          plusIcon={true}
          onClick={toggleModal}
          crownIcon={planUtils.hasPlanRestriction()}
        />
      }>
      <div className="relative lg:flex lg:flex-wrap w-ful">
        <AddDomainModal
          modalVisibility={modalVisibility}
          setModalVisibility={setModalVisibility}
        />

        <div className={`${borderStyle} w-full mb-6`}>
          <div className="xs:flex p-4 border-b border-sideral-100">
            <div className="flex items-center">
              <figure className="w-20">
                <images.DnsIcon width="60" height="60" color="#90A4AE" />
              </figure>

              <div className="flex justify-between">
                <div>
                  <BackButton fallbackLink="/dns" />

                  <div className="flex pt-2">
                    <div className="h-4">
                      <ScoreBadge letter={domain.score} />
                    </div>
                    <p className="font-medium text-lg text-sideral-700">
                      {domain.hostname}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex text-astral-900 text-sm  mt-6 xs:mt-0 ml-auto">
              <div className="tooltip flex justify-center items-center">
                <AlternateActionButton
                  text={i18next.t('buttons.delete')}
                  onClick={handleRemoveDomain}
                  disabled={planUtils.hasPlanRestriction()}
                  trashIcon
                />
                {planUtils.hasPlanRestriction() && (
                  <span
                    className={`${tooltipStyle.default} -mt-16 w-56 text-xs text-center`}>
                    {i18next.t('sales.notAvailable')}
                  </span>
                )}
              </div>
            </div>
          </div>
          <ul className="text-sm p-4 text-astral-900">
            {domain.registrar && (
              <li className="mt-2">
                <span className="font-medium">Registrar:</span>
                <span className="font-light ml-1">{domain.registrar} </span>
              </li>
            )}

            {domain.owner && (
              <li className="mt-2">
                <span className="font-medium">Owner:</span>
                <span className="font-light ml-1">{domain.owner}</span>
              </li>
            )}
            {domain.ownerid && (
              <li className="mt-2">
                <span className="font-medium">Owner ID:</span>
                <span className="font-light ml-1">{domain.ownerid}</span>
              </li>
            )}
            {domain.country && (
              <li className="mt-2">
                <span className="font-medium">Country:</span>
                <span className="font-light ml-1">{domain.country}</span>
              </li>
            )}
            {domain.nserver && (
              <li className="mt-2">
                <span className="font-medium">Servers:</span>
                <span className="font-light ml-1">
                  {' '}
                  {domain.nserver.split(' ').join(', ')}
                </span>
              </li>
            )}
          </ul>
        </div>
        <TableWrapper
          id="findingsTable"
          title={i18next.t('misc.findings')}
          loaded={findings}
          table={
            <PrimaryTableV8
              columns={findingsColumns}
              data={findings || []}
              pageNumber={0}
              detailPath={row => `/dns/finding/${row.original.id}`}
              emptyStateWithFilter={
                <GenericEmptyState
                  icon={<FindingIcon width="55" color={sideral300} />}
                  title={i18next.t('tables.nothingFoundWithSearch')}
                  body={i18next.t('tables.searchSomethingElse')}
                  margin={'m-2 mt-4'}
                />
              }
              emptyStateWithoutFilter={
                <GenericEmptyState
                  icon={<FindingIcon width="55" color={sideral300} />}
                  body={i18next.t('dns.noFindings')}
                  margin={'m-2 mt-4'}
                />
              }
            />
          }
          isPremiumFeature
        />
        <TableWrapper
          id="subdomainsAndDnsRecords"
          title={i18next.t('dns.subdomains')}
          button={
            <button
              className="ml-auto"
              onClick={() => handleGenerateSubdomainsCSV(csvFilteringProps)}
              title={i18next.t('buttons.downloadCSV')}>
              <images.DownloadIcon height="24" width="24" color={sky500} />
            </button>
          }
          margin="my-8"
          loaded={subdomains && subdomains.data && subdomains.data.subdomains}
          table={
            <TableServerSide
              fetchData={props => {
                dispatch(
                  fetchDomainSubdomains({
                    domainId: params.id,
                    pagination: props,
                  })
                )
              }}
              data={
                (subdomains && subdomains.data && subdomains.data.subdomains) ||
                []
              }
              columns={subdomainsColumns}
              csvFilteringProps={csvFilteringProps}
              setCsvFilteringProps={setCsvFilteringProps}
              pageCount={subdomains.pages}
              pageIndex={pageIndex}
              pageSize={pageSize}
              total={subdomains.total}
              emptyStateWithFilter={
                <GenericEmptyState
                  icon={<images.DnsIcon width="55" color={sideral300} />}
                  title={i18next.t('tables.nothingFoundWithSearch')}
                  body={i18next.t('tables.searchSomethingElse')}
                  margin="m-2 mt-4"
                />
              }
              emptyStateWithoutFilter={
                <GenericEmptyState
                  icon={<images.DnsIcon width="55" color={sideral300} />}
                  title={i18next.t('tables.nothingFound')}
                  body={i18next.t('tables.waitAFewMinutes')}
                  margin="m-2 mt-4"
                />
              }
            />
          }
          isPremiumFeature
        />
        <TableWrapper
          id="subdomainsAndDnsRecords"
          title={i18next.t('dns.dnsRecords')}
          loaded={records && records.data && records.data.records}
          button={
            <SecondaryButton
              redirect={`/dns/domain/${params.id}/logs`}
              text={i18next.t('tables.viewLogs')}
              size="sm"
              direction="right"
              disabled={planUtils.hasPlanRestriction()}
            />
          }
          table={
            <TableServerSide
              fetchData={props => {
                dispatch(
                  fetchDomainRecords({
                    domainId: params.id,
                    pagination: props,
                  })
                )
              }}
              data={(records && records.data && records.data.records) || []}
              columns={recordsColumns}
              pageCount={records.pages}
              pageIndex={pageIndex}
              pageSize={pageSize}
              total={records.total}
              emptyStateWithFilter={
                <GenericEmptyState
                  icon={<images.DnsIcon width="55" color={sideral300} />}
                  title={i18next.t('tables.nothingFoundWithSearch')}
                  body={i18next.t('tables.searchSomethingElse')}
                  margin="m-2 mt-4"
                />
              }
              emptyStateWithoutFilter={
                <GenericEmptyState
                  icon={<images.DnsIcon width="55" color={sideral300} />}
                  title={i18next.t('tables.nothingFound')}
                  body={i18next.t('tables.waitAFewMinutes')}
                  margin="m-2 mt-4"
                />
              }
            />
          }
          isPremiumFeature
        />

        {removeDomainModal.showModal && (
          <RemoveDomainModal
            modal={removeDomainModal}
            setModal={setRemoveDomainModal}
          />
        )}
      </div>
    </Page>
  )
}
