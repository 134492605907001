import i18next from 'i18next'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {clearAction, fetchSuppliers, fetchSuppliersStats} from 'actions'
import Subsection from 'components/partials/headers/Subsection'
import {Page} from 'components/partials/Page'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import SuppliersList from './SuppliersList'
import SupplierAddModal from './modals/SupplierAddModal'
import StatsBox from 'components/partials/StatsBox'
import PlanUtils from 'utils/plan'
import SuppliersEmptyState from './SuppliersEmptyState'

export default function SuppliersOverview() {
  const dispatch = useDispatch()

  const suppliersState = useSelector(state => state.suppliers)
  const suppliersStatsState = useSelector(state => state.suppliersStats)

  const [addSupplierModal, setAddSupplierModal] = useState({
    domain: '',
    showModal: false,
  })

  const planUtils = PlanUtils()

  const handleAddSupplier = () => {
    dispatch(clearAction('addSupplierDone'))
    setAddSupplierModal({showModal: true})
  }

  useEffect(() => {
    if (!planUtils.hasPlanRestriction()) {
      dispatch(fetchSuppliers())
      dispatch(fetchSuppliersStats())
    }
  }, [])

  return (
    <Page pageTitle={i18next.t('pageTitles.suppliers')}>
      <StatsBox
        classContainer="md:col-span-3 xl:col-span-3"
        slots={2}
        label={i18next.t('suppliers.stats.registered')}
        data={planUtils.hasPlanRestriction() ? 0 : suppliersStatsState?.count}
        secondaryLabel={i18next.t('suppliers.stats.monitored')}
        secondaryData={suppliersStatsState?.monitoredCount}
      />

      {planUtils.hasPlanRestriction() || suppliersState?.length === 0 ? (
        <SuppliersEmptyState handleAddSupplier={handleAddSupplier} />
      ) : (
        <Subsection
          title={i18next.t('suppliers.sectionTitle')}
          content={<SuppliersList />}
          button={
            <SecondaryButton
              text={i18next.t('suppliers.addNewBtn')}
              size="sm"
              margin="ml-auto"
              onClick={handleAddSupplier}
              plusIcon
            />
          }
          margin="my-10"
        />
      )}

      {addSupplierModal.showModal && (
        <SupplierAddModal
          modal={addSupplierModal}
          setModal={setAddSupplierModal}
        />
      )}
    </Page>
  )
}
