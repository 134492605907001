import React, {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import images from 'res'
import PrimaryInput from './partials/inputs/PrimaryInput'
import SecondaryButton from './partials/buttons/SecondaryButton'
import {Link} from 'react-router-dom'
import Footer from './partials/Footer'
import ScoreBadgeSquare from './partials/ScoreBadgeSquare'
import bgOrangePurple from 'res/backgrounds/bgOrangePurple.svg'
import {useDispatch, useSelector} from 'react-redux'
import {fetchCompaniesPagination} from 'actions'
import Spinner from './partials/Spinner'
import Pagination from './partials/tables/Pagination'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import GenericEmptyState from './partials/GenericEmptyState'
import {changeCurrentCompany} from 'actions'
import {Page} from './partials/Page'
import Timestamp from './partials/Timestamp'
import CompanyLogo from './partials/CompanyLogo'

const PAGE_INDEX = 0
const PAGE_SIZE = 3

const SwitchCompany = () => {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)
  const companiesPaginationState = useSelector(
    state => state.companiesPagination
  )

  const [firstPageLoad, setFirstPageLoad] = useState(true)
  const [searchFilter, setSearchFilter] = useState('')
  const [pageIndex, setPageIndex] = useState(PAGE_INDEX)
  const [loading, setLoading] = useState(true)

  const getCompaniesData = filterValue => {
    dispatch(
      fetchCompaniesPagination({
        filterValue,
        pageIndex,
        limit: PAGE_SIZE,
      })
    )
  }

  const handleSelectCompany = id => {
    dispatch(
      changeCurrentCompany({
        _csrf: csrfState,
        companyId: id,
      })
    )
  }

  const debouncedGetCompaniesData = useMemo(() => {
    return AwesomeDebouncePromise(getCompaniesData, 1000)
  }, [])

  useEffect(() => setFirstPageLoad(false), [])
  useEffect(() => setLoading(false), [companiesPaginationState])

  useEffect(() => {
    getCompaniesData(searchFilter)
  }, [pageIndex])

  useEffect(() => {
    if (!firstPageLoad) {
      setPageIndex(PAGE_INDEX)
      debouncedGetCompaniesData(searchFilter)
    }
  }, [searchFilter])

  const handleSearchFilter = e => {
    setLoading(true)
    setSearchFilter(e.target.value)
  }

  const handleNext = () => {
    setLoading(true)
    setPageIndex(pageIndex + 1)
  }

  const handlePrevious = () => {
    setLoading(true)
    setPageIndex(pageIndex - 1)
  }

  return (
    <Page pageTitle={i18next.t('pageTitles.chooseCompany')} hideHeader>
      <div className="flex flex-col h-screen">
        <header id="companies-page-header" className="py-6 pl-10 bg-sky-900">
          <Link to="/">
            <img
              src={images.WhiteLogo}
              alt="Unxpose"
              className="cursor-pointer w-40"
            />
          </Link>
        </header>

        <div id="companies-page-body" className="container mx-auto px-10 mb-10">
          {userState && companiesPaginationState ? (
            <>
              <div className="flex justify-between items-center py-6 mb-6 border-b">
                <div id="greetings" className="text-lg">
                  {i18next.t('companiesPage.hi') + ', '}
                  <span className="font-medium">{userState.firstName}</span>!
                </div>

                <div className="logout">
                  <SecondaryButton
                    text={i18next.t('dropdownMenu.logout')}
                    redirect="/logout"
                  />
                </div>
              </div>

              <div id="search-company" className="flex justify-between">
                <div className="w-100 text-sm">
                  <PrimaryInput
                    placeholder={i18next.t('companiesPage.searchCompany')}
                    onChange={handleSearchFilter}
                    icon={
                      <i className="icofont-search-1 absolute top-1/2 transform -translate-y-1/2 ml-3 text-gray-500" />
                    }
                    margin="0"
                  />
                </div>

                <Pagination
                  handlePrevious={handlePrevious}
                  handleNext={handleNext}
                  canPreviousPage={
                    companiesPaginationState.total !== 0 &&
                    companiesPaginationState.currentPage !== 1
                  }
                  canNextPage={
                    companiesPaginationState.total !== 0 &&
                    companiesPaginationState.currentPage <
                      companiesPaginationState.pages
                  }
                  pageIndex={pageIndex}
                  pageCount={companiesPaginationState.pages}
                  total={companiesPaginationState.total}
                />
              </div>

              <div id="items-company">
                {loading ? (
                  <Spinner />
                ) : companiesPaginationState.data.length ? (
                  companiesPaginationState.data.map(company => (
                    <ItemCompany
                      key={company.id}
                      data={company}
                      handleSelectCompany={handleSelectCompany}
                    />
                  ))
                ) : (
                  <GenericEmptyState
                    icon={<img src={images.SearchIcon} className="w-12" />}
                    title={i18next.t('tables.nothingFoundWithSearch')}
                    body={i18next.t('tables.searchSomethingElse')}
                    margin="mt-10"
                    bgColor="transparent"
                  />
                )}
              </div>
            </>
          ) : (
            <Spinner />
          )}
        </div>

        <footer
          id="companies-page-footer"
          className="bg-white border-t"
          style={{marginTop: 'auto'}}>
          <div className="container mx-auto">
            <Footer padding="px-10 py-6" />
          </div>
          <div
            style={{
              backgroundImage: `url(${bgOrangePurple})`,
            }}
            className="h-3"
          />
        </footer>
      </div>
    </Page>
  )
}

const ItemCompany = ({data, handleSelectCompany}) => {
  return (
    <div className="item-company bg-white border rounded-lg px-4 my-4">
      <div className="header border-b">
        <div className="flex items-center justify-between px-2 py-4">
          <div id="left-side" className="flex">
            <div className="company-image pr-4">
              <CompanyLogo logoUrl={data.logo} />
            </div>
            <div className="company-title dm-mono font-semibold text-xl">
              {data.name}
            </div>
          </div>
          <div id="right-side" className="open-company-link">
            <SecondaryButton
              text={i18next.t('companiesPage.accessCompany')}
              onClick={() => handleSelectCompany(data.id)}
              direction="right"
              size="sm"
            />
          </div>
        </div>
      </div>

      <div className="body flex items-center justify-between px-2 py-4">
        <div id="left-side">
          <div className="company-status">
            <span className="key font-semibold">
              {i18next.t('companiesPage.monitoring')}
            </span>
            <span className="value capitalize">
              {data.status === 'monitoring'
                ? i18next.t('labels.enabled')
                : i18next.t('labels.disabled')}
            </span>
          </div>
          {data.subscription?.endDate ? (
            <div className="flex items-center text-sideral-700 mt-1">
              <span className="font-semibold">
                {i18next.t('companiesPage.subscriptionEndDate')}
              </span>
              <Timestamp
                className="pl-1"
                date={data.subscription.endDate}
                format="LL"
              />
            </div>
          ) : null}
        </div>
        <div id="right-side">
          <ScoreBadgeSquare letter={data.score.grade} />
        </div>
      </div>
    </div>
  )
}

ItemCompany.propTypes = {
  data: PropTypes.object,
  handleSelectCompany: PropTypes.func,
}

export default SwitchCompany
