import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Timestamp from 'components/partials/Timestamp'
import Badge from 'components/partials/Badge'
import TrashIcon from 'res/icons/trashIcon'
import {sky900} from 'constant'
import SupplierRemoveInviteModal from './modals/SupplierRemoveInviteModal'
import {useDispatch} from 'react-redux'
import {clearSuccess} from 'actions'
import moment from 'moment'
import i18next from 'i18next'

export default function SupplierInvites({supplierId, invites}) {
  const dispatch = useDispatch()

  const [modal, setModal] = useState({
    supplierId,
    inviteId: null,
    inviteEmail: null,
    showModal: false,
  })

  return invites.map(invite => {
    const isExpiredInvite = moment(new Date()).isAfter(invite.expiresAt)

    return (
      <>
        <div
          key={invite.id}
          className="flex justify-between items-center flex-wrap border-b border-sideral-50 last:border-none rounded-md py-6 px-8">
          <div id="left-side">
            <div className="text-sm mr-1">
              <div className="font-medium">{invite.email}</div>
              <div className="text-xs">
                <Timestamp date={invite.createdAt} fromNow />
              </div>
            </div>
          </div>
          <div id="right-side" className="flex items-center">
            {isExpiredInvite ? (
              <Badge label={'Expirado'} style="error" />
            ) : (
              <Badge
                label={i18next.t('labels.inviteExpires', {
                  datetime: moment(invite.expiresAt).fromNow(),
                })}
                style="warning"
              />
            )}

            <button
              className="ml-4"
              onClick={() => {
                dispatch(clearSuccess())

                setModal({
                  ...modal,
                  inviteId: invite.id,
                  inviteEmail: invite.email,
                  showModal: true,
                })
              }}>
              <TrashIcon color={sky900} height="22" width="22" />
            </button>
          </div>
        </div>

        {modal.showModal && (
          <SupplierRemoveInviteModal modal={modal} setModal={setModal} />
        )}
      </>
    )
  })
}

SupplierInvites.propTypes = {
  supplierId: PropTypes.string,
  invites: PropTypes.array,
}
