import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import Spinner from '../Spinner'
import images from 'res'
import {sky900} from 'constant'

export default function SecondaryButton(props) {
  let size = {}

  switch (props.size) {
    case 'sm':
      size = {
        text: 'text-xs',
        icon: 'text-lg',
        arrow: '10',
        plus: '14',
        crown: '18',
        spinner: '14',
      }
      break
    case 'md':
      size = {
        text: 'text-sm md:text-base',
        icon: 'text-xl',
        arrow: '14',
        plus: '18',
        spinner: '18',
      }
      break
    default:
      size = {
        text: 'text-sm md:text-base',
        icon: 'text-xl',
        arrow: '14',
        plus: '18',
        crown: '26',
        spinner: '18',
      }
  }

  const buttonJsx = (
    <button
      data-testid={props.testId}
      type={props.type || 'button'}
      style={{maxHeight: '18px'}}
      className={`${props.disabled || props.loading ? 'opacity-50' : ''} ${
        props.color || 'text-sky-900'
      } ${
        props.iconClass && 'items-baseline'
      } dm-mono inline-flex items-center`}
      onClick={props.onClick}
      disabled={props.disabled || props.loading}>
      {props.iconClass && (
        <i className={`${props.iconClass} ${size.icon} mr-1 mt-auto`} />
      )}

      {props.direction === 'left' && (
        <span className="pr-1">
          <images.ArrowIcon
            size={size.arrow}
            height={size.arrow}
            color={props.color || sky900}
            direction="left"
          />
        </span>
      )}

      {props.crownIcon && (
        <span className="mr-2">
          <images.crownIcon width={size.crown} height={size.crown} />
        </span>
      )}

      {props.icon && <span className="mr-1">{props.icon}</span>}

      <span className={`${size.text} underline uppercase`}>{props.text}</span>
      {props.direction === 'right' && (
        <span className="pl-1">
          <images.ArrowIcon
            size={size.arrow}
            height={size.arrow}
            color={props.color || sky900}
          />
        </span>
      )}
      {props.plusIcon ? (
        <images.PlusIcon
          size={size.plus}
          height={size.plus}
          color={props.color || sky900}
        />
      ) : (
        ''
      )}

      {props.newTabIcon && (
        <i className={'icofont-external-link text-sm ml-2'} />
      )}

      {props.loading ? (
        <span>
          <Spinner height={size.spinner} color={props.color || sky900} />
        </span>
      ) : (
        ''
      )}
      {props.tooltip}
    </button>
  )

  return (
    <div
      id={props.id}
      className={`${props.classContainer} ${props.margin} ${
        props.tooltip && `tooltip ${props.isTooltipRelativeToBtn && 'relative'}`
      }`}>
      {props.redirect ? (
        <Link to={props.redirect} className={`${props.crownIcon && 'flex'}`}>
          {buttonJsx}
        </Link>
      ) : (
        buttonJsx
      )}
    </div>
  )
}

SecondaryButton.propTypes = {
  id: PropTypes.string,
  testId: PropTypes.string,
  classContainer: PropTypes.string,
  direction: PropTypes.oneOf(['left', 'right']),
  disabled: PropTypes.bool,
  loading: PropTypes.any,
  margin: PropTypes.string,
  onClick: PropTypes.func,
  plusIcon: PropTypes.bool,
  crownIcon: PropTypes.bool,
  newTabIcon: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  redirect: PropTypes.string,
  iconClass: PropTypes.string,
  text: PropTypes.string,
  tooltip: PropTypes.object,
  isTooltipRelativeToBtn: PropTypes.bool,
  color: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md']),
  spinner: PropTypes.bool,
}
