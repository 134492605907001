import i18next from 'i18next'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {
  fetchQuestionnaireSupplierInvites,
  fetchQuestionnaires,
  loading,
  fetchQuestionnaireInvites,
} from 'actions'

import Subsection from 'components/partials/headers/Subsection'
import {Page} from 'components/partials/Page'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import {QuestionnaireBase} from './QuestionnaireBase'
import QuestionnairesList from './QuestionnairesList'
import QuestionnaireSupplierInvites from 'components/suppliers/questionnaire/QuestionnaireSupplierInvites'
import QuestionnaireInvites from './QuestionnaireInvites'
import {isLoading} from 'utils'

export default function QuestionnaireOverview() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loadingState = useSelector(state => state.loading)
  const questionnaireSupplierInvitesState = useSelector(
    state => state.questionnaireSupplierInvites
  )
  const questionnaireInvitesState = useSelector(
    state => state.questionnaireInvites
  )

  const [displaySupplierInvites, setDisplaySupplierInvites] = useState(false)
  const [displayQuestionnaireInvites, setDisplayQuestionnaireInvites] =
    useState(false)

  useEffect(() => {
    dispatch(fetchQuestionnaires())

    dispatch(loading({questionnaireInvites: true}))
    dispatch(fetchQuestionnaireSupplierInvites())
    dispatch(fetchQuestionnaireInvites())
  }, [])

  useEffect(() => {
    setDisplaySupplierInvites(questionnaireSupplierInvitesState?.length > 0)
    setDisplayQuestionnaireInvites(questionnaireInvitesState?.length > 0)
  }, [questionnaireSupplierInvitesState, questionnaireInvitesState])

  return (
    <Page
      pageTitle={i18next.t('pageTitles.questionnaires')}
      helpPath="questionnaires"
      button={
        <SecondaryButton
          text={i18next.t('buttons.addQuestionnaire')}
          onClick={() => navigate('/questionnaires/new')}
          plusIcon
        />
      }>
      <QuestionnaireBase />

      {(displaySupplierInvites || displayQuestionnaireInvites) && (
        <Subsection
          title={i18next.t('suppliers.invites.title')}
          content={
            <>
              {displaySupplierInvites && (
                <QuestionnaireSupplierInvites
                  invites={questionnaireSupplierInvitesState}
                />
              )}

              {displayQuestionnaireInvites && (
                <div className={displaySupplierInvites ? 'border-t' : ''}>
                  <QuestionnaireInvites invites={questionnaireInvitesState} />
                </div>
              )}
            </>
          }
          margin="mb-10"
          loading={isLoading(loadingState.questionnaireSupplierInvites)}
        />
      )}

      <Subsection
        title={i18next.t('questionnaires.tables.questionnairesAvailable')}
        content={<QuestionnairesList />}
      />
    </Page>
  )
}
