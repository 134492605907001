export const messages = {
  pt: {
    translations: {
      auth: {
        errors: {
          incorrectPassword: 'A senha atual está incorreta',
          invalidLink: 'Este link é inválido ou está expirado.',
          notReady:
            'Sua organização ainda não está pronta para uso. Por favor, entre em contato com nosso time.',
          shortPassword: 'A senha deve ter pelo menos 10 caracteres',
          unexpectedError: 'Ocorreu um erro inesperado, tente novamente',
          googleAccountNotConnected:
            'Sua conta do Google não está conectada a uma conta da Unxpose. Se voce já criou uma conta na Unxpose, faça o login usando outra forma de autenticação e conecte sua conta do Google em Configurações.',
          microsoftAccountNotConnected:
            'Sua conta da Microsoft não está conectada a uma conta da Unxpose. Se voce já criou uma conta na Unxpose, faça o login usando outra forma de autenticação e conecte sua conta da Microsoft em Configurações.',
          emailNotFound:
            'O usuário escolhido para autenticação não está cadastrado.',
          planExpired:
            'Seu plano expirou. Mas fique tranquilo, é super fácil reativá-lo. Fale com a gente em sales@unxpose.com',
          companyExists:
            'Sua empresa já está na Unxpose! Entre em contato com o administrador da conta para obter acesso.',
          authenticationRequired:
            'Você deve estar autenticado para concluir esta ação.',
        },
        success: {
          checkYourMailBox: 'Verifique seu email!',
          passwordUpdated: 'Senha atualizada com sucesso',
        },
      },
      pageTitles: {
        marketplace: 'Marketplace',
        unxpose: 'Unxpose',
        overview: 'Visão Geral',
        internetExposure: 'Internet Exposure',
        dns: 'DNS',
        network: 'Network',
        web: 'Web',
        cloud: 'Cloud',
        compliance: 'Compliance',
        people: 'People',
        information: 'Information',
        allFindings: 'Achados',
        findingsBulkChange: 'Achados - Edição em Massa',
        reports: 'Relatórios',
        questionnaires: 'Questionários',
        suppliers: 'Fornecedores',
        documents: 'Documentos',
        settings: 'Configurações',
        registration: 'Cadastro',
        forgotPassword: 'Esqueci a senha',
        chooseCompany: 'Escolher empresa',
        logs: 'Logs',
        planExpired: 'Plano expirado',
        help: 'Ajuda',
        whyUnxpose: 'Por que assinar a Unxpose?',
        subscription: 'Assinatura',
        yourSubscription: 'Sua assinatura',
      },
      dropdownMenu: {
        categories: 'Categorias',
        profile: 'Perfil',
        companySettings: 'Configurações da empresa',
        subscriptionFree: 'Assine agora',
        subscriptionPremium: 'Minha assinatura',
        security: 'Segurança',
        notifications: 'Notificações',
        switchCompany: 'Trocar empresa',
        logout: 'Sair',
      },
      buttons: {
        acceptTerms: 'Aceitar Termos e Condições',
        addNewDomain: 'Adicionar domínio',
        addNewMember: 'Convidar novo membro',
        addWebhook: 'Adicionar webhook',
        addQuestionnaire: 'Adicionar novo',
        apply: 'Aplicar',
        back: 'Voltar',
        backLogin: 'Voltar ao login',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
        save: 'Salvar',
        remove: 'Remover',
        close: 'Fechar',
        copy: 'Copiar',
        reload: 'Recarregar',
        invite: 'Convidar',
        createAccount: 'Criar minha conta',
        createFirstWebhook: 'Crie seu primeiro webhook',
        createPassword: 'Criar nova senha',
        createWebhook: 'Criar webhook',
        declineTerms: 'Recusar e fazer logout',
        delete: 'Deletar',
        details: 'Detalhes',
        checkPermissions: 'Checar permissões',
        disableTwoFactorAuthentication:
          'Desativar autenticação de dois fatores',
        disconnectGoogleAccount: 'Clique aqui para desconectar.',
        disconnectMicrosoftAccount: 'Clique aqui para desconectar.',
        enableTwoFactorAuthentication: 'Ativar 2FA',
        forgotPassword: 'Esqueci a senha',
        generateNewKey: 'Gerar nova chave',
        generateQrCode: 'Clique aqui para gerar um QR Code e ativar a 2FA',
        generateReport: 'Gerar relatório',
        googleSignIn: 'Continuar com Google',
        googleWorkspaceIntegration: 'Integração com Google Workspace',
        integrateYourAccounts: 'Integre suas contas',
        learnMore: 'Saiba mais',
        letsTalk: 'Sim, vamos conversar',
        login: 'Login',
        microsoftSignin: 'Continuar com Microsoft',
        monitorDomain: 'Monitorar domínio',
        needHelp: 'Precisa de ajuda?',
        newIntegration: 'Nova integração',
        notInterested: 'Obrigado, não tenho interesse',
        recoverAccess: 'Recuperar acesso',
        removeAndTry: 'Remover',
        retry: 'Tentar novamente',
        removeUrl: 'Remover URL',
        removeWebhook: 'Remover webhook',
        resendInvite: 'Reenviar convite',
        resending: 'Reenviando...',
        saveChanges: 'Salvar mudanças',
        createWebhookTooltip:
          'Você poderá criar apenas quando o webhook for testado com sucesso',
        saveChangesWebhookTooltip:
          'Você poderá salvar apenas quando o webhook for testado com sucesso',
        sendInvite: 'Enviar convite',
        setUpNow: 'Configure agora',
        slackNotifications: 'Receber notificações!',
        startMonitoring: 'Começar a monitorar',
        testWebhook: 'Testar webhook',
        unmerge: 'Desagrupar',
        unmergeAll: 'Desagrupar todos',
        viewAll: 'Ver todos',
        viewAllFindings: 'Ver todos os achados',
        downloadCSV: 'Download CSV',
        download: 'Baixar',
        add: 'Adicionar',
        next: 'Próximo',
        verify: 'Verificar',
        startForFree: 'Começar grátis',
        buyNow: 'Assine agora',
        mySubscription: 'Minha assinatura',
      },
      userRoles: {
        regular: 'Membro',
        readOnly: 'Somente leitura',
        questionnairesOnly: 'Somente questionários',
        suppliersQuestionnaires: 'Fornecedores e Questionários',
      },
      notFound: {
        title: 'Página não encontrada',
        reaction: 'Ops!',
        body: 'A página que você está procurando não existe.',
        goToHomeBtn: 'Ir para página inicial',
      },
      sentryFeedbackReport: {
        title: 'Reportar problema',
        description: 'Descrição',
        required: '(obrigatório)',
        messagePlaceholder: 'Qual o erro? Conte-nos o que você esperava',
        addScreenshotBtn: 'Adicionar captura de tela',
        removeScreenshotBtn: 'Remover captura de tela',
        submitBtn: 'Enviar',
        successMessageText: 'Obrigado! Iremos investigar.',
      },
      unxpose: {
        support:
          'Se precisar de ajuda, é só falar com a gente pelo <a>support@unxpose.com</a>',
        slogan: 'Unxpose simplifica cibersegurança para você.',
      },
      dropFile: {
        dragAndDrop:
          'Arraste e solte o arquivo ou <br> <strong>clique para selecionar</strong>',
        dragHere: 'Solte aqui',
        fileSelected: 'O arquivo foi <strong>selecionado</strong>',
      },
      invitationPage: {
        pageTitle: 'Visualizar convite',
        title: 'Convite',
        body: '<strong>{{userName}}</strong> convidou você para participar do workspace da <strong>{{companyName}}</strong> na Unxpose. Clique no botão abaixo para aceitar o convite e ter acesso à organização.',
        expirationInfo:
          'Este convite irá expirar em <strong>{{remainingDays}} dia(s)</strong>.',
        acceptInvite: 'Aceitar convite',
      },
      checkboxes: {
        newFindings:
          'Ative para receber notificações quando encontrarmos algo novo. Você pode aplicar filtros de severidade.',
        termsAndConditions: 'Eu concordo com os',
        weeklyReport:
          'Ative para receber o relatório de segurança semanal da sua empresa.',
      },
      planInfo: {
        free: {
          expiredMonitoring:
            'Sua empresa não é monitorada desde <strong>{{date}}</strong>',
          paperformTitle: 'Fale com vendas',
          menu: {
            title: 'Monitoramento desligado',
            body: 'Sua empresa não é monitorada desde <strong>{{date}}</strong>.',
            subscribeBtn: 'Assine agora',
          },
          section: {
            title: 'Esta funcionalidade não está disponível para o seu plano',
            subtitle:
              'Comece agora o TRIAL e acesse a solução sem restrições por 7 dias',
            button: 'Saiba mais',
          },
        },
        paid: {
          paperformTitle: 'Agende conosco a renovação ou upgrade do seu plano',
          menu: {
            title: 'Hey, {{username}}!',
            body: 'Restam <strong>{{remainingDays}} dias</strong> para o seu plano terminar.',
            subscribeBtn: 'Renove agora',
          },
          modal: {
            title: 'Restam {{remainingDays}} dias para o seu plano terminar',
            body: 'Ao final do seu plano, o monitoramento será interrompido e seu time não terá mais acesso à plataforma. Renove agora para continuar mantendo sua empresa protegida.',
            primaryBtn: 'Receba uma proposta por email',
            secondaryBtn: 'Fale com vendas',
            ignoreBtn: 'Talvez depois',
          },
        },
        proposalSuccess:
          'Tudo certo! Em breve você receberá uma proposta no seu e-mail cadastrado',
      },
      sales: {
        subscribeBadge: 'Grátis por 7 dias',
        upgradeBadge: 'Atualize seu plano',
        whyUnxposeBtn: 'Por que assinar a Unxpose?',
        talkToSalesBtn: 'Fale com vendas',
        footer:
          'Ainda não tem certeza? Você pode pedir acesso a todas as funcionalidades gratuitamente por 7 dias!',
        stripeFooter:
          'Teste grátis por 7 dias! Sem cobrança inicial e com segurança garantida pela Stripe.',
        notAvailable: 'Opção não disponível no plano FREE.',
        autoSubscriptionModal: {
          titleFree: 'Unxpose grátis por 7 dias',
          titleSubscribe: 'Assine a Unxpose',
          body: {
            firstItem: {
              title: 'Teste grátis sem preocupações',
              description:
                'Experimente 7 dias de acesso completo para explorar todas as funcionalidades da Unxpose sem custo.',
            },
            secondItem: {
              title: 'Segurança garantidas',
              description:
                'Seus dados de pagamento estão protegidos pela Stripe, uma das plataformas de pagamento mais seguras e reconhecidas do mundo.',
            },
            thirdItem: {
              title: 'Sem cobranças imediatas',
              description:
                'Adicione seu cartão de crédito com tranquilidade: nenhuma cobrança será feita antes do fim do período de teste de 7 dias.',
            },
            fourthItem: {
              title: 'Cancele a qualquer momento',
              description:
                'Decida no seu tempo! Cancele sua assinatura a qualquer momento sem complicações ou taxas adicionais.',
            },
          },
          subscribeBtn: 'Assine agora',
          expiredTrial:
            'Seu período de TRIAL finalizou em <strong>{{expirationDate}}</strong>.',
        },
        csv: {
          downloadCsvBtn: {
            title: 'Dados relevantes em apenas um clique',
            details:
              '<strong>Exportação em CSV</strong> não está disponível no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
            listItems: {
              first: 'Extraia as listas de ativos da sua empresa ',
              second:
                'Exporte o catálogo de vulnerabilidades aplicando os filtros necessários',
              third:
                'Utilize dados da Unxpose para compor relatórios customizados',
            },
          },
        },
        dns: {
          addDomainBtn: {
            title: 'Monitore todos os ativos expostos',
            details:
              '<strong>Adição de Domínios</strong> não está disponível no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
            domainLimit_zero:
              'Você <strong>não possui limites para adicionar domínios</strong> no seu plano. Atualize seu plano para liberar recursos.',
            domainLimit_one:
              'Você alcançou o <strong>limite de {{count}} domínio</strong> do seu plano. Atualize seu plano para liberar recursos.',
            domainLimit_other:
              'Você alcançou o <strong>limite de {{count}} domínios</strong> do seu plano. Atualize seu plano para liberar recursos.',
            listItems: {
              first:
                'Descubra todos os ativos que podem ser vistos de fora para dentro',
              second: 'Entenda as falhas de segurança que ameaçam esses ativo',
              third: 'Siga os tutoriais para fazer as correções',
            },
          },
          detailSections: {
            subdomainsAndDnsRecords: {
              title: 'Sua segurança diária',
              details:
                '<strong>Listagens de registros de DNS e subdomínios</strong> não estão disponíveis no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
              listItems: {
                first:
                  'Descubra diariamente os subdomínios da sua empresa para uma auditoria completa da sua superfície de ataque',
                second:
                  'Entenda se houve mudança em algum registro, o que pode indicar atividade maliciosa',
                third:
                  'Consulte e exporte a lista de subdomínios e registros DNS da sua empresa, atualizada diariamente',
              },
            },
          },
        },
        web: {
          addWebAppBtn: {
            title: 'Proteja as aplicações da sua empresa',
            details:
              '<strong>Adição de Aplicações Web</strong> não está disponível no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
            listItems: {
              first: 'Monitore a segurança das aplicações web em tempo real',
              second:
                'Seja alertado e entenda as falhas que ameaçam os sites da sua empresa',
              third:
                'Veja um passo a passo sobre como fazer a correção das falhas detectadas',
            },
          },
        },
        cloud: {
          cloudIntegrationTutorialBtn: {
            title: 'Monitore os seus ativos de Cloud',
            details:
              '<strong>Monitoramento de Ativos de Cloud</strong> não está disponível no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
            integrationLimit_zero:
              'Você <strong>não possui limites para integrações</strong> no seu plano. Atualize seu plano para liberar recursos.',
            integrationLimit_one:
              'Você alcançou o <strong>limite de {{count}} integração</strong> do seu plano. Atualize seu plano para liberar recursos.',
            integrationLimit_other:
              'Você alcançou o <strong>limite de {{count}} integrações</strong> do seu plano. Atualize seu plano para liberar recursos.',
            listItems: {
              first:
                'Entenda as vulnerabilidades que podem expor o seu ambiente',
              second:
                'Veja os achados priorizados a partir de frameworks reconhecidos',
              third: 'Siga os tutoriais para fazer a correção',
            },
          },
        },
        reports: {
          title: 'Tenha a segurança em mãos',
          details:
            '<strong>Gerar relatório</strong> não está disponível no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
          listItems: {
            first:
              'Prove que a sua empresa é segura com o nosso relatório executivo com recursos visuais e linguagem simples',
            second:
              'Mostre evidências do que vem sendo feito em cibersegurança',
            third: 'Compartilhe internamente e com parceiros de negócios',
          },
          exampleFileBtn: 'Relatório de exemplo',
        },
        config: {
          addWebhookBtn: {
            title: 'Crie alertas personalizados',
            details:
              '<strong>Criação de Webhooks</strong> não está disponível no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
            listItems: {
              first: 'Receba alertas nas soluções que o time já utiliza',
              second: 'Escolha a severidade das falhas que devem ser alertadas',
              third: 'Integre a Unxpose ao dia a dia do time',
            },
          },
          auditLogs: {
            title: 'Rastreie Cada Ação',
            details:
              '<strong>Audit Logs</strong> não estão disponíveis no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
            listItems: {
              first:
                'Obtenha um registro abrangente das atividades dos usuários, aumentando a segurança e a conformidade',
              second:
                'Revise dados históricos para investigar incidentes ou rastrear mudanças ao longo do tempo',
              third:
                'Identifique tendências e padrões no comportamento dos usuários para tomadas de decisão informadas',
            },
          },
        },
        people: {
          addIdPBtn: {
            google: {
              details:
                '<strong>Integração com Google Workspacce</strong> não está disponível no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
            },
            microsoft365: {
              details:
                '<strong>Integração com Microsoft 365</strong> não está disponível no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
            },
            integrationLimit_zero:
              'Você <strong>não possui limites para integrações</strong> no seu plano. Atualize seu plano para liberar recursos.',
            integrationLimit_one:
              'Você alcançou o <strong>limite de {{count}} integração</strong> do seu plano. Atualize seu plano para liberar recursos.',
            integrationLimit_other:
              'Você alcançou o <strong>limite de {{count}} integrações</strong> do seu plano. Atualize seu plano para liberar recursos.',
            title: 'Monitore o elo mais fraco: as pessoas',
            listItems: {
              first: 'Faça a integração em poucos cliques',
              second:
                'Veja quem está (ou não) aderindo às boas práticas de segurança',
              third:
                'Descubra as credenciais corporativas encontradas em vazamentos ',
            },
          },
        },
        overview: {
          securityScoreOverTime: {
            title: 'Acompanhe a evolução da sua empresa',
            details:
              '<strong>Score de Segurança ao Longo do Tempo</strong> não está disponível no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
            listItems: {
              first:
                'Mostre o progresso da saúde em cibersegurança da sua organização',
              second:
                'Comunique o nível de exposição a um ciberataque utilizando KPIs de fácil entendimento',
              third:
                'Entenda o impacto das mudanças em seu ambiente no grau de exposição da sua empresa a um ciberataque ao longo do tempo',
            },
          },
          totalClosedOverTime: {
            featureName: 'Estatísticas',
            title: 'Ciclo de vida dos achados',
            details:
              '<strong>Estatísticas</strong> não estão disponíveis no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
            listItems: {
              first:
                'Entenda a capacidade do seu time em resolver as falhas que a Unxpose encontra ao longo do tempo',
              second:
                'Use a informação para planejar melhor o uso dos recursos que fazem as correções de segurança',
              third:
                'Acompanhe a evolução do número de achados da sua empresa ao longo do tempo',
            },
          },
        },
        findings: {
          detailSections: {
            title: 'Consulte os achados da sua empresa',
            details:
              '<strong>A visualização de achados</strong> não está disponível no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
            listItems: {
              first:
                'Veja os achados priorizados a partir de frameworks reconhecidos',
              second:
                'Entenda o potencial impacto de cada um e como fazer a correção',
              third: 'Aponte quem deve fazer a correção e exporte relatórios',
            },
          },
        },
        whatsImportant: {
          title: 'Saiba o que corrigir primeiro',
          details:
            '<strong>Ocorrências importantes</strong> não estão disponíveis no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
          listItems: {
            first:
              'Veja os achados priorizados a partir de frameworks reconhecidos',
            second: 'Entenda o potencial impacto de cada um para o negócio',
            third: 'Siga os tutoriais para fazer a correção',
          },
        },
        generateAnswerQuestionnaire: {
          title: 'Seu time mais eficiente',
          details:
            '<strong>Geração de respostas por IA</strong> não está disponível no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
          listItems: {
            first:
              'Use IA para responder os questionários e deixe seu time focado em atividades mais estratégicas',
            second:
              'Garanta uma maior acuracidade das respostas ao evitar erro humano',
            third:
              'Economize o tempo de vasculhar todo o histórico de questionários para encontrar uma resposta',
          },
        },
      },
      processingAlert: {
        content:
          'Acabamos de começar seu monitoramento! <strong>Aguarde alguns minutos</strong> para visualizar o score atualizado e muito mais...',
      },
      paymentFailedAlert: {
        content:
          'Houve um problema com o seu pagamento. <a>Acesse sua assinatura</a> <i></i>',
      },
      survey: {
        title: 'Ajude-nos a moldar o futuro da segurança digital! 🚀',
        openBtn: 'Abrir pesquisa',
      },
      marketingAlert: {
        content:
          'Olá, <strong>{{username}}</strong>. Precisamos de <strong>1 minuto</strong> da sua atenção para melhorar a Unxpose. <strong>Participe da nossa pesquisa</strong> e ajude-nos a moldar o futuro da segurança digital! 🚀',
        primaryBtn: 'Participar',
      },
      cloud: {
        tags: {
          nonPersonalData: 'dados não pessoais',
        },
        success: {
          notSensitiveTagAdded: `A tag "dados não pessoais" foi adicionada ao ativo`,
          notSensitiveTagRemoved: `A tag "dados não pessoais" foi removida do ativo`,
        },
        markAsNonPersonal: `Marcar que ativo não possui dados pessoais`,
        noIntegrationsBeingMonitored:
          'Nenhuma das suas integrações está sendo monitorada no momento.',
        accountBeingMonitored: 'Conta monitorada',
        accountFailing: 'Conta falhando',
        accountId: 'ID da conta',
        accountsBeingMonitored: 'Contas monitoradas',
        accountsFailing: 'Contas falhando',
        updateAvailable: 'Atualização disponível!',
        followOurGuide:
          'Clique aqui e siga o tutorial para acessar nossas funcionalidades mais recentes.',
        removal: {
          modal: {
            submit: 'Remover',
            title: 'Remoção de Integração',
            warning:
              'Todos os ativos e achados relacionados a esta integração serão excluídos permanentemente da Unxpose.',
          },
          alternateActionButton: 'Remover Integração',
          dropdownButton: 'Remover Integração',
        },
        aws: {
          errors: {
            accessDenied: {
              explanation:
                'Acesso negado. Para resolver este problema, siga estas etapas:',
              listItem1:
                "Certifique-se de que a Role e a Policy criadas sejam nomeadas exatamente como 'UnxposeRole' e 'UnxposePolicy', respectivamente.",
              listItem2:
                'Verifique se o Account ID liberado para assumir o Role corresponde ao Account ID da Unxpose (708205383577).',
              listItem3:
                'Verifique se o External ID inserido é igual ao fornecido no tutorial de integração ({{externalId}}).',
            },
          },
          cloudFormationIntegration: {
            title:
              'Como integrar a Unxpose com sua conta da AWS usando o CloudFormation',
            intro: {
              title: 'Introdução',
              body: `Neste tutorial, iremos guiá-lo pelos passos necessários para conectar sua conta da AWS com a Unxpose usando o CloudFormation. Não se preocupe, esse processo é simples e pode ser concluído em apenas alguns minutos. Então, vamos começar!`,
            },

            stepOne: {
              title: 'Passo 1: Criar pilha do CloudFormation',
              body: `{{click_here}} para ir para a URL de criação de uma pilha. Assim que estiver na página, marque a caixa de seleção para conceder a permissão necessária. Observe que você não deve modificar o campo de parâmetros. Por fim, clique em {{create_stack}} para criar a pilha e finalizar o processo.`,
              createStack: 'Criar pilha',
              attention:
                'Atenção: aguarde até que a pilha seja criada antes de prosseguir para a próxima etapa. Este processo pode levar alguns minutos. Você verá a seguinte mensagem assim que a pilha estiver pronta.',
            },
            stepTwo: {
              title: 'Passo 2: Habilitar monitoramento da conta',
              body: 'Preencha o campo abaixo com o ID de sua conta da Amazon (número de 12 dígitos) e clique no botão {{monitor_my_account}}. Parabéns! O processo de integração começará agora. Não se esqueça de visitar a seção de Cloud mais tarde!',
              accountIdHelp:
                '{{click_here}} se precisar de ajuda para encontrar o ID da sua conta.',
              amazonAccountId: 'ID da conta da Amazon',
              monitorMyAccount: 'Monitorar minha conta',
            },
          },
          integrationOptions: {
            chooseMethod: 'Escolha um dos seguintes métodos de integração',
            manualSetup: 'Configuração manual',
            manualSetupDescription:
              'Acesse seu console da AWS e siga as instruções para concluir a integração.',
            selectThisMethod: 'Selecionar este método',
            cloudFormationDescription:
              'Conecte sua conta em apenas alguns cliques com o CloudFormation.',
          },
          ebsEncrypted: 'Verdadeiro',
          ebsNotEncrypted: 'Falso',
          mfaEnabled: 'Habilitado',
          mfaNotEnabled: 'Não habilitado',
          route53PrivateZoneFalse: 'Falso',
          route53PrivateZoneTrue: 'Verdadeiro',
          route53AutoRenewEnabled: 'Verdadeiro',
          route53AutoRenewNotEnabled: 'Falso',
          route53TransferLockEnabled: 'Verdadeiro',
          route53TransferLockNotEnabled: 'Falso',
          vpcIsDefault: 'Verdadeiro',
          vpcIsNotDefault: 'Falso',
          integrationRemoved: `Sua conta da AWS não está mais integrada conosco. Não se esqueça de remover o UnxposeRole em seu console de gerenciamento da AWS.`,
          integrationRetried: `Fazendo nova tentativa de integração. Volte em alguns instantes!`,
          integration: {
            title: 'Como integrar a Unxpose com sua conta da AWS',
            stepOne: {
              title:
                'Passo 1: Criando uma política "somente leitura" para a Unxpose',
              inOrderToMonitor: `Para podermos monitorar sua cloud, você precisa nos conceder acesso a alguns de seus recursos na AWS. Não se preocupe: criamos uma política especial que nos permitirá buscar por vulnerabilidades`,
              toCreateThisPolicy:
                'Para criar essa política, navegue até a seção IAM, no console AWS, e selecione',
              nowClickOn: 'Agora clique em',
              andOpenThe: 'e abra a aba',
              tab: '',
              theFollowingConfiguration:
                'Copie a configuração a seguir e cole-a no editor de texto.',
              select: 'Selecione',
              addSomeTags: 'Adicione algumas tags, se desejar, e clique em',
              finallyFill: 'Finalmente, preencha o campo',
              fieldWith: 'com',
              andClickOn: 'e clique em',
              nowLetsMove: `Agora, vamos para o próximo passo.`,
              thePolicyNameMust: 'O nome da política deve ser exatamente',
              highlights: {
                mantainingTheContent:
                  'sem permitir acesso ao conteúdo dos arquivos.',
                policies: 'Policies.',
                createPolicy: 'Create policy',
                json: 'JSON',
                nextReview: 'Next: Review',
                name: 'Name',
                unxposePolicy: 'UnxposePolicy',
                attention: 'Atenção!',
              },
            },
            stepTwo: {
              andThen: 'e depois',
              title: 'Passo 2: Configurando um IAM role para a Unxpose',
              goToTheIam: 'Vá para a seção IAM, no console AWS. Lá, selecione',
              clickOnThe: 'Clique no botão',
              button: '',
              select: 'Selecione',
              fill: 'Preencha o campo',
              fieldWithOur: 'com o nosso ID',
              selectThe: 'Marque a opção',
              checkbox: '',
              fillThe: 'Preencha o campo',
              fieldWithTheFollowing: 'com o valor a seguir (',
              theValueFor: 'o valor do',
              updatedTo: 'muda para cada nova tentativa de integração):',
              click: 'Clique em',
              selectTheFollowing: 'Selecione a seguinte política na lista:',
              optionalAddTags:
                '(Opcional) Se desejar, adicione tags a esta função. Clique em',
              fieldWith: 'com',
              theRoleNameMust: 'o nome da função deve ser exatamente',
              youCanAlsoAdd:
                'Você também pode adicionar uma descrição, se desejar.',
              reviewTheInformation: 'Revise as informações e clique em',
              highlights: {
                roles: 'Roles',
                createRole: 'Create role',
                awsAccount: 'AWS Account',
                anotherAwsAccount: 'Another AWS account',
                accountId: 'Account ID',
                requireExternalId: 'Require external ID',
                externalId: 'External ID',
                important: 'ATENÇÃO:',
                next: 'Next',
                roleName: 'Role name',
                unxposeRole: 'UnxposeRole',
                unxposePolicy: 'UnxposePolicy',
              },
            },
            stepThree: {
              title: 'Passo 3: Integrando a Unxpose com a sua conta da AWS',
              fillTheField:
                'Preencha o campo abaixo com o ID de sua conta da Amazon (número de 12 dígitos).',
              clickHere: 'Clique aqui',
              ifYouNeedHelp:
                ' se precisar de ajuda para encontrar o ID da sua conta.',
              amazonAccountId: 'ID da conta da Amazon',
              monitorMyAccount: 'Monitorar minha conta',
              idAlreadyInUse: 'Este ID de conta já está sendo usado.',
              invalidAccountId: 'ID da conta inválido.',
            },
          },
          removeIntegration: {
            tryingToRemoveAccount:
              'Você está tentando remover a integração da conta com ID: ',
          },
          integrationUpdate: {
            manual: {
              title: 'Como atualizar sua integração na AWS (manual)',
              description:
                'Este guia irá ajudá-lo a atualizar as permissões de sua integração na AWS.',
              steps: {
                one: '1. Após acessar o console da [AWS](https://console.aws.amazon.com/), procure por **IAM** no campo superior de busca',
                two: '2. No menu lateral esquerdo, acesse **Políticas** _(1)_',
                three:
                  '3. Na lista de políticas, busque por **UnxposePolicy** _(2)_',
                four: '4. Na aba **Permissões** *(3)* você irá alterar a política. Clique no botão **Editar** *(4)*',
                five: '5. Escolha a opção **JSON** *(5)*. No editor de texto *(6)*, substitua o conteúdo da política pelo novo conteúdo a seguir:',
                six: '6. Clique em **Próximo** e revise as informações ',
                seven: '7. Para finalizar, clique em **Salvar**',
              },
              conclusion:
                'Agora você deve aguardar até 24h para que a Unxpose faça uma nova checagem desta integração atualizada.',
              copyJson: 'Copiar JSON',
            },
            cloudFormation: {
              title: 'Como atualizar sua integração na AWS (CloudFormation)',
              description:
                'Este guia irá ajudá-lo a atualizar as permissões de sua integração usando o serviço CloudFormation na AWS.',
              steps: {
                one: '1. Após acessar o console da [AWS](https://console.aws.amazon.com/), procure por **CloudFormation** no campo superior de busca',
                two: '2. Procure pela stack **UnxposeIntegration** *(1)* e clique nela',
                three: '3. Clique no botão **Atualizar**',
                four: '4. Selecione a opção **Substituir modelo atual** *(3)*',
                five: '5. No campo **URL do Amazon S3** *(4)*, insira a URL a seguir:',
                six: '6. Clique em **Próximo** até a última página',
                seven:
                  '7. Marque o checkbox *(5)* da seção **Capacidades** que aparece no final da página',
                eight:
                  '8. Clique em **Enviar** e aguarde a atualização da stack ser concluída',
              },
              errorCase: {
                title: 'O que fazer caso a atualização da stack falhar?',
                description:
                  'Quando existe falha na atualização da stack, é necessário removê-la e adicioná-la novamente seguindo os passos:',
                steps: {
                  one: '1. Remova a stack **UnxposeIntegration** *(6)*. Aguarde alguns instantes para que a remoção seja finalizada',
                  two: '2. Adicione a stack novamente utilizando [este link]({{stack_link}})',
                },
                conclusion:
                  'Depois de criar a stack, você deve aguardar até 24h para confirmar que a atualização foi efetuada com sucesso.',
              },
            },
          },
        },
        gcp: {
          errors: {
            billingDisabled:
              'O faturamento do projeto que você está tentando integrar está desativado. Habilite-o visitando https://console.cloud.google.com/billing/projects e associando seu projeto a uma conta de cobrança.',
            disabledApi: {
              failedIntegrationMessage:
                'Esta API não foi usada antes ou está desabilitada no projeto que estamos verificando. Se você ativou esta API recentemente, aguarde alguns minutos para que a ação se propague em nossos sistemas e tente novamente.',
              accordionWarningMessage: 'Ative esta API no console do GCP',
            },
            notAuthorized:
              'Acesso não autorizado. Refaça a integração e certifique-se de que tem permissão para acessar os seguintes serviços:',
            invalidProject:
              'O projeto que você está tentando checar não existe ou foi desabilitado.',
            accountDeleted:
              'A conta que você está tentando checar não existe ou foi desabilitada.',
          },
          integrationRemoved: `Sua conta da GCP não está mais integrada conosco. Não se esqueça de remover os recursos criados para integração com Unxpose em seu console de gerenciamento da GCP.`,
          integrationRetried:
            'Fazendo nova tentativa de integração. Volte em alguns instantes!',
          integrationOptions: {
            chooseMethod: 'Escolha um dos seguintes métodos de integração',
            manualSetup: 'Configuração manual',
            manualSetupDescription:
              'Acesse seu console do GCP e siga as instruções para concluir a integração.',
            selectThisMethod: 'Selecionar este método',
            terraformDescription:
              'Utilize o Terraform para gerar um arquivo de configuração e fazer a integração de forma rápida.',
          },
          terraformIntegration: {
            title:
              'Como integrar a Unxpose à sua conta do GCP usando Terraform',
            intro: {
              title: 'Introdução',
              body: 'Aprenda como integrar sua conta GCP usando Terraform com este tutorial detalhado.',
            },
            prerequisites: {
              title: 'Pré-requisitos',
              gcpAccount: 'Conta do GCP',
              gcpCli: 'CLI do GCP instalada em seu computador',
              terraform: 'Terraform instalado em seu computador',
            },
            stepOne: {
              title: 'Passo 1: Autenticar a CLI do GCP',
              body: 'Execute o comando {{command}} no terminal e faça login na sua conta GCP na página que será aberta em seu navegador. Isso permitirá que você autentique a CLI do GCP e acesse seus projetos.',
            },
            stepTwo: {
              title: 'Passo 2: Baixar o arquivo de configuração',
              downloadLink: 'Clique aqui',
              body: ' para baixar o arquivo zip contendo as configurações necessárias para integrar seus projetos com a aplicação. Em seguida, descompacte o arquivo zip para acessar os arquivos de configuração.',
            },
            stepThree: {
              title: 'Passo 3: Inserir o ID do projeto',
              body: 'Abra o arquivo {{file}} e procure pelo campo {{organization_id}}. Insira nele o ID da sua organização, que é um número de 10 dígitos. Você pode encontrar o ID da sua organização no dropdown no topo da página do console do GCP.<br/><br/>Em seguida, localize o campo {{project_ids}} e insira o(s) ID(s) do(s) projeto(s) que deseja integrar. Salve as alterações no arquivo {{file}}.',
            },
            stepFour: {
              title: 'Passo 4: Inicializar o Terraform',
              body: 'Abra o terminal e navegue até o diretório onde o arquivo {{file}} está localizado. Em seguida, execute o comando {{command}} para inicializar o Terraform e baixar as dependências necessárias.',
            },
            stepFive: {
              title: 'Passo 5: Criar os recursos necessários com o Terraform',
              body: `Em seguida, execute o comando {{command}}. Isso irá criar os recursos do GCP necessários para a integração com a sua aplicação, como chaves de acesso e segredos. No terminal, você poderá visualizar as alterações que serão feitas e você será solicitado a digitar 'yes' para continuar com a criação dos recursos`,
            },
            stepSix: {
              title: 'Passo 6: Aguardar a criação dos recursos',
              body: 'Aguarde até que o Terraform termine a criação dos recursos. Quando finalizado, um arquivo será gerado na raiz do diretório para cada projeto integrado. Esses arquivos conterão as credenciais necessárias para acessar os recursos do projeto.',
            },
            stepSeven: {
              title: 'Passo 7: Fazer o upload do arquivo de configuração',
              body: 'Faça o upload do(s) arquivo(s) gerado(s) na raiz do projeto. Eles serão nomeados como {{file}}. Observe que haverá um arquivo gerado por projeto, e você precisará fazer o upload de cada um individualmente. Para fazer o upload, arraste cada arquivo para a área de upload abaixo.',
            },
            conclusion: {
              title: 'Conclusão',
              body: 'Parabéns! Quase tudo pronto. Só falta clicar no botão abaixo para tornar sua conta do Google Cloud Platform mais segura com a Unxpose.',
            },
          },
          manualIntegration: {
            title: 'Como integrar a Unxpose à sua conta do GCP',
            intro: {
              title: 'Introdução',
              inOrderToDiscover:
                'Para descobrir e monitorar seus recursos do Google Cloud Platform, precisamos que você configure uma autorização para nossa conta. Isso pode ser feito usando diferentes estratégias que podem ou não incluir chaves/senhas. Optamos por usar uma integração sem chaves para minimizar riscos para a sua organização. Ela funciona usando a federação de identidades para permitir que nossa conta da AWS represente uma conta de serviço em seu projeto do GCP.',
              stepOne: 'Passo 1: Criar um Papel no nível da organização',
              stepTwo: 'Passo 2: Criar uma Conta de Serviço',
              stepThree:
                'Passo 3: Conceder Acesso Organizacional à Conta de Serviço',
              stepFour:
                'Passo 4: Criar um Pool de Identidade de Carga de Trabalho',
              stepFive: 'Passo 5: Conceder acesso à Conta de Serviço',
              theFollowingTutorial:
                'O tutorial a seguir vai orientar você na integração do GCP. Se houver algum problema durante o processo, sinta-se à vontade para solicitar assistência através do nosso',
              supportEmail: 'email de suporte.',
            },
            stepOne: {
              title: 'Passo 1: Criar um Papel no nível da organização',
              theFirstThing: `A primeira coisa que você precisa fazer é criar um novo papel personalizado na sua organização do GCP. Esse papel terá todas as permissões de que a Unxpose precisa para monitorar seus recursos. Acesse seu console do GCP em`,
              selectYourOrganization:
                'Selecione sua organização usando o dropdown na barra superior.',
              onceYouClick:
                'Depois de clicar no dropwdown, um modal com uma lista contendo projetos e organizações irá aparecer. Selecione a organização que você gostaria de monitorar.',
              onceYouSelected:
                'Depois de selecionar a organização, clique no menu de navegação no canto superior esquerdo. Selecione',
              andThen: 'e em seguida',
              chooseARole:
                'Escolha um título para o papel que possa ser facilmente associado à Unxpose.',
              clickOn: 'Clique em',
              selectEachOf:
                'Selecione cada uma das seguintes permissões para o novo papel:',
              finishThisStep: 'Para finalizar este passo, clique no botão',
              button: '',
              highlights: {
                iamAdmin: 'IAM & Admin',
                roles: 'Roles',
                createRole: 'Create Role.',
                addPermissions: 'Add permissions.',
                create: 'Create',
              },
            },
            stepTwo: {
              title: 'Passo 2: Criar uma Conta de Serviço',
              firstlyYouNeed:
                'Primeiro, você precisa selecionar o projeto que deseja integrar à Unxpose usando o mesmo dropdown em que você selecionou a organização no primeiro passo deste tutorial.',
              nowYouNeed:
                'Agora você precisa criar uma conta de serviço no projeto que deseja monitorar. Você pode fazer isso acessando',
              clickOn: 'Clique em',
              giveTheService:
                'Dê à conta de serviço um nome que possa ser facilmente associado à Unxpose.',
              whileCreating:
                'Ao criar a conta de serviço, adicione o papel criado no primeiro passo deste tutorial.',
              highlights: {
                iamServiceAccounts: 'IAM & Admin > Service Accounts.',
                createServiceAccount: 'Create Service Account.',
              },
            },
            stepThree: {
              title:
                'Passo 3: Conceder Acesso Organizacional à Conta de Serviço',
              evenThough:
                'Mesmo criando a Conta de Serviço no nível do projeto, você deve conceder a ela permissões no nível da organização para que possamos validar as permissões do role. Siga estes passos:',
              steps: {
                organizationSelect:
                  'Certifique-se de que sua organização está selecionada no topo da página do console do Google Cloud.',
                accessIAM: 'Acesse <strong>IAM & Admin > IAM</strong>.',
                grantAccess: 'Clique em Conceder Acesso.',
                newPrincipal:
                  'No campo Novo Principal, insira o e-mail da Conta de Serviço criada no Passo 2.',
                selectRole:
                  'No campo Papel, selecione <strong>Papéis Personalizados > [Seu Papel Unxpose]</strong>.',
                saveButton: 'Clique em Salvar para aplicar as permissões.',
              },
            },
            stepFour: {
              title:
                'Passo 4: Criar um Pool de Identidade de Carga de Trabalho',
              aWorkloadIdentity:
                'Um Pool de Identidade de Carga de Trabalho concederá papéis de IAM a identidades externas, incluindo a capacidade de representar Contas de Serviço. Assim é possível acessar recursos diretamente, usando um token de acesso de curta duração, e eliminar a manutenção e os riscos de segurança associados às chaves de Contas de Serviço.',
              toReadMore:
                'Para ler mais sobre Federação de Identidade da Carga de Trabalho, acesse',
              withTheProject: 'Com o projeto selecionado, acesse ',
              clickOn: 'Clique em',
              chooseAPool:
                'Escolha um nome ou ID para o pool que possa ser facilmente associado à Unxpose.',
              addAAwsProvider: 'Adicione um provedor da AWS ao pool.',
              chooseAProvider:
                'Escolha um nome ou ID para o provedor que possa ser facilmente associado à Unxpose e, quando for solicitado o ID da conta da AWS, insira o nosso:',
              finishThisStep: 'Finalize este passo clicando no botão',
              button: '',
              highlights: {
                iamWorkload: 'IAM & Admin > Workload Identity Federation.',
                createPool: 'Create Pool.',
                save: 'Save',
              },
              attributeMapping:
                'Na seção <strong>Configure provider attributes</strong> clique em <strong>Edit mapping</strong> e adicione o mapeamento do atributo <strong>attribute.account</strong> para <strong>assertion.account</strong>, como demonstrado na imagem a seguir.',
            },
            stepFive: {
              title: 'Passo 5: Conceder accesso à Conta de Serviço',
              nowThatYou:
                'Agora que você criou o Pool de Identidade de Carga de Trabalho, você precisa conceder acesso à conta de serviço a todas as identidades desse pool. Na tela Federação de Identidade da Carga de Trabalho, clique no botão de editar referente ao pool criado.',
              clickOn: 'Clique em',
              selectImpersonation:
                'Selecione <strong>Grant access using Service Account impersonation</strong> nas opções disponíveis',
              selectTheService:
                'Selecione a Conta de Serviço que você criou neste tutorial.',
              attributeMatching:
                'Na seção <strong>Select principals</strong> escolha o atributo <strong>account</strong> e coloque novamente o Account ID da Unxpose (<strong>708205383577</strong>) e clique em <strong>Salvar</strong>, como demonstrado na imagem a seguir.',
              onTheModal:
                'No modal que surgiu, escolha o provedor criado anteriormente e baixe o arquivo de configuração.',
              uploadTheConfig:
                'Faça o upload do arquivo de configuração para que possamos nos conectar com sua cloud. Você pode fazer isso arrastando o arquivo para a área abaixo.',
              dragAndDrop:
                'Arraste e solte o arquivo de configuração aqui ou clique para buscá-lo em seu computador',
              invalidConfigFile: 'Arquivo de configuração inválido.',
              highlights: {
                grantAccess: 'Grant Access.',
                save: 'Save.',
              },
            },
            stepSix: {
              title:
                'Passo 6: Certifique-se de que todas as APIs estão habilitadas',
              text: 'Verifique se as APIs dos ativos necessários estão habilitadas para o seu projeto. Use os links a seguir para verificar e habilitar cada API:',
            },
            theEnd: {
              title: 'Conclusão',
              congratulations: `Parabéns! Quase tudo pronto. Só falta clicar no botão abaixo para tornar sua conta do Google Cloud Platform mais segura com a Unxpose.`,
              monitorMyAccount: 'Monitorar minha conta',
            },
          },
          updateIntegration: {
            updateYourIntegration:
              'Atualize sua integração com Google Cloud Plataform',
            sinceLastUpdate:
              'Desde a última atualização as seguintes permissões foram adicionadas:',
            whyDoingThis: 'Por que tenho que fazer isso?',
            alwaysAddingNew:
              'Estamos sempre incluindo novas checagens, seja para aumentar o escopo dos serviços já monitorados ou para começar o monitoramento de novos serviços do GCP. Para isso, em algumas ocasiões vai ser necessário pedir para que você adicione novas permissões relacionadas a esses novos serviços. Caso tenha dúvidas, sinta-se à vontade para solicitar assistência atráves do nosso',
            stepByStep: {
              title:
                'Segue o passo a passo do que precisa ser feito para adicionar essa nova permissão:',
              stepOne: {
                title: 'Passo 1: Acessar o papel no nível de organização',
                afterDropDown: `Após clicar no dropdown, um modal com uma lista contendo projetos e organizações irá aparecer. Selecione a organização que foi utilizada na integração. Note que é preciso selecionar a organização "pai" do projeto integrado e não o projeto em si.`,
                accessPortal:
                  'Acesse o portal do Google Cloud Plataform com seu usuário corporativo em',
                clickDropDown: '. Clique no dropdown na barra superior.',
              },
              stepTwo: {
                title: 'Passo 2: Adicionar permissões que estão faltando',
                addButton: 'Add',
                addPermissions: 'Add permissions',
                lookForRole:
                  'Busque pelo papel que foi criado no momento da integração, é provável que ele tenha sido criado utilizando o nome',
                accessRole:
                  'Acesse o papel clicando nele e em seguida clique em',
                demonstration: 'como demonstra a imagem a seguir.',
                editRole: 'Edit Role',
                editionScreenClick:
                  'Dentro da tela de edição de um papel, clique em',
                toAdd:
                  'Para adicionar as permissões que estão faltando você pode utilizar a funcionalidade de filtragem, presente acima da tabela. Em seguida é preciso marcar o checkbox da permissão e clicar em ',
                toFinish: 'Para finalizar este passo, clique no botão ',
                updateButton: 'Update',
                verifyPermissions:
                  'Verifique se o seu papel contém todas permissões a seguir:',
              },
              stepThree: {
                title:
                  'Passo 3: Conceder Acesso Organizacional à Conta de Serviço',
                evenThough:
                  'Mesmo criando a Conta de Serviço no nível do projeto, você deve conceder a ela permissões no nível da organização para que possamos validar as permissões do role. Siga estes passos:',
                steps: {
                  organizationSelect:
                    'Certifique-se de que sua organização está selecionada no topo da página do console do Google Cloud.',
                  accessIAM: 'Acesse <strong>IAM & Admin > IAM</strong>.',
                  grantAccess: 'Clique em Conceder Acesso.',
                  newPrincipal:
                    'No campo Novo Principal, insira o e-mail da Conta de Serviço criada no Passo 2.',
                  selectRole:
                    'No campo Papel, selecione <strong>Papéis Personalizados > [Seu Papel Unxpose]</strong>.',
                  saveButton: 'Clique em Salvar para aplicar as permissões.',
                },
              },
              stepFour: {
                title: 'Passo 4: Confirmar o sucesso da atualização',
              },
            },
          },
          removeIntegration: {
            tryingToRemoveProject:
              'Você está tentando remover a integração do projeto: ',
          },
        },
        azure: {
          errors: {
            applicationNotFound:
              'O aplicativo não foi encontrado no diretório/locatário. Isso poderá acontecer se o aplicativo não tiver sido instalado pelo administrador do locatário ou aceito por qualquer usuário no locatário. Você pode ter configurado incorretamente o valor do identificador para o aplicativo ou ter enviado a solicitação de autenticação para o locatário errado.',
            endpointResolutionError:
              'Não foi possível resolver o endpoint. Certifique-se de que o ID do diretório está correto.',
            expiredClientSecret:
              'As chaves secretas do cliente fornecidas expiraram. Acesse o portal do Azure para criar novas chaves para seu aplicativo.',
            invalidClientSecret:
              'Segredo do cliente inválido. Certifique-se de que o segredo que está sendo enviado na solicitação é o Valor do segredo do cliente, e não o ID do segredo do cliente.',
            noSubscriptionFound:
              'Não foi possível encontrar uma assinatura. Verifique se você tem uma assinatura relacionada ao seu locatário (tenant) do Azure Active Directory.',
            tenantNotFound:
              'Diretório não encontrado. Certifique-se de que o ID do diretório esteja correto e que você está logado na nuvem correta. Esse erro pode ocorrer se não houver assinaturas ativas para o diretório.',
            subscriptionTypeNotPermitted:
              'O tipo atual de assinatura não tem permissão para executar operações em nenhum namespace de provedor. Use uma assinatura diferente.',
          },
          integrationRemoved: `Sua conta da Azure não está mais integrada conosco. Não se esqueça de remover os recursos criados para integração com Unxpose em seu console de gerenciamento da Azure.`,
          integration: {
            title: 'Como integrar a Unxpose com sua conta do Azure',
            intro: {
              inOrderTo:
                'Para que possamos monitorar sua cloud, você precisa nos conceder acesso a alguns de seus recursos do Azure. Sempre tentamos solicitar a menor quantidade de permissões possível.',
              theFirstThing:
                'A primeira coisa a ser feita é criar um aplicativo do Azure para a Unxpose.',
              toCreateAn:
                'Para criar um aplicativo do Azure, sua conta deve ter a função',
              roleForTheDirectory: 'para o Azure Active Directory e a função',
              roleForTheSubscription:
                'para o Azure subscription. Atribua essas funções à sua conta do Azure antes de continuar.',
              highlights: {
                userAdministrator: 'User Administrator',
                userAccessAdministrator: 'User Access Administrator',
              },
            },
            stepOne: {
              title: 'Passo 1: Criar o aplicativo do Azure',
              accessThe: 'Acesse a página',
              page: '',
              clickOnThe: 'Clique no botão',
              buttonAndThen: 'e depois em',
              enterAName:
                'Insira um nome para o seu aplicativo do Azure (Unxpose Integration, por exemplo)',
              forTheSupported: 'Na seção "Supported account types", selecione',
              clickOn: 'Clique em',
              highlights: {
                microsoftEntraId: 'Microsoft Entra ID',
                add: 'Add',
                appRegistration: 'App registration.',
                accountsInThis:
                  'Accounts in this organizational directory only.',
                register: 'Register.',
              },
            },
            stepTwo: {
              andThen: 'e depois em',
              title: 'Passo 2: Criar um segredo do aplicativo',
              inTheAzureActive: 'Acesse a página',
              menu: '',
              clickApplication:
                'Clique no aplicativo que você acabou de criar.',
              clickMenu: 'Clique no menu',
              clickOn: 'Clique em',
              enterADescription:
                'Insira uma descrição para o segredo do cliente (Unxpose Integration Secret, por exemplo).',
              selectTheDesired:
                'Selecione a duração desejada para esta credencial (24 meses). O segredo do cliente expira após esse período. Recomendamos que você gere um novo segredo e o envie aqui quando a data de expiração estiver próxima.',
              theClientSecret: 'O Valor do segredo do cliente deve aparecer.',
              recordTheClient: 'Guarde o',
              youWillUse:
                'Você o utilizará ao registrar o aplicativo do Azure com a Unxpose. Certifique-se de copiar o campo',
              andNotThe: 'e não o',
              warningTitle: 'Atenção',
              warningBody:
                'O Valor do segredo do cliente aparece somente uma vez, então registre-o agora. Caso contrário, você precisará gerar um novo segredo do cliente para obter um novo ',
              highlights: {
                appRegistrations: 'App registrations',
                certificatesAndSecrets: 'Certificates & secrets.',
                manage: 'Manage',
                newClientSecret: 'New client secret.',
                add: 'Add',
                value: 'Valor',
                secretId: 'Secret ID.',
              },
            },
            stepThree: {
              title: 'Passo 3: Configurar Assinaturas',
              intro:
                'Para que a Unxpose monitore seus recursos, é necessário criar uma nova Função Personalizada na Assinatura e atribuí-la ao aplicativo criado anteriormente. Essa etapa deve ser repetida para cada assinatura que você deseja monitorar.',
              one: '3.1: Criar uma nova função personalizada',
              two: '3.2: Atribuir a função ao aplicativo do Azure',
              inThe: 'Acesse a página',
              pageClick: 'clique na sua assinatura.',
              clickOnTheAccess: 'Clique em',
              clickOnTheButton: 'Clique no botão',
              clickOnTheTab: 'Clique na aba',
              menu: '',
              clickOn: 'Clique em',
              andThen: 'e depois em',
              createARole:
                'Crie uma função com um nome descritivo (UnxposeRole, por exemplo).',
              tabAndThen: 'e depois no botão',
              button: '',
              addTheFollowing:
                'Adicione as seguintes permissões à propriedade "actions" do JSON. Você pode encontrá-la em',
              buttonForThe: 'do campo JSON.',
              andThenClick: 'e depois em',
              unfortunatelyItMight:
                'Infelizmente, pode levar alguns minutos até que sua função fique disponível, então respire fundo antes de partir para a próxima etapa.',
              highlights: {
                subscriptions: 'Subscriptions,',
                accessControl: 'Access Control (IAM)',
                add: 'Add',
                addCustomRole: 'Add Custom Role.',
                json: 'JSON',
                edit: 'Edit',
                propertiesActions: 'properties > permissions > actions.',
                save: 'Save',
                reviewCreate: 'Review + create',
                create: 'Create',
              },
              conclusion:
                'Lembre-se de repetir este processo para cada assinatura que você deseja monitorar.',
            },
            stepFour: {
              inTheSection: 'Na seção',
              inThePage: 'Na página',
              pageClick: 'clique na sua inscrição.',
              clickOnThe: 'Clique no menu',
              menu: '',
              clickOn: 'Clique em',
              andThen: 'e depois em',
              selectTheRole:
                'Selecione a função que você acabou de criar (UnxposeRole, por exemplo) e clique em',
              ifYouCantFind: `Se você não conseguir encontrá-la, aguarde alguns minutos e atualize a página.`,
              on: 'Na subseção',
              sectionSelect: 'selecione',
              sectionClick: 'clique em',
              onThe: 'No campo',
              fieldType:
                'digite o nome do aplicativo do Azure que você criou (Unxpose Integration, por exemplo) e clique nele. Em seguida, clique no botão',
              button: '',
              reviewCreate: 'Review + create.',
              invalidConfiguration: 'Configuração inválida.',
              directoryIdAlreadyInUse:
                'Este ID de diretório já está sendo usado.',
              genericError:
                'Algo deu errado. Verifique a sua configuração e tente novamente.',
              highlights: {
                subscriptions: 'Subscriptions',
                accessControl: 'Access Control (IAM)',
                add: 'Add',
                addRoleAssignment: 'Add role assignment.',
                next: 'Next',
                assignAccessTo: 'Assign access to',
                userGroupOrService:
                  'Assign access to User, group, or service principal.',
                select: 'Select',
                selectMembers: '+Select members',
                members: 'Members',
              },
            },
            stepFive: {
              title: 'Passo 4: Integrar sua conta da Azure',
              nowThatYou: `Agora que todas as etapas foram concluídas em sua conta do Azure, você precisa nos fornecer as informações necessárias para identificar e autenticar em sua conta.`,
              youCanEasily:
                'Você pode encontrar facilmente o ID do aplicativo e o ID do diretório acessando a página',
              andClickingOn:
                'e, em seguida clicando no aplicativo que você criou.',
              findingTheApplication:
                'Encontrando o ID do Aplicativo e o ID do diretório:',
              type: 'Digite',
              inTheSearch: 'na barra de pesquisa.',
              clickOnApplication:
                'Clique no aplicativo que você criou neste tutorial.',
              copyTheCredentials:
                'Copie as credenciais destacadas na imagem acima.',
              applicationId: 'ID do Aplicativo (cliente)',
              directoryId: 'ID do diretório (locatário)',
              secretValue: 'Valor do segredo',
              uuidFound: 'UUID encontrado na página do seu aplicativo',
              randomValue: 'Valor aleatório que você copiou no Passo 2',
              monitorMyAccount: 'Monitorar minha conta',
              highlights: {
                appRegistrations: 'App registrations',
              },
            },
          },
          removeIntegration: {
            tryingToRemoveTenant:
              'Você está tentando remover a integração do diretório: ',
          },
        },
        connectingToProvider: 'Estamos nos conectando ao seu provedor',
        directoriesBeingMonitored: 'Diretórios monitorados',
        directoriesFailing: 'Diretórios falhando',
        directoryBeingMonitored: 'Diretório monitorado',
        directoryFailing: 'Diretório falhando',
        directoryId: 'ID do diretório',
        failedIntegration: 'O processo de integração falhou.',
        gcpIntegrationInProgress: 'Sua integração GCP está em andamento.',
        integrations: 'Integrações',
        filterByProvider: {
          placeholder: 'Filtrar por provedor',
          options: {
            aws: 'AWS',
            gcp: 'GCP',
            azure: 'Azure',
          },
        },
        sortBy: {
          placeholder: 'Ordenar por',
          options: {
            bestScore: 'Melhor score',
            worstScore: 'Pior score',
          },
        },
        manageResources: 'Gerencie seus recursos de cloud',
        monitorYourCloud1: 'Monitore seus recursos de cloud',
        monitorYourCloud2: 'para garantir que estejam sempre protegidos',
        multicloudNotEnabled:
          'Ainda não é possível adicionar múltiplas contas.',
        noAssets:
          'No momento, não há nenhum ativo deste serviço sendo monitorado',
        noFindingsInIntegration:
          'Não há nenhum achado associado a esta integração no momento',
        noFindingsInService:
          'Não há nenhum achado associado a este serviço no momento',
        projectBeingMonitored: 'Projeto monitorado',
        projectFailing: 'Projeto falhando',
        projectId: 'ID do projeto',
        projectsBeingMonitored: 'Projetos monitorados',
        projectsFailing: 'Projetos falhando',
        selectProvider1:
          'Escolha abaixo a conta que deseja acessar e fique em dia',
        selectProvider2: 'com as descobertas mais recentes',
        startIntegration:
          'Selecione o provedor para inicar o processo de integração',
        noResults: {
          title: 'Nenhuma conta foi configurada',
          advantages: {
            title: 'Proteja a Cloud da sua empresa',
            listItems: {
              first:
                'Conecte as Clouds da sua empresa em poucos cliques e identifique falhas de configuração a partir de frameworks reconhecidos',
              second:
                'Veja as falhas de segurança priorizadas por nível de risco com evidência de onde foram encontradas, entenda o impacto de cada uma delas e siga o passo a passo para corrigí-las',
              third:
                'A Unxpose faz varreduras diárias para você não ter que se preocupar em agendar os scans',
            },
          },
        },
      },
      compliance: {
        lgpd: {
          integrationsUnderMonitoring: 'Integrações sob monitoramento em LGPD',
          lgpdBenefitsItems: {
            first: 'Saiba o nível de conformidade do seu ambiente com a LGPD',
            second: 'Veja quais falhas de segurança impactam esse indicador',
            third: 'Entenda como melhorá-lo',
          },
          toEnsureYourCompany:
            'Para garantir que sua empresa está em conformidade com a LGPD, precisamos primeiro que você conecte seu provedor de Cloud à Unxpose. Clique no link abaixo e siga o passo a passo!',
          selectTheIntegrations:
            'Selecione as integrações que você deseja monitorar em LGPD',
          lgpdAndCloud:
            'LGPD e Cloud: aumente o nível de conformidade do seu ambiente',
          definition: `A Lei Geral de Proteção de Dados (13.709/2018) tem como principal objetivo proteger os direitos fundamentais de liberdade e de privacidade. Para estar em conformidade com a Lei, as empresas precisam, dentre outros, implementar melhorias no processo de tratamento de dados na camada de tecnologia – inclusive a nuvem.`,
          improveYourCompliance:
            'Clique em Detalhes para entender como você pode aumentar a conformidade da sua empresa à LGPD.',
          buttons: {
            manage: 'Gerenciar',
            saveChanges: 'Salvar alterações',
            startMonitoring: 'Começar a monitorar',
          },
          disclaimer: {
            title: 'Aviso legal',
            body: 'A Unxpose é uma empresa de cibersegurança focada em identificar falhas de segurança na superfície de exposição, na Cloud e nos SaaS utilizados pelos seus colaboradores. Assim, nossa análise de conformidade também considera estes ambientes, e não pode ser utilizada como indicativo de conformidade à LGPD em outros ambientes virtuais ou físicos de sua empresa. Nosso índice de conformidade avalia configurações de segurança que se relacionam à LGPD e podem reduzir ou mitigar riscos de incidentes.',
          },
          modal: {
            lgpdArticle: 'O que diz a lei?',
            reason: 'Qual a relação desse achado com a LGDP?',
          },
          stats: {
            adhesion: 'Conformidade',
            assets: 'Ativos',
            findings: 'Achados',
            integrations: 'Integrações',
            providers: 'Provedores',
          },
          success: {
            noFindings:
              'No momento, não há achados relacionados a Compliance nas suas integrações.',
            updateIntegration: 'Suas integrações foram atualizadas.',
          },
          tables: {
            article: 'Artigo',
            name: 'Nome',
            provider: 'Provedor',
          },
        },
      },
      dns: {
        addDomainBtn: {
          submit: 'Adicionar',
          cancel: 'Cancelar',
        },
        domainVerification: {
          title: 'Verificação do domínio',
          body: {
            firstParagraph:
              'É necessário verificar o domínio  <strong>{{hostName}}</strong> para concluir sua adição. Você pode verificá-lo através de duas formas: adicionando um <strong>registro TXT no DNS</strong> do domínio ou fazendo <strong>upload de um arquivo TXT</strong> no seu servidor.',
            secondParagraph:
              'A verificação é importante para garantir que somente pessoas autorizadas tenham acesso aos dados relacionados ao domínio.',
          },
          selectMethod: {
            label: 'Método de verificação',
            recordMethod: 'Adicionar registro TXT no seu domínio',
            fileMethod: 'Fazer upload de arquivo TXT no seu servidor',
          },
          recordMethodSteps: {
            first:
              'Acesse seu provedor de domínios e procure pela seção de <strong>Registros DNS</strong>',
            second: 'Copie o valor TXT abaixo e registre-o em seu provedor',
            third:
              'Após se certificar de que o registro foi atualizado, clique em <strong>Verificar</strong>',
            noteForThird:
              '<strong>Nota:</strong> As mudanças de DNS podem se propagar em até 48h',
          },
          fileMethodSteps: {
            first: '<a>Baixe o arquivo</a> de verificação TXT',
            second: 'Faça o upload deste arquivo na raiz do domínio',
            noteForSecond: 'Iremos buscá-lo em <strong>{{domainPath}}</strong>',
            third:
              'Pronto! Após fazer o upload, clique em <strong>Verificar</strong>',
            noteForThird: 'Nós faremos a checagem automaticamente',
          },
          support:
            'Se precisar de ajuda, entre em contato pelo <a>support@unxpose.com</a>',
        },
        filters: {
          typePlaceholder: 'Filtrar por tipo de registro',
        },
        nameServersTable: {
          nakedDomainsCount: '# de naked domains',
          nameServers: 'Name servers',
          subdomainsCount: '# de subdomínios',
          title: 'Detalhamento de name servers',
        },
        filterByRegistrar: {
          placeholder: 'Filtrar por registrador',
        },
        nameServerDnsRecords: 'Registos DNS do tipo Name Server',
        dnsRecords: 'Registros DNS',
        domains: 'Domínios',
        removeDomain: 'Remover domínio?',
        noFindings:
          'No momento, não há achados importantes associados a este domínio',
        noImportantFindings:
          'No momento, não há achados importantes nesta seção',
        noRecords: 'No momento, não há registros associados a este domínio',
        noSubdomains:
          'No momento, não há subdomínios associados a este domínio',
        organizationDomains: 'Domínios da empresa',
        subdomains: 'Subdomínios',
        domainScheduledForRemoval: 'será removido em alguns instantes.',
      },
      errors: {
        atLeastFiveCharacters: 'O email deve ter ao menos 5 caracteres',
        atLeastTwoCharacters: 'O nome deve ter ao menos 2 caracteres',
        checkPermissions: 'Verifique se as permissões estão atualizadas.',
        checkPermissionsAws:
          'Confira se sua UnxposePolicy possui as permissões atualizadas conforme nosso tutorial',
        checkPermissionsGcp:
          'Habilite as APIs e confira permissões conforme nosso tutorial',
        confirmationNotMatching: 'A confirmação da senha não corresponde.',
        fieldsMustBeFilled: 'Todos os campos devem ser preenchidos',
        expiredCode: 'O código inserido expirou.',
        invalidCode: 'O código inserido é inválido.',
        invalidCredentials: 'Nome de usuário ou senha inválidos.',
        invalidEmailAddress: 'Endereço de email inválido',
        invalidOrExpiredInvite:
          'Este link pode ter expirado ou é inválido. Os links de convite de usuário são válidos por 7 dias. Se este convite for mais antigo, você precisa solicitar outro convite ao administrador da empresa.',
        invalidOrExpiredLink: 'Este link é inválido ou já expirou.',
        latestCheckFailed: 'Ocorreu um erro na última checagem',
        somethingWentWrong: 'Algo deu errado.',
        tryAgain: 'Tente novamente',
        unexpectedError: 'Ocorreu um erro inesperado, tente novamente.',
        forbiddenError: 'Você não tem permissão para executar esta ação.',
        domainTaken: 'Este domínio já foi adicionado por outra empresa.',
        domainInvalid: 'Domínio inválido.',
        emailInvalid: 'O e-mail informado não é válido.',
        domainInvalidWithSuggestion:
          'Domínio inválido. Você quis dizer {{domainName}}?',
        paasDomain:
          'Domínio inválido. Adicione apenas domínios pertencentes à sua organização.',
        domainExists: 'Este domínio já existe na sua conta.',
        domainDoesNotRespond:
          'O domínio não está respondendo: Verifique se existe um website ativo neste domínio e se o arquivo foi salvo na raiz dele.',
        dnsRecordDoesNotResolve:
          'Não foi encontrado um registro TXT no domínio. Verifique se o registro foi adicionado e aguarde alguns minutos para tentar novamente.',
        domainNotVerified:
          'Um registro TXT foi encontrado, mas ele não possui o valor indicado. Revise os passos e certifique-se se tudo foi feito corretamente.',
      },
      finding: {
        assignment: 'Atribuição',
        assigned: 'Atribuído',
        placeholderSearch: 'Estou procurando por...',
        findingAssignedTo: 'Este achado foi atribuído para {{assignee}}',
        findingUnassigned: 'A atribuição deste achado foi removida',
        firstSeen: 'Primeira detecção:',
        lastSeen: 'Última detecção:',
        lgpdArticles: 'Artigos da LGPD',
        movedFrom: ' moveu de',
        severity: 'Severidade',
        statusUpdatedFrom: 'Status atualizado de',
        assignedTo: 'atribuiu este achado para',
        unassign: 'removeu a atribuição deste achado',
        unassigned: 'Não atribuído',
        modal: {
          title: 'Justificativa para Risco Aceito',
          label: 'Conte-nos porque escolheu a opção de risco aceito (opcional)',
          description:
            'Use este campo para explicar as razões de aceitar este risco. Isso pode incluir ações tomadas para minimizar possíveis impactos ou outras considerações relevantes. Justificar sua escolha é importante para criar um histórico de decisões tomadas.',
          concludeBtn: 'Concluir',
          cancelBtn: 'Cancelar',
        },
      },
      footer: {
        privacyPolicy: 'Política de Privacidade',
        termsOfUse: 'Termos de Uso',
      },
      googleWorkspace: {
        status: {
          active: 'Ativa',
          failed: 'Falhada',
          started: 'Iniciada',
        },
        badgesTooltipMessages: {
          compliantPassword: 'Possui senha em conformidade',
          notCompliantPassword: 'Não possui senha em conformidade',
          informationMissing:
            'Não foi possível obter essa informação do Google Workspace',
          lessSecureAppsDisabled: 'Acesso a apps menos seguros desabilitado',
          lessSecureAppsEnabled: 'Acesso a apps menos seguros habilitado',
          strongPassword: 'Possui senha forte',
          weakPassword: 'Não possui senha forte',
          mfaEnabled: 'MFA habilitado',
          mfaDisabled: 'MFA desabilitado',
        },
        accessDenied: 'Você precisa liberar acesso a sua conta.',
        activeIntegrations: 'Integrações ativas',
        allSet:
          'E tudo pronto! O processo deve ser concluído em poucos minutos.',
        anErrorOccurred: 'Ocorreu um erro, tente integrar novamente',
        clickHereToManageUpdates:
          'Clique aqui para verificar as integrações que precisam ser atualizadas.',
        clickHereToUpdate: 'Atualizar agora',
        clickOnContinue: 'Clicar em "Continue"',
        clickOnTheButton: 'Clicar no botão abaixo',
        connectingToOrganization: 'Estamos nos conectando à sua organização.',
        createdAt: 'Criada em:',
        integrationCreatedBy: 'Criada por:',
        customerId: 'ID do cliente',
        emptyStateBody: 'Volte em alguns minutos!',
        emptyStateLink: 'Ir para Visão Geral',
        emptyStateTitle: 'Aguarde a conclusão do processo de integração',
        googleButton: 'Monitore meu Google Workspace',
        integrationNotFound:
          'A integração que você está procurando não existe.',
        integrationNeedsUpdate:
          'Para que a integração funcione corretamente, conceda permissão às APIs listadas:',
        integrationFailed: 'A integração falhou. Por favor, tente novamente.',
        integrationInProgress:
          'Integração em andamento. Volte em alguns minutos!',
        integrationStarted: 'Integração em andamento...',
        integrationStatus: 'Status da integração:',
        integratingIsEasy:
          'Realizar a integração é fácil. Primeiro, se certifique de que você é administrador no Google Workspace.',
        integrations: 'Integrações',
        latestCheck: 'Última checagem:',
        manageIntegrations: 'Gerenciar integrações',
        newIntegration: 'Nova integração',
        markTheFields:
          'Marcar os campos "Ver relatórios de uso sobre seu domínio do G Suite" e "Visualize os domínios relacionados a seus clientes"',
        noUsers: 'Nenhum usuário',
        permissionsNeeded:
          'Privilégios de administrador ou permissões de API necessárias para integração:',
        refreshTokenMissing:
          'Remova integrações anteriores na Autorização de Apps do Google em https://myaccount.google.com/connections e tente novamente.',
        removeIntegrationSuccess: 'Integração removida com sucesso.',
        thenYouJustHaveTo: 'Em seguida, basta você:',
        title: 'Monitore seu Google Workspace em apenas alguns cliques!',
        update: 'Atualizar integração',
        updateRequired: 'Atualização necessária!',
        users: 'Usuários',
        workspaceEmpty: `Não encontramos nenhum usuário neste Workspace`,
        errors: {
          customerIdAlreadyInUse:
            'ID do cliente já está sendo utilizado. A organização do Workspace que você está tentando conectar já está conectada à Unxpose.',
          insufficientPermission:
            'Permissões insuficientes. Tente a integração com o Google Workspace novamente e marque os seguintes checkboxes:',
          invalidGrant:
            'Concessão inválida. O acesso à Unxpose foi revogado, favor entrar em contato com support@unxpose.com.',
          notAuthorized:
            'O usuário que fez a integração não era um administrador. Peça a um administrador para conceder as seguintes permissões:',
          unauthorizedClient:
            'Cliente não autorizado. Entre em contato com support@unxpose.com.',
          unknown:
            'Tente a integração com o Google Workspace novamente. Algo deu errado com as seguintes APIs:',
        },
        failedIntegration: {
          failedIntegration: 'Uma das suas integrações falhou.',
          seeErrorDetails:
            'Clique para mais detalhes e remova-a para tentar novamente.',
        },
        startedIntegration: {
          integrationInProgress: 'Integração em andamento.',
          comeBackInMinutes: 'Volte em alguns minutos!',
        },
      },
      help: {
        sectionTitle: 'Ajuda',
        takeATourBtn: 'Faça um tour',
        updatedAt: 'Atualizado em {{dateTime}}',
        noResults: {
          title: 'Nenhum resultado encontrado',
          subtitle: 'Tente com outros termos',
        },
        footer: {
          title: 'Ainda precisa de ajuda?',
          contactUs: 'Fale com a gente',
        },
        intro: {
          cloud:
            'Veja os recursos de cloud e potenciais falhas de configuração que encontramos.',
          dns: 'Veja se os seus domínios estão seguros contra ameaças de DNS.',
          help: 'Na nossa seção de Ajuda, você pode ver este tutorial novamente e encontrar outras informações úteis.',
          management:
            'Acesse todos os nossos achados para realizar as devidas correções.',
          network:
            'Veja os serviços de rede expostos (e suas potenciais vulnerabilidades) que estamos monitorando.',
          people:
            'Descubra se há credenciais dos seus colaboradores expostas em vazamentos de dados.',
          reports:
            'Gere um relatório executivo com o resumo do estado de cibersegurança da empresa. Ativos, achados, notas: tudo em apenas um clique ',
          scoreOverTime:
            'Esta é a variação do seu score de segurança nos últimos 15 dias.',
          securityScore:
            'Este é o seu score de segurança. Uma nota mais alta indica que sua empresa está mais segura.',
          web: 'Acompanhe a saúde de cibersegurança das suas aplicações web.',
        },
        peopleIntro: {
          accountsTable:
            'Os e-mails listados nessa tabela foram encontrados pela Unxpose através dos nossos scanners na Web.',
          newIntegrationButton:
            'Acesse este link para integrar sua conta do Google Workspace ou Microsoft 365.',
          helpIcon:
            'Quer saber mais? Clica no ícone de interrogação para ler o artigo de ajuda sobre a seção.',
        },
        twoFactorAuthentication:
          'Autenticação de dois fatores (2FA) é uma camada extra de segurança utilizada para acessar sites ou apps. Com ela, você primeiro precisa logar com seu nome de usuário e senha e, depois, fornecer outra forma de autenticação à qual só você tenha acesso.',

        unresolvedFindingsBySeverity:
          'Achados não resolvidos incluem os status <b>aberto</b>, <b>reaberto</b>, <b>corrigido</b> e <b>intermitente</b>.',
      },
      internetExposure: {
        domain: 'Domínio',
        hostsByCountry: {
          title: 'Hosts por país',
          info: 'TO DO: DEVERÁ SER EDITADO',
          ipCount: 'Quantidade de IPs',
        },
        domainsOverview: 'Visão geral dos domínios',
        subdomains: 'Subdomínios',
        noFindings: 'No momento, não há achados associados a esse domínio',
        registrarNotDetected: 'Não detectado',
        tables: {
          domainsByRegistrar: 'Domínios por registrador',
          hostsByAsOrg: 'Hosts por sistema autônomo',
          registrar: 'Registrador',
          webAppsByProtocol: 'Aplicações web por protocolo',
        },
      },
      nextSteps: {
        noResults: 'Tudo certo por aqui',
      },
      labels: {
        authenticationCode: 'Código de autenticação',
        closedFindings: 'Achados fechados',
        companyName: 'Nome da empresa',
        condition: 'Condição',
        country: 'País',
        currentPassword: 'Senha atual',
        customHeaders: 'Cabeçalhos personalizados',
        days: 'dias',
        disabled: 'Inativo',
        email: 'Email',
        enableEncryption: 'Habilitar criptografia',
        enableWebhook: 'Habilitar webhook',
        enabled: 'Ativo',
        encryptionKey: 'Chave de encriptação',
        inviteExpires: 'Expira {{datetime}}',
        inviteExpired: 'Convite expirado',
        firstName: 'Nome',
        identificationNumber: 'CNPJ',
        jobTitle: 'Cargo',
        language: 'Idioma',
        lastName: 'Sobrenome',
        lastStatus: 'Último status',
        name: 'Nome',
        newFindings: 'Novos achados',
        newPassword: 'Nova senha',
        numberOfEmployees: 'Número de funcionários',
        password: 'Senha',
        passwordConfirmation: 'Confirmação da senha',
        phoneNumber: 'Celular',
        role: 'Função',
        emailSource: 'Origem',
        status: 'Status',
        totalFindings: 'Total de achados',
        usersNotShown: 'Algumas contas podem não estar sendo exibidas aqui',
        weeklyReport: 'Relatório semanal',
        you: 'Você',
        dontHaveAnAccount: 'Não tem conta?',
        createAccount: 'Criar conta',
      },
      links: {
        termsAndConditions: 'Termos e Condições',
      },
      management: {
        noFindings: 'No momento, não há achados associados a sua empresa',
        sales: {
          findingsUnavailable:
            'A visualização de achados não está disponível no seu plano',
          title: 'Ganhe inteligência sobre os achados',
          advantages: {
            first:
              'Exporte relatórios em csv aplicando diversos filtros, como status, atribuição ou ativos onde foram encontrados',
            second:
              'Tenha visibilidade unificada sobre o catálogo de brechas de segurança',
            third:
              'Entenda em quais estágios do ciclo de vida estão os achados da sua empresa',
          },
        },
        bulkChange: {
          selectionItemsBtn: 'Selecionar itens',
          hideSelectionBtn: 'Ocultar seleção',
          bulkChangeBtn: 'Edição em massa',
          nextStepBtn: 'Próximo passo',
          backBtn: 'Voltar',
          confirmBtn: 'Confirmar',
          allFindingsSelected:
            '<strong>Todos os {{qty}} achados</strong> foram selecionados.',
          allFindingsPageSelected:
            '<strong>Os {{qty}} achados</strong> desta página foram selecionados.',
          selectAllFindingsPage: 'Selecionar todos os {{qty}} achados',
          uncheckAll: 'Desmarcar todos',
          nothingFound: 'Há algo errado, pois nenhum resultado foi encontrado.',
          stepOne: {
            stepTitle: 'Passo 1',
            stepDescription: 'Escolha a ação',
            title: 'Você selecionou {{qty}} achado(s)',
            chooseActionLabel: 'Escolha a ação desejada para a seleção',
            changeStatus: 'Alterar status',
            newStatusLabel: 'Escolha o novo status',
          },
          stepTwo: {
            stepTitle: 'Passo 2',
            stepDescription: 'Confirmação',
            title: ' A alteração será feita em {{qty}} achado(s)',
            table: {
              title: 'Título',
              transition: 'Transição (status)',
            },
            modal: {
              title: 'Confirmação',
              description:
                'A operação que será realizada em {{qty}} achado(s) <strong>não poderá ser desfeita</strong>. Deseja continuar?',
              continueBtn: 'Continuar',
              cancelBtn: 'Cancelar',
            },
          },
          stepThree: {
            stepTitle: 'Passo 3',
            stepDescription: 'Resultado',
            title: 'A alteração está sendo feita em {{qty}} achado(s)...',
            subtitle:
              'Você verá aqui o resultado quando o processo for concluído',
            success: 'Sucesso',
            fail: 'Falha',
            operationCompleted: 'Operação concluída!',
            seeDetails: 'Veja detalhes abaixo',
            noFindingsChanged: 'Nenhum achado foi alterado.',
            changeSuccess: 'Sucesso na alteração de {{qty}} achado(s).',
            changeFailure: ' Houve {{qty}} falha(s).',
            goToFindings: 'Ir para Achados',
            errorMsg: {
              invalidFlow:
                'Não é possível alterar de {{oldStatus}} para {{newStatus}}',
              people: 'Os achados de People não podem ser alterados em massa',
            },
            table: {
              title: 'Título',
              previousStatus: 'Status anterior',
              currentStatus: 'Status atual',
              changeStatus: 'Status alteração',
              observations: 'Observações',
            },
          },
        },
      },
      microsoft365: {
        adminAccessNeeded:
          'Para conceder acesso à nossa aplicação Azure no seu diretório, você deve ter privilégios de administrador e ser um usuário nativo do referido diretório.',
        directoryIdAlreadyInUse:
          'ID de diretório já está em uso. Certifique-se de que o diretório não está conectado e use um usuário nativo no diretório que você está tentando conectar.',
        tenantId: 'ID do diretório',
        emptyStateBody: 'Volte em alguns minutos!',
        connectingToOrganization: 'Estamos nos conectando à sua organização...',
        removeIntegrationSuccess: 'Integração removida com sucesso.',
        integrationStatus: 'Status da integração:',
        createdAt: 'Criada em:',
        latestCheck: 'Última checagem:',
        integrationCreatedBy: 'Criada por:',
      },
      misc: {
        active: 'Ativo',
        created: 'Criado',
        all: 'Todos',
        asset: 'Ativo',
        assets: 'Ativos',
        at: 'Em',
        changes: 'Mudanças',
        city: 'Cidade',
        clickHere: 'Clique aqui',
        domain: 'Domínio',
        expires: 'Expira',
        expired: 'Expirado',
        failed: 'Falhado',
        files: 'Arquivos',
        finding: 'Achado',
        findings: 'Achados',
        loadMore: 'Ver mais',
        loading: 'Carregando...',
        noOptions: 'Sem opções.',
        no: 'Não',
        of: 'De',
        justification: 'Justificativa',
        edit: 'Editar',
        remove: 'Remover',
        removed: 'removido',
        resources: 'Recursos',
        since: 'Desde',
        started: 'Iniciado',
        thanks: 'Obrigado',
        to: 'para',
        users: 'Usuários',
        wasAdded: 'Foi adicionado.',
        wasRemoved: 'Foi removido.',
        yes: 'Sim',
        datePickerPlaceholder: 'DD/MM/AAAA',
        datePickerMultiplePlaceholder: 'DD/MM/AAAA - DD/MM/AAAA',
        paginationItems: '{{qty}} itens',
        select: 'Selecionar...',
        copiedToClipboard: 'Copiado!',
        addNew: 'Adicionar',
        calculating: 'Calculando...',
        searchPlaceholder: 'Buscar...',
      },
      network: {
        filterByAsorg: {
          placeholder: 'Filtrar por AS org',
        },
        filterbyCountry: {
          placeholder: 'Filtrar por país',
        },
        host: 'Host',
        fingerprints: 'Fingerprints',
        hostname: 'Hostname',
        hostsFound: 'Hosts encontrados',
        noFindingsInHost: 'No momento, não há achados relacionados a este host',
        noFindingsInService:
          'No momento, não há achados relacionados a este serviço',
        port: 'Porta',
        protocol: 'Protocolo',
        region: 'Região',
        relatedRecords: 'Registros relacionados',
        responsible: 'Responsável',
        services: 'Serviços',
        servicesDetected: 'Serviços detectados',
        servicesBreakdown: 'Detalhamento de serviços',
        nHosts: '# de hosts',
        nServices: '# de serviços',
        csvNhosts: 'Quantidade de hosts',
        csvNservices: 'Quantidade de serviços',
        errors: {
          serviceNotResponding: 'Este serviço não respondeu na última checagem',
        },
        buttons: {
          viewMore: 'ver mais',
        },
      },
      nps: {
        couldntTestTheProduct:
          'O tempo para testar o produto não foi suficiente',
        dontHaveASecurityTeam:
          'Não tenho time para atuar nas demandas de cibersegurança',
        other: 'Outro',
        productDontMeetMyNeeds: 'O produto não atende às minhas necessidades.',
        questionOne: 'Por que nossa parceria não vai continuar?',
        questionThree: 'Conta pra gente o porquê dessa nota:',
        questionTwo:
          'Numa escala de 1 a 10, qual nota você daria à nossa solução?',
        submit: 'Enviar minha avaliação',
        subtitle:
          'Ficamos tristes que a nossa parceria não vai continuar nesse momento. Mas a gente quer ouvir você.',
        surveySent:
          'Recebemos a sua opinião, e ela vai nos ajudar a democratizar ainda mais a cibersegurança.',
        title: 'Sua opinião é importante para nós',
        valueIsTooHigh: 'O valor dos planos é muito alto',
        whatWasMissing: 'Do que você sentiu falta?',
      },
      options: {
        allFindings: 'Todos os achados',
        highOrCritical: 'Achados de severidade alta ou crítica',
        mediumOrHigher: 'Achados de severidade média, alta ou crítica',
      },
      people: {
        account: 'Conta',
        accountNotBreached: 'Não encontramos esta conta em nenhum vazamento.',
        accounts: 'Contas',
        affectedAccounts: 'Contas afetadas',
        breachCount: '# de vazamentos',
        breachDetails: 'Detalhes do vazamento',
        breachesOpen: 'Vazamentos abertos',
        breachesOverTime: 'Vazamentos encontrados ao longo do tempo',
        breaches: 'Vazamentos',
        breachesChecked: 'Vazamentos analisados',
        breachFinding: {
          description:
            'A Unxpose descobriu que as informações deste usuário foram comprometidas como resultado de uma violação em um site de terceiros. A violação ocorreu devido ao registro do usuário no site afetado usando o endereço de e-mail mencionado anteriormente. Nosso sistema de monitoramento sinalizou este e-mail porque está associado a um dos seus provedores de identidade conectados, registrado dentro desta aplicação ou vinculado a um dos seus domínios monitorados.',
          remediation: {
            intro:
              'Quando as informações de um usuário são comprometidas, é importante tomar medidas imediatas para mitigar qualquer dano potencial. Aqui estão alguns passos comuns de remediação para um usuário afetado:',
            notify:
              'Notificar o usuário: Informar o usuário sobre a violação o mais rápido possível. Fornecer informações claras e concisas sobre quais dados foram comprometidos e quais riscos potenciais eles podem enfrentar.',
            passwords:
              'Redefinir senhas: Instruir o usuário a alterar imediatamente as senhas das contas afetadas. Encorajá-los a criar senhas fortes e exclusivas e habilitar autenticação em dois fatores para segurança aprimorada.',
            monitor:
              'Monitorar contas: Aconselhar o usuário a monitorar regularmente suas contas financeiras, de e-mail e outras contas sensíveis em busca de qualquer atividade suspeita. Eles devem relatar imediatamente quaisquer transações não autorizadas ou comportamento incomum.',
            educate:
              'Educar os usuários: Aumentar a conscientização entre os usuários sobre as melhores práticas de segurança online, como evitar e-mails ou links suspeitos, usar senhas fortes e atualizar regularmente software e aplicativos.',
            warning:
              'Lembre-se de que cada violação pode ter circunstâncias únicas, portanto, é essencial adaptar os passos de remediação com base na situação específica.',
            conclusion:
              'Após de implementar as medidas necessárias, você tem a opção de marcar esse problema como resolvido, a fim de registrar seu histórico de resoluções.',
          },
        },
        changeBreachFindingStatus: {
          closed: {
            mitigated: 'Medidas de segurança foram tomadas',
            removed: 'Conta não existe mais',
            other: 'Outro',
          },
          falsePositive:
            'Essa opção indica que este e-mail nunca esteve associado à sua organização, o que significa que se trata de um erro da plataforma. Nossa equipe realizará uma verificação manual e poderá removê-lo, se necessário.',
        },
        dashboard: {
          accountsUnderMonitoring: 'Contas',
          accountsWithoutMfa: 'Contas sem MFA',
          integrations: 'Integrações',
        },
        credentials: 'Credenciais',
        dataLeaked: 'Dados vazados',
        failedIntegration: 'O processo de integração falhou.',
        general: 'Geral',
        googleLoginEnabled: 'Habilitado',
        googleLoginDisabled: 'Desabilitado',
        noBreachedAccounts:
          'Nenhuma conta vazada associada à sua empresa foi encontrada',
        noFindings: 'No momento, não há achados associados a essa conta.',
        sources: 'Origem',
        strongPassword: 'Senha forte',
        userAccounts: 'Contas',
        usersInBreach: 'Contas encontradas no vazamento',
        emailSources: {
          manual: 'Inserção manual',
          registration: 'Cadastro na Unxpose',
        },
        addIntegration: {
          title1: 'Promova um ambiente de trabalho seguro avaliando',
          title2:
            'a adesão de seus funcionários às melhores práticas de segurança.',
          subtitle:
            'Selecione seu provedor e faça a integração em apenas alguns cliques.',
        },
        filters: {
          activeAccountsOnly: 'Apenas contas ativas',
          accountSource: 'Origem da conta',
          adminOnly: 'Apenas administradores',
          clearFilters: 'Limpar filtros',
          filters: 'Filtros',
          filtersApplied: 'Filtros aplicados',
          googleWorkspaceFilters: 'Filtros do Google Workspace',
          mfaDisabledOnly: 'Apenas MFA desabilitado',
          microsoft365Filters: 'Filtros do Microsoft 365',
          suspendedAccountsOnly: 'Apenas contas suspensas',
        },
        labels: {
          admin: 'Administrador',
          alias: 'Alias',
          creationTime: 'Criado em',
          email: 'Email',
          emailSources: 'Origem',
          filesAnyoneWithLink:
            'Arquivos disponíveis para qualquer pessoa com link',
          filesSharedExternally: 'Arquivos compartilhados externamente',
          lastLogin: 'Último login',
          lessSecureApps: 'Apps menos seguros',
          mfa: 'MFA',
          name: 'Nome',
          passwordCompliant: 'Senha em conformidade',
          passwordStrength: 'Senha forte',
          publicFiles: 'Arquivos públicos',
          role: 'Função',
          riskySharing: 'Compartilhamentos externos',
          suspended: 'Usuário suspenso: ',
          suspensionReason: 'Suspenso por: ',
        },
        tooltips: {
          betaTesting1: 'Nova funcionalidade em fase de testes beta.',
          betaTesting2: 'Quer testar? Envie um email para support@unxpose.com.',
          isAdmin: 'Administrador',
          isNotAdmin: 'Usuário comum',
          mfaEnabled: 'MFA habilitado',
          mfaDisabled: 'MFA desabilitado',
          noDataAvailable: 'Indisponível',
        },
        subtitles: {
          anyoneWithLink: 'Qualquer pessoa com o link pode ter acesso',
          googleDrive1: 'Eventos relacionados a mudanças nas configurações',
          googleDrive2:
            'de privacidade de arquivos da empresa nos últimos 180 dias.',
          publicOnTheWeb: 'Publicados na web',
          sharedExternally:
            'Compartilhados com usuários/grupos explícitos externos ao domínio',
        },
        severityBreakdown: {
          title: 'Detalhamento da severidade',
          findingSeverity: 'Severidade do achado',
          account:
            'Contém informações relacionadas à conta (emails, nomes de usuário, IPs, dispositivos, etc)',
          credentials:
            'Contém credenciais (senhas, chaves de acesso, PINs, etc.)',
          breachAge: 'O vazamento ocorreu há {{age}} anos',
          privateMessages:
            'Contém mensagens privadas (mensagem de email, chat, suporte, etc)',
          personal: 'Contém outras informações pessoais',
        },
        advantages: {
          headerTitle: 'Faça agora uma integração de conta',
          actionBtn: 'Integre agora',
          secondaryBtn: 'Ver demonstração',
          title: 'Monitore o elo mais fraco: os colaboradores',
          items: {
            first:
              'Conecte o Google Workspace ou Microsoft 365 para entender quais colaboradores estão aderindo a boas práticas de segurança',
            second:
              'Monitore parâmetros como ativação do MFA, força da senha e compartilhamento de arquivos no Google Drive',
            third:
              'Verifique se as credenciais corporativas dos seus funcionários foram comprometidas em vazamentos de dados e entenda como solucionar',
          },
        },
      },
      placeholders: {
        currentPassword: 'Sua senha atual',
        email: 'Endereço de email',
        firstName: 'Jane',
        jobTitle: 'CEO, CSO, SysAdmin, etc',
        key: 'Chave',
        lastName: 'Doe',
        minCharacters: 'Pelo menos 10 caracteres',
        newPasswordConfirmation: 'Repita sua nova senha',
        password: 'Senha',
        passwordConfirmation: 'Repita sua senha',
        select: 'Selecione',
        value: 'Valor',
      },
      suppliers: {
        addNewBtn: 'Adicionar novo',
        unavailable:
          'A visualização de Fornecedores não está disponível no seu plano',
        noResults: 'Você ainda não cadastrou fornecedores',
        addSupplierBtn: {
          title: 'Gerencie seus fornecedores',
          details:
            '<strong>Adição de fornecedores</strong> não está disponível no seu plano. Comece um TRIAL e acesse todas as funcionalidades de forma gratuita por 7 dias.',
          supplierLimit_zero:
            'Você <strong>não tem limites para adicionar fornecedores</strong> no seu plano. Atualize seu plano para liberar recursos.',
          supplierLimit_one:
            'Você atingiu o <strong>limite de {{count}} fornecedor</strong> do seu plano. Atualize seu plano para liberar recursos.',
          supplierLimit_other:
            'Você atingiu o <strong>limite de {{count}} fornecedores</strong> do seu plano. Atualize seu plano para liberar recursos.',
          listItems: {
            first:
              'Avalie a segurança dos seus fornecedores monitorando sua exposição na internet através de um score de A a F',
            second:
              'Convide fornecedores para compartilhar questionários de segurança e faça a revisão das respostas de forma centralizada',
            third:
              'Crie e armazene modelos de questionários que você geralmente envia para seus fornecedores',
          },
        },
        monitoringSupplier: {
          title: 'Monitore seus fornecedores',
          supplierLimit_zero:
            'Você <strong>não tem limites para monitorar fornecedores</strong> no seu plano. Atualize seu plano para liberar recursos.',
          supplierLimit_one:
            'Você atingiu o <strong>limite de {{count}} monitoramento de fornecedor</strong> do seu plano. Atualize seu plano para liberar recursos.',
          supplierLimit_other:
            'Você atingiu o <strong>limite de {{count}} monitoramentos de fornecedores</strong> do seu plano. Atualize seu plano para liberar recursos.',
          listItems: {
            first:
              'Monitore o nível de exposição dos seus fornecedores na internet',
            second:
              'Conheça o nível de segurança deles através de um score de A a F',
          },
        },
        stats: {
          registered: 'Registrados',
          monitored: 'Monitorados',
        },
        sectionTitle: 'Fornecedores registrados',
        seeScoreBtn: 'Ver score',
        table: {
          supplier: 'Fornecedor',
          questionnaires: 'Questionários',
          created: 'Criado',
          score: 'Score',
          calculating: 'Calculando...',
        },
        addNew: {
          title: 'Adicionar novo fornecedor',
        },
        detail: {
          invitationStatus: 'Status do convite',
          resendInvitationBtn: 'Reenviar',
          addedIn: 'Adicionado {{timestamp}}',
          score: {
            sectionTitle: 'Score',
            calculating: 'Calculando score...',
            waitFewMinutes: 'Aguarde alguns minutos',
            lastMonitoring: 'Último monitoramento',
            monitoredDomains: 'Domínios monitorados',
            sectionBtn: 'Parar monitoramento',
            initialState: {
              title: 'Veja o score deste fornecedor',
              body: 'Conhecer o score de segurança de um fornecedor possibilita a análise do seu nível de proteção e conformidade com as melhores práticas de segurança. A pontuação, que varia de A a F, representa a média dos scores das diferentes categorias avaliadas.',
            },
            gradesDescription: {
              a: 'O nível de exposição geral da empresa fornecedora a ciberataques é muito baixo. O score geral é calculado com base na média das categorias analisadas.',
              b: 'O nível de exposição geral da empresa fornecedora a ciberataques atualmente está baixo. O score geral é calculado com base na média das categorias analisadas.',
              c: 'O nível de exposição geral da empresa fornecedora a ciberataques neste momento requer atenção. O score geral é calculado com base na média das categorias analisadas.',
              d: 'A empresa fornecedora está significativamente exposta a ciberataques neste momento. O score geral é calculado com base na média das categorias analisadas.',
              e: 'A empresa fornecedora está gravemente exposta a ciberataques neste momento. O score geral é calculado com base na média das categorias analisadas.',
              f: 'A empresa fornecedora está sob risco iminente! Ela está exposta de forma crítica a ciberataques neste momento. O score geral é calculado com base na média das categorias analisadas.',
            },
          },
          invitation: {
            sectionTitle: 'Convites',
            sendInviteBtn: 'Enviar convite',
            title: 'Convidar fornecedor',
            label: 'E-mail de convite',
            placeholder: 'abc@unxpose.com',
          },
          securityQuestionnaires: {
            sectionTitle: 'Questionários de Segurança',
            initialState: {
              title: 'Convide o fornecedor',
              body: 'Sua empresa ainda não tem permissão para compartilhar questionários com este fornecedor. Envie um convite agora e, assim que ele aceitar, você poderá começar a enviar questionários.',
            },
            sentQuestionnaires: {
              sectionTitle: 'Questionários enviados',
              sectionBtn: 'Enviar questionário',
              table: {
                name: 'Nome',
                questions: 'Questões',
                sent: 'Enviado',
              },
              remove: {
                success: 'Questionário removido com sucesso.',
                modal: {
                  title: 'Remover questionário?',
                  body: 'O questionário <strong>"{{questionnaireName}}"</strong> será removido da lista de enviados.',
                },
              },
            },
          },
        },
        removeModal: {
          title: 'Remover fornecedor?',
          body: 'O fornecedor <strong>{{companyName}}</strong> será removido da sua empresa.',
        },
        startMonitoringModal: {
          title: 'Monitorar score do fornecedor',
          body_zero:
            'Sua assinatura permite o monitoramento do score de <strong>{{currentLimit}} fornecedore(s)</strong>. Você não possui fornecedores sendo monitorados. </br>Deseja monitorar o score da empresa <strong>{{supplierName}}</strong>?',
          body: 'Sua assinatura permite o monitoramento do score de <strong>{{currentLimit}} fornecedore(s)</strong>. Você possui <strong>{{currentMonitoring}} fornecedore(s)</strong> sendo monitorado(s). </br>Deseja monitorar o score da empresa <strong>{{supplierName}}</strong> também?',
          bodyUnlimited_zero:
            'Você tem monitoramento de score ilimitado e nenhum fornecedor está sendo monitorado. </br>Deseja monitorar o score da empresa <strong>{{supplierName}}</strong>?',
          bodyUnlimited:
            'Você tem monitoramento de score ilimitado e possui <strong>{{currentMonitoring}} fornecedore(s)</strong> sendo monitorado(s). </br>Deseja monitorar o score da empresa <strong>{{supplierName}}</strong> também?',
        },
        stopMonitoringModal: {
          title: 'Parar monitoramento?',
          body: 'Ao interromper o monitoramento, o score do fornecedor não será mais visível.',
          actionBtn: 'Parar',
        },
        noSuppliers: 'Para começar, cadastre um fornecedor',
        noSentQuestionnaires: 'Para começar, envie um questionário',

        sentQuestionnaireDetail: {
          answer: 'Resposta',
          comment: 'Comentário',
        },
        invites: {
          acceptSupplierOfCompany:
            'Aceita passar pela Avaliação de Fornecedores da empresa <strong>{{companyName}}</strong>?',
          title: 'Convites em aberto',
          acceptBtn: 'Aceitar',
          declineBtn: 'Recusar',
          description: 'Convite enviado por <strong>{{name}}</strong> ',
          successAccepted: 'Convite aceito com sucesso!',
          successDeclined: 'Convite rejeitado com sucesso!',
        },
        messages: {
          supplierRegistered: 'Fornecedor cadastrado com sucesso!',
          invalidEmailError:
            'O e-mail informado não é válido. Informe o e-mail corporativo do fornecedor.',
          multipleDomainsError:
            'Todos os e-mails de contatos devem ser do mesmo domínio',
          emailsInUseError: 'Um ou mais e-mails de contato já estão em uso',
          domainInUseError:
            'Sua empresa já possui outro fornecedor com esse domínio',
          nameInUseError:
            'O nome do fornecedor {{name}} já está em uso por um dos fornecedores da empresa',
          companyOwnsDomainError: 'Esse domínio pertence a sua empresa',
          supplierEdited: 'Fornecedor editado com sucesso!',
          supplierRemoved: 'Fornecedor removido com sucesso!',
          resentInvitation: 'Convite reenviado com sucesso!',
          calculatingScore:
            'O monitoramento do fornecedor foi iniciado com sucesso! Em alguns minutos será possível visualizar o score.',
          interruptedMonitoring:
            'O monitoramento do fornecedor foi interrompido',
        },
        inviteHandler: {
          loading: {
            title: 'Carregando...',
            description: 'Isso pode levar alguns segundos.',
          },
          invalidSession: {
            title: 'O convite não foi encontrado',
            description: 'Há algo errado com o link do convite.',
          },
          expiredSession: {
            title: 'Seu convite expirou',
            description:
              'Você precisa pedir para o remetente enviar o convite novamente.',
          },
          unknownError: {
            title: 'Encontramos um problema',
            description: 'Entre em contato pelo e-mail abaixo se necessário.',
          },
          companyExists: {
            title: 'Sua empresa já está na Unxpose',
            description:
              'Entre em contato com o administrador da conta para obter acesso.',
          },
        },
      },
      questionnaires: {
        base: {
          title: 'Base de Conhecimento',
          description:
            'A Base de Conhecimento é um documento especial gerado automaticamente pela Unxpose. Ela é utilizada como a base de conhecimento de segurança da sua organização, sendo consultada e priorizada ao responder novos questionários utilizando inteligência artificial. ',
          actionBtn: 'Acessar',
          progress: 'Progresso',
        },
        title: 'Questionários',
        answer: 'Resposta',
        considerations: 'Comentário',
        evidences: {
          evidencesQty_zero: 'Evidências',
          evidencesQty_one: 'Evidências ({{count}} documento)',
          evidencesQty_other: 'Evidências ({{count}} documentos)',
          addDocumentBtn: 'Adicionar documento',
          addDocumentTitleModal: 'Adicionar novo documento',
          uploadSuccess:
            'Documento enviado! Você já pode incluí-lo nas evidências.',
        },
        discard: 'Descartar',
        save: 'Salvar',
        generateAnswer: 'Gerar resposta',
        creationInfo: 'Criado {{createdAt}} por <strong>{{createdBy}}</strong>',
        updateInfo:
          'Modificado {{updatedAt}} por <strong>{{updatedBy}}</strong>',
        sentInfo: 'Enviado por <strong>{{companyName}}</strong> {{receivedAt}}',
        acceptanceInfo: 'Aceito {{createdAt}}',
        progress: 'Progresso',
        generationSuccess: 'Resposta gerada com sucesso!',
        generationNoAnswer:
          'Não encontramos resposta. Ao responder mais perguntas, a IA terá dados para ajudá-lo a resolver as questões.',
        cannotChange: 'Um questionário entregue não permite alterações.',
        cannotEvaluateApproved:
          'Um questionário aprovado não permite avaliações.',
        cannotEvaluateChangesRequested:
          'Aguardando ajustes do fornecedor. Você poderá fazer novas avaliações após o reenvio do questionário.',
        unableToGenerateModal: {
          title: 'Você ainda não pode gerar respostas',
          body: 'No momento você não possui nenhum questionário entregue. As respostas geradas dependem diretamente desses questionários pois eles fazem parte de sua base de conhecimento. Quanto mais questionários entregues, melhores serão as respostas.',
          primaryBtn: 'Ok',
          learnMore: 'Saiba mais',
        },
        answers: {
          yes: 'Implementado',
          no: 'Não implementado',
          inProgress: 'Em andamento',
          notApply: 'Não faz sentido',
        },
        buttons: {
          addConsiderations: 'Adicionar considerações',
          hideConsiderations: 'Esconder considerações',
          saveConsiderations: 'Salvar considerações',
          showConsiderations: 'Exibir considerações',
          skipQuestion: 'Pular pergunta',
        },
        csv: {
          answer: 'Resposta',
          considerations: 'Comentário',
          noConsiderations: '-',
          notAnswered: '-',
          question: 'Pergunta',
        },
        placeholders: {
          provideDetails: 'Forneça mais detalhes (opcional)',
        },
        tables: {
          questionnairesAvailable: 'Questionários disponíveis',
          name: 'Nome',
          questionsNumber: 'Questões',
          progress: 'Progresso',
          createdAt: 'Criação',
          modifiedAt: 'Modificação',
          modifiedBy: 'por {{username}}',
          sentBy: 'Enviado por {{companyName}}',
          status: 'Status',
        },
        emptyState: {
          title: 'Não há questionários para serem exibidos',
          body: 'Você deve fazer o upload primeiro',
        },
        new: {
          title: 'Adicionar novo',
          questionnaireName: 'Nome do questionário',
          questionnaireNameHelper:
            'Como seu questionário será exibido na listagem',
          questionnaireDescription: 'Descrição',
          questionnaireDescriptionHelper: 'Adicione um contexto ou observações',
          learnAboutFile: {
            title: 'Arquivo de questões',
            sampleFile: {
              doubts: 'Ficou com dúvidas?',
              download: 'Baixe um <a>arquivo de exemplo</a>',
              online:
                'Visualize um exemplo <withAnswer>com respostas</withAnswer> e outro <withoutAnswer>sem respostas</withoutAnswer>',
              onlineTemplate: 'Visualize um <a>arquivo de exemplo</a>',
            },
            steps: {
              one: 'O formato do arquivo deve ser um CSV (Comma Separated Values). Este formato é amplamente utilizado e pode ser aberto ou exportado por qualquer editor de planilhas, como Excel ou Google Sheets.',
              two: 'Ele deve possuir <strong>3 colunas</strong>: uma para <strong>questão</strong> (obrigatória), outra para <strong>resposta</strong> (opcional) e a última para <strong>comentário</strong> (opcional).',
              three:
                'A primeira linha do arquivo precisa conter o cabeçalho com valores na primeira, segunda e terceira coluna. Esta linha será ignorada durante o carregamento.',
            },
          },
          maxFileSize:
            'Apenas arquivos <strong>.csv</strong> são suportados <strong>(até 1 MB)</strong>',
          onSubmitBtn: 'Enviar',
          messages: {
            success: 'Seu arquivo foi salvo com sucesso!',
            dragAndDropError:
              'Apenas arquivos CSV são suportados <br> e você deve enviá-los um por vez',
            error: {
              invalidCsv:
                'O arquivo CSV fornecido é inválido. Verifique se todas colunas estão presentes e se há uma linha formatada incorretamente.',
              invalidQuestionnaireName:
                'Nome do questionário inválido. Certifique-se de fornecer um nome que não seja muito longo.',
              invalidQuestionnaireDescription:
                'Descrição do questionário inválida. Certifique-se de que a descrição não seja maior que 500 caracteres.',
              missingQuestionnaireFile:
                'Está faltando o arquivo do questionário.',
            },
          },
          modal: {
            firstContent: {
              title: 'Questionário salvo com sucesso',
              body: 'O questionário  possui uma ou mais respostas. Este documento já foi entregue?',
              primaryBtn: 'Sim, informar data',
              secondaryBtn: 'Ainda não',
            },
            secondContent: {
              title: 'Quando foi feita a entrega?',
              body: 'Insira a data de entrega abaixo. É importante registrar este dado para garantir a precisão das informações.',
              primaryBtn: 'Concluir',
              secondaryBtn: 'Voltar',
            },
          },
        },
        sendQuestionnaire: {
          title: 'Enviar questionário',
          questionnaireName: 'Nome do questionário',
          questionnaireNameHelper:
            'Como o questionário será exibido na listagem',
          questionnaireDescription: 'Descrição',
          questionnaireDescriptionHelper: 'Adicione um contexto ou observações',
          template: 'Template',
          templateHelper: 'Escolha um template para ser usado no questionário',
          sendBtn: 'Enviar',
          addNew: 'Adicionar novo',
          success: 'Questionário enviado com sucesso!',
          alreadySentError: 'Este questionário já foi enviado',
        },
        invites: {
          title: 'Convites de questionários em aberto',
          acceptRequestOfCompany:
            'Aceita responder <strong>{{inviteName}}</strong> da empresa <strong>{{inviteCompany}}</strong>?',
          acceptBtn: 'Aceitar',
          declineBtn: 'Recusar',
          description: 'Convite enviado por <strong>{{name}}</strong> ',
          successAccepted: 'Convite aceito com sucesso!',
        },
        remove: {
          success: 'Questionário removido com sucesso.',
          modal: {
            title: 'Remover questionário?',
            body: 'O questionário <strong>"{{questionnaireName}}"</strong> será removido da Unxpose',
          },
        },
        submit: {
          submitBtn: 'Entregar',
          name: 'Nome',
          description: 'Descrição',
          modal: {
            title: 'Entregar questionário',
            body: 'As repostas do questionário <strong>"{{questionnaireName}}</strong>" serão compartilhadas com a empresa <strong>{{companyName}}</strong> e não será mais possível modificá-las. Tem certeza de que deseja entregar o questionário agora?',
            submissionDate: 'Data de entrega',
          },
        },
        edit: {
          title: 'Editar',
          submittedAt: 'Entregue em {{datetime}}',
          modal: {
            title: 'Editar questionário',
            name: 'Nome',
            description: 'Descrição',
            submissionDate: 'Data de entrega',
          },
        },
        saveAlert: {
          title: 'Continuar sem salvar?',
          body: 'Você possui alterações não salvas na resposta.',
          continueBtn: 'Continuar',
        },
        templates: {
          title: 'Templates de questionários',
          noTemplates: 'Para começar, adicione um template',
          table: {
            name: 'Nome',
            questions: 'Questões',
            created: 'Criado',
          },
          new: {
            title: 'Adicionar novo template',
            templateName: 'Nome do template',
            templateNameHelper: 'Como seu template será exibido na listagem',
            templateDescription: 'Descrição',
            templateDescriptionHelper: 'Adicione um contexto ou observações',
            learnAboutFile: {
              title: 'Arquivo de questões',
              steps: {
                one: 'O formato do arquivo deve ser um CSV (Comma Separated Values). Este formato é amplamente utilizado e pode ser aberto ou exportado por qualquer editor de planilhas, como Excel ou Google Sheets.',
                two: 'Ele deve possuir obrigatoriamente uma única coluna: <strong>questão</strong>.',
                three:
                  'A primeira linha do arquivo precisa conter o cabeçalho com valor na primeira coluna. Esta linha será ignorada durante o carregamento.',
              },
            },
          },
          upload: {
            success: 'Template de questionário enviado com sucesso!',
          },
          remove: {
            success: 'Template de questionário removido com sucesso.',
            modal: {
              title: 'Remover template de questionário?',
              body: 'O template <strong>"{{templateName}}"</strong> será removido da Unxpose',
            },
          },
          edit: {
            success: 'Template de questionário editado com sucesso.',
            modal: {
              title: 'Editar template de questionário',
              name: 'Nome',
              description: 'Descrição',
            },
          },
        },
        updateSuccess: 'As alterações foram salvas com sucesso!',
        questionReferences: {
          references: 'Referências',
          generatedByAI: 'Resposta gerada por IA.',
          openReferences: 'Ver referências',
        },
        history: {
          viewHistoryBtn: 'Ver histórico',
          title: 'Histórico do Questionário',
          by: 'Por <strong>{{fullName}} ({{supplierCompany}})</strong>',
          byFor:
            'Por <strong>{{fullName}} ({{buyerCompany}})</strong> para a empresa fornecedora <strong>{{supplierCompany}}</strong>',
        },
        review: {
          evaluations: {
            meets: 'Atende',
            partiallyMeets: 'Atende parcialmente',
            doesNotMeet: 'Não atende',
            noEvaluated: 'Não avaliadas',
          },
          questionBox: {
            boxTitle: 'Avaliar resposta',
            evaluationLabel: 'Como você avalia a resposta?',
            commentsLabel: 'Observações',
            answerEvaluation: 'Avaliação da resposta',
            editedAnswer:
              ' Resposta alterada! O questionário deve ser entregue para as respostas serem reavaliadas.',
          },
          modal: {
            title: 'Revisão',
            reviewBtn: 'Revisão',
            body: 'As avaliações e observações serão compartilhadas com o fornecedor apenas se você solicitar mudanças.',
            note: 'Ao aprovar ou pedir mudanças em um questionário, você não poderá mais avaliar as respostas.',
            table: {
              evaluation: 'Avaliação',
              questions: 'Questões',
            },
            approveBtn: 'Aprovar',
            requestChangesBtn: 'Pedir mudanças',
            evaluationTooltip:
              "Para pedir mudanças, é necessário existir pelo menos uma avaliação do tipo 'Atende parcialmente' ou 'Não atende'",
          },
          messages: {
            approvedSuccess: 'O questionário foi aprovado com sucesso!',
            changesRequestedSuccess:
              'O fornecedor foi notificado sobre sua revisão! Aguarde enquanto os ajustes necessários são realizados.',
          },
        },
      },
      documents: {
        availableDocuments: 'Documentos disponíveis',
        types: {
          pentest: 'Relatório de Teste de Intrusão (Pentest)',
          securityPolicy: 'Política de Segurança da Informação',
          backupPolicy: 'Política de Backup',
          businessContinuityDRPolicy:
            'Política de Continuidade de Negócios e Recuperação de Desastres',
          dataClassificationPolicy: 'Política de Classificação de Dados',
          encryptionPolicy: 'Política de Criptografia',
          pentestSummaryReport: 'Atestado de Teste de Intrusão (Pentest)',
          privacyPolicy: 'Política de Privacidade',
          secIncidentMgmtPolicy:
            'Política de Gerenciamento de Incidentes de Segurança',
          vulnerabilityMgmtPolicy: 'Política de Gestão de Vulnerabilidades',
          changeManagementPolicy: 'Política de Gestão de Mudanças',
          dataRetentionDisposalPolicy:
            'Política de Retenção e Descarte de Dados',
          accessControlPolicy: 'Política de Controle de Acesso',
          humanResourcesPolicy: 'Política de Recursos Humanos',
          vendorManagementPolicy: 'Política de Gestão de Fornecedores',
          riskManagementPolicy: 'Política de Gestão de Risco',
          assetManagementPolicy: 'Política de Gestão de Ativos',
          authPasswordPolicy: 'Política de Autenticação',
          auditLoggingPolicy: 'Política de Gestão de Registros',
          physicalSecurityPolicy: 'Política de Segurança Física',
          acceptableUsePolicy: 'Política de Uso Aceitável',
        },
        table: {
          document: 'Tipo de documento',
          documentDate: 'Data do documento',
          uploadedBy: 'Enviado por',
          uploadDate: 'Data de upload',
          downloadFile: 'Baixar arquivo',
        },
        addNew: {
          addNewDocument: 'Adicionar novo',
          documentType: 'Tipo de documento',
          documentTypeDetail: 'Escolha uma opção',
          consultancyCompany: 'Empresa que realizou',
          consultancyCompanyDetail: 'Nome da empresa',
          executionRange: 'Período de realização',
          executionRangeDetail:
            'Período em que o pentest foi executado (início e fim)',
          generationDate: 'Data da versão do documento',
          generationDateDetail: 'Selecione uma data',
          fileTitle: 'Arquivo',
          maxFileSize:
            'Apenas arquivos <strong>.pdf, .png e .jpg</strong> são suportados <strong>(até 40 MB)</strong>',
          onSubmitBtn: 'Enviar',
          messages: {
            success: 'Seu arquivo foi salvo com sucesso!',
            dragAndDropError:
              'Apenas arquivos PDF, PNG e JPG são suportados <br> e você deve enviá-los um por vez',
          },
        },
        modal: {
          remove: {
            title: 'Remover documento?',
            body: 'O documento <strong>"{{documentName}}"</strong> será removido da Unxpose',
            primaryBtn: 'Remover',
            secondaryBtn: 'Cancelar',
            success: 'Documento removido com sucesso.',
          },
          removeFeedback: {
            title: 'Erro ao remover documento',
            body: 'Não foi possível remover o documento <strong>"{{documentName}}"</strong> de {{documentDate}}, pois ele está vinculado a um ou mais questionários listados abaixo:',
            primaryBtn: 'Ok',
          },
          download: {
            modal: {
              title: 'Baixar documento?',
              body: 'O download deste arquivo pode representar um risco à segurança do seu dispositivo. Certifique-se de confiar na origem deste conteúdo.',
              primaryBtn: 'Continuar',
              secondaryBtn: 'Cancelar',
            },
          },
        },
        advantages: {
          documentsUnavailable:
            'A visualização de Documentos não está disponível no seu plano',
          noResults: 'Ainda não existe nenhum documento cadastrado',
          title:
            'Adicione seus documentos de cibersegurança e mantenha um inventário atualizado',
          items: {
            first:
              'Centralize sua documentação de segurança e permita que várias equipes acessem facilmente as informações',
            second:
              'Garanta que todos os documentos estejam disponíveis para análise durante as avaliações e auditorias',
            third:
              'Anexe documentos relevantes às respostas dos questionários de segurança',
          },
          addNewBtn: 'Adicionar novo',
        },
      },
      reports: {
        emptyStateTitle: 'Você ainda não possui relatórios',
        errors: {
          errorGeneratingReport: 'Ocorreu um erro ao gerar o relatório.',
        },
        languages: {
          en: 'Inglês',
          pt: 'Português',
        },
        table: {
          createdBy: 'Criado por',
          date: 'Data',
          language: 'Idioma',
          downloadReport: 'Baixar relatório',
        },
        executiveReports: 'Relatórios executivos',
      },
      signUp: {
        primaryBtn: 'Criar conta grátis',
        loginCall: 'Já tem uma conta?',
        login: 'Entrar',
        policy: 'Concordo que li e aceitei a <a>Política de Privacidade</a>',
        learnMore: 'Quer conhecer mais sobre a Unxpose?',
        goToSite: '<a>Acesse nosso site</a>',
        callToAction:
          'Crie sua conta e comece a <span>proteger</span> sua empresa',
        subtitle: '100% gratuito. Não é necessário cartão de crédito.',
        messages: {
          success: 'Tudo certo! Verifique seu e-mail para continuar.',
          invalidEmail:
            'Você precisa utilizar o seu email corporativo para criar uma conta na Unxpose.',
          tooManyRequests:
            'Ops! Você fez muitas tentativas. Tente novamente mais tarde.',
        },
        register: {
          messages: {
            success:
              'Cadastro realizado com sucesso! Faça login para continuar.',
          },
        },
      },
      scores: {
        progressBar: {
          lowPercentage: `Um passo de cada vez e você chegará na próxima nota!`,
          mediumPercentage: `Continue se esforçando para alcançar a próxima nota!`,
          highPercentage: `restantes! Você está quase lá!`, // there is a percentage number before this text
          perfectScore: `Você conseguiu! Continue o ótimo trabalho para manter sua nota!`,
        },
      },
      settings: {
        subscription: {
          domains: 'Domínios',
          unlimited: {
            domains: 'Domínios ilimitados',
            idpIntegrations: 'Provedores de identidade ilimitados',
            cloudIntegrations: 'Provedores de cloud ilimitados',
          },
          idpIntegrations: 'Provedores de identidade',
          cloudIntegrations: 'Provedores de cloud',
        },
        tabs: {
          company: 'Empresa',
          members: 'Membros',
          profile: 'Perfil',
          security: 'Segurança',
          notifications: 'Notificações',
          auditLogs: 'Audit Logs',
          apiKeys: 'API Keys',
        },
        advanced: {
          title: 'Avançado',
          monitoring: {
            title: 'Preferências de monitoramento',
            subtitle: 'Defina como seus ativos devem ser monitorados',
            cloudflare: {
              label: 'Ignorar portas adicionais do Cloudflare',
              description:
                'Para ativos usando Cloudflare, o monitoramento ocorrerá apenas nas portas 80 e 443. As demais portas serão ignoradas e seus achados serão fechados.',
            },
            aws: {
              label: 'Ignorar portas adicionais dos buckets S3 da AWS',
              description:
                'Para ativos usando buckets S3 da AWS, o monitoramento ocorrerá apenas na porta 445. As demais portas serão ignoradas e seus achados serão fechados.',
            },
          },
        },
        company: {
          placeholders: {
            select: 'Selecione',
          },
          dropdown: {
            10: '1 - 10',
            50: '11 - 50',
            200: '51 - 200',
            500: '201 - 500',
            1000: '501 - 1.000',
            5000: '1.001 - 5.000',
            10000: 'Mais de 5.000',
          },
          premiumAdvantages: {
            text: 'Assine a Unxpose e <strong>libere todos os recursos</strong> para manter sua empresa protegida.',
            callToAction: 'Conhecer vantagens',
            subscriptionManagement: 'Gerencie sua assinatura',
            tryForFree:
              'Teste gratuitamente <strong>todos os recursos</strong> da Unxpose por 7 dias!',
          },
          trialUser: 'Você está em um TRIAL',
          trialUserExpired:
            'Seu TRIAL expirou. Assine a Unxpose e deixe sua empresa segura.',
          paymentFailed:
            'Houve um problema com o seu pagamento. Acesse sua assinatura.',
          inactiveSubscription: 'Você não possui uma assinatura ativa.',
          companyUpdateSuccess:
            'As informações da empresa foram atualizadas com sucesso',
        },
        members: {
          inviteUserModal: {
            note: 'Quando o convite for aceito, este usuário terá acesso a todos os ativos relacionados a esta empresa.',
          },
          removeUserModal: {
            title: 'Remover usuário?',
            bodySingle:
              '<strong>{{userName}}</strong> será removido e perderá acesso à Unxpose.',
            bodyMultiple:
              '<strong>{{userName}}</strong> é membro das seguintes empresas:',
            options: {
              removeSingle:
                'Remover apenas da empresa atual ({{currentCompany}})',
              removeMultiple: 'Remover de TODAS as empresas listadas acima',
            },
            actionButtons: {
              remove: 'Remover',
              cancel: 'Cancelar',
            },
          },
          removeInviteModal: {
            title: 'Remover convite?',
            body: 'Tem certeza que deseja remover o convite para <strong>{{email}}</strong>?',
          },
        },
        errors: {
          selectACondition:
            'Por favor, selecione uma condição que irá acionar este webhook.',
          requiredFields: 'Nome e URL são campos obrigatórios.',
          userAlreadyInvited: '{{email}} já foi convidado anteriormente.',
          userAlreadyRegistered: '{{email}} já está cadastrado na Unxpose',
          userAlreadyRegisteredInCompany:
            '{{email}} já está cadastrado na {{company}}',
          inviteInvalidSubscription:
            'Esta empresa não possui uma assinatura válida. Verifique os detalhes do seu plano no menu Configurações -> Empresa.',
        },
        oauth: {
          emailMismatch:
            'O e-mail que você está tentando conectar não é igual ao e-mail em uso.',
          emailNotFound:
            'O usuário que você está tentando conectar não está cadastrado.',
          googleConnected: 'Sua conta do Google foi conectada com sucesso.',
          microsoftConnected:
            'Sua conta da Microsoft foi conectada com sucesso.',
          googleDisconnected:
            'Sua conta do Google foi desconectada com sucesso.',
          microsoftDisconnected:
            'Sua conta da Microsoft foi desconectada com sucesso.',
        },
        notifications: {
          placeholders: {
            select: 'Selecione',
          },
          slack: {
            webhookAlreadyConfigured1: 'Você já tem um',
            webhookAlreadyConfigured2:
              'criado para enviar notificações pelo Slack.',
            toDisableSlack1:
              'Para desativar as notificações do Slack, basta acessar a aba Webhooks e desativar a URL correspondente. Se você ainda tiver dúvidas, entre em contato conosco em',
            toDisableSlack2: ', ficaremos felizes em falar com você!',
            notifications:
              'Você pode receber notificações da Unxpose diretamente em um canal do Slack da sua preferência. Para isso, é necessário configurar um webhook de entrada do Slack e apontar um webhook da Unxpose para a URL fornecida pelo Slack. O passo a passo a seguir vai te ajudar a fazer isso.',
            stepFourBody:
              'Cole a sua URL no campo abaixo, selecione uma condição, e a gente toma conta do resto!',
            stepFourHeader: 'Envie-nos a URL do seu webhook de entrada',
            stepOneBody:
              'O primeiro passo é criar um aplicativo do Slack no qual você ira configurar o webhook de entrada. Você pode selecionar o workspace da sua empresa. Depois, dê o nome que preferir.',
            stepOneHeader: 'Crie um app do Slack (se você ainda não tiver um)',
            stepThreeBody:
              'Clique em Adicionar Novo Webhook ao Workspace, no final da página. Selecione o canal em que você quer receber as notificações e clique em Autorizar.',
            stepThreeHeader: 'Crie um webhook de entrada',
            stepTwoBody:
              'Selecione o app que você criou na página Your Apps. Agora, navegue para Add Features e clique em Webhooks de Entrada. Clique em Ativar Webhooks de Entrada.',
            stepTwoHeader: 'Ative webhooks de entrada',
          },
        },
        auditLogs: {
          title: 'Audit Logs',
          tableColumns: {
            user: 'Usuário',
            action: 'Ação',
            ip: 'IP',
            userAgent: 'User agent',
            dateTime: 'Data',
          },
        },
        apiKeys: {
          title: 'API Keys Registradas',
          accessDocsBtn: 'Acessar documentação',
          generateApiKeyBtn: 'Gerar API Key',
          callTitle: 'Gerencie e proteja suas API Keys com segurança',
          columns: {
            name: 'Nome',
            keyId: 'Key ID',
            expiration: 'Expiração',
            status: 'Status',
          },
          generatedAt: 'Gerada em {{dateTime}}',
          generationModal: {
            title: 'Gerar nova API key',
            keyName: 'Nome da chave',
            expirationDate: 'Expiração',
            expirationDates: {
              oneMonth: '1 mês',
              threeMonths: '3 meses',
              sixMonths: '6 meses',
              twelveMonths: '12 meses',
            },
            generateBtn: 'Gerar',
          },
          resultGenerationModal: {
            title: 'Salve a chave',
            firstParagraph:
              'Guarde sua chave em um lugar seguro. Por segurança, ela <strong>não será exibida novamente</strong>. Caso você perca-a, será preciso gerar uma nova.',
            secondParagraph:
              'O valor abaixo inclui o <strong>ID e valor</strong> da chave, separados por dois pontos. Os valores devem ser incluídos no cabeçalho <strong>X-API-KEY</strong> no formato abaixo. Para mais informações, <a>acesse a documentação da API</a>.',
            closeBtn: 'Já salvei a chave',
          },
          removeModal: {
            title: 'Remover API key?',
            body: 'Tem certeza que deseja remover a chave <strong>{{keyName}}</strong>?',
          },
          messages: {
            generated: 'A API key foi gerada com sucesso!',
            removed: 'A API key foi removida com sucesso!',
          },
          advantages: {
            firstItem:
              'Gere chaves de API para integrar seus sistemas com nossa plataforma de forma segura e eficiente',
            secondItem:
              'Revogue acessos a qualquer momento para manter a segurança dos seus dados',
            thirdItem:
              'Defina uma data de expiração para suas API Keys e reduza riscos de uso indevido',
          },
        },
        success: {
          inviteSent: 'Convite enviado para {{email}}',
        },
      },
      status: {
        closed: 'Fechado',
        falsePositive: 'Incorreto',
        fixed: 'Corrigido',
        irrelevant: 'Irrelevante',
        new: 'Aberto', // backwards compat
        open: 'Aberto',
        plural: {
          closed: 'Fechados',
          falsePositive: 'Incorretos',
          fixed: 'Corrigidos',
          irrelevant: 'Irrelevantes',
          new: 'Abertos',
          open: 'Abertos',
          reopened: 'Reabertos',
          riskAccepted: 'Riscos aceitos',
          stale: 'Intermitentes',
        },
        reopened: 'Reaberto',
        resolved: 'Resolvido',
        revoked: 'Credenciais revogadas',
        riskAccepted: 'Risco aceito',
        stale: 'Intermitente',
        unreachable: 'Inacessível',
        unrelated: 'Não relacionado',
        sent: 'Enviado',
        accepted: 'Aceito',
        submitted: 'Entregue',
        pending: 'Pendente',
        approved: 'Aprovado',
        changesRequested: 'Mudanças solicitadas',
        awaitingReview: 'Aguardando sua revisão {{timestamp}}',
        awaitingEvaluation: 'Aguardando avaliação {{timestamp}}',
        edited: 'A resposta foi editada',
        declined: 'Rejeitado',
        expired: 'Expirado',
        active: 'Ativo',
      },
      success: {
        genericSuccess: 'Tudo correu como esperado.',
      },
      severity: {
        critical: 'Crítica',
        high: 'Alta',
        info: 'Info',
        low: 'Baixa',
        medium: 'Média',
      },
      subtitles: {
        changePassword1: 'Use sua senha atual',
        changePassword2: 'para criar uma nova',
        companyDetails: 'Como devemos chamar sua empresa?',
        companySize: 'Será usado para calcular seu score',
        contact: 'Como falar com você?',
        description: 'Descrição',
        googleAccount: 'Faça login com o Google',
        holdOn: 'Estamos testando seu webhook...',
        microsoftAccount: 'Faça login com a Microsoft',
        newWebhook: 'Configure um novo webhook',
        noBreachedEmails:
          'No momento, não há emails vazados associados à sua empresa.',
        noWebhooks: 'Tem dúvidas sobre os próximos passos?',
        personalInformation: 'Insira seus dados pessoais',
        plan: 'Confira as informações do seu plano',
        public: 'É uma jornada',
        setUpPreferences: 'Defina quando voce quer ser notificado',
        setUpSlack: 'Integre as notificações da Unxpose ao Slack',
        somethingWrong:
          'Verifique a configuração do seu webhook ou cheque o seu servidor.',
        taxIdentification: 'Será usada para descobrir domínios',
        testYourWebhook:
          'Primeiro, vamos garantir que tudo está funcionando como o desejado.',
        twoFactorAuthentication: 'Torne sua conta mais segura',
        unknownBreachedEmails:
          'Detectamos que há emails vazados associados à sua empresa, mas não foi possível determinar quais.',
        webhookSuccess:
          'Está funcionando! Salve suas alterações para concluir.',
      },
      tables: {
        access: 'Access',
        accessKeyAge: 'Access key age',
        actions: 'Ações',
        admin: 'Administrador',
        asset: 'Ativo',
        attachmentCount: 'Attachment Count',
        autoRenew: 'Auto renew',
        breach: 'Vazamento',
        bucketName: 'Bucket name',
        createTime: 'Create time',
        createdAt: 'Criado em',
        createdBy: 'Criado por',
        creator: 'Criador',
        date: 'Data',
        dateCreated: 'Date created',
        dbName: 'DB name',
        domainName: 'Domain name',
        displayName: 'Nome de exibição',
        encrypted: 'Encrypted',
        expiry: 'Expiry',
        false: 'Falso',
        filterByAssignee: 'Filtre por usuários atribuídos',
        filterByAssignment: 'Filtre por atribuição',
        filterByCategory: 'Filtre por categoria',
        filterByDomain: 'Filtre por domínio',
        filterBySeverity: 'Filtre por severidade',
        filterByStatus: 'Filtre por status',
        filterByTitleIn: 'Filtre por título em',
        filterByCloudIntegration: 'Filtrar por integração de cloud',
        filterIn: 'Filtrar em',
        filterStartingWith: 'Filtrar por entradas começando com...',
        filterByTitleOrAsset: 'Filtre por título ou ativo',
        finding: 'Achado',
        host: 'Host',
        instanceClass: 'Instance class',
        instanceId: 'Instance Id',
        instanceStatus: 'Instance status',
        instanceType: 'Instance type',
        items_one: 'Item',
        items_other: 'Itens',
        lastActivity: 'Last activity',
        lastCheck: 'Última checagem',
        launchTime: 'Launch time',
        name: 'Nome',
        never: 'Nunca',
        noMatchWithSelectedFilters:
          'Nenhum resultado para os filtros selecionados',
        noResultsFound: 'Nenhum resultado encontrado',
        nothingFound: 'Nada encontrado até agora',
        nothingFoundWithSearch: 'Nenhum resultado para esta pesquisa',
        page: 'Página',
        passwordAge: 'Password Age',
        port: 'Porta',
        privateZone: 'Zona privada',
        publicDns: 'Public DNS',
        records: 'Registros',
        dnsRecord: 'Registro DNS',
        dnsRecords: 'Registros DNS',
        region: 'Region',
        resourceName: 'Nome do recurso',
        resourceRecordSetCount: '# Registros',
        searchSomethingElse: 'Tente utilizar outros termos',
        servicePrincipal: 'Service Principal',
        firstSeen: 'Primeira detecção',
        lastSeen: 'Última detecção',
        seen: 'Detecção',
        status: 'Status',
        severity: 'Severidade',
        title: 'Título',
        transferLock: 'Transfer lock',
        true: 'Verdadeiro',
        tryDifferentFilters:
          'Use outros filtros para encontrar o que você precisa',
        tryOtherTerms: 'Tente buscar outros termos',
        type: 'Tipo',
        updatedAt: 'Atualizado em',
        username: 'username',
        value: 'Valor',
        viewLogs: 'Ver logs',
        volumeId: 'Volume Id',
        volumeType: 'Volume type',
        waitAFewMinutes:
          'Pode levar alguns minutos até acharmos algo. Volte mais tarde!',
        year: 'Ano',
      },
      titles: {
        about: 'Sobre',
        activity: 'Atividade',
        addDomain: 'Qual domínio você quer adicionar?',
        allFindingsFor: 'Todos os achados de',
        assetDiscovery: 'Descoberta de ativos',
        categoryScore: 'Score da categoria',
        changeFindingStatus: {
          title: 'Mudança de status',
          text: 'Você está tentando mudar o status desse finding de {{status}} para {{newStatus}}. Para isso é necessário informar o motivo da mudança:',
        },
        changePassword: 'Alteração de senha',
        companyDetails: 'Informações da empresa',
        companySize: 'Porte da empresa',
        completeRegistration: 'Finalize seu cadastro',
        contact: 'Contato',
        description: 'Descrição',
        unresolvedFindingsBySeverity: 'Achados não resolvidos por severidade',
        goodNews: 'Boas notícias',
        googleAccount: 'Conta do Google',
        googleAccountConnected: 'Sua conta do Google está conectada',
        holdOn: 'Aguarde',
        inviteMembers: 'Convide novos membros para colaborar',
        microsoftAccount: 'Conta da Microsoft',
        microsoftAccountConnected: 'Sua conta da Microsoft está conectada',
        newWebhook: 'Novo webhook',
        noResultsFound: 'Nenhum resultado encontrado',
        noWebhooks: 'Você não tem nenhum webhook configurado.',
        password: 'Senha',
        personalInformation: 'Informações pessoais',
        plan: 'Plano',
        yourPlan: 'Seu plano',
        public: 'Segurança não é um projeto',
        rawData: 'Evidência',
        references: 'Referências',
        remediation: 'Remediação',
        scoreByCategory: 'Score por categoria',
        securityScore: 'Score de segurança',
        nextSteps: 'Próximos Passos',
        securityScoreOverTime: 'Score de segurança ao longo do tempo',
        setUpPreferences: 'Configure suas preferências',
        setUpSlack: 'Configure o Slack',
        solution: 'Correção',
        somethingWrong: 'Ops, algo deu errado',
        taxIdentification: 'Identificação fiscal',
        teamMembers: 'Membros do time',
        sentInvites: 'Convites enviados',
        testYourWebhook: 'Teste o seu Webhook',
        totalVsClosed: 'Total e fechados ao longo do tempo',
        twoFactorAuthentication: 'Autenticação de dois fatores',
        webhookSuccess: 'Webhook testado com sucesso',
        whatsImportant: 'Ocorrências importantes',
        watchDemo: 'Assista a uma demonstração',
      },
      warnings: {
        accessUsingDesktop:
          'Para ver todo o conteúdo, utilize um dispositivo desktop.',
        comeBackInMinutes: 'Volte em alguns minutos',
        comeBackLater: 'Volte mais tarde!',
        contactUs: 'Dúvidas? Fale com a gente em',
        domainRemoval:
          'Ao remover o domínio <strong>{{hostname}}</strong>, você também apagará todos os registros, subdomínios, aplicações web e achados relacionados a ele. Tem certeza que deseja continuar?',
        followUs1: 'Enquanto isso, siga nossa página no',
        followUs2: 'e visite nosso',
        followUs3:
          'Lá a gente fala sobre cibersegurança e boas práticas no desenvolvimento de aplicações seguras',
        generatingReport:
          'Gerando relatório. Isso pode levar alguns minutos...',
        googleAccountNotConnected1: 'Sua conta do Google não está conectada.',
        googleAccountNotConnected2:
          'Se o email que você está usando é uma conta do Google, você pode',
        googleAccountNotConnected3: 'conectá-la à nossa plataforma',
        insufficientInformation: 'Informações insuficientes',
        keepWebhookSecret:
          'Mantenha a URL do seu webhook em segredo! Ela contém uma chave secreta e, portanto, não deve ser compartilhada (principalmente em plataformas de versionamento de código).',
        microsoftAccountNotConnected1:
          'Sua conta da Microsoft não está conectada.',
        microsoftAccountNotConnected2:
          'Se o email que você está usando é uma conta da Microsoft, você pode',
        microsoftAccountNotConnected3: 'conectá-la à nossa plataforma',
        passwordConfirmationDontMatchRegister: 'As senhas não coincidem',
        passwordConfirmationDontMatch:
          'A nova senha e a confirmação de senha não coincidem',
        planExpiredBody1:
          'Fique tranquilo, a gente quer continuar protegendo a sua empresa, monitorando e identificando falhas de segurança. Vamos nessa?',
        planExpiredBody2:
          'Para ativar o seu plano e seguir mantendo a sua empresa segura contra ciberataques, solicite contato e um especialista retornará em instantes.',
        planExpiredHeader: 'seu plano expirou!',
        planExpiredListItem1:
          'Monitoramento 24/7 para você ficar sempre protegido.',
        planExpiredListItem2:
          'Redução do tempo de correção com tutoriais acessíveis.',
        planExpiredListItem3:
          'A expertise de um time de segurança em poucos cliques.',
        planExpiredListTitle:
          'Lembre-se das vantagens de ter a Unxpose no seu time:',
        planExpiredSuccess:
          'Em breve, um profissional do nosso time entrará em contato',
        selectOneOption: 'Por favor, escolha uma das opções listadas',
        twoFactorAuthenticationEnabled:
          'A autenticação de dois fatores está ativada. Se você deseja desativá-la (o que não é recomendado), digite a sua senha abaixo.',
        updateTerms:
          'Nossos Termos e Condições foram atualizados. Você precisa aceitá-los para continuar usando os serviços da Unxpose. ',
        warning: 'Atenção',
      },
      web: {
        errors: {
          invalidName: 'Nome da aplicação web inválido.',
          urlNotResponding: 'Esta URL não respondeu na última checagem',
          webAppNotResponding:
            'Não há URLs conhecidas apontando para esta aplicação web. Caso isso persista por uma semana, os achados relacionados serão fechados.',
        },
        tables: {
          product: 'Produto',
          total: 'Total',
          version: 'Versão',
          versions: 'Versões',
          webAppsWith: 'Aplicações web com',
        },
        applicationNotWorking: 'Esta aplicação não está mais funcionando',
        deleteUrlConfirmation:
          'Deletar esta URL? Você perderá o acesso ao seu conteúdo.',
        host: 'Host',
        identifiedWebApplications: 'Aplicações web identificadas',
        mergeDuplicates: 'Agrupar duplicadas',
        mergeWebApps: 'Agrupar aplicações',
        mergedWebApps: 'Aplicações agrupadas',
        noFindingsInWebApp: 'No momento, não há nenhum achado neste web app',
        noImportantFindings:
          'No momento, não há achados importantes nos seus web apps',
        path: 'Caminho',
        port: 'Porta',
        protocol: 'Protocolo',
        selectWebApps: 'Selecione duas ou mais aplicações para agrupá-las',
        unmergeUrlsConfirmation:
          'Tem certeza de que deseja desagrupar todas as URLs?',
        urlNotWorking: 'Está URL não está acessível',
        webApplicationDetails: 'Detalhes da aplicação web',
        webApplications: 'Aplicações web',
        webAppsMerged: 'Aplicações web agrupadas',
        firstSeen: 'Primeira detecção:',
        lastSeen: 'Última detecção:',
        details: 'Detalhes',
        statusCode: 'Código da resposta: ',
        title: 'Título: ',
        url: {
          key: 'Chave',
          value: 'Valor',
        },
        entryPoints: 'Pontos de entrada',
        urlQueryString: 'Parâmetros da URL',
        wafBlocked:
          'Parece que há um WAF bloqueando nossas requisições. Por favor, adicione nossos endereços IP na sua lista de permissões:',
        wildcardTooltip:
          'Esta aplicação web está em um subdomínio que possui um wildcard e por isso qualquer valor usado ao invés do asterisco irá funcionar',
        addWebApps: {
          addWebAppBtn: 'Adicionar web app',
          modalTitle: 'Qual aplicação web você quer adicionar?',
          helperText:
            'Apenas aplicações web que estejam em domínios monitorados pela sua organização podem ser adicionadas.',
          urlInput: 'URL',
          messages: {
            invalidUrl: 'URL inválida.',
            ipsNotAllowed: 'Endereços de IPs não são permitidos.',
            unmonitoredDomainerror:
              'Não é possível adicionar aplicações web de domínios que não são monitorados.',
            domainNotOwned: 'O domínio não pertence a esta empresa.',
            noResponseUrl:
              'Não existe uma aplicação web associada a este registro DNS.',
            urlExists: 'A URL {{url}} já existe.',
            urlExistsEntryPoint:
              'A URL {{url}} já existe e está apontando para {{entryPoint}}.',
            tooManyRequests:
              'Ops! Muitas requisições seguidas. Aguarde um momento e tente novamente.',
            webAppNotLive: 'A aplicação web não está respondendo.',
            serviceBehindCloudflare:
              'Não é possível adicionar esta aplicação web porque a opção de ignorar portas adicionais do Cloudflare está habilitada.',
            webAppSuccess:
              'A aplicação web {{url}} foi adicionada com sucesso.',
          },
        },
      },

      sortSelect: {
        placeholder: 'Ordernar por',
        options: {
          highestScore: 'Pior score',
          lowestScore: 'Melhor score',
          alphabetical: 'A - Z',
        },
      },
      companiesPage: {
        hi: 'Olá',
        searchCompany: 'Buscar empresa...',
        accessCompany: 'Acessar empresa',
        monitoring: 'Monitoramento: ',
        plan: 'Plano: ',
        subscriptionEndDate: 'Fim da assinatura: ',
      },
      whyUnxpose: {
        mainTitle: 'Por que assinar a <span>Unxpose?</span>',
        bottom: {
          title: 'Vamos agendar uma <span>conversa?</span>',
          subtitle:
            'Se você não tem certeza ainda, é possível pedir acesso a todas as funcionalidades gratuitamente por 7 dias!',
        },
        firstItem: {
          title: 'Automatização = Eficiência',
          paragraph_1:
            'Com as automatizações da Unxpose a sua empresa consegue fazer mais com menos pessoas e em menos tempo. Os scans de descoberta de ativos e os scans de identificação de falhas de segurança rodam  **de forma automatizada e contínua**. Ou seja, você não precisa se preocupar em agendar os scans ou cadastrar novos ativos de forma manual. Além disso, para cada novo achado, a solução faz a priorização de forma automatizada para que o seu time saiba onde agir primeiro e porquê.',
          testimonial: {
            text: `"Vejo a Unxpose como uma extensão dos times de tecnologia e cibersegurança, pois ela reduz a chance de erro humano ao automatizar o monitoramento do ambiente e ainda realizar a priorização do que foi encontrado. Assim, o nosso time evita trabalho repetitivo e diminui o tempo para correção dos achados de segurança."`,
            authorName: 'Mateus Neves',
            authorRole: 'CTO da Seedz',
          },
        },
        secondItem: {
          title: 'Uma única solução, diferentes pontos de vista de segurança',
          paragraph_1:
            'A Unxpose dá **visibilidade unificada da infraestrutura da sua empresa**, trazendo o olhar externo - através do monitoramento da Superfície de Ataque, o olhar interno - através do **monitoramento dos provedores de Cloud** (AWS, Azure e GCP), Compliance (LGPD) e **comportamento dos colaboradores** no Google Workspace e Microsoft 365. E mais, o discovery contínuo e automatizado da solução descobre os ativos que precisam ser protegidos de maneira simples para que você não tenha pontos cegos na infraestrutura da empresa.',
          testimonial: {
            text: `"A Unxpose mostra o que precisa ser protegido. Não existe outra solução no mercado que proporcione uma visibilidade tão ampla em uma única solução, unindo Superfície de Ataque, Cloud e Compliance a um custo tão baixo."`,
            authorName: 'Denis Oliveira',
            authorRole: 'Head of Cybersecurity da CAF',
          },
        },
        thirdItem: {
          title: 'Relatórios de auditoria e Compliance em 1 clique',
          paragraph_1:
            'Você consegue **gerar relatórios em PDF** criados especialmente para auditorias externas que vão mostrar o estado de segurança da sua empresa. O melhor: 100% das informações mostradas nos dashboards da Unxpose são **exportáveis em CSV** com apenas um clique. ',
        },
        fourthItem: {
          title: 'Acelere os processos de venda',
          paragraph_1:
            'Diminua o tempo para responder **Questionários de Segurança** recebidos usando a Unxpose. Nossa solução utiliza **Inteligência Artificial para preencher automaticamente as respostas** a partir de questionários respondidos anteriormente.',
          paragraph_2:
            'E mais, você consegue **centralizar todo o processo** em uma interface intuitiva que facilita a colaboração com pessoas de times diferentes, além de poder acompanhar o progresso, anexar evidências e ter o controle de entregas.',
        },
        fifthItem: {
          title: 'Gestão de Fornecedores',
          paragraph_1:
            'Centralize a sua **Gestão de Fornecedores** usando a Unxpose. Você consegue enviar questionários para seu fornecedores, avaliar as respostas e solicitar ajustes com apenas alguns cliques. Acabe com a troca de planilhas por emails e **tenha um visão unificada dos seus fornecedores!**',
        },
        sixthItem: {
          title: 'Custo-Benefício sem igual',
          paragraph_1:
            'A partir de **R$ 166,66 / mês** é possível assinar a Unxpose e ter os monitoramentos de superfície de ataque e de Cloud, que vão garantir visibilidade sobre os ativos e conhecimento sobre as falhas de segurança que colocam a sua empresa em risco. Em todos os planos **o número de usuários é ilimitado**. Preço acessível para a sua empresa ter o trabalho de um time de segurança ao alcance de poucos cliques.',
        },
        seventhItem: {
          title: 'Sem instalação de agentes, sem setup complicado',
          paragraph_1:
            'Unxpose é totalmente plug and play. A descoberta e monitoramento de ativos expostos na internet é feito a partir do cadastro do(s) domínio(s) principal(is) da empresa na solução de forma simples. A integração com a Cloud é feita em 5 minutos via API, seguindo o tutorial apresentado na solução. Conectar o **Google Workspace ou Microsoft 365** leva o tempo de 4 cliques. Você recebe o convite, cria a sua conta na Unxpose e **em poucos minutos tem acesso aos dashboards da solução para proteger a sua empresa**.',
        },
        eighthItem: {
          title:
            'Para empresas de todos os tamanhos. Para todos os tipos de time',
          paragraph_1:
            'A Unxpose é uma solução de segurança que preza pelo didatismo e facilidade de uso. Você definitivamente não precisa ter especialistas em segurança no time para usar a solução. O **Unxpose Scoring vai de A a F e traduz o grau de exposição da empresa no momento**, um KPI de fácil entendimento. Para cada falha encontrada, a solução apresenta um passo a passo para a correção, escrito para que um desenvolvedor junior seja capaz de executá-lo.',
        },
        ninthItem: {
          title: 'Suporte próximo',
          paragraph_1:
            'Tem dúvida sobre algum dos achados da Unxpose, está com dificuldade para fazer alguma integração ou não sabe muito bem como fornecer as informações que estão sendo pedidas em uma auditoria? O time da Unxpose está à disposição de forma ágil para **ajudar você e a sua empresa a tirarem o melhor da solução** e tomarem as ações de segurança necessárias.',
          testimonial: {
            text: `"O time da Unxpose une a experiência de décadas em cibersegurança com uma visão inovadora de produto."`,
            authorName: 'Cristiano Lincoln',
            authorRole: 'Founder & CEO da Tempest',
          },
        },
      },
      memberGetMember: {
        body: 'Que tal sua empresa com a Unxpose 100% gratuita?',
        button: 'Saiba mais',
        modal: {
          title: 'Sua empresa com a Unxpose 100% gratuita',
          description:
            'Ao indicar a Unxpose a uma empresa que contrate um dos nossos planos, a sua organização ganha 10% de desconto*. Os descontos são cumulativos e podem chegar a 100% — ou seja, a sua empresa pode ter a Unxpose grátis!',
          steps: {
            first: {
              title: 'Convide uma empresa',
              body: 'Peça para ela nos informar o seu e-mail aqui na Unxpose',
            },
            second: {
              title: 'Ganhem 10% de desconto',
              body: 'Se a empresa contratar um plano, vocês já ganham 10% de desconto',
            },
            third: {
              title: 'Mais empresas convidadas, maior o desconto',
              body: 'Se 10 empresas contratarem um plano, o seu será grátis!',
            },
          },
          conditions:
            '* O desconto será válido enquanto a empresa indicada estiver com a assinatura vigente',
        },
      },
      marketplace: {
        services: 'Serviços',
        by: 'por',
        about: 'Sobre',
        startingAt: 'Iniciando em',
        noServicesFound: 'Nenhum serviço encontrado para essa categoria.',
        hire: 'Solicitar orçamento',
        requestQuote: 'Solicitar orçamento',
        quoteRequested:
          'Orçamento solicitado com sucesso! Você receberá um e-mail com uma proposta em até 3 dias.',
        sharedInfo:
          'As informações fornecidas poderão ser compartilhadas com o parceiro {{partnerName}}.',
      },
      subscription: {
        checkout: {
          title: 'Produtos',
          products: {
            domains: {
              title: 'Monitoramento de Domínios',
              description:
                'A Unxpose descobre e cataloga automaticamente todos os subdomínios, hosts, serviços e sites associados a cada domínio e, em seguida, verifica cada ativo identificado em busca de possíveis vulnerabilidades de segurança.',
              limit: 'Até <strong>{{limit}}</strong> subdomínios',
            },
            cloudIntegrations: {
              title: 'Monitoramento de Contas na Nuvem',
              description:
                'A Unxpose descobre e cataloga automaticamente todos os ativos dentro de cada conta na nuvem e, em seguida, verifica configurações incorretas de segurança usando as melhores práticas do setor.',
              limit: 'Até <strong>{{limit}}</strong> ativos',
            },
            idpIntegrations: {
              title: 'Monitoramento de Provedores de Identidade',
              description:
                'A Unxpose cataloga automaticamente todos os funcionários em seus ambientes do Google Workspace ou Microsoft 365, realizando verificações de segurança com base nas melhores práticas do setor e verificando credenciais comprometidas.',
              limit: 'Até <strong>{{limit}}</strong> contas',
            },
            apiKeys: {
              title: 'Acesso à API keys',
              description:
                'Gere chaves de API para integrar seus sistemas com a plataforma Unxpose de forma segura e eficiente.',
            },
            suppliersScoringMonitoring: {
              title: 'Monitoramento de Score de Fornecedores',
              description:
                'A Unxpose cataloga e monitora a superfície de ataque externa dos seus fornecedores e, em seguida, classifica-a com um sistema de classificação de A a F para ajudar a gerenciar o risco cibernético de terceiros.',
            },
            suppliersQuestionnairesManagement: {
              title: 'Gestão de Questionários de Fornecedores',
              description:
                'Os fluxos de trabalho de due diligence centralizados da Unxpose simplificam as avaliações de fornecedores ao automatizar a distribuição de questionários, a coleta de respostas e a validação.',
            },
          },
          cartItem: {
            quantity: 'Quantidade',
            include: 'Incluir',
            value: 'Valor',
            total: 'Total / mês',
          },
          cartFooter: {
            subtotal: 'Subtotal',
            coupon: 'Cupom',
            afterTrial: 'Após 7 dias',
            total: 'Total agora',
            perMonth: '/ mês',
          },
          paymentVia: 'Pagamento via',
          paymentViaDescription: 'Transação segura.',
          subscribeBtn: 'Assinar agora',
          emailInput: 'E-mail de cobrança',
          taxIdInput: 'CNPJ',
          processingScreen: {
            title: 'Estamos processando sua assinatura...',
            subtitle: 'Aguarde alguns segundos',
          },
          removeModal: {
            title: 'Remover item?',
            body: 'Essa é a única unidade do produto. Após remover, o item deixará de aparecer. Você pode atualizar a página para vê-lo novamente.',
          },
          footer:
            'Após o fim do TRIAL, você será cobrado <strong>{{total}}</strong> por mês a partir de <strong>{{date}}</strong>. Você pode cancelar antes disso.',
          cartError:
            'Não foi possível carregar os produtos. Tente novamente em alguns instantes.',
          messages: {
            couponApplied: 'Cupom aplicado com sucesso!',
            couponRemoved: 'Cupom removido',
            couponError: 'Cupom inválido',
            couponRemoveError: 'Não foi possível remover o cupom',
            activeSubscriptions: 'Você já possui uma assinatura ativa',
            cardDeclined: 'Você não possui limite suficiente em seu cartão',
            removeItemError: 'Você não pode remover o único item do carrinho',
          },
        },
        update: {
          planSubsection: {
            title: 'Plano',
            creation: 'Criação',
            expiration: 'Expiração',
            expirated: 'Expirado',
            cancelSubscriptionBtn: 'Cancelar assinatura',
            cancelSubscriptionModal: {
              title: 'Cancelamento de assinatura',
              body: 'Para efetuar o cancelamento do seu plano, entre em contato conosco pelo <a>support@unxpose.com</a>',
            },
          },
          products: {
            title: 'Produtos contratados',
            currentQty: 'Quantidade atual',
            newQty: 'Nova quantidade',
            newState: 'Novo estado',
            included: 'Incluído',
            currentCost: 'Custo atual / mês',
            newCost: 'Novo custo / mês',
            quantity: 'Quantidade',
            value: 'Valor',
            total: 'Total / mês',
            currentSubscriptionCost: 'Custo atual da assinatura',
            newSubscriptionCost: 'Novo custo',
            changeProductsBtn: 'Alterar produtos',
            reviewBtn: 'Revisar',
            updateSubscriptionBtn: 'Atualizar cobrança',
            discardBtn: 'Descartar',
            subscriptionHelper:
              'Quando você altera seu plano, a cobrança é ajustada proporcionalmente ao tempo restante.',
            billingProviderModal: {
              title: 'Alteração de produtos',
              body: 'Para realizar alterações em seu plano, entre em contato conosco através do agendamento abaixo ou diretamente pelo e-mail <a>sales@unxpose.com</a>.',
              paperformTitle: 'Fale com vendas e atualize seu plano',
              talkToSalesBtn: 'Fale com vendas',
            },
          },
          messages: {
            updateSuccess: 'Seu plano foi alterado com sucesso!',
          },
        },
        limitRestrictionModal: {
          title: 'Antes de continuar...',
          quantityExcerpt: 'a quantidade',
          subscriptionExcerpt: 'sua assinatura',
          domains: {
            firstParagraph:
              'Você está tentando reduzir {{excerpt}} de <strong>{{prevQty}}</strong> para <strong>{{newQty}} domínio(s)</strong>, mas atualmente a sua empresa está monitorando <strong>{{currentQty}}</strong>.',
            secondParagraph:
              'Para continuar, <externalLink>acesse a lista de domínios</externalLink><i/>, remova a quantidade necessária e <reload>atualize esta página</reload> após alguns segundos.',
          },
          cloudIntegrations: {
            firstParagraph:
              'Você está tentando reduzir {{excerpt}} de <strong>{{prevQty}}</strong> para <strong>{{newQty}} conta(s) de Cloud</strong>, mas atualmente a sua empresa está monitorando <strong>{{currentQty}}</strong>.',
            secondParagraph:
              'Para continuar, <externalLink>acesse a lista de integrações</externalLink><i/>, remova a quantidade necessária e <reload>atualize esta página</reload> após alguns segundos.',
          },
          idpIntegrations: {
            firstParagraph:
              'Você está tentando reduzir {{excerpt}} de <strong>{{prevQty}}</strong> para <strong>{{newQty}} Provedor(es) de Identidade</strong>, mas atualmente a sua empresa está monitorando <strong>{{currentQty}}</strong>.',
            secondParagraph:
              'Para continuar, <externalLink>acesse a lista de integrações</externalLink><i/>, remova a quantidade necessária e <reload>atualize esta página</reload> após alguns segundos.',
          },
          apiKeys: {
            firstParagraph:
              'Você está tentando desabilitar <strong>API Keys</strong>, mas atualmente possui <strong>{{currentQty}}</strong> chave(s) gerada(s).',
            secondParagraph:
              'Para continuar, <externalLink>acesse a lista de API Keys</externalLink><i/>, remova todas elas e <reload>atualize esta página</reload> após alguns segundos.',
          },
          monitoredSuppliers: {
            firstParagraph:
              'Você está tentando reduzir {{excerpt}} de fornecedores monitorados de <strong>{{prevQty}}</strong> para <strong>{{newQty}}</strong>, mas atualmente a sua empresa está monitorando <strong>{{currentQty}}</strong>.',
            secondParagraph:
              'Para continuar, <externalLink>acesse a lista de fornecedores</externalLink><i/>, interrompa o monitoramento da quantidade necessária de fornecedores e <reload>atualize esta página</reload> após alguns segundos.',
          },
          securityQuestionnaires: {
            firstParagraph:
              'Para reduzir o produto <strong>Gestão de Questionários de Fornecedores</strong>, entre em contato com <a>nosso suporte</a>.',
          },
        },
      },
    },
  },
}
