import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import i18next from 'i18next'

import PlanUtils from 'utils/plan'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import images from 'res'
import {
  memberGetMemberModal,
  showAutoSubscriptionModal,
  showPlanRenewalModal,
} from 'actions'

const PlanInfoMenu = () => {
  const dispatch = useDispatch()

  const [showPlanNotification, setShowPlanNotification] = useState(false)

  const planUtils = PlanUtils()

  useEffect(() => {
    setShowPlanNotification(planUtils.showSubscriptionAlert())
  }, [])

  const labels = planUtils.getPlanMenuLabels()

  return (
    <div className="mdHeight:absolute bottom-0 m-4 hidden lg:block">
      {showPlanNotification ? (
        <div className="border border-sky-600/50 bg-darkBlue rounded-md py-3 px-4">
          <div className="flex">
            <div className="mb-2 mr-2">
              <img src={images.notificationIcon} width="20" />
            </div>
            <div className="leading-5 font-medium text-xs mb-1">
              {labels.title}
            </div>
          </div>

          <span className="leading-5 text-xs mr-1">{labels.body}</span>

          <SecondaryButton
            text={labels.subscribeBtn}
            color="white"
            size="sm"
            onClick={() => {
              planUtils.userHasPremiumPlan()
                ? dispatch(showPlanRenewalModal(true))
                : dispatch(showAutoSubscriptionModal(true))
            }}
            classContainer="inline-flex"
          />
        </div>
      ) : (
        planUtils.userHasPremiumPlan() && (
          <div className="border-t border-sky-800">
            <div className="pt-3 px-3">
              <span className="leading-5 text-xs mr-1">
                {i18next.t('memberGetMember.body')}
              </span>
              <SecondaryButton
                text={i18next.t('memberGetMember.button')}
                color="white"
                size="sm"
                onClick={() => {
                  dispatch(memberGetMemberModal({showModal: true}))
                }}
                classContainer="inline"
              />
            </div>
          </div>
        )
      )}
    </div>
  )
}

export default PlanInfoMenu
