import React from 'react'
import CheckoutContainer from './../CheckoutContainer'
import {getProductInfo} from 'components/subscription/subscriptionUtils'

export default function CheckoutLoader() {
  const producs = [
    {
      ...getProductInfo('domains'),
    },
    {
      ...getProductInfo('cloudIntegrations'),
    },
    {
      ...getProductInfo('idpIntegrations'),
    },
    {
      ...getProductInfo('apiKeys'),
    },
    {
      ...getProductInfo('monitoredSuppliers'),
    },
    {
      ...getProductInfo('securityQuestionnaires'),
    },
  ]

  return (
    <CheckoutContainer
      id="checkout-loader-container"
      products={producs}
      isLoadingState
    />
  )
}
