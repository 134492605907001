import React from 'react'
import {useSelector} from 'react-redux'
import i18next from 'i18next'
import {v4 as uuidv4} from 'uuid'
import {sideral100, sideral700} from 'constant'
import ArrowIcon from 'res/icons/ArrowIcon'
import SecurityShield from 'res/icons/securityShield'
import {Link} from 'react-router-dom'

export default function NextSteps() {
  const nextStepsState = useSelector(state => state.nextSteps)

  return (
    <div className="p-4">
      {nextStepsState?.length > 0 ? (
        nextStepsState.map(item => (
          <Link key={uuidv4()} to={item.link}>
            <div className="border-b p-4 last:border-0 rounded-md hover:bg-astral-100 cursor-pointer">
              <div className="flex items-center justify-between border-l-4 border-l-sky-900">
                <div className="ml-3">
                  <div>{item.title}</div>
                  <div className="text-sm text-sideral-700">
                    {item.subtitle}
                  </div>
                </div>

                <div className="ml-4">
                  <ArrowIcon color={sideral700} width="14" direction="right" />
                </div>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <div className="h-full py-16">
          <div className="flex flex-col items-center">
            <SecurityShield width="72" height="72" color={sideral100} />
            <div className="mt-4">{i18next.t('nextSteps.noResults')}</div>
          </div>
        </div>
      )}
    </div>
  )
}
