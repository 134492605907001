import React from 'react'
import i18next from 'i18next'
import {Page} from 'components/partials/Page'
import bgOrangePurple from '../../res/backgrounds/bgOrangePurple.svg'
import creditCardIllustration from '../../res/illustrations/credit-card.svg'

export default function ProcessingPayment() {
  return (
    <Page pageTitle={i18next.t('pageTitles.subscription')}>
      <div className="relative bg-white border rounded-bl rounded-br rounded-tr">
        <div
          id="border-top"
          className={'absolute top-0 h-1 loading-animation'}
          style={{
            backgroundImage: `url(${bgOrangePurple})`,
          }}
        />

        <div className="flex flex-col justify-center items-center text-ink text-center py-80">
          <img src={creditCardIllustration} className="w-64" />

          <div className="font-medium text-lg mb-1 mt-8">
            {i18next.t('subscription.checkout.processingScreen.title')}
          </div>
          <div className="text-sm">
            {i18next.t('subscription.checkout.processingScreen.subtitle')}
          </div>
        </div>
      </div>
    </Page>
  )
}
