import React, {useEffect, useState} from 'react'
import i18next from 'i18next'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {isLoading, viewFindingsByCategory} from 'utils'

import ScoreBox from 'components/partials/ScoreBox'
import Subsection from 'components/partials/headers/Subsection'
import Spinner from 'components/partials/Spinner'
import StatsBox from 'components/partials/StatsBox'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'

import {
  fetchCloudFeed,
  fetchCloudStats,
  fetchCloudIntegrations,
  loading,
} from 'actions'

import GeneralWhatsImportant from './GeneralWhatsImportant'
import Providers from './Providers'
import {Page} from 'components/partials/Page'
import PlanUtils from 'utils/plan'

export default function CloudDashboard() {
  const loadingState = useSelector(state => state.loading)
  const cloudFeedState = useSelector(state => state.cloudFeed)
  const cloudIntegrationsState = useSelector(state => state.cloudIntegrations)
  const statsCloudState = useSelector(state => state.statsCloud)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const planUtils = PlanUtils()

  const [componentLoaded, setComponentLoaded] = useState(false)
  const [importantFindings, setImportantFindings] = useState([])

  const [searchFilter, setSearchFilter] = useState('')
  const [selectedProviderFilter, setSelectedProviderFilter] = useState([])

  useEffect(() => {
    const page = 1

    if (!planUtils.hasPlanRestriction()) {
      dispatch(fetchCloudFeed(page))
      dispatch(loading({cloudFeed: true}))
    }

    dispatch(fetchCloudIntegrations())

    setComponentLoaded(true)
  }, [])

  useEffect(() => {
    if (
      componentLoaded &&
      cloudFeedState &&
      cloudFeedState.findings &&
      cloudFeedState.findings.length > 0
    ) {
      setImportantFindings(prevFindings => [
        ...prevFindings,
        ...cloudFeedState.findings,
      ])
    }
  }, [cloudFeedState])

  const hasNoCloudIntegrations = () =>
    searchFilter === '' &&
    !selectedProviderFilter.length &&
    !cloudIntegrationsState?.data?.length

  useEffect(() => {
    if (componentLoaded) {
      hasNoCloudIntegrations()
        ? navigate('/cloud/integration')
        : dispatch(fetchCloudStats())
    }
  }, [cloudIntegrationsState])

  useEffect(() => {
    if (loadingState.cloudIntegrations || !statsCloudState) return

    if (window.location.hash.includes('#cloud-integrations')) {
      document.getElementById('cloud-integrations').scrollIntoView()
    }
  }, [loadingState, statsCloudState])

  if (isLoading(loadingState.cloudIntegrations)) return <Spinner />

  return (
    <Page
      pageTitle={i18next.t('pageTitles.cloud')}
      button={
        <SecondaryButton
          text={i18next.t('buttons.newIntegration')}
          redirect="/cloud/integration"
          plusIcon
        />
      }>
      {statsCloudState ? (
        <div>
          <div className="grid md:grid-cols-2 xl:grid-cols-7 gap-4 w-full">
            <StatsBox
              classContainer="md:col-span-2 xl:col-span-3"
              slots={2}
              title={`/${i18next.t('misc.assets')}`}
              label={i18next.t('cloud.integrations')}
              data={
                (cloudIntegrationsState && cloudIntegrationsState.total) || 0
              }
              secondaryLabel={i18next.t('misc.resources')}
              secondaryData={
                (statsCloudState && statsCloudState.assetsCount) || 0
              }
            />

            <StatsBox
              classContainer="xl:col-span-2"
              slots={1}
              title={`/${i18next.t('misc.findings')}`}
              data={(statsCloudState && statsCloudState.findingsCount) || 0}
              link={{
                text: i18next.t('buttons.viewAll'),
                onClick: () => viewFindingsByCategory('cloud'),
              }}
              size="xs"
            />

            <ScoreBox
              classContainer="xl:col-span-2"
              score={statsCloudState && statsCloudState.scores}
            />
          </div>

          <GeneralWhatsImportant
            findings={importantFindings}
            loading={isLoading(loadingState.cloudFeed, false)}
          />

          <Subsection
            id="cloud-integrations"
            title={i18next.t('cloud.integrations')}
            content={
              <Providers
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                selectedProviderFilter={selectedProviderFilter}
                setSelectedProviderFilter={setSelectedProviderFilter}
              />
            }
            button={
              <SecondaryButton
                text={i18next.t('buttons.newIntegration')}
                size="sm"
                margin="ml-auto"
                redirect="/cloud/integration"
                plusIcon
              />
            }
            margin="mt-8"
          />
        </div>
      ) : (
        <Spinner />
      )}
    </Page>
  )
}
