import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {sky900} from 'constant'

import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import TrashIcon from 'res/icons/trashIcon'
import Spinner from 'components/partials/Spinner'
import SupplierRemoveModal from './modals/SupplierRemoveModal'
import {clearAction, fetchSuppliers, toggleRestrictionModal} from 'actions'
import Timestamp from 'components/partials/Timestamp'
import ScoreBadge from 'components/partials/ScoreBadge'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import StartMonitoringModal from './modals/StartMonitoringModal'
import DropdownButton from 'components/partials/buttons/DropdownButton'
import EllipsisIcon from 'res/icons/ellipsisIcon'
import StopMonitoringModal from './modals/StopMonitoringModal'
import CloseIcon from 'res/icons/closeIcon'
import Badge from 'components/partials/Badge'
import CompanyLogo from 'components/partials/CompanyLogo'
import {getFeatureSubscription} from 'utils'

export default function SuppliersList() {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const suppliersState = useSelector(state => state.suppliers)
  const suppliersStatsState = useSelector(state => state.suppliersStats)

  const processingScoreRef = useRef(null)

  const [removeModal, setRemoveModal] = useState({
    selected: null,
    showModal: false,
  })

  const [startMonitoringModal, setStartMonitoringModal] = useState({
    selected: null,
    showModal: false,
  })

  const [stopMonitoringModal, setStopMonitoringModal] = useState({
    selected: null,
    showModal: false,
  })

  const handleDisplayMonitoringModal = (id, name) => {
    const monitoredSuppliersSubscription = getFeatureSubscription(
      userState,
      'monitoredSuppliers'
    )

    if (
      suppliersStatsState.monitoredCount >= monitoredSuppliersSubscription.limit
    ) {
      return dispatch(
        toggleRestrictionModal({
          contentId: 'monitoringSupplier',
          showModal: true,
        })
      )
    }

    dispatch(clearAction('startSupplierMonitoringDone'))
    setStartMonitoringModal({
      selected: {
        id,
        name,
      },
      showModal: true,
    })
  }

  const handleDisplayRemoveModal = (id, name) => {
    setRemoveModal({
      selected: {
        id,
        name,
      },
      showModal: true,
    })
  }

  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: i18next.t('suppliers.table.supplier'),
        minSize: 250,
        meta: {
          align: 'left',
        },
        cell: cell => {
          return (
            <div className="flex items-center">
              <div className="mr-3">
                <CompanyLogo logoUrl={cell.row.original.logo} />
              </div>
              <div>{cell.getValue()}</div>
              {cell.row.original.status === 'pending' && (
                <div className="ml-4">
                  <Badge
                    label={i18next.t('status.pending')}
                    style="warning"
                    classes="text-xs h-[1.3rem]"
                  />
                </div>
              )}
            </div>
          )
        },
      },

      {
        id: 'questionnairesCount',
        accessorKey: 'questionnairesCount',
        header: i18next.t('suppliers.table.questionnaires'),
      },

      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        header: i18next.t('suppliers.table.created'),
        cell: cell => <Timestamp date={cell.getValue()} fromNow />,
      },

      {
        id: 'score',
        accessorKey: 'score',
        header: i18next.t('suppliers.table.score'),
        cell: cell => {
          const isMonitoring = cell.row.original.monitoring.enabled
          const isProcessing =
            isMonitoring && cell.row.original.monitoring.score === null

          if (!isMonitoring) {
            return (
              <SecondaryButton
                text={i18next.t('suppliers.seeScoreBtn')}
                size="sm"
                onClick={() =>
                  handleDisplayMonitoringModal(
                    cell.row.original.id,
                    cell.row.original.name
                  )
                }
                disabled={cell.row.original.status === 'pending'}
              />
            )
          }

          if (isProcessing) {
            return (
              <div className="inline-flex items-center justify-center gap-3">
                <div className="relative flex h-3 w-3">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75" />
                  <span className="rounded-full bg-orange-400 h-3 w-3" />
                </div>

                <div className="text-sm">
                  {i18next.t('suppliers.table.calculating')}
                </div>
              </div>
            )
          }

          return (
            <div className="inline-flex justify-center -mr-2">
              <ScoreBadge letter={cell.row.original.monitoring.score.grade} />
            </div>
          )
        },
        hasCustomNoValue: true,
        isButton: true,
        sortingFn: (a, b) => {
          return a.original.monitoring?.score?.grade.localeCompare(
            b.original.monitoring?.score?.grade
          )
        },
      },

      {
        id: 'actionButtons',
        accessorKey: 'id',
        header: '',
        size: 5,
        cell: cell => {
          const canStopMonitoring =
            cell.row.original.monitoring.enabled &&
            cell.row.original.monitoring.score !== null

          return (
            <div className="flex items-center justify-end z-100">
              <DropdownButton
                label={<EllipsisIcon width="20" />}
                buttonTheme="icon-only"
                innerClasses="right-[30px] w-56"
                items={[
                  {
                    name: i18next.t('suppliers.detail.score.sectionBtn'),
                    icon: <CloseIcon width="20" color={sky900} />,
                    disabled: !canStopMonitoring,
                    onClick: () => {
                      dispatch(clearAction('stopSupplierMonitoringDone'))

                      setStopMonitoringModal({
                        supplierId: cell.getValue(),
                        showModal: true,
                      })
                    },
                  },

                  {
                    name: i18next.t('misc.remove'),
                    icon: <TrashIcon height="20" width="20" color={sky900} />,
                    disabled: cell.row.original.type === 'received',
                    onClick: () => {
                      handleDisplayRemoveModal(
                        cell.getValue(),
                        cell.row.original.name
                      )
                    },
                  },
                ]}
              />
            </div>
          )
        },
        disableGlobalFilter: true,
        isButton: true,
      },
    ],
    [suppliersStatsState]
  )

  const someItemIsCalcScore = suppliersState?.some(
    item => item.monitoring.enabled && item.monitoring.score === null
  )

  useEffect(() => {
    if (someItemIsCalcScore) {
      processingScoreRef.current = setInterval(() => {
        dispatch(fetchSuppliers())
      }, 60000)
    } else {
      clearInterval(processingScoreRef.current)
    }

    return () => clearInterval(processingScoreRef.current)
  }, [someItemIsCalcScore])

  if (!suppliersState) return <Spinner />

  return (
    <div className="p-6">
      <PrimaryTableV8
        columns={columns}
        data={suppliersState}
        detailPath={row =>
          row.original.status !== 'pending'
            ? `/suppliers/${row.original.id}`
            : null
        }
        emptyStateWithFilter={
          <GenericEmptyState
            icon={<i className={`icofont-list text-6xl text-sideral-300`} />}
            title={i18next.t('tables.nothingFoundWithSearch')}
            body={i18next.t('tables.searchSomethingElse')}
          />
        }
        emptyStateWithoutFilter={
          <GenericEmptyState
            icon={<i className={`icofont-list text-6xl text-sideral-300`} />}
            title={i18next.t('tables.noResultsFound')}
            body={i18next.t('suppliers.noSuppliers')}
          />
        }
      />

      {removeModal.showModal && (
        <SupplierRemoveModal
          supplierDetails={removeModal.selected}
          setRemoveModal={setRemoveModal}
        />
      )}

      {startMonitoringModal.showModal && (
        <StartMonitoringModal
          supplierDetails={startMonitoringModal.selected}
          setModal={setStartMonitoringModal}
        />
      )}

      {stopMonitoringModal.showModal && (
        <StopMonitoringModal
          supplierId={stopMonitoringModal.supplierId}
          setModal={setStopMonitoringModal}
        />
      )}
    </div>
  )
}
