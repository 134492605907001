import React, {useState, useMemo} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {i18next} from 'translate/i18n'
import {v4 as uuidv4} from 'uuid'

import images from 'res'
import {mars700, sky500, assetServiceMap, assetApiUrlMap} from 'constant'

import {
  fetchGcpBuckets,
  fetchGcpFindingsByAsset,
  fetchGcpInstances,
  fetchGcpKms,
  fetchGcpApiKeys,
  fetchGcpCloudRunServices,
  fetchGcpPrincipals,
  fetchGcpServiceAccountKeys,
  fetchGcpSqlInstances,
} from 'actions'

import {
  apiKeysColumns,
  bucketsColumns,
  cloudRunServicesColumns,
  findingsColumns,
  instancesColumns,
  kmsColumns,
  principalsColumns,
  serviceAccountKeysColumns,
  sqlInstanceColumns,
} from './columns'

import Spinner from 'components/partials/Spinner'
import config from 'config'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import {viewFindingsByCategory} from 'utils'

export default function AssetsItems(props) {
  const gcpApiKeysState = useSelector(state => state.gcpApiKeys)
  const gcpCloudRunServicesState = useSelector(
    state => state.gcpCloudRunServices
  )
  const gcpStatsState = useSelector(state => state.gcpStats)
  const gcpFindingsByAssetState = useSelector(state => state.gcpFindingsByAsset)
  const gcpBucketsState = useSelector(state => state.gcpBuckets)
  const gcpKmsState = useSelector(state => state.gcpKms)
  const gcpPrincipalsState = useSelector(state => state.gcpPrincipals)
  const gcpInstancesState = useSelector(state => state.gcpInstances)
  const gcpServiceAccountKeysState = useSelector(
    state => state.gcpServiceAccountKeys
  )
  const gcpSqlInstancesState = useSelector(state => state.gcpSqlInstances)

  const dispatch = useDispatch()

  const [openAccordion, setOpenAccordion] = useState('')
  const [tabs, setTabs] = useState({})

  const toggleAccordion = asset => {
    openAccordion === asset ? setOpenAccordion('') : setOpenAccordion(asset)
  }

  const handleTab = (eventTarget, asset) => {
    if (eventTarget === tabs[asset]) return

    setTabs({...tabs, [asset]: eventTarget})
  }

  function fetchAssets(asset) {
    /*
     * Every time a new asset is included it must be also added to the
     * reducer's listener CLEAR_GCP_ASSETS_DATA, so the props gets cleared
     * when the user goes back to the GCP overview.
     */

    switch (asset) {
      case 'apiKeys':
        !gcpApiKeysState && dispatch(fetchGcpApiKeys(props.projectId))
        break
      case 'cloudRunServices':
        !gcpCloudRunServicesState &&
          dispatch(fetchGcpCloudRunServices(props.projectId))
        break
      case 'storageBuckets':
        !gcpBucketsState && dispatch(fetchGcpBuckets(props.projectId))
        break
      case 'kmsKeys':
        !gcpKmsState && dispatch(fetchGcpKms(props.projectId))
        break
      case 'principals':
        !gcpPrincipalsState && dispatch(fetchGcpPrincipals(props.projectId))
        break
      case 'vmInstances':
        !gcpInstancesState && dispatch(fetchGcpInstances(props.projectId))
        break
      case 'serviceAccountKeys':
        !gcpServiceAccountKeysState &&
          dispatch(fetchGcpServiceAccountKeys(props.projectId))
        break
      case 'sqlInstances':
        !gcpSqlInstancesState && dispatch(fetchGcpSqlInstances(props.projectId))
        break
      default:
        return
    }
  }

  function handleRowClick(asset) {
    if (
      !gcpFindingsByAssetState ||
      (gcpFindingsByAssetState && !gcpFindingsByAssetState[asset])
    )
      dispatch(
        fetchGcpFindingsByAsset({
          projectId: props.projectId,
          asset,
        })
      )
    fetchAssets(asset)

    // set the findings tab as default on row click
    handleTab('findings', asset)

    toggleAccordion(asset)
  }

  const innerTableProps = {
    apiKeys: {
      data: gcpApiKeysState || [],
      columns: useMemo(() => apiKeysColumns, []),
    },
    cloudRunServices: {
      data: gcpCloudRunServicesState || [],
      columns: useMemo(() => cloudRunServicesColumns, []),
    },
    storageBuckets: {
      data: gcpBucketsState || [],
      columns: useMemo(() => bucketsColumns, []),
    },
    kmsKeys: {
      data: gcpKmsState || [],
      columns: useMemo(() => kmsColumns, []),
    },
    principals: {
      data: gcpPrincipalsState || [],
      columns: useMemo(() => principalsColumns, []),
    },
    vmInstances: {
      data: gcpInstancesState || [],
      columns: useMemo(() => instancesColumns, []),
    },
    findings: {columns: useMemo(() => findingsColumns, [])},
    serviceAccountKeys: {
      data: gcpServiceAccountKeysState || [],
      columns: useMemo(() => serviceAccountKeysColumns, []),
    },
    sqlInstances: {
      data: gcpSqlInstancesState || [],
      columns: useMemo(() => sqlInstanceColumns, []),
    },
  }

  return props.data.map(item => (
    <div key={uuidv4()} className="my-2 rounded-lg border-2 border-sideral-50">
      <div
        onClick={() => handleRowClick(item.asset)}
        className="flex items-center justify-between w-full p-4">
        <div
          id="name"
          className="basis-1/3 uppercase flex cursor-pointer text-left text-sideral-700 font-medium text-sm items-center">
          {item.name}
        </div>

        <div
          id="findingsCount"
          className="basis-1/3 flex cursor-pointer items-center text-sm">
          <figure className="pr-1">
            <images.FindingIcon width="20" />
          </figure>
          {typeof item.findings === 'number' ? item.findings : '-'}
          <div className="ml-1">
            {item.findings > 1
              ? i18next.t('misc.findings').toLowerCase()
              : i18next.t('misc.finding').toLowerCase()}
          </div>
        </div>

        <div
          id="chevron"
          className="flex cursor-pointer items-center w-20 px-4">
          {latestCheckFailed({
            assetName: item.asset,
            gcpStatsState,
            projectId: props.projectId,
          }) && (
            <figure className="mr-4">
              <images.WarningIcon width="20" color={mars700} />
            </figure>
          )}
          <figure className="ml-auto">
            <images.Chevron
              width="12"
              color={sky500}
              direction={openAccordion === item.asset ? 'up' : 'down'}
            />
          </figure>
        </div>
      </div>

      {openAccordion === item.asset && (
        <AccordionContent
          tabs={tabs}
          assetName={item.asset}
          innerTableProps={innerTableProps}
          handleTab={handleTab}
          props={props}
        />
      )}
    </div>
  ))
}

function AccordionContent({
  tabs,
  assetName,
  innerTableProps,
  handleTab,
  props,
}) {
  const gcpStatsState = useSelector(state => state.gcpStats)
  const gcpFindingsByAssetState = useSelector(state => state.gcpFindingsByAsset)

  const serviceIsLoading = asset => {
    return !gcpFindingsByAssetState || !gcpFindingsByAssetState[asset]
  }

  function getFindingsData(asset) {
    return (gcpFindingsByAssetState && gcpFindingsByAssetState[asset]) || []
  }

  return (
    <>
      {serviceIsLoading(assetName) ? (
        <div className="w-full -mt-4">
          <Spinner />
        </div>
      ) : (
        <div className="w-full">
          {latestCheckFailed({
            assetName,
            gcpStatsState,
            projectId: props.projectId,
          }) ? (
            <div
              className="bg-astral-50 border-t-2 border-sideral-50 w-full p-10"
              onClick={e => e.stopPropagation()}>
              <CheckFailed
                assetName={assetName}
                integrationId={props.integrationId}
                log={getServiceLog({
                  gcpStatsState,
                  projectId: props.projectId,
                  assetName,
                })}
              />
            </div>
          ) : (
            <div
              className="relative bg-astral-50 border-t-2 border-sideral-50 w-full p-4"
              onClick={e => e.stopPropagation()}>
              <div className="flex text-sky-500 text-sm mb-4">
                <button
                  value="findings"
                  className={`${
                    tabs[assetName] === 'findings' || !tabs[assetName]
                      ? ' border-sky-500 text-sky-900'
                      : 'border-transparent'
                  } border-b-2 uppercase cursor-pointer outline-none mr-5 pb-2`}
                  onClick={e => {
                    handleTab(e.target.value, assetName)
                  }}>
                  {i18next.t('misc.findings')}
                </button>
                <button
                  value="assets"
                  className={`${
                    tabs[assetName] === 'assets'
                      ? ' border-sky-500 text-sky-900'
                      : 'border-transparent'
                  } border-b-2 uppercase cursor-pointer outline-none mr-5 pb-2`}
                  onClick={e => {
                    handleTab(e.target.value, assetName)
                  }}>
                  {i18next.t('misc.assets')}
                </button>
              </div>

              {tabs[assetName] === 'assets' && (
                <PrimaryTableV8
                  data={innerTableProps[assetName].data}
                  columns={innerTableProps[assetName].columns}
                  emptyStateWithFilter={
                    <GenericEmptyState
                      icon={<images.CloudIcon width="55" color="#90A4AE" />}
                      title={i18next.t('tables.nothingFoundWithSearch')}
                      body={i18next.t('tables.searchSomethingElse')}
                      bgColor="bg-astral-50"
                      margin="m-2"
                    />
                  }
                  emptyStateWithoutFilter={
                    <GenericEmptyState
                      icon={<images.CloudIcon width="55" color="#90A4AE" />}
                      title={i18next.t('titles.noResultsFound')}
                      body={i18next.t('cloud.noAssets')}
                      bgColor="bg-astral-50"
                      margin="m-2"
                    />
                  }
                />
              )}

              {tabs[assetName] === 'findings' && (
                <PrimaryTableV8
                  data={getFindingsData(assetName)}
                  columns={innerTableProps.findings.columns}
                  detailPath={row => `/gcp/finding/${row.original.id}`}
                  emptyStateWithFilter={
                    <GenericEmptyState
                      icon={<images.FindingIcon width="55" color="#90A4AE" />}
                      title={i18next.t('tables.nothingFoundWithSearch')}
                      body={i18next.t('tables.searchSomethingElse')}
                      buttonText={i18next.t('buttons.viewAllFindings')}
                      linkPath={viewFindingsByCategory('cloud')}
                      bgColor={'bg-astral-50'}
                      margin="m-2"
                    />
                  }
                  emptyStateWithoutFilter={
                    <GenericEmptyState
                      icon={<images.FindingIcon width="55" color="#90A4AE" />}
                      title={i18next.t('titles.goodNews')}
                      body={i18next.t('cloud.noFindingsInService')}
                      buttonText={i18next.t('buttons.viewAllFindings')}
                      linkPath={viewFindingsByCategory('cloud')}
                      bgColor={'bg-astral-50'}
                      margin="m-2"
                    />
                  }
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

function getServiceLog({assetName, gcpStatsState, projectId}) {
  return gcpStatsState[projectId]?.latestCheck?.logs[
    assetServiceMap.gcp[assetName]
  ]
}

function latestCheckFailed({assetName, gcpStatsState, projectId}) {
  const log = getServiceLog({assetName, gcpStatsState, projectId})
  if (log?.code !== 'ok') return true
}

function CheckFailed({assetName, log, integrationId}) {
  const errorCorde = log?.code
  let errorContent = ''

  switch (errorCorde) {
    case 'DISABLED_API':
      errorContent = (
        <>
          <div className="mx-1">
            {i18next.t('cloud.gcp.errors.disabledApi.accordionWarningMessage')}
          </div>
          <Link
            to={assetApiUrlMap.gcp[assetName]}
            target={'_blank'}
            className="underline font-light">
            {assetApiUrlMap.gcp[assetName]}
          </Link>
        </>
      )

      break

    default:
      errorContent = (
        <>
          <div>{i18next.t('errors.checkPermissions')}</div>
          <Link
            to={`${config.CLIENT_URL}/cloud/gcp/${integrationId}/update`}
            target={'_blank'}
            className="underline font-light">
            {`${config.CLIENT_URL}/cloud/gcp/${integrationId}/update`}
          </Link>
        </>
      )
  }

  return (
    <>
      <div className="items-center text-center text-mars-500">
        {errorContent}
      </div>
    </>
  )
}

AccordionContent.propTypes = {
  handleTab: PropTypes.func,
  innerTableProps: PropTypes.object,
  projectId: PropTypes.string,
  integrationId: PropTypes.string,
  props: PropTypes.object,
  assetName: PropTypes.string,
  tabs: PropTypes.object,
}

AssetsItems.propTypes = {
  data: PropTypes.array,
  projectId: PropTypes.string,
  integrationId: PropTypes.string,
}

CheckFailed.propTypes = {
  assetName: PropTypes.string,
  log: PropTypes.object,
  integrationId: PropTypes.string,
}
