import React from 'react'
import PropTypes from 'prop-types'
import CheckoutFooter from './CheckoutFooter'
import CheckoutItem from './CheckoutItem'
import CheckoutPayment from './CheckoutPayment'
import CheckoutItemLoader from './loadingState/CheckoutItemLoader'
import Spinner from 'components/partials/Spinner'
import PaymentHeader from './partials/PaymentHeader'

export default function CheckoutContainer({id, products, isLoadingState}) {
  return (
    <div id={id} className="xxl:flex text-ink">
      <div className="basis-6/12 xl:basis-8/12 xxl:basis-9/12 p-8">
        {products.map(product => {
          return isLoadingState ? (
            <CheckoutItemLoader key={product.id} item={product} />
          ) : (
            <CheckoutItem key={product.id} item={product} />
          )
        })}

        {!isLoadingState && <CheckoutFooter />}
      </div>

      <div className="flex flex-col basis-6/12 xl:basis-4/12 xxl:basis-3/12 bg-astral-50 border-t lg:border-t-0 lg:border-l p-6 pt-8">
        <PaymentHeader />
        {isLoadingState ? (
          <div className="mt-10">
            <Spinner />
          </div>
        ) : (
          <CheckoutPayment />
        )}
      </div>
    </div>
  )
}

CheckoutContainer.propTypes = {
  id: PropTypes.string,
  products: PropTypes.array,
  isLoadingState: PropTypes.bool,
}
