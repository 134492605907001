import {getRequestParams} from 'utils/pagination'
import config from '../config'
import i18next from 'i18next'
import {handler} from './handler'

import {
  fetchDnsFeedDone,
  fetchDnsFinding,
  fetchDnsFindingActivity,
  fetchDnsFindingActivityDone,
  fetchDnsFindingDone,
  fetchDnsRecordsLogsDone,
  fetchDnsStatsDone,
  fetchDomainDone,
  fetchDomainFindingsDone,
  fetchDomainRecordsDone,
  fetchDomainSubdomainsDone,
  fetchDomains,
  fetchDomainsByRegistrarDone,
  fetchDomainsDone,
  fetchNameServerDetailsDone,
  fetchNameServersDone,
  genericError,
  toggleRestrictionModal,
  redirectFeedback,
  success,
  updateDnsFindingDone,
  loading,
  redirect404,
  fetchDomainsHostnamesDone,
} from 'actions'

const addDomain = function (body) {
  return fetch(config.SERVER_URL + '/dns/domains', {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'POST',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleAddDomain(action) {
  yield handler({
    handler: addDomain,
    error: [
      () => loading({addDomain: false}),
      res => {
        let message

        const getInvalidDomainMsg = () => {
          if (res.isPaaS) return i18next.t('errors.paasDomain')

          if (res.domainName)
            return i18next.t('errors.domainInvalidWithSuggestion', {
              domainName: res.domainName,
            })

          return i18next.t('errors.domainInvalid')
        }

        switch (res.code) {
          case 'INVALID_DOMAIN':
            message = getInvalidDomainMsg()
            break

          case 'DOMAIN_TAKEN':
            message = i18next.t('errors.domainTaken')
            break

          case 'DOMAIN_EXISTS':
            message = i18next.t('errors.domainExists')
            break

          case 'DOMAIN_DOES_NOT_RESPOND':
            message = i18next.t('errors.domainDoesNotRespond')
            break

          case 'DNS_RECORD_DOES_NOT_RESOLVE':
            message = i18next.t('errors.dnsRecordDoesNotResolve')
            break

          case 'DOMAIN_NOT_VERIFIED':
            message = i18next.t('errors.domainNotVerified')
            break

          case 'SUBSCRIPTION_LIMIT':
            return toggleRestrictionModal({
              contentId: 'addDomainBtn',
              showModal: true,
            })

          default:
            message = null
        }

        return genericError({
          code: res.code,
          message,
        })
      },
    ],
    success: [
      () => loading({addDomain: false}),
      () =>
        redirectFeedback({
          path: '/dns',
          message: `${i18next.t('misc.domain')} ${
            action.payload.hostname
          } ${i18next.t('misc.wasAdded').toLowerCase()}`,
          status: 'success',
        }),
      fetchDomains,
    ],
    action,
  })
}

const removeDomain = function (body) {
  return fetch(`${config.SERVER_URL}/dns/domains/${body.domainId}`, {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'DELETE',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleRemoveDomain(action) {
  yield handler({
    handler: removeDomain,
    error: genericError,
    success: () =>
      redirectFeedback({
        path: '/dns',
        message: `${i18next.t('misc.domain')} ${
          action.payload.hostname
        } ${i18next.t('dns.domainScheduledForRemoval')}`,
        status: 'success',
      }),
    action,
  })
}

export function* handleFetchDomains(action) {
  yield handler({
    handler: _fetchDomains,
    success: fetchDomainsDone,
    action,
  })
}

const _fetchDomains = params => {
  const url = new URL(`${config.SERVER_URL}/dns/domains`)
  url.search = new URLSearchParams(getRequestParams(params)).toString()

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchDomainsHostnames(action) {
  yield handler({
    handler: _fetchDomainsHostnames,
    success: fetchDomainsHostnamesDone,
    action,
  })
}

const _fetchDomainsHostnames = () => {
  return fetch(`${config.SERVER_URL}/dns/domains-filter`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

const _fetchDomainRecords = params => {
  const url = new URL(
    `${config.SERVER_URL}/dns/domains/${params.domainId}/records`
  )
  url.search = new URLSearchParams(
    getRequestParams(params.pagination)
  ).toString()

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchDomainRecords(action) {
  yield handler({
    handler: _fetchDomainRecords,
    success: fetchDomainRecordsDone,
    action,
  })
}

const _fetchDnsRecordsLogs = params => {
  const url = new URL(
    `${config.SERVER_URL}/dns/domains/${params.domainId}/logs`
  )

  url.search = new URLSearchParams(
    getRequestParams(params.pagination)
  ).toString()

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchDnsRecordsLogs(action) {
  yield handler({
    handler: _fetchDnsRecordsLogs,
    success: fetchDnsRecordsLogsDone,
    action,
  })
}

const _fetchDomainFindings = params => {
  const url = new URL(
    `${config.SERVER_URL}/dns/domains/${params.domainId}/findings`
  )
  url.search = new URLSearchParams(
    getRequestParams(params.pagination)
  ).toString()

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchDomainFindings(action) {
  yield handler({
    handler: _fetchDomainFindings,
    success: fetchDomainFindingsDone,
    action,
  })
}

const _fetchDomainsByRegistrar = () => {
  const url = new URL(`${config.SERVER_URL}/dns/registrars`)

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchDomainsByRegistrar(action) {
  yield handler({
    handler: _fetchDomainsByRegistrar,
    success: fetchDomainsByRegistrarDone,
    action,
  })
}

const _fetchDomainSubdomains = params => {
  const url = new URL(
    `${config.SERVER_URL}/dns/domains/${params.domainId}/subdomains`
  )
  url.search = new URLSearchParams(
    getRequestParams(params.pagination)
  ).toString()

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchDomainSubdomains(action) {
  yield handler({
    handler: _fetchDomainSubdomains,
    success: fetchDomainSubdomainsDone,
    action,
  })
}

export function* handleFetchDomain(action) {
  yield handler({
    action,
    error: genericError,
    handler: _fetchDomain,
    success: fetchDomainDone,
  })
}

const _fetchDomain = domainId => {
  return fetch(`${config.SERVER_URL}/dns/domains/${domainId}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

const _fetchDnsFeed = () => {
  return fetch(`${config.SERVER_URL}/dns/feed`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  })
}

export function* handleFetchDnsFeed(action) {
  yield handler({
    handler: _fetchDnsFeed,
    success: fetchDnsFeedDone,
    action,
  })
}

const _fetchDnsFinding = findingId => {
  return fetch(`${config.SERVER_URL}/issue/${findingId}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  })
}

export function* handleFetchDnsFinding(action) {
  yield handler({
    handler: _fetchDnsFinding,
    error: genericError,
    success: fetchDnsFindingDone,
    action,
  })
}

const _fetchDnsFindingActivity = findingId => {
  const url = `${config.SERVER_URL}/issue/${findingId}/activity`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchDnsFindingActivity(action) {
  yield handler({
    handler: _fetchDnsFindingActivity,
    success: fetchDnsFindingActivityDone,
    action,
  })
}

const _updateDnsFinding = change => {
  const url = `${config.SERVER_URL}/issue/${change.findingId}/${change.status}`
  return fetch(url, {
    body: JSON.stringify(change),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'POST',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdateDnsFinding(action) {
  const {updateStatusMessage, findingId} = action.payload

  yield handler({
    handler: _updateDnsFinding,
    success: [
      updateDnsFindingDone,
      () =>
        success({
          message: updateStatusMessage,
        }),
      () => fetchDnsFinding(findingId),
      () => fetchDnsFindingActivity(findingId),
    ],
    error: genericError,
    action,
  })
}

const _fetchActivity = findingId => {
  const url = `${config.SERVER_URL}/issue/${findingId}/activity`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchActivity(action) {
  yield handler({
    handler: _fetchActivity,
    success: fetchDnsFindingActivity,
    action,
  })
}

const fetchDnsStats = () => {
  return fetch(`${config.SERVER_URL}/dns/stats`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchDnsStats(action) {
  yield handler({
    handler: fetchDnsStats,
    success: fetchDnsStatsDone,
    action,
  })
}

const fetchNameServerDetails = nameServerId => {
  return fetch(`${config.SERVER_URL}/dns/nameservers/${nameServerId}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchNameServerDetails(action) {
  yield handler({
    handler: fetchNameServerDetails,
    success: fetchNameServerDetailsDone,
    error: redirect404,
    action,
  })
}

const fetchNameServers = () => {
  return fetch(`${config.SERVER_URL}/dns/nameservers`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchNameServers(action) {
  yield handler({
    handler: fetchNameServers,
    success: fetchNameServersDone,
    action,
  })
}
