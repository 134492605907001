import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {DefaultModal} from 'components/partials/DefaultModal'
import {removeSupplierInvite} from 'actions'
import {Trans} from 'react-i18next'

export default function SupplierRemoveInviteModal({modal, setModal}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const successState = useSelector(state => state.success)

  const onCloseModal = () => {
    setModal({inviteId: null, showModal: false})
  }

  useEffect(() => {
    if (successState) onCloseModal()
  }, [successState])

  return (
    <DefaultModal
      id="removeSupplierInviteModal"
      title={i18next.t('settings.members.removeInviteModal.title')}
      body={
        <div className="text-sm text-gray-700 leading-6">
          <Trans
            i18nKey="settings.members.removeInviteModal.body"
            values={{
              email: modal.inviteEmail,
            }}
          />
        </div>
      }
      primaryBtnText={i18next.t('buttons.remove')}
      primaryButtonTheme="red"
      onClickPrimaryBtn={() =>
        dispatch(
          removeSupplierInvite({
            supplierId: modal.supplierId,
            inviteId: modal.inviteId,
            _csrf: csrfState,
          })
        )
      }
      secondayBtnText={i18next.t('buttons.cancel')}
      onClickSecondaryBtn={onCloseModal}
      onCloseModal={onCloseModal}
    />
  )
}

SupplierRemoveInviteModal.propTypes = {
  modal: PropTypes.object,
  setModal: PropTypes.func,
}
