import i18next from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {loading, removeWorkspaceIntegration} from 'actions'
import {shouldRenderErrors} from '../peopleUtils'

import AlternateActionButton from 'components/partials/buttons/AlternateActionButton'
import Spinner from 'components/partials/Spinner'
import ErrorHandling from './ErrorHandling'
import Timestamp from 'components/partials/Timestamp'

export default function GoogleWorkspaceIntegration({integration}) {
  const csrfState = useSelector(state => state.csrf)

  const dispatch = useDispatch()

  function undoWorkspaceIntegration(integration) {
    const {id} = integration

    dispatch(
      removeWorkspaceIntegration({
        integrationId: id,
        _csrf: csrfState,
        successMessage: i18next.t('googleWorkspace.removeIntegrationSuccess'),
      })
    )
    dispatch(loading({googleWorkspaceIntegrations: {[id]: true}}))
  }

  if (!integration) return <Spinner />

  return (
    <>
      <div className="pl-6 pr-6 mb-4">
        <div className={'border border-sideral-50 rounded-lg p-4 mt-4'}>
          <div className={'flex border-b text-sm pb-4'}>
            <p className="font-bold">
              {i18next.t('googleWorkspace.customerId')}
            </p>
            :
            <span className={'font-light ml-1'}>
              {integration.customerId || '-'}
            </span>
            <AlternateActionButton
              text={i18next.t('cloud.removal.alternateActionButton')}
              onClick={() => undoWorkspaceIntegration(integration)}
              size="sm"
              margin="ml-auto"
              trashIcon
            />
          </div>
          {
            <>
              <IntegrationInfo currentIntegration={integration} />
              <IntegrationBasics currentIntegration={integration} />
            </>
          }
        </div>
      </div>
    </>
  )
}

const IntegrationInfo = ({currentIntegration}) => {
  return (
    <>
      {currentIntegration.status === 'started' ? (
        <GoogleWorkspaceStartedMessage integration={currentIntegration} />
      ) : (
        <>
          {currentIntegration.logs &&
          shouldRenderErrors(currentIntegration.logs) ? (
            <ErrorHandling currentIntegration={currentIntegration} />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  )
}

const IntegrationBasics = ({currentIntegration}) => {
  return (
    <div className="flex justify-between mt-4 text-sm">
      <div className="flex-direction: column">
        <div className="flex font-light">
          <p className="font-medium mr-1">
            {i18next.t('googleWorkspace.integrationStatus')}
          </p>
          <p>
            {i18next.t(`googleWorkspace.status.${currentIntegration.status}`)}
          </p>
        </div>

        <div className="flex font-light">
          <p className="font-medium mr-1">
            {i18next.t('googleWorkspace.integrationCreatedBy')}
          </p>
          <p>{currentIntegration.userEmail}</p>
        </div>
      </div>

      <div className="flex-direction: column">
        <div className="flex font-light">
          <p className="font-medium mr-1">
            {i18next.t('googleWorkspace.createdAt')}
          </p>
          <p>
            <Timestamp date={currentIntegration.createdAt} format="LLL" />
          </p>
        </div>

        <div className="flex font-light">
          <p className="font-medium mr-1">
            {i18next.t('googleWorkspace.latestCheck')}
          </p>
          <p>
            <Timestamp date={currentIntegration.latestCheck} format="LLL" />
          </p>
        </div>
      </div>
    </div>
  )
}

const GoogleWorkspaceStartedMessage = () => (
  <>
    <div className="flex text-sm flex-col">
      <div className="border-b">
        <div className="mb-4 mt-4">
          <span className="block font-light">
            {i18next.t('googleWorkspace.connectingToOrganization')}{' '}
            {i18next.t('googleWorkspace.emptyStateBody')}
          </span>
        </div>
      </div>
    </div>
  </>
)

GoogleWorkspaceIntegration.propTypes = {
  integration: PropTypes.object,
}
GoogleWorkspaceStartedMessage.propTypes = {
  integration: PropTypes.object,
}
IntegrationBasics.propTypes = {
  currentIntegration: PropTypes.object,
  miscStatus: PropTypes.string,
}
IntegrationInfo.propTypes = {
  currentIntegration: PropTypes.object,
}
