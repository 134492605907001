import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import {DefaultModal} from 'components/partials/DefaultModal'
import {Trans} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {removeSupplier} from 'actions'

export default function SupplierRemoveModal({supplierDetails, setRemoveModal}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)

  const handleRemove = supplierId => {
    dispatch(
      removeSupplier({
        supplierId,
        _csrf: csrfState,
      })
    )

    setRemoveModal({
      selected: null,
      showModal: false,
    })
  }

  const closeModal = () => {
    setRemoveModal({
      selected: null,
      showModal: false,
    })
  }

  return (
    <DefaultModal
      id="removeSupplierModal"
      title={i18next.t('suppliers.removeModal.title')}
      primaryButtonTheme="red"
      primaryBtnText={i18next.t('buttons.remove')}
      secondayBtnText={i18next.t('buttons.cancel')}
      body={
        <div className="text-sm text-gray-700 leading-6">
          <Trans
            i18nKey="suppliers.removeModal.body"
            values={{
              companyName: supplierDetails?.name,
            }}
            shouldUnescape
          />
        </div>
      }
      onClickPrimaryBtn={() => handleRemove(supplierDetails?.id)}
      onClickSecondaryBtn={closeModal}
      onCloseModal={closeModal}
    />
  )
}

SupplierRemoveModal.propTypes = {
  supplierDetails: PropTypes.object,
  setRemoveModal: PropTypes.func,
}
