import React from 'react'
import PropTypes from 'prop-types'

export default function PlayIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960">
      <path d="M320-200v-560l440 280-440 280Z" />
    </svg>
  )
}

PlayIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
