import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {Trans} from 'react-i18next'
import moment from 'moment'
import {sideral700} from 'constant'
import ClockIcon from 'res/icons/clockIcon'

export default function ArticleFooter({updatedAt}) {
  return (
    <div className="flex flex-wrap items-center justify-between gap-8 border-t pt-10 mt-10">
      <div className="flex items-center justify-end gap-1 text-xs">
        <ClockIcon width="18" color={sideral700} />
        <div className="text-sideral-700">
          <Trans
            i18nKey="help.updatedAt"
            values={{
              dateTime: moment(updatedAt).format('LLL'),
            }}
            shouldUnescape
          />
        </div>
      </div>

      <div className="flex items-center">
        <i className="icofont-ui-email text-2xl text-sky-900 bg-sky-900/[.2] rounded-full p-3 mr-4"></i>
        <div>
          <span className="w-full block">{i18next.t('help.footer.title')}</span>
          <a
            href="mailto:support@unxpose.com"
            className="text-sky-900 underline mx-auto text-xs">
            {i18next.t('help.footer.contactUs')}
          </a>
        </div>
      </div>
    </div>
  )
}

ArticleFooter.propTypes = {
  updatedAt: PropTypes.string,
}
