import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'

export default function CompanyLogo({
  logoUrl,
  logoClasses = 'w-8',
  genericLogoClasses = 'text-3xl',
}) {
  const [displayGenericLogo, setDisplayGenericLogo] = useState(false)

  useEffect(() => {
    if (!logoUrl) setDisplayGenericLogo(true)
  }, [])

  return displayGenericLogo ? (
    <i className={`icofont-building-alt opacity-50 ${genericLogoClasses}`} />
  ) : (
    <img
      src={logoUrl}
      alt="Company logo"
      className={logoClasses}
      onError={() => setDisplayGenericLogo(true)}
    />
  )
}

CompanyLogo.propTypes = {
  logoUrl: PropTypes.string,
  logoClasses: PropTypes.string,
  genericLogoClasses: PropTypes.string,
}
