import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import {DefaultModal} from 'components/partials/DefaultModal'
import {useDispatch, useSelector} from 'react-redux'
import {loading, stopSupplierMonitoring} from 'actions'
import {isLoading} from 'utils'

export default function StopMonitoringModal({supplierId, setModal}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)
  const stopSupplierMonitoringDoneState = useSelector(
    state => state.stopSupplierMonitoringDone
  )

  const handleConfirm = () => {
    dispatch(loading({stopSupplierMonitoring: true}))
    dispatch(
      stopSupplierMonitoring({
        supplierId,
        _csrf: csrfState,
      })
    )
  }

  useEffect(() => {
    if (stopSupplierMonitoringDoneState)
      setModal({
        supplierId: null,
        showModal: false,
      })
  }, [stopSupplierMonitoringDoneState])

  return (
    <DefaultModal
      id="stopMonitoringModal"
      title={i18next.t('suppliers.stopMonitoringModal.title')}
      body={
        <div className="text-sm text-gray-700 leading-6 mb-8">
          {i18next.t('suppliers.stopMonitoringModal.body')}
        </div>
      }
      primaryBtnText={i18next.t('suppliers.stopMonitoringModal.actionBtn')}
      secondayBtnText={i18next.t('buttons.cancel')}
      onClickPrimaryBtn={handleConfirm}
      onClickSecondaryBtn={() =>
        setModal({
          showModal: false,
        })
      }
      primaryButtonTheme="red"
      primaryButtonLoading={isLoading(
        loadingState.stopSupplierMonitoring,
        false
      )}
    />
  )
}

StopMonitoringModal.propTypes = {
  supplierId: PropTypes.string,
  setModal: PropTypes.func,
}
