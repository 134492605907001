import React, {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import {useLocation, useNavigate} from 'react-router-dom'
import {v4 as uuidv4} from 'uuid'

import {clearAction, fetchHelpArticleItem, fetchHelpArticles} from 'actions'

import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import ExpandContentIcon from 'res/icons/expandContentIcon'
import CollapseContentIcon from 'res/icons/collapseContentIcon'
import images from 'res'
import CloseIcon from 'res/icons/closeIcon'
import PlayIcon from 'res/icons/playIcon'
import ArticleSummary from './ArticleSummary'
import Article from './Article'

export default function HelpMenu({showHelp, setShowHelp, setShowIntro}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const location = useLocation()

  const helpArticlesState = useSelector(state => state.helpArticles)
  const helpArticleItemState = useSelector(state => state.helpArticleItem)

  const [isFullScreen, setIsFullScreen] = useState(false)
  const [loadingArticles, setLoadingArticles] = useState(true)
  const [loadingArticleItem, setLoadingArticleItem] = useState(false)
  const [searchInput, setSearchInput] = useState('')

  const hashIncludesHelp = () => {
    return location?.hash?.includes('#/help')
  }

  const updateURL = sectionId => {
    window.history.replaceState(null, null, `#/help/${sectionId}`)
  }

  const fetchArticles = (filterValue = '') => {
    setLoadingArticles(true)
    dispatch(
      fetchHelpArticles({
        filterValue,
      })
    )
  }

  const handleOpenArticle = id => {
    setLoadingArticleItem(true)
    dispatch(fetchHelpArticleItem({articleId: id}))

    updateURL(id)
  }

  const debouncedFetchArticles = useMemo(() => {
    return AwesomeDebouncePromise(fetchArticles, 1000)
  }, [])

  const onChangeSearch = value => {
    setLoadingArticles(true)

    setSearchInput(value)
    debouncedFetchArticles(value)
  }

  const resetHelp = () => {
    if (searchInput) {
      dispatch(clearAction('helpArticles'))
      setSearchInput('')

      fetchArticles()
    }

    dispatch(clearAction('helpArticleItem'))
  }

  const startGuide = () => {
    setShowHelp(false)

    if (location.pathname !== '/') navigate('/')

    document.documentElement.scrollTop = 0
    document.documentElement.classList.add('overflow-hidden')

    setTimeout(() => setShowIntro(true), 500)
  }

  useEffect(() => {
    resetHelp()
    if (showHelp && !helpArticlesState) fetchArticles()
  }, [showHelp])

  useEffect(() => {
    if (helpArticlesState) setLoadingArticles(false)
    if (helpArticleItemState) setLoadingArticleItem(false)
  }, [helpArticlesState, helpArticleItemState])

  useEffect(() => {
    if (hashIncludesHelp()) {
      const isHelpHome = location.hash.endsWith('/home')

      if (!isHelpHome) {
        const sectionId = location.hash.split('/').pop()
        handleOpenArticle(sectionId)
      }

      setShowHelp(true)
    }
  }, [location])

  const isLoading = loadingArticles || loadingArticleItem

  return (
    <section
      className={`fixed hidden md:flex md:flex-col md:justify-between left-0 top-0 shadow-md 
        bg-white duration-300 transform ease-out z-50 
        ${isFullScreen ? 'w-full' : 'w-120'}  
        ${showHelp ? 'translate-x-0' : '-translate-x-full'}`}>
      <>
        <div
          id="help-header"
          className="flex items-center justify-between bg-sky-900 text-white p-6 py-4">
          <div className="flex items-center">
            <div className="flex items-center">
              {helpArticleItemState ? (
                <button
                  className="self-end w-[26px]"
                  onClick={() => {
                    resetHelp()
                    updateURL('home')
                  }}>
                  <i className="icofont-simple-left text-2xl" />
                </button>
              ) : (
                <images.HelpIcon width="26" height="26" color="white" />
              )}

              <div className="font-medium text-lg tracking-wide ml-3">
                {i18next.t('help.sectionTitle')}
              </div>
            </div>
          </div>

          <div id="btns-area" className="flex items-center gap-1">
            <button
              className="flex items-center gap-1 bg-white/[.1] hover:bg-white/[.15] rounded text-xs py-2 pl-2 pr-3 mr-2"
              onClick={startGuide}>
              <div>
                <PlayIcon width="20" color="white" />
              </div>
              <div>{i18next.t('help.takeATourBtn')}</div>
            </button>

            <button
              className="bg-white/[.1] hover:bg-white/[.15] rounded p-2"
              onClick={() => setIsFullScreen(!isFullScreen)}>
              {isFullScreen ? (
                <CollapseContentIcon width="20" color="white" />
              ) : (
                <ExpandContentIcon width="20" color="white" />
              )}
            </button>

            <button
              className="bg-white/[.1] hover:bg-white/[.15] rounded p-2"
              onClick={() => {
                setShowHelp(false)
                setIsFullScreen(false)

                navigate(window.location.pathname, {replace: true})
              }}>
              <CloseIcon width="20" color="white" />
            </button>
          </div>
        </div>

        <div id="help-body" className="overflow-auto menu-scrollbar h-screen">
          <div className="relative pb-24">
            {!helpArticleItemState && (
              <div className="p-6 bg-astral-50 border-b">
                <PrimaryInput
                  placeholder={i18next.t('misc.searchPlaceholder')}
                  margin="mb-0"
                  value={searchInput}
                  onChange={e => onChangeSearch(e.target.value)}
                />
              </div>
            )}

            {isLoading && (
              <div
                id="loading-border"
                className="absolute top-0 h-1 loading-animation-fast bg-sky-600"
              />
            )}

            {!isLoading &&
              !helpArticleItemState &&
              helpArticlesState?.map(article => (
                <ArticleSummary
                  key={uuidv4()}
                  article={article}
                  handleOpenArticle={handleOpenArticle}
                />
              ))}

            {!isLoading && helpArticleItemState && (
              <Article
                content={helpArticleItemState.content}
                updatedAt={helpArticleItemState.updatedAt}
              />
            )}

            {!isLoading && searchInput && helpArticlesState?.length === 0 && (
              <div className="flex flex-col items-center justify-center text-center h-screen mdHeight:-mt-56">
                <div className="bg-sky-900/[.1] rounded-full p-6 mb-4">
                  <i className="icofont-search-1 text-4xl text-sky-900" />
                </div>

                <div className="font-medium text-lg mb-1">
                  {i18next.t('help.noResults.title')}
                </div>
                <div className="text-xs">
                  {i18next.t('help.noResults.subtitle')}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </section>
  )
}

HelpMenu.propTypes = {
  showHelp: PropTypes.bool,
  setShowHelp: PropTypes.func,
  setShowIntro: PropTypes.func,
}
