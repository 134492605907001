import React, {useEffect, useState} from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import {useDispatch, useSelector} from 'react-redux'
import {i18next} from 'translate/i18n'

import {
  fetchAzureFinding,
  fetchAzureFindingActivity,
  fetchLgpdItemDetails,
  updateAzureFindingStatus,
} from 'actions'
import {allowedStatuses} from 'constant'
import images from 'res'
import {getFeatureSubscription, getPropByLocale} from 'utils'

import ContentBox from 'components/partials/ContentBox'
import GenericFinding from 'components/GenericFinding'
import NewTabLink from 'components/partials/NewTabLink'
import SeverityChip from 'components/partials/SeverityChipV2'
import WrappedProperty from 'components/partials/WrappedProperty'
import Tag from 'components/partials/Tag'
import MarkNotSensitiveButton from '../MarkNotSensitiveButton'
import RemovableTag from 'components/partials/RemovableTag'
import Timestamp from 'components/partials/Timestamp'

export default function AzureFinding(props) {
  const userState = useSelector(state => state.user)
  const azureFindingState = useSelector(state => state.azureFinding)
  const azureFindingActivityState = useSelector(
    state => state.azureFindingActivity
  )

  const dispatch = useDispatch()

  const [finding, setFinding] = useState(null)
  const [findingActivity, setFindingActivity] = useState(null)
  const [modalVisibility, setModalVisibility] = useState('hidden')

  const toggleModal = lgpdItemId => {
    dispatch(fetchLgpdItemDetails(lgpdItemId))

    modalVisibility === 'hidden'
      ? setModalVisibility('block')
      : setModalVisibility('hidden')
  }

  useEffect(() => {
    if (!azureFindingState) return

    const obj = azureFindingState
    obj.hasComplianceFeature = getFeatureSubscription(
      userState,
      'cloudIntegrations'
    ).hasAccess
    obj.toggleModal = toggleModal

    setFinding(obj)
  }, [azureFindingState])

  useEffect(() => {
    if (azureFindingActivityState) setFindingActivity(azureFindingActivityState)
  }, [azureFindingActivityState])

  return (
    <GenericFinding
      {...props}
      allowedStatuses={allowedStatuses}
      fetchFinding={fetchAzureFinding}
      fetchFindingActivity={fetchAzureFindingActivity}
      finding={finding}
      findingActivity={findingActivity}
      findingBody={FindingBody}
      findingDetails={FindingDetails}
      findingIcon={FindingIcon}
      findingType="azure"
      //helpPath={'azure'}
      modalVisibility={modalVisibility}
      setFinding={setFinding}
      setFindingActivity={setFindingActivity}
      setModalVisibility={setModalVisibility}
      title={'Azure'}
      updateFinding={updateAzureFindingStatus}
    />
  )
}

const FindingIcon = () => {
  return (
    <div className="w-20 p-4">
      <images.CloudIcon width="60" height="60" color="#90A4AE" />
    </div>
  )
}

const FindingDetails = finding => {
  return (
    <>
      <div className="p-4 md:flex bg-white rounded-lg">
        <div className="flex-1 w-9/12 md:mb-0 mb-6 md:pb-0 pb-6 md:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 ml-1">
            <images.InfoIcon size="20" height="20" color="#0A50A0" />
          </div>

          <div className="break-all py-1 px-2">
            <span className="font-semibold mr-2">Azure resource name:</span>
            {finding && finding.resourceId}
          </div>
          <div className="break-all py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('cloud.directoryId')}:
            </span>
            {finding && finding.tenantId}
          </div>
        </div>
        <div className="flex-1 md:mb-0 mb-6 md:pb-0 pb-6 md:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 px-2 ml-1">
            <images.FireIcon size="20" height="20" color="#0A50A0" />
          </div>

          <div>
            <WrappedProperty
              label={i18next.t('finding.severity')}
              helpPath="scoring"
              value={<SeverityChip severity={finding && finding.severity} />}
            />
          </div>

          {finding.tags.includes('notSensitive') ? (
            <div>
              <span className="font-semibold m-2">Tags:</span>

              <span>
                <RemovableTag
                  content={i18next.t('cloud.tags.nonPersonalData')}
                  asset={finding.resourceId}
                  provider={'azure'}
                  findingId={finding.id}
                />
              </span>
            </div>
          ) : (
            <></>
          )}

          {finding.hasComplianceFeature &&
          finding.lgpdItems &&
          finding.lgpdItems.length &&
          !finding.tags.includes('notSensitive') ? (
            <div>
              <span className="font-semibold m-2">
                {i18next.t('finding.lgpdArticles')}:
              </span>

              <span className="flex flex-wrap items-center">
                {Array.isArray(finding.lgpdItems)
                  ? finding.lgpdItems.map((article, index) => (
                      <Tag
                        key={`${article.title} ${index}`}
                        content={article.title}
                        onClick={() => finding.toggleModal(article.id)}
                      />
                    ))
                  : '-'}
                {finding.metadata &&
                  finding.metadata.cloud &&
                  finding.metadata.cloud.table && (
                    <MarkNotSensitiveButton
                      provider={'azure'}
                      findingId={finding.id}
                      resource={{
                        name: finding.resourceId,
                        asset_table_name: finding.metadata.cloud.table,
                      }}
                    />
                  )}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="flex-1 md:mb-2 md:pb-0 pb-6 md:border-none last:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 ml-1">
            <images.EyeIcon size="20" height="20" color="#0A50A0" />
          </div>

          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('finding.firstSeen')}
            </span>
            <Timestamp format="LLL" date={finding?.createdAt} />
          </div>
          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('finding.lastSeen')}
            </span>
            <Timestamp format="LLL" date={finding?.checkedAt} />
          </div>
        </div>
      </div>
    </>
  )
}

const FindingBody = finding => {
  const Description = () => {
    return (
      <div className="py-4 rounded-lg break-words overflow-auto">
        <ReactMarkdown>
          {finding && finding.template
            ? getPropByLocale(finding.template.description)
            : getPropByLocale(finding.description)}
        </ReactMarkdown>
      </div>
    )
  }

  const Solution = () => {
    return (
      <div className="py-4 rounded-lg break-words overflow-auto">
        <ReactMarkdown>
          {finding && finding.template
            ? getPropByLocale(finding.template.solution)
            : getPropByLocale(finding.solution)}
        </ReactMarkdown>
      </div>
    )
  }

  const References = () => {
    return (
      <div className="py-4 rounded-lg template-markdown overflow-auto">
        <ReactMarkdown remarkPlugins={[gfm]} components={{a: NewTabLink}}>
          {finding && finding.template
            ? finding.template.references
            : finding.references}
        </ReactMarkdown>
      </div>
    )
  }

  return (
    <>
      <div className="mb-6">
        <ContentBox
          header={i18next.t('titles.about')}
          content={<Description />}
        />
      </div>

      <div className="mb-6">
        <ContentBox
          header={i18next.t('titles.solution')}
          content={<Solution />}
        />
      </div>

      <div className="mb-6">
        <ContentBox
          header={i18next.t('titles.references')}
          content={<References />}
        />
      </div>
    </>
  )
}
