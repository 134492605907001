import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import GenericFinding from 'components/GenericFinding'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import i18next from 'i18next'
import {useParams} from 'react-router-dom'

import {
  fetchGoogleWorkspaceFinding,
  fetchGoogleWorkspaceFindingActivity,
  updateGoogleWorkspaceFindingStatus,
} from 'actions'

import {allowedStatuses, sideral300, sky900} from 'constant'
import images from 'res'

import NewTabLink from 'components/partials/NewTabLink'
import ContentBox from 'components/partials/ContentBox'
import WrappedProperty from 'components/partials/WrappedProperty'
import SeverityChip from 'components/partials/SeverityChipV2'
import {getPropByLocale} from 'utils'
import Timestamp from 'components/partials/Timestamp'

export default function GoogleWorkspaceFinding(props) {
  const googleWorkspaceFindingState = useSelector(
    state => state.googleWorkspaceFinding
  )
  const workspaceFindingActivityState = useSelector(
    state => state.workspaceFindingActivity
  )

  const dispatch = useDispatch()
  const params = useParams()

  const [finding, setFinding] = useState(null)
  const [findingActivity, setFindingActivity] = useState(null)

  useEffect(() => {
    dispatch(fetchGoogleWorkspaceFinding(params.id))
    dispatch(fetchGoogleWorkspaceFindingActivity(params.id))
  }, [])

  useEffect(() => {
    if (googleWorkspaceFindingState) setFinding(googleWorkspaceFindingState)
  }, [googleWorkspaceFindingState])

  useEffect(() => {
    if (workspaceFindingActivityState)
      setFindingActivity(workspaceFindingActivityState)
  }, [workspaceFindingActivityState])

  return (
    <div>
      <GenericFinding
        {...props}
        allowedStatuses={allowedStatuses}
        fetchFinding={fetchGoogleWorkspaceFinding}
        fetchFindingActivity={fetchGoogleWorkspaceFindingActivity}
        finding={finding}
        findingActivity={findingActivity}
        findingBody={FindingBody}
        findingDetails={FindingDetails}
        findingIcon={FindingIcon}
        findingType="people"
        setFinding={setFinding}
        setFindingActivity={setFindingActivity}
        title={'Workspace'}
        updateFinding={updateGoogleWorkspaceFindingStatus}
      />
    </div>
  )
}

const FindingIcon = () => {
  return (
    <div className="w-20 p-4">
      <images.CloudIcon width="60" height="60" color={sideral300} />
    </div>
  )
}

const FindingDetails = finding => {
  return (
    <>
      <div className="p-4 md:flex bg-white rounded-lg">
        <div className="flex-1 w-9/12 md:mb-0 mb-6 md:pb-0 pb-6 md:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 ml-1">
            <images.InfoIcon size="20" height="20" color={sky900} />
          </div>

          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('labels.email')}
            </span>
            {finding && finding.email}
          </div>
        </div>
        <div className="flex-1 md:mb-0 mb-6 md:pb-0 pb-6 md:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 px-2 ml-1">
            <images.FireIcon size="20" height="20" color={sky900} />
          </div>

          <div>
            <WrappedProperty
              label={i18next.t('finding.severity')}
              helpPath="scoring"
              value={<SeverityChip severity={finding && finding.severity} />}
            />
          </div>
        </div>
        <div className="flex-1 md:mb-2 md:pb-0 pb-6 md:border-none last:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 ml-1">
            <images.EyeIcon size="20" height="20" color={sky900} />
          </div>

          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('finding.firstSeen')}
            </span>
            <Timestamp format="LLL" date={finding?.createdAt} />
          </div>
          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('finding.lastSeen')}
            </span>
            <Timestamp format="LLL" date={finding?.checkedAt} />
          </div>
        </div>
      </div>
    </>
  )
}

const FindingBody = finding => {
  const Description = () => {
    return (
      <div className="py-4 rounded-lg break-words overflow-auto">
        <ReactMarkdown>
          {finding && finding.template
            ? getPropByLocale(finding.template.description)
            : getPropByLocale(finding.description)}
        </ReactMarkdown>
      </div>
    )
  }

  const Solution = () => {
    return (
      <div className="py-4 rounded-lg break-words overflow-auto">
        <ReactMarkdown>
          {finding && finding.template
            ? getPropByLocale(finding.template.solution)
            : getPropByLocale(finding.solution)}
        </ReactMarkdown>
      </div>
    )
  }

  const References = () => {
    return (
      <div className="py-4 rounded-lg template-markdown overflow-auto">
        <ReactMarkdown remarkPlugins={[gfm]} components={{a: NewTabLink}}>
          {finding && finding.template
            ? finding.template.references
            : finding.references}
        </ReactMarkdown>
      </div>
    )
  }

  return (
    <>
      <div className="mb-6">
        <ContentBox
          header={i18next.t('titles.about')}
          content={<Description />}
        />
      </div>

      <div className="mb-6">
        <ContentBox
          header={i18next.t('titles.solution')}
          content={<Solution />}
        />
      </div>

      <div className="mb-6">
        <ContentBox
          header={i18next.t('titles.references')}
          content={<References />}
        />
      </div>
    </>
  )
}
