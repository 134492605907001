import i18next from 'i18next'
import awsIcon from 'res/icons/awsIcon.svg'
import gcpIcon from 'res/icons/gcpIcon.png'
import azureIcon from 'res/icons/azure.svg'
import googleIcon from 'res/icons/googleIcon.svg'
import microsoftIcon from 'res/icons/microsoft.svg'
import exploreIcon from 'res/products/exploreIcon.svg'
import keyIcon from 'res/products/keyIconBlue.svg'
import suppliersManagement from 'res/products/suppliersManagement.svg'
import suppliersScoring from 'res/products/suppliersScoring.svg'

export const getProductInfo = productId => {
  let icons = []
  let title = ''
  let description = ''
  let i18nLimitKey = ''
  let subQuantity = null
  let isOnOffItem = false

  switch (productId.toLowerCase()) {
    case 'domains monitoring':
    case 'domains':
      icons = [exploreIcon]
      title = i18next.t(`subscription.checkout.products.domains.title`)
      description = i18next.t(
        `subscription.checkout.products.domains.description`
      )
      i18nLimitKey = 'subscription.checkout.products.domains.limit'
      subQuantity = 100
      break

    case 'cloud accounts monitoring':
    case 'cloudintegrations':
      icons = [awsIcon, gcpIcon, azureIcon]
      title = i18next.t(
        `subscription.checkout.products.cloudIntegrations.title`
      )
      description = i18next.t(
        `subscription.checkout.products.cloudIntegrations.description`
      )
      i18nLimitKey = 'subscription.checkout.products.cloudIntegrations.limit'
      subQuantity = 500

      break

    case 'identity providers monitoring':
    case 'idpintegrations':
      icons = [googleIcon, microsoftIcon]
      title = i18next.t(`subscription.checkout.products.idpIntegrations.title`)
      description = i18next.t(
        `subscription.checkout.products.idpIntegrations.description`
      )
      i18nLimitKey = 'subscription.checkout.products.idpIntegrations.limit'
      subQuantity = 500

      break

    case 'api access':
    case 'apikeys':
      icons = [keyIcon]
      title = i18next.t(`subscription.checkout.products.apiKeys.title`)
      description = i18next.t(
        `subscription.checkout.products.apiKeys.description`
      )
      isOnOffItem = true
      break

    case 'suppliers score monitoring':
    case 'monitoredsuppliers':
      icons = [suppliersScoring]
      title = i18next.t(
        `subscription.checkout.products.suppliersScoringMonitoring.title`
      )
      description = i18next.t(
        `subscription.checkout.products.suppliersScoringMonitoring.description`
      )
      break

    case 'suppliers questionnaires management':
    case 'securityquestionnaires':
      icons = [suppliersManagement]
      title = i18next.t(
        `subscription.checkout.products.suppliersQuestionnairesManagement.title`
      )
      description = i18next.t(
        `subscription.checkout.products.suppliersQuestionnairesManagement.description`
      )
      break

    default:
      title = '———'
      description = '—'
  }

  return {
    id: productId,
    icons,
    title,
    description,
    i18nLimitKey,
    subQuantity,
    isOnOffItem,
  }
}

export const getFeatureCountProp = productId => {
  switch (productId.toLowerCase()) {
    case 'domains monitoring':
    case 'domains':
      return 'domains'

    case 'cloud accounts monitoring':
    case 'cloudintegrations':
      return 'cloudIntegrations'

    case 'identity providers monitoring':
    case 'idpintegrations':
      return 'idpIntegrations'

    case 'api access':
    case 'apikeys':
      return 'apiKeys'

    case 'suppliers score monitoring':
    case 'monitoredsuppliers':
      return 'monitoredSuppliers'

    case 'suppliers questionnaires management':
    case 'securityquestionnaires':
      return 'securityQuestionnaires'
  }
}

export const convertToCurrency = (value, currency) => {
  let locale

  switch (currency) {
    case 'usd':
    case 'USD':
      locale = 'en-US'
      break

    default:
      locale = 'pt-BR'
      break
  }

  return value.toLocaleString(locale, {
    style: 'currency',
    currency,
  })
}
