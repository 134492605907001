import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {Trans} from 'react-i18next'
import moment from 'moment'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import images from 'res'
import SecondaryButton from './buttons/SecondaryButton'
import {clearError, companyRenewal, showPlanRenewalModal} from 'actions'
import PlanUtils from 'utils/plan'
import PaperForm from './PaperForm'

export default function PlanRenewalModal() {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const planRenewalModalState = useSelector(state => state.showPlanRenewalModal)
  const errorState = useSelector(state => state.error)
  const csrfState = useSelector(state => state.csrf)

  const [showPaperForm, setShowPaperform] = useState(false)
  const [showProposalSuccess, setShowProposalSuccess] = useState(false)

  const modalHasBeenDisplayed = JSON.parse(
    localStorage.getItem('planRenewalModalHasBeenDisplayed')
  )

  const planUtils = PlanUtils()

  const registerAction = element => {
    dispatch(
      companyRenewal({
        _csrf: csrfState,
        companyId: userState.company.id,
        element,
        source: 'mainSalesModal',
      })
    )
  }

  const onClickPrimaryBtn = () => {
    registerAction('requestProposal')
    setShowProposalSuccess(true)
  }

  const onClickSecondaryBtn = () => {
    registerAction('talkToSales')
    setShowPaperform(true)
  }

  const onCloseModal = () => {
    dispatch(showPlanRenewalModal(false))
    dispatch(clearError())

    setShowProposalSuccess(false)
    setShowPaperform(false)

    localStorage.setItem('planRenewalModalHasBeenDisplayed', true)
  }

  const onClickBack = () => {
    dispatch(clearError())

    setShowProposalSuccess(false)
    setShowPaperform(false)
  }

  useEffect(() => {
    if (!modalHasBeenDisplayed) {
      dispatch(showPlanRenewalModal(planUtils.shouldShowPlanRenewalModal()))
    }

    document.addEventListener('mouseup', e => {
      const planRenewalModal = document.querySelector('#planRenewalModal')
      if (e.target === planRenewalModal) onCloseModal()
    })
  }, [])

  return (
    planRenewalModalState && (
      <div
        id="planRenewalModal"
        className="modal-bg fixed top-0 left-0 w-screen h-screen z-[100]">
        <div className="fixed bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 max-w-lg w-full bg-white rounded-md shadow-md p-6">
          <div className="relative">
            <div className="pt-6">
              {!errorState && showProposalSuccess ? (
                <ProposalSuccess onClickBack={onClickBack} />
              ) : showPaperForm ? (
                <PaperForm
                  title={i18next.t('planInfo.paid.paperformTitle')}
                  onClickBack={onClickBack}
                />
              ) : (
                <div id="expiration-content">
                  <div id="modal-header" className="text-center">
                    <div id="icon" className="inline-block m-auto">
                      <images.crownIcon width="42" height="42" />
                    </div>
                    <div
                      id="title"
                      className="font-medium text-sideral-90 text-lg mb-6">
                      {i18next.t('planInfo.paid.modal.title', {
                        remainingDays: planUtils.getPlanExpirationDays(),
                      })}
                    </div>
                  </div>
                  <div id="modal-body">
                    <div className="description text-sm text-gray-600 leading-6 mb-6">
                      {
                        <Trans
                          shouldUnescape
                          i18nKey="planInfo.paid.modal.body"
                          values={{
                            deadLine: moment(
                              userState.subscription?.endDate
                            ).format('L'),
                          }}
                        />
                      }
                    </div>
                  </div>

                  <div id="modal-footer" className="text-center">
                    <div className="w-full">
                      <PrimaryButton
                        text={i18next.t('planInfo.paid.modal.primaryBtn')}
                        type="submit"
                        size="full"
                        margin="mb-2"
                        onClick={onClickPrimaryBtn}
                      />
                    </div>
                    {planUtils.userHasPremiumPlan() && (
                      <div className="w-full">
                        <PrimaryButton
                          text={i18next.t('planInfo.paid.modal.secondaryBtn')}
                          type="submit"
                          size="full"
                          margin="mb-2"
                          theme="blue-outline"
                          onClick={onClickSecondaryBtn}
                        />
                      </div>
                    )}
                    <div className="w-full">
                      <SecondaryButton
                        text={i18next.t('planInfo.paid.modal.ignoreBtn')}
                        onClick={onCloseModal}
                        size="sm"
                        margin="mt-4"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <button className="absolute top-0 right-0" onClick={onCloseModal}>
              <i className="icofont-close text-xl" />
            </button>
          </div>
        </div>
      </div>
    )
  )
}

const ProposalSuccess = ({onClickBack}) => (
  <>
    <div className="text-center py-10">
      <div id="icon">
        <img src={images.checkIcon} width="62" className="m-auto mb-6" />
      </div>
      <div id="title" className="font-medium text-sideral-90 text-lg mb-6 px-6">
        {i18next.t('planInfo.proposalSuccess')}
      </div>
    </div>

    <button className="absolute top-0 left-0" onClick={onClickBack}>
      <i className="icofont-simple-left text-xl" />
    </button>
  </>
)

ProposalSuccess.propTypes = {
  onClickBack: PropTypes.func,
}

PlanRenewalModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
}
