import React from 'react'
import PropTypes from 'prop-types'
import {skeletonLoadingStyle} from 'constant'

import ProductLeftSide from '../partials/ProductLeftSide'

export default function CheckoutItemLoader({item}) {
  return (
    <div
      key={item.id}
      className="lg:flex items-center mb-8 pb-8 border-b transition ease-out duration-100">
      <ProductLeftSide product={item} />

      <div id="right-content" className="basis-1/2 mt-6 lg:mt-0">
        <div className="xs:flex gap-10 items-start justify-between">
          <div className="flex items-center justify-between xs:block text-end xs:text-center basis-1/3">
            <div className={`${skeletonLoadingStyle} w-24 h-10 mx-auto`} />
          </div>

          <div className="flex items-center justify-between my-5 xs:my-0 xs:block text-center basis-1/3">
            <div className={`${skeletonLoadingStyle} w-24 h-10 mx-auto`} />
          </div>

          <div className="flex items-center justify-between xs:block text-center max-w-xl basis-1/3">
            <div className={`${skeletonLoadingStyle} w-24 h-10 mx-auto`} />
          </div>
        </div>
      </div>
    </div>
  )
}

CheckoutItemLoader.propTypes = {
  item: PropTypes.object,
}
