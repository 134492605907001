import React, {useEffect, useRef, useState} from 'react'
import i18next from 'i18next'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import moment from 'moment'
import {Page} from 'components/partials/Page'
import {sky900} from 'constant'
import {getFeatureSubscription, isLoading} from 'utils'
import {getSupplierScoreDescription} from './supplierUtils'

import {
  clearAction,
  fetchSupplierDetails,
  fetchSupplierInvites,
  loading,
  fetchQuestionnaireTemplates,
  fetchSuppliersStats,
  toggleRestrictionModal,
} from 'actions'

import BackButton from 'components/partials/buttons/BackButton'
import Spinner from 'components/partials/Spinner'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import Subsection from 'components/partials/headers/Subsection'
import TrashIcon from 'res/icons/trashIcon'
import SupplierRemoveModal from './modals/SupplierRemoveModal'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import SupplierQuestionnairesList from './questionnaire/SupplierQuestionnairesList'
import StartMonitoringModal from './modals/StartMonitoringModal'
import ScoreBadgeSquare from 'components/partials/ScoreBadgeSquare'
import StopMonitoringModal from './modals/StopMonitoringModal'
import CloseIcon from 'res/icons/closeIcon'
import SupplierInviteModal from './modals/SupplierInviteModal'
import SupplierInvites from './SupplierInvites'
import Timestamp from 'components/partials/Timestamp'
import QuestionnaireTemplatesList from './questionnaire/templates/QuestionnaireTemplatesList'
import CompanyLogo from 'components/partials/CompanyLogo'

export default function SupplierDetails() {
  const supplierDetailsState = useSelector(state => state.supplierDetails)
  const supplierInvitesState = useSelector(state => state.supplierInvites)
  const suppliersStatsState = useSelector(state => state.suppliersStats)
  const userState = useSelector(state => state.user)
  const loadingState = useSelector(state => state.loading)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const processingScoreRef = useRef(null)

  const [removeModal, setRemoveModal] = useState({
    selected: null,
    showModal: false,
  })

  const [startMonitoringModal, setStartMonitoringModal] = useState({
    selected: null,
    showModal: false,
  })

  const [stopMonitoringModal, setStopMonitoringModal] = useState({
    selected: null,
    showModal: false,
  })

  const [showInviteSupplierModal, setShowInviteSupplierModal] = useState(false)

  const handleDisplayRemoveModal = () => {
    setRemoveModal({
      selected: {
        id: supplierDetailsState.id,
        name: supplierDetailsState.name,
      },
      showModal: true,
    })
  }

  const handleDisplayStartMonitoringModal = () => {
    const monitoredSuppliersSubscription = getFeatureSubscription(
      userState,
      'monitoredSuppliers'
    )

    if (
      suppliersStatsState.monitoredCount >= monitoredSuppliersSubscription.limit
    ) {
      return dispatch(
        toggleRestrictionModal({
          contentId: 'monitoringSupplier',
          showModal: true,
        })
      )
    }

    dispatch(clearAction('startSupplierMonitoringDone'))

    setStartMonitoringModal({
      selected: {
        id: supplierDetailsState.id,
        name: supplierDetailsState.name,
      },
      showModal: true,
    })
  }

  const handleDisplayStopMonitoringModal = () => {
    dispatch(clearAction('stopSupplierMonitoringDone'))

    setStopMonitoringModal({
      showModal: true,
    })
  }

  useEffect(() => {
    dispatch(loading({supplierDetails: true}))
    dispatch(fetchSupplierDetails({supplierId: params.id}))

    dispatch(loading({supplierInvitesState: true}))
    dispatch(fetchSupplierInvites({supplierId: params.id}))

    dispatch(fetchSuppliersStats())

    dispatch(fetchQuestionnaireTemplates())
  }, [])

  const isMonitoring = supplierDetailsState?.monitoring.enabled
  const isProcessing =
    isMonitoring && supplierDetailsState?.monitoring.score === null

  const hasSupplierInvite = supplierInvitesState?.length > 0

  const hasAcceptedInvite = supplierInvitesState?.some(
    invite => invite.status === 'accepted'
  )

  useEffect(() => {
    if (isProcessing) {
      processingScoreRef.current = setInterval(() => {
        dispatch(fetchSupplierDetails({supplierId: params.id}))
      }, 60000)
    } else {
      clearInterval(processingScoreRef.current)
    }

    return () => clearInterval(processingScoreRef.current)
  }, [isProcessing])

  if (isLoading(loadingState.supplierDetails)) return <Spinner />

  return (
    <>
      <Page pageTitle={i18next.t('pageTitles.suppliers')}>
        <BackButton forceFallbackLink fallbackLink="/suppliers" />

        <div
          id="header"
          className="flex items-center justify-between p-6 bg-white border border-sideral-100 rounded-lg mt-4">
          <div className="flex items-center">
            <div className="mr-4">
              <CompanyLogo
                logoUrl={supplierDetailsState.logo}
                logoClasses="w-10"
                genericLogoClasses="text-5xl"
              />
            </div>
            <div>
              <div className="font-medium text-lg">
                {supplierDetailsState.name}
              </div>
              <div className="text-xs">
                {i18next.t('suppliers.detail.addedIn', {
                  timestamp: moment(supplierDetailsState.createdAt)
                    .locale(i18next.language)
                    .fromNow(),
                })}
              </div>
            </div>
          </div>

          <div className="flex items-center">
            <SecondaryButton
              text={i18next.t('misc.remove')}
              icon={<TrashIcon height="20" width="20" color={sky900} />}
              onClick={handleDisplayRemoveModal}
              classContainer="inline-flex"
              size="sm"
            />
          </div>
        </div>

        <Subsection
          id="score"
          title={i18next.t('suppliers.detail.score.sectionTitle')}
          margin="my-10"
          button={
            isMonitoring &&
            !isProcessing && (
              <SecondaryButton
                text={i18next.t('suppliers.detail.score.sectionBtn')}
                icon={<CloseIcon width="20" color={sky900} />}
                onClick={handleDisplayStopMonitoringModal}
                size="sm"
                margin="ml-auto"
                classContainer="inline-flex"
              />
            )
          }>
          {(!isMonitoring || isProcessing) && (
            <div className="flex items-center justify-between p-8">
              <div className="basis-10/12 font-thin border-r mr-10 pr-10">
                <div className="font-medium mb-2">
                  {i18next.t('suppliers.detail.score.initialState.title')}
                </div>
                <div>
                  {i18next.t('suppliers.detail.score.initialState.body')}
                </div>
              </div>

              <div className="basis-2/12">
                {isProcessing ? (
                  <div className="flex justify-center">
                    <div className="inline-flex items-center justify-center gap-5">
                      <div className="relative flex h-6 w-6">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75" />
                        <span className="rounded-full bg-orange-400 h-6 w-6" />
                      </div>

                      <div>
                        <div className="font-medium">
                          {i18next.t('suppliers.detail.score.calculating')}
                        </div>
                        <div className="text-xs">
                          {i18next.t('suppliers.detail.score.waitFewMinutes')}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="w-full">
                    <PrimaryButton
                      text={i18next.t('suppliers.seeScoreBtn')}
                      size="full"
                      onClick={handleDisplayStartMonitoringModal}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {isMonitoring && !isProcessing && (
            <div className="flex items-center gap-10 p-8">
              <div>
                <div className="font-thin">
                  {getSupplierScoreDescription(
                    supplierDetailsState.monitoring.score.grade
                  )}
                </div>
              </div>
              <div className="flex items-center shrink-0 gap-5">
                <ScoreBadgeSquare
                  letter={supplierDetailsState.monitoring.score.grade}
                />
                <div className="text-sm">
                  <div className="font-medium mb-1">
                    {i18next.t('suppliers.detail.score.lastMonitoring')}
                  </div>
                  <div className="font-thin">
                    {
                      <Timestamp
                        date={supplierDetailsState.monitoring.updatedAt}
                        fromNow
                      />
                    }
                  </div>
                </div>
              </div>

              <div className="text-sm shrink-0 border-l pr-10 pl-10">
                <div className="font-medium mb-1">
                  {i18next.t('suppliers.detail.score.monitoredDomains')}
                </div>
                <div className="font-thin">
                  {supplierDetailsState.monitoring.domainsCount}
                </div>
              </div>
            </div>
          )}
        </Subsection>

        <Subsection
          title={i18next.t(
            'suppliers.detail.securityQuestionnaires.sectionTitle'
          )}>
          <div className="p-8">
            {hasSupplierInvite && (
              <>
                {!hasAcceptedInvite && (
                  <Subsection
                    title={i18next.t(
                      'suppliers.detail.invitation.sectionTitle'
                    )}
                    margin="mb-10"
                    button={
                      <SecondaryButton
                        text={i18next.t(
                          'suppliers.detail.invitation.sendInviteBtn'
                        )}
                        onClick={() => {
                          dispatch(clearAction('inviteSupplierDone'))
                          setShowInviteSupplierModal(true)
                        }}
                        size="sm"
                        margin="ml-auto"
                      />
                    }>
                    <SupplierInvites
                      supplierId={supplierDetailsState.id}
                      invites={supplierInvitesState}
                    />
                  </Subsection>
                )}

                <Subsection
                  margin="mb-10"
                  title={i18next.t(
                    'suppliers.detail.securityQuestionnaires.sentQuestionnaires.sectionTitle'
                  )}
                  button={
                    <SecondaryButton
                      text={i18next.t(
                        'suppliers.detail.securityQuestionnaires.sentQuestionnaires.sectionBtn'
                      )}
                      onClick={() =>
                        navigate(`/suppliers/${params.id}/sendQuestionnaire`)
                      }
                      size="sm"
                      margin="ml-auto"
                    />
                  }>
                  <SupplierQuestionnairesList supplierId={params.id} />
                </Subsection>

                <Subsection
                  title={i18next.t('questionnaires.templates.title')}
                  content={<QuestionnaireTemplatesList />}
                  button={
                    <SecondaryButton
                      text={i18next.t('suppliers.addNewBtn')}
                      size="sm"
                      margin="ml-auto"
                      redirect="/questionnaires/templates/new"
                      plusIcon
                    />
                  }
                />
              </>
            )}

            {!hasSupplierInvite && (
              <div className="flex items-center">
                <div className="basis-10/12 font-thin border-r mr-10 pr-10">
                  <div className="font-medium mb-2">
                    {i18next.t(
                      'suppliers.detail.securityQuestionnaires.initialState.title'
                    )}
                  </div>

                  <div>
                    {i18next.t(
                      'suppliers.detail.securityQuestionnaires.initialState.body'
                    )}
                  </div>
                </div>

                <div className="basis-2/12">
                  <PrimaryButton
                    text={i18next.t(
                      'suppliers.detail.invitation.sendInviteBtn'
                    )}
                    size="full"
                    onClick={() => {
                      dispatch(clearAction('inviteSupplierDone'))
                      setShowInviteSupplierModal(true)
                    }}
                    margin="mt-4 mx-auto"
                  />
                </div>
              </div>
            )}
          </div>
        </Subsection>

        {removeModal.showModal && (
          <SupplierRemoveModal
            supplierDetails={supplierDetailsState}
            setRemoveModal={setRemoveModal}
          />
        )}

        {startMonitoringModal.showModal && (
          <StartMonitoringModal
            supplierDetails={supplierDetailsState}
            setModal={setStartMonitoringModal}
          />
        )}

        {stopMonitoringModal.showModal && (
          <StopMonitoringModal
            supplierId={supplierDetailsState.id}
            setModal={setStopMonitoringModal}
          />
        )}

        {showInviteSupplierModal && (
          <SupplierInviteModal
            supplierId={supplierDetailsState.id}
            setModal={setShowInviteSupplierModal}
          />
        )}
      </Page>
    </>
  )
}
