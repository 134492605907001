import i18next from 'i18next'
import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

export default function Footer(props) {
  const padding = props.padding || 'py-6'

  return (
    <div
      className={`flex items-center text-xs text-gray-600 w-full ${padding}`}>
      <div className="flex items-center w-full">
        <ul>
          <li className="inline-block mr-4 text-sky-500 hover:text-sky-600">
            <a href="https://www.unxpose.com" className="underline">
              unxpose.com
            </a>
          </li>
          <li className="inline-block mr-4 text-sky-500 hover:text-sky-600">
            <Link
              className="underline"
              to={`/terms?lang=${i18next.language}`}
              target="_blank">
              {i18next.t('footer.termsOfUse')}
            </Link>
          </li>
          <li className="inline-block mr-4 text-sky-500 hover:text-sky-600">
            <Link
              className="underline"
              to={`/privacy-policy?lang=${i18next.language}`}
              target="_blank">
              {i18next.t('footer.privacyPolicy')}
            </Link>
          </li>
        </ul>
        <span className="ml-auto">© {new Date().getFullYear()} Unxpose.</span>
      </div>
    </div>
  )
}

Footer.propTypes = {
  padding: PropTypes.string,
}
