import {
  fetchHelpArticleItemDone,
  fetchHelpArticlesDone,
  genericError,
} from 'actions'
import {handler} from './handler'
import config from 'config'

export function* handleFetchHelpArticles(action) {
  yield handler({
    handler: _fetchHelpArticles,
    error: genericError,
    success: fetchHelpArticlesDone,
    action,
  })
}

const _fetchHelpArticles = function (body) {
  return fetch(
    `${config.SERVER_URL}/help/articles?filter=${body?.filterValue}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchHelpArticleItem(action) {
  yield handler({
    handler: _fetchHelpArticleItem,
    error: genericError,
    success: fetchHelpArticleItemDone,
    action,
  })
}

const _fetchHelpArticleItem = function (body) {
  return fetch(`${config.SERVER_URL}/help/articles/${body.articleId}`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}
