import i18next from 'i18next'

export const getSupplierScoreDescription = letter => {
  switch (letter) {
    case 'A':
      return i18next.t('suppliers.detail.score.gradesDescription.a')

    case 'B':
      return i18next.t('suppliers.detail.score.gradesDescription.b')

    case 'C':
      return i18next.t('suppliers.detail.score.gradesDescription.c')

    case 'D':
      return i18next.t('suppliers.detail.score.gradesDescription.d')

    case 'E':
      return i18next.t('suppliers.detail.score.gradesDescription.e')

    case 'F':
      return i18next.t('suppliers.detail.score.gradesDescription.f')

    default:
      return '-'
  }
}
