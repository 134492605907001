import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

export default function ArticleSummary({article, handleOpenArticle}) {
  return (
    <div
      className="px-6 py-5 border-b transition duration-100 ease-out hover:bg-astral-50 hover:cursor-pointer"
      onClick={() => handleOpenArticle(article.id)}>
      <div className="template-markdown help-summary">
        <ReactMarkdown>{article.summary}</ReactMarkdown>
      </div>
    </div>
  )
}

ArticleSummary.propTypes = {
  article: PropTypes.object,
  handleOpenArticle: PropTypes.func,
}
