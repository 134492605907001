import config from 'config'
import i18next from 'i18next'
import {handler} from './handler'
import {
  fetchSupplierInvites,
  fetchSupplierDetails,
  fetchSupplierDetailsDone,
  fetchSuppliers,
  fetchSuppliersDone,
  genericError,
  loading,
  redirectFeedback,
  success,
  fetchSupplierSentQuestionnairesDone,
  fetchSupplierSentQuestionnaires,
  fetchQuestionnaireInvites,
  verifySupplierInviteDone,
  toggleRestrictionModal,
  fetchQuestionnaireEventsDone,
  addSupplierDone,
  startSupplierMonitoringDone,
  stopSupplierMonitoringDone,
  fetchSupplierInvitesDone,
  inviteSupplierDone,
  fetchSuppliersStatsDone,
  fetchSuppliersStats,
  fetchQuestionnaireSupplierInvites,
} from 'actions'

const getSupplierErrorMessage = res => {
  switch (res.code) {
    case 'INVALID_DOMAIN':
      return res.domainName
        ? i18next.t('errors.domainInvalidWithSuggestion', {
            domainName: res.domainName,
          })
        : i18next.t('errors.domainInvalid')

    case 'INVALID_EMAIL':
      return i18next.t('suppliers.messages.invalidEmailError')

    case 'COMPANY_OWNS_DOMAIN':
      return i18next.t('suppliers.messages.companyOwnsDomainError')

    case 'MULTIPLE_DOMAINS':
      return i18next.t('suppliers.messages.multipleDomainsError')

    case 'EMAILS_IN_USE':
      return i18next.t('suppliers.messages.emailsInUseError')

    case 'DOMAIN_IN_USE':
      return i18next.t('suppliers.messages.domainInUseError')

    case 'NAME_IN_USE':
      return i18next.t('suppliers.messages.nameInUseError', {
        name: res.name,
      })

    default:
      return i18next.t('errors.unexpectedError')
  }
}

export function* handleFetchSuppliers(action) {
  yield handler({
    handler: _fetchSuppliers,
    error: genericError,
    success: fetchSuppliersDone,
    action,
  })
}

const _fetchSuppliers = function () {
  return fetch(`${config.SERVER_URL}/suppliers`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchSupplierDetails(action) {
  yield handler({
    handler: _fetchSupplierDetails,
    error: genericError,
    success: fetchSupplierDetailsDone,
    action,
  })
}

const _fetchSupplierDetails = function (body) {
  return fetch(`${config.SERVER_URL}/suppliers/${body.supplierId}`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleResendSupplierInvite(action) {
  yield handler({
    handler: _resendSupplierInvite,
    error: genericError,
    success: [
      () =>
        success({
          message: i18next.t('suppliers.messages.resentInvitation'),
        }),
      () => fetchSupplierDetails({supplierId: action.payload.supplierId}),
    ],
    action,
  })
}

const _resendSupplierInvite = function (body) {
  return fetch(`${config.SERVER_URL}/suppliers/${body.supplierId}`, {
    method: 'POST',
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleAddSupplier(action) {
  yield handler({
    handler: _addSupplier,
    error: [
      () => loading({addSupplier: false}),
      res => {
        switch (res.code) {
          case 'SUBSCRIPTION_LIMIT':
            return toggleRestrictionModal({
              contentId: 'addSupplierBtn',
              showModal: true,
            })
          default:
            return genericError({
              message: getSupplierErrorMessage(res),
            })
        }
      },
    ],
    success: [
      addSupplierDone,
      fetchSuppliersStats,
      fetchSuppliers,
      () =>
        success({
          message: i18next.t('suppliers.messages.supplierRegistered'),
        }),
    ],
    action,
  })
}

const _addSupplier = function (body) {
  return fetch(`${config.SERVER_URL}/suppliers/add`, {
    method: 'POST',
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleStartSupplierMonitoring(action) {
  yield handler({
    handler: _startSupplierMonitoring,
    error: res =>
      genericError({
        loading: {startSupplierMonitoring: false},
        message: res.message,
      }),
    success: [
      startSupplierMonitoringDone,
      fetchSuppliersStats,
      () => {
        if (
          window.location.pathname === '/suppliers' ||
          window.location.pathname === '/suppliers/'
        ) {
          return fetchSuppliers()
        }

        return fetchSupplierDetails(action.payload)
      },
      () =>
        success({
          message: i18next.t('suppliers.messages.calculatingScore'),
        }),
    ],
    action,
  })
}

const _startSupplierMonitoring = function (body) {
  return fetch(
    `${config.SERVER_URL}/suppliers/${body.supplierId}/monitoring/start`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleStopSupplierMonitoring(action) {
  yield handler({
    handler: _stopSupplierMonitoring,
    error: res =>
      genericError({
        loading: {stopSupplierMonitoring: false},
        message: res.message,
      }),
    success: [
      stopSupplierMonitoringDone,
      () => {
        if (
          window.location.pathname === '/suppliers' ||
          window.location.pathname === '/suppliers/'
        ) {
          return fetchSuppliers()
        }

        return fetchSupplierDetails(action.payload)
      },
      () =>
        success({
          message: i18next.t('suppliers.messages.interruptedMonitoring'),
        }),
    ],
    action,
  })
}

const _stopSupplierMonitoring = function (body) {
  return fetch(
    `${config.SERVER_URL}/suppliers/${body.supplierId}/monitoring/stop`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleRemoveSupplier(action) {
  yield handler({
    handler: _removeSupplier,
    error: genericError,
    success: [
      () => {
        if (
          window.location.pathname === '/suppliers' ||
          window.location.pathname === '/suppliers/'
        )
          return success({
            message: i18next.t('suppliers.messages.supplierRemoved'),
          })

        return redirectFeedback({
          path: '/suppliers',
          status: 'success',
          message: i18next.t('suppliers.messages.supplierRemoved'),
        })
      },
      fetchSuppliersStats,
      fetchSuppliers,
    ],
    action,
  })
}

const _removeSupplier = function (body) {
  return fetch(`${config.SERVER_URL}/suppliers/${body.supplierId}`, {
    method: 'DELETE',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleRespondSupplierInvite(action) {
  yield handler({
    handler: _respondSupplierInvite,
    error: genericError,
    success: [
      () =>
        success({
          message:
            action.payload.status === 'accepted'
              ? i18next.t('suppliers.invites.successAccepted')
              : i18next.t('suppliers.invites.successDeclined'),
        }),
      fetchQuestionnaireSupplierInvites,
      fetchQuestionnaireInvites,
    ],
    action,
  })
}

const _respondSupplierInvite = function (body) {
  return fetch(`${config.SERVER_URL}/questionnaires/suppliers/invites`, {
    method: 'PATCH',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchSupplierSentQuestionnaires(action) {
  yield handler({
    handler: _fetchSupplierSentQuestionnaires,
    error: genericError,
    success: fetchSupplierSentQuestionnairesDone,
    action,
  })
}

const _fetchSupplierSentQuestionnaires = function (body) {
  return fetch(
    `${config.SERVER_URL}/suppliers/${body.supplierId}/questionnaires`,
    {
      method: 'GET',
      credentials: 'include',
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleVerifySupplierInvite(action) {
  yield handler({
    handler: _verifySupplierInvite,
    error: verifySupplierInviteDone,
    success: verifySupplierInviteDone,
    action,
  })
}

const _verifySupplierInvite = function (body) {
  return fetch(`${config.SERVER_URL}/supplier-invite/${body.sessionId}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleRemoveSentQuestionnaire(action) {
  yield handler({
    handler: _removeSentQuestionnaire,
    error: genericError,
    success: [
      () =>
        success({
          message: i18next.t('suppliers.detail.remove.success'),
        }),
      fetchSupplierSentQuestionnaires,
    ],
    action,
  })
}

const _removeSentQuestionnaire = function (body) {
  return fetch(
    `${config.SERVER_URL}/suppliers/${body.supplierId}/questionnaires`,
    {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchQuestionnaireEvents(action) {
  yield handler({
    handler: _fetchQuestionnaireEvents,
    error: genericError,
    success: fetchQuestionnaireEventsDone,
    action,
  })
}

const _fetchQuestionnaireEvents = function (body) {
  return fetch(
    `${config.SERVER_URL}/suppliers/${body.supplierId}/questionnaires/${body.questionnaireId}/events`,
    {
      method: 'GET',
      credentials: 'include',
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchSupplierInvites(action) {
  yield handler({
    handler: _fetchSupplierInvites,
    error: genericError,
    success: fetchSupplierInvitesDone,
    action,
  })
}

const _fetchSupplierInvites = function (body) {
  return fetch(`${config.SERVER_URL}/suppliers/${body.supplierId}/invites`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleInviteSupplier(action) {
  yield handler({
    handler: _inviteSupplier,
    error: res => {
      let message
      switch (res.code) {
        case 'INVALID_EMAIL':
          message = i18next.t('errors.emailInvalid')
          break

        default:
          message = res.message
          break
      }

      return genericError({
        loading: {inviteSupplier: false},
        message: message,
      })
    },
    success: [inviteSupplierDone, () => fetchSupplierInvites(action.payload)],
    action,
  })
}

const _inviteSupplier = function (body) {
  return fetch(`${config.SERVER_URL}/suppliers/${body.supplierId}/invites`, {
    method: 'POST',
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleRemoveSupplierInvite(action) {
  yield handler({
    handler: _removeSupplierInvite,
    error: genericError,
    success: [
      () => success({message: 'Convite removido com sucesso!'}),
      () => fetchSupplierInvites(action.payload),
    ],
    action,
  })
}

const _removeSupplierInvite = function (body) {
  return fetch(
    `${config.SERVER_URL}/suppliers/${body.supplierId}/invites/${body.inviteId}`,
    {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchSuppliersStats(action) {
  yield handler({
    handler: _fetchSuppliersStats,
    error: genericError,
    success: fetchSuppliersStatsDone,
    action,
  })
}

const _fetchSuppliersStats = function () {
  return fetch(`${config.SERVER_URL}/suppliers/stats`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}
