import React from 'react'
import PropTypes from 'prop-types'
import {v4 as uuidv4} from 'uuid'

export default function ProductLeftSide({product}) {
  return (
    <div id="left-content" className="basis-1/2 lg:mr-20">
      <div className="flex mb-2">
        {product.icons.map(icon => (
          <img key={uuidv4()} src={icon} className="h-5 mr-4" />
        ))}
      </div>
      <div className="font-medium mb-2">{product.title}</div>
      <div className="text-sm font-light text-sideral-900">
        {product.description}
      </div>
    </div>
  )
}

ProductLeftSide.propTypes = {
  product: PropTypes.object,
}
