import React, {useEffect, useState} from 'react'
import ReactMarkdown from 'react-markdown'
import {Link} from 'react-router-dom'
import {i18next} from 'translate/i18n'
import {useSelector} from 'react-redux'

import {breachAllowedStatuses} from 'constant'
import images from 'res'

import ContentBox from 'components/partials/ContentBox'
import SeverityChip from 'components/partials/SeverityChipV2'
import SimplifiedGenericFinding from 'components/people/breaches/SimplifiedGenericFinding'
import WrappedProperty from 'components/partials/WrappedProperty'

import {
  fetchPeopleFinding,
  fetchPeopleFindingActivity,
  updatePeopleFindingStatus,
} from 'actions'
import Timestamp from 'components/partials/Timestamp'

export default function BreachFinding(props) {
  const peopleFindingState = useSelector(state => state.peopleFinding)
  const peopleFindingActivityState = useSelector(
    state => state.peopleFindingActivity
  )

  const [finding, setFinding] = useState(null)

  const [findingActivity, setFindingActivity] = useState(null)

  useEffect(() => {
    if (!peopleFindingState) return

    const obj = peopleFindingState

    setFinding(obj)
  }, [peopleFindingState])

  useEffect(() => {
    if (peopleFindingActivityState)
      setFindingActivity(peopleFindingActivityState)
  }, [peopleFindingActivityState])

  return (
    <SimplifiedGenericFinding
      {...props}
      allowedStatuses={breachAllowedStatuses}
      fetchFinding={fetchPeopleFinding}
      fetchFindingActivity={fetchPeopleFindingActivity}
      finding={finding}
      findingActivity={findingActivity}
      findingBody={FindingBody}
      findingDetails={FindingDetails}
      findingIcon={FindingIcon}
      findingType="people"
      setFinding={setFinding}
      setFindingActivity={setFindingActivity}
      updateFinding={updatePeopleFindingStatus}
    />
  )
}

const FindingIcon = () => {
  return (
    <div className="w-20 p-4">
      <images.PeopleIcon width="60" height="60" color="#90A4AE" />
    </div>
  )
}

const FindingDetails = finding => {
  return (
    <>
      <div className="p-4 md:flex bg-white rounded-lg">
        <div className="flex-1 w-9/12 md:mb-0 mb-6 md:pb-0 pb-6 md:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 ml-1">
            <images.InfoIcon size="20" height="20" color="#0A50A0" />
          </div>

          <div className="break-all py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('labels.email')}:
            </span>
            <Link
              className="hover:underline"
              to={`/people/account/${finding.emailId}`}>
              {finding && finding.email}
            </Link>
          </div>
          <div className="break-all py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('tables.breach')}:
            </span>
            {finding && finding.fullName}
          </div>
          <div className="break-all py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('tables.year')}:
            </span>
            {finding && finding.year}
          </div>
        </div>
        <div className="flex-1 md:mb-0 mb-6 md:pb-0 pb-6 md:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 px-2 ml-1">
            <images.FireIcon size="20" height="20" color="#0A50A0" />
          </div>

          <div>
            <WrappedProperty
              label={i18next.t('finding.severity')}
              helpPath="scoring"
              value={<SeverityChip severity={finding && finding.severity} />}
            />
          </div>
        </div>
        <div className="flex-1 md:mb-2 md:pb-0 pb-6 md:border-none last:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 ml-1">
            <images.EyeIcon size="20" height="20" color="#0A50A0" />
          </div>

          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('finding.firstSeen')}
            </span>
            <Timestamp format="LLL" date={finding?.createdAt} />
          </div>
        </div>
      </div>
    </>
  )
}

const FindingBody = finding => {
  const BreachDescription = () => {
    return (
      <div className="py-4 rounded-lg break-words overflow-auto">
        <ReactMarkdown>
          {i18next.t('people.breachFinding.description')}
        </ReactMarkdown>
      </div>
    )
  }

  const Description = () => {
    return (
      <div className="py-4 rounded-lg break-words overflow-auto">
        <ReactMarkdown>
          {finding && finding.template
            ? finding.template.description
            : finding.description}
        </ReactMarkdown>
        <span className="font-medium">{i18next.t('people.dataLeaked')}:</span>{' '}
        {finding &&
          finding.data &&
          finding.data.replace(/["[\]]/g, '').replace(/,/g, ', ')}
      </div>
    )
  }

  const BreachRemediation = () => {
    return (
      <div className="py-6 rounded-lg break-words overflow-auto">
        {i18next.t('people.breachFinding.remediation.intro')}
        <ul className="list-none">
          <li className="mt-2">
            {i18next.t('people.breachFinding.remediation.notify')}
          </li>
          <li>{i18next.t('people.breachFinding.remediation.passwords')}</li>
          <li>{i18next.t('people.breachFinding.remediation.monitor')}</li>
          <li>{i18next.t('people.breachFinding.remediation.educate')}</li>
          <li>{i18next.t('people.breachFinding.remediation.warning')}</li>
        </ul>
        {i18next.t('people.breachFinding.remediation.conclusion')}
      </div>
    )
  }

  const BreachSeverityBreakdown = () => {
    if (
      !finding.breakdown ||
      !finding.breakdown.factors ||
      !finding.breakdown.factors.age ||
      !finding.breakdown.factors.dataType ||
      !finding.breakdown.factors.dataType.categories ||
      !Array.isArray(finding.breakdown.factors.dataType.categories)
    )
      return <></>

    return (
      <>
        <div className="py-2 text-sm">
          <div className="flex flex-col border-b">
            <div className="flex flex-row p-2">
              <div className="w-10/12">
                {i18next.t('people.severityBreakdown.breachAge', {
                  age: new Date().getFullYear() - finding.year,
                })}
              </div>
              <div className="w-2/12 text-center font-normal">
                +{finding.breakdown.factors.age.pontuation / 2}
              </div>
            </div>
          </div>
          <>
            {finding.breakdown.factors.dataType.categories.map(
              (item, index) => {
                item, index
                return (
                  <div key={`${item.name} ${index}`}>
                    <div className="flex flex-col border-b">
                      <div className="flex flex-row p-2">
                        <div className="w-10/12">
                          {i18next.t(`people.severityBreakdown.${item.name}`)}
                        </div>
                        <div className="w-2/12 text-center font-normal">
                          +{item.weight / 2}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            )}
          </>
          <div className="flex flex-col font-medium">
            <div className="flex flex-row p-2">
              <div className="w-10/12">
                {i18next.t('people.severityBreakdown.findingSeverity')}
              </div>
              <div className="w-2/12 text-center">
                <SeverityChip severity={finding.breakdown.severity} />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="mb-6">
        <ContentBox
          header={i18next.t('subtitles.description')}
          content={<BreachDescription />}
        />
      </div>

      <div className="mb-6">
        <ContentBox
          header={i18next.t('people.breachDetails')}
          content={<Description />}
        />
      </div>

      <div className="mb-6">
        <ContentBox
          header={i18next.t('titles.remediation')}
          content={<BreachRemediation />}
        />
      </div>

      <div className="mb-6">
        <ContentBox
          header={i18next.t('people.severityBreakdown.title')}
          content={<BreachSeverityBreakdown />}
        />
      </div>
    </>
  )
}
