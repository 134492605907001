import React, {useState} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {Trans} from 'react-i18next'
import {convertToCurrency, getFeatureCountProp} from '../../subscriptionUtils'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import {useSelector} from 'react-redux'
import LimitRestrictionModal from '../../LimitRestrictionModal'

export default function EditableState({
  item,
  setProducts,
  setEditedProducts,
  currency,
}) {
  const featuresCountState = useSelector(state => state.featuresCount)

  const [limitRestrictionModal, setLimitRestrictionModal] = useState({
    showModal: false,
    newQty: 0,
  })
  const [tempValue, setTempValue] = useState({
    itemId: item.id,
    quantity: item.quantity,
  })

  const updateQty = (itemId, quantity) => {
    setProducts(prev =>
      prev.map(prevItem =>
        prevItem.id === itemId ? {...prevItem, quantity} : prevItem
      )
    )

    setEditedProducts(prev => ({
      ...prev,
      [item.id]: item.originalQuantity !== quantity,
    }))
  }

  const shouldShowLimitRestrictionModal = (itemId, newQty) => {
    const featureCountProp = getFeatureCountProp(itemId)

    if (itemId === 'apiKeys') {
      const hasApiKeys = featuresCountState[featureCountProp] > 0
      const isDisablingApiKeys = item.originalQuantity === 1 && newQty === 0

      return hasApiKeys && isDisablingApiKeys
    }

    if (itemId === 'securityQuestionnaires') {
      return newQty < item.originalQuantity
    }

    return newQty < featuresCountState[featureCountProp]
  }

  const handleChange = (itemId, newQty, autoConfirm = true) => {
    if (shouldShowLimitRestrictionModal(itemId, newQty)) {
      return setLimitRestrictionModal({newQty, showModal: true})
    }

    setTempValue({itemId, quantity: isNaN(newQty) ? 0 : newQty})

    if (autoConfirm) updateQty(itemId, newQty)
  }

  return (
    <>
      <div className="xs:flex gap-10 items-start justify-between">
        {item.isOnOffItem ? (
          <div className="flex items-center justify-between xs:block basis-1/3 text-center">
            <div className="font-medium text-sm xs:mb-4">
              {i18next.t('subscription.checkout.cartItem.include')}
            </div>
            <div className="flex justify-center">
              <input
                id="include-item-checkbox"
                type="checkbox"
                className="switch switch--shadow"
                onChange={e => {
                  const quantity = !e.target.checked ? 0 : 1
                  handleChange(tempValue.itemId, quantity)
                }}
                checked={tempValue.quantity === 1}
              />
              <label htmlFor="include-item-checkbox" />
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-between xs:block basis-1/3 text-end xs:text-center">
            <div className="flex items-center justify-between xs:block font-medium text-sm">
              {i18next.t('subscription.update.products.quantity')}
            </div>
            <div>
              <div className="flex items-baseline justify-center">
                <button
                  onClick={() => handleChange(item.id, item.quantity - 1)}
                  disabled={item.quantity <= 0}>
                  <i className="icofont-minus text-lg" />
                </button>
                <div className="w-16 mx-3">
                  <PrimaryInput
                    name="cartQty"
                    type="number"
                    value={tempValue.quantity}
                    onChange={e =>
                      handleChange(item.id, e.target.valueAsNumber, false)
                    }
                    onBlur={() =>
                      handleChange(tempValue.itemId, tempValue.quantity)
                    }
                    onKeyDown={e =>
                      e.key === 'Enter' &&
                      handleChange(tempValue.itemId, tempValue.quantity)
                    }
                    onFocus={e => e.target.select()}
                    className="text-center"
                  />
                </div>
                <button
                  onClick={() => handleChange(item.id, item.quantity + 1)}>
                  <i className="icofont-plus text-xs" />
                </button>
              </div>
              {item.subQuantity && item.quantity > 0 && (
                <div className="text-xs inline-block bg-astral-100 border rounded px-1 text-nowrap">
                  {
                    <Trans
                      i18nKey={item.i18nLimitKey}
                      values={{limit: item.subQuantity * item.quantity}}
                    />
                  }
                </div>
              )}
            </div>
          </div>
        )}

        <div className="flex items-center justify-between xs:block my-5 xs:my-0 text-center">
          <div className="font-medium text-sm xs:mb-4">
            {i18next.t('subscription.update.products.value')}
          </div>
          <div>{convertToCurrency(item.value / 100, currency)}</div>
        </div>

        <div className="flex items-center justify-between xs:block text-center max-w-xl">
          <div className="font-medium text-sm xs:mb-4">
            {i18next.t('subscription.update.products.total')}
          </div>
          <div>
            {convertToCurrency((item.value * item.quantity) / 100, currency)}
          </div>
        </div>
      </div>

      {limitRestrictionModal.showModal && (
        <LimitRestrictionModal
          productId={item.id}
          prevQty={item.originalQuantity}
          newQty={limitRestrictionModal.newQty}
          setModal={setLimitRestrictionModal}
          source="updateSubscription"
        />
      )}
    </>
  )
}

EditableState.propTypes = {
  item: PropTypes.object,
  setProducts: PropTypes.func,
  setEditedProducts: PropTypes.func,
  currency: PropTypes.string,
}
