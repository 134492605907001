import React, {useState, useMemo} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {i18next} from 'translate/i18n'
import {v4 as uuidv4} from 'uuid'
import images from 'res'
import {sky500} from 'constant'
import {
  fetchAwsFindingsByAsset,
  fetchAwsIamPolicies,
  fetchAwsUsers,
  fetchCloudtrailTrails,
  fetchEbsVolumes,
  fetchEc2Amis,
  fetchEc2Instances,
  fetchEc2SecurityGroups,
  fetchEc2Vpcs,
  fetchEfs,
  fetchKmsKeys,
  fetchRdsInstances,
  fetchRdsSnapshots,
  fetchRoute53Domains,
  fetchRoute53HostedZones,
  fetchS3Buckets,
} from 'actions'
import {
  cloudtrailTrailsColumns,
  ebsColumns,
  ec2AmisColumns,
  ec2InstancesColumns,
  ec2SecurityGroupsColumns,
  ec2VpcsColumns,
  efsColumns,
  findingsColumns,
  iamPoliciesColumns,
  iamUsersColumns,
  kmsKeysColumns,
  rdsInstancesColumns,
  rdsSnapshotsColumns,
  route53Columns,
  route53HostedZonesColumns,
  s3Columns,
} from './columns'

import Spinner from 'components/partials/Spinner'
import {ProviderPermissionAlertBtn} from '../ProviderPermissionAlertBtn'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import {viewFindingsByCategory} from 'utils'

export default function AssetsItems(props) {
  const awsStatsState = useSelector(state => state.awsStats)
  const awsFindingsByAssetState = useSelector(state => state.awsFindingsByAsset)
  const cloudtrailTrailsState = useSelector(state => state.cloudtrailTrails)
  const s3BucketsState = useSelector(state => state.s3Buckets)
  const iamPoliciesState = useSelector(state => state.iamPolicies)
  const iamUsersState = useSelector(state => state.iamUsers)
  const kmsKeysState = useSelector(state => state.kmsKeys)
  const rdsInstancesState = useSelector(state => state.rdsInstances)
  const rdsSnapshotsState = useSelector(state => state.rdsSnapshots)
  const route53DomainsState = useSelector(state => state.route53Domains)
  const route53HostedZonesState = useSelector(state => state.route53HostedZones)
  const ec2AmisState = useSelector(state => state.ec2Amis)
  const ec2InstancesState = useSelector(state => state.ec2Instances)
  const ec2SecurityGroupsState = useSelector(state => state.ec2SecurityGroups)
  const ec2VpcsState = useSelector(state => state.ec2Vpcs)
  const ebsVolumesState = useSelector(state => state.ebsVolumes)
  const efsState = useSelector(state => state.efs)

  const dispatch = useDispatch()

  const [openAccordion, setOpenAccordion] = useState('')
  const [tabs, setTabs] = useState({})

  const toggleAccordion = awsAsset => {
    openAccordion === awsAsset
      ? setOpenAccordion('')
      : setOpenAccordion(awsAsset)
  }

  const handleTab = (eventTarget, awsAsset) => {
    if (eventTarget === tabs[awsAsset]) return

    setTabs({...tabs, [awsAsset]: eventTarget})
  }

  const latestCheckFailed = assetName => {
    const assetServiceMapping = {
      cloudtrailTrails: 'logging',
      ebsVolumes: 'ec2',
      ec2Amis: 'ec2',
      ec2Instances: 'ec2',
      ec2SecurityGroups: 'ec2',
      ec2Vpcs: 'ec2',
      efs: 'efs',
      iamUsers: 'iam',
      iamPolicies: 'iam',
      kmsKeys: 'kms',
      rdsInstances: 'rds',
      rdsSnapshots: 'rds',
      route53Domains: 'route53',
      route53HostedZones: 'route53',
      s3Buckets: 's3',
    }

    if (
      awsStatsState &&
      awsStatsState[props.integrationId] &&
      awsStatsState[props.integrationId].latestCheck &&
      awsStatsState[props.integrationId].latestCheck.logs &&
      awsStatsState[props.integrationId].latestCheck.logs[
        assetServiceMapping[assetName]
      ] &&
      awsStatsState[props.integrationId].latestCheck.logs[
        assetServiceMapping[assetName]
      ] !== 'ok'
    )
      return true
  }

  const assetIsLoading = awsAsset => {
    return !awsFindingsByAssetState || !awsFindingsByAssetState[awsAsset]
  }

  const shouldFetchAsset = (integrationId, state) =>
    !state || (state && !state[integrationId])

  function fetchAssets(awsAsset) {
    switch (awsAsset) {
      case 'cloudtrailTrails':
        shouldFetchAsset(props.integrationId, cloudtrailTrailsState) &&
          dispatch(fetchCloudtrailTrails(props.integrationId))
        break
      case 'ebsVolumes':
        shouldFetchAsset(props.integrationId, ebsVolumesState) &&
          dispatch(fetchEbsVolumes(props.integrationId))
        break
      case 'ec2Instances':
        shouldFetchAsset(props.integrationId, ec2InstancesState) &&
          dispatch(fetchEc2Instances(props.integrationId))
        break
      case 'ec2Amis':
        shouldFetchAsset(props.integrationId, ec2AmisState) &&
          dispatch(fetchEc2Amis(props.integrationId))
        break
      case 'ec2SecurityGroups':
        shouldFetchAsset(props.integrationId, ec2SecurityGroupsState) &&
          dispatch(fetchEc2SecurityGroups(props.integrationId))
        break
      case 'ec2Vpcs':
        shouldFetchAsset(props.integrationId, ec2VpcsState) &&
          dispatch(fetchEc2Vpcs(props.integrationId))
        break
      case 'efs':
        shouldFetchAsset(props.integrationId, efsState) &&
          dispatch(fetchEfs(props.integrationId))
        break
      case 'iamPolicies':
        shouldFetchAsset(props.integrationId, iamPoliciesState) &&
          dispatch(fetchAwsIamPolicies(props.integrationId))
        break
      case 'iamUsers':
        shouldFetchAsset(props.integrationId, iamUsersState) &&
          dispatch(fetchAwsUsers(props.integrationId))
        break
      case 'kmsKeys':
        shouldFetchAsset(props.integrationId, kmsKeysState) &&
          dispatch(fetchKmsKeys(props.integrationId))
        break
      case 'rdsInstances':
        shouldFetchAsset(props.integrationId, rdsInstancesState) &&
          dispatch(fetchRdsInstances(props.integrationId))
        break
      case 'rdsSnapshots':
        shouldFetchAsset(props.integrationId, rdsSnapshotsState) &&
          dispatch(fetchRdsSnapshots(props.integrationId))
        break
      case 'route53Domains':
        shouldFetchAsset(props.integrationId, route53DomainsState) &&
          dispatch(fetchRoute53Domains(props.integrationId))
        break
      case 'route53HostedZones':
        shouldFetchAsset(props.integrationId, route53HostedZonesState) &&
          dispatch(fetchRoute53HostedZones(props.integrationId))
        break
      case 's3Buckets':
        shouldFetchAsset(props.integrationId, s3BucketsState) &&
          dispatch(fetchS3Buckets(props.integrationId))
        break
      default:
        return
    }
  }

  function handleRowClick(awsAsset) {
    if (
      !awsFindingsByAssetState ||
      (awsFindingsByAssetState && !awsFindingsByAssetState[awsAsset])
    )
      dispatch(
        fetchAwsFindingsByAsset({
          integrationId: props.integrationId,
          awsAsset: awsAsset,
        })
      )
    fetchAssets(awsAsset)

    // set the findings tab as default on row click
    handleTab('findings', awsAsset)

    toggleAccordion(awsAsset)
  }

  function getFindingsData(awsAsset) {
    return awsFindingsByAssetState && awsFindingsByAssetState[awsAsset]
  }

  const innerTableProps = {
    cloudtrailTrails: {
      data: cloudtrailTrailsState && cloudtrailTrailsState[props.integrationId],
      columns: useMemo(() => cloudtrailTrailsColumns, []),
    },
    s3Buckets: {
      data: s3BucketsState && s3BucketsState[props.integrationId],
      columns: useMemo(() => s3Columns, []),
    },
    iamPolicies: {
      data: iamPoliciesState && iamPoliciesState[props.integrationId],
      columns: useMemo(() => iamPoliciesColumns, []),
    },
    iamUsers: {
      data: iamUsersState && iamUsersState[props.integrationId],
      columns: useMemo(() => iamUsersColumns, []),
    },
    kmsKeys: {
      data: kmsKeysState && kmsKeysState[props.integrationId],
      columns: useMemo(() => kmsKeysColumns, []),
    },
    rdsInstances: {
      data: rdsInstancesState && rdsInstancesState[props.integrationId],
      columns: useMemo(() => rdsInstancesColumns, []),
    },
    rdsSnapshots: {
      data: rdsSnapshotsState && rdsSnapshotsState[props.integrationId],
      columns: useMemo(() => rdsSnapshotsColumns, []),
    },
    route53Domains: {
      data: route53DomainsState && route53DomainsState[props.integrationId],
      columns: useMemo(() => route53Columns, []),
    },
    route53HostedZones: {
      data:
        route53HostedZonesState && route53HostedZonesState[props.integrationId],
      columns: useMemo(() => route53HostedZonesColumns, []),
    },
    ec2Amis: {
      data: ec2AmisState && ec2AmisState[props.integrationId],
      columns: useMemo(() => ec2AmisColumns, []),
    },
    ec2Instances: {
      data: ec2InstancesState && ec2InstancesState[props.integrationId],
      columns: useMemo(() => ec2InstancesColumns, []),
    },
    ec2SecurityGroups: {
      data:
        ec2SecurityGroupsState && ec2SecurityGroupsState[props.integrationId],
      columns: useMemo(() => ec2SecurityGroupsColumns, []),
    },
    ec2Vpcs: {
      data: ec2VpcsState && ec2VpcsState[props.integrationId],
      columns: useMemo(() => ec2VpcsColumns, []),
    },
    ebsVolumes: {
      data: ebsVolumesState && ebsVolumesState[props.integrationId],
      columns: useMemo(() => ebsColumns, []),
    },
    efs: {
      data: efsState && efsState[props.integrationId],
      columns: useMemo(() => efsColumns, []),
    },
    findings: {columns: useMemo(() => findingsColumns, [])},
  }

  return props.data.map(item => (
    <div key={uuidv4()} className="my-2 rounded-lg border-2 border-sideral-50">
      <div
        onClick={() => handleRowClick(item.awsAsset)}
        className="flex items-center justify-between w-full p-4">
        <div
          id="name"
          className="basis-1/3 uppercase flex cursor-pointer text-left text-sideral-700 font-medium text-sm items-center">
          {item.name}
        </div>

        <div
          id="findingsCount"
          className="basis-1/3 flex cursor-pointer items-center text-sm">
          <figure className="pr-1">
            <images.FindingIcon width="20" />
          </figure>
          {typeof item.findings === 'number' ? item.findings : '-'}
          <div className="ml-1">
            {item.findings > 1
              ? i18next.t('misc.findings').toLowerCase()
              : i18next.t('misc.finding').toLowerCase()}
          </div>
        </div>

        <div
          id="chevron"
          className="flex cursor-pointer items-center w-20 px-4">
          {latestCheckFailed(item.awsAsset) && (
            <ProviderPermissionAlertBtn
              provider="aws"
              integration={props.currentIntegration}
              tooltipClass="top-[15px] right-[200px]"
              isTooltipRelativeToBtn={false}
            />
          )}

          <figure className="ml-auto">
            <images.Chevron
              width="12"
              color={sky500}
              direction={openAccordion === item.awsAsset ? 'up' : 'down'}
            />
          </figure>
        </div>
      </div>

      {openAccordion === item.awsAsset ? (
        <>
          {assetIsLoading(item.awsAsset) ? (
            <div className="w-full -mt-4">
              <Spinner />
            </div>
          ) : (
            <div
              className="relative bg-astral-50 border-t-2 border-sideral-50 w-full p-4"
              onClick={e => e.stopPropagation()}>
              <div className="flex text-sky-500 text-sm mb-4">
                <button
                  value="findings"
                  className={`${
                    tabs[item.awsAsset] === 'findings' || !tabs[item.awsAsset]
                      ? ' border-sky-500 text-sky-900'
                      : 'border-transparent'
                  } border-b-2 uppercase cursor-pointer outline-none mr-5 pb-2`}
                  onClick={e => {
                    handleTab(e.target.value, item.awsAsset)
                  }}>
                  {i18next.t('misc.findings')}
                </button>
                <button
                  value="assets"
                  className={`${
                    tabs[item.awsAsset] === 'assets'
                      ? ' border-sky-500 text-sky-900'
                      : 'border-transparent'
                  } border-b-2 uppercase cursor-pointer outline-none mr-5 pb-2`}
                  onClick={e => {
                    handleTab(e.target.value, item.awsAsset)
                  }}>
                  {i18next.t('misc.assets')}
                </button>
              </div>

              {tabs[item.awsAsset] === 'assets' && (
                <PrimaryTableV8
                  data={innerTableProps[item.awsAsset].data}
                  columns={innerTableProps[item.awsAsset].columns}
                  emptyStateWithFilter={
                    <GenericEmptyState
                      icon={<images.CloudIcon width="55" color="#90A4AE" />}
                      title={i18next.t('tables.nothingFoundWithSearch')}
                      body={i18next.t('tables.searchSomethingElse')}
                      bgColor="bg-astral-50"
                      margin="m-2"
                    />
                  }
                  emptyStateWithoutFilter={
                    <GenericEmptyState
                      icon={<images.CloudIcon width="55" color="#90A4AE" />}
                      title={i18next.t('titles.noResultsFound')}
                      body={i18next.t('cloud.noAssets')}
                      bgColor="bg-astral-50"
                      margin="m-2"
                    />
                  }
                />
              )}

              {tabs[item.awsAsset] === 'findings' && (
                <PrimaryTableV8
                  data={getFindingsData(item.awsAsset)}
                  columns={innerTableProps.findings.columns}
                  detailPath={row => `/aws/finding/${row.original.id}`}
                  emptyStateWithFilter={
                    <GenericEmptyState
                      icon={<images.FindingIcon width="55" color="#90A4AE" />}
                      title={i18next.t('tables.nothingFoundWithSearch')}
                      body={i18next.t('tables.searchSomethingElse')}
                      buttonText={i18next.t('buttons.viewAllFindings')}
                      linkPath={viewFindingsByCategory('cloud')}
                      bgColor={'bg-astral-50'}
                      margin="m-2"
                    />
                  }
                  emptyStateWithoutFilter={
                    <GenericEmptyState
                      icon={<images.FindingIcon width="55" color="#90A4AE" />}
                      title={i18next.t('titles.goodNews')}
                      body={i18next.t('cloud.noFindingsInService')}
                      buttonText={i18next.t('buttons.viewAllFindings')}
                      linkPath={viewFindingsByCategory('cloud')}
                      bgColor={'bg-astral-50'}
                      margin="m-2"
                    />
                  }
                />
              )}
            </div>
          )}
        </>
      ) : null}
    </div>
  ))
}

AssetsItems.propTypes = {
  integrationId: PropTypes.string,
  defaultSorted: PropTypes.array,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  currentIntegration: PropTypes.object,
}
