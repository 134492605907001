/* eslint-disable no-extra-semi */

import './styles/build/index.css'
import './styles/icofont/icofont.min.css'
import 'highlight.js/styles/github.css'
import 'intro.js/introjs.css'

import App from './components/App'
import React from 'react'
import {createRoot} from 'react-dom/client'
import store from './store'
import {Provider} from 'react-redux'
import {init, browserTracingIntegration} from '@sentry/react'

window.VERSION = process.env.REACT_APP_VERSION

if (
  process.env.REACT_APP_ENV === 'production' ||
  process.env.REACT_APP_ENV === 'staging'
) {
  init({
    release: `front-unxpose@${process.env.npm_package_version}`,
    environment: process.env.REACT_APP_ENV,
    dsn: 'https://ab10f19c07ca4ba7a641b869db931fd9@o544442.ingest.sentry.io/5694073',
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 0.8,
    beforeSend: (event, hint) => {
      if (hint.originalException?.stack?.match(/\/recaptcha\/releases\//))
        return null

      return event
    },
  })
}

if (process.env.REACT_APP_ENV === 'production') {
  // Google Tag Manager
  ;(function (w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'})
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'dataLayer' ? '&l=' + l : ''
    j.async = true
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
    f.parentNode.insertBefore(j, f)
  })(window, document, 'script', 'dataLayer', 'GTM-KFR7ZHLC')
  // End Google Tag Manager

  // Clarity
  ;(function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        ;(c[a].q = c[a].q || []).push(arguments)
      }
    t = l.createElement(r)
    t.async = 1
    t.src = 'https://www.clarity.ms/tag/' + i
    y = l.getElementsByTagName(r)[0]
    y.parentNode.insertBefore(t, y)
  })(window, document, 'clarity', 'script', 'dm2wggikbb')
  // End Clarity
}

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
