import React, {useEffect, useState} from 'react'
import i18next from 'i18next'
import {v4 as uuidv4} from 'uuid'
import {convertToCurrency, getProductInfo} from '../subscriptionUtils'
import {Page} from 'components/partials/Page'
import Subsection from 'components/partials/headers/Subsection'

import CardIcon from 'res/icons/cardIcon'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {useDispatch, useSelector} from 'react-redux'
import EditIcon from 'res/icons/editIcon'
import ArrowIcon from 'res/icons/ArrowIcon'
import {clearAction, updateSubscription} from 'actions'
import BillingProviderModal from './BillingProviderModal'
import PlanSubsection from './PlanSubsection'
import ReadOnlyState from './itemStates/ReadOnlyState'
import EditableState from './itemStates/EditableState'
import ReviewState from './itemStates/ReviewState'

const initialProducts = [
  {
    ...getProductInfo('domains'),
  },
  {
    ...getProductInfo('cloudIntegrations'),
  },
  {
    ...getProductInfo('idpIntegrations'),
  },
  {
    ...getProductInfo('apiKeys'),
  },
  {
    ...getProductInfo('monitoredSuppliers'),
  },
  {
    ...getProductInfo('securityQuestionnaires'),
  },
]

export default function Update() {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)
  const updateSubscriptionState = useSelector(state => state.updateSubscription)

  const [products, setProducts] = useState(initialProducts)
  const [editedProducts, setEditedProducts] = useState([])
  const [itemsState, setItemsState] = useState('readOnly')
  const [showBillingProviderModal, setShowBillingProviderModal] =
    useState(false)

  const hasStripeSubscription =
    userState.subscription?.billingProvider === 'STRIPE'

  const currency =
    userState.subscription.currency ??
    (i18next.language === 'pt-br' ? 'BRL' : 'USD')

  const resetUI = () => {
    setItemsState('readOnly')
    initProducts()
    setEditedProducts([])
  }

  const initProducts = () => {
    setProducts(prev =>
      prev.map(prevItem => {
        const featureLimit = userState.subscription?.features[prevItem.id]

        let quantity = featureLimit
        if (prevItem.isOnOffItem) {
          quantity = featureLimit === 'UNLIMITED' || featureLimit > 0 ? 1 : 0
        }

        return {
          ...prevItem,
          value: userState.subscription?.prices[prevItem.id] ?? '—',
          quantity,
          originalQuantity: quantity,
        }
      })
    )
  }

  useEffect(() => {
    initProducts()
  }, [])

  useEffect(() => {
    dispatch(clearAction('updateSubscription'))

    if (updateSubscriptionState) resetUI()
  }, [updateSubscriptionState])

  const getItemStateContent = item => {
    switch (itemsState) {
      case 'readOnly':
        return (
          <ReadOnlyState
            item={item}
            currency={currency}
            hideCost={!hasStripeSubscription}
          />
        )

      case 'editableState':
        return (
          <EditableState
            item={item}
            setProducts={setProducts}
            setEditedProducts={setEditedProducts}
            currency={currency}
          />
        )

      case 'reviewState':
        return <ReviewState item={item} currency={currency} />
    }
  }

  const onUpdateSubscription = () => {
    const productsResult = {}

    products.forEach(item => {
      productsResult[item.id] = item.quantity
    })

    dispatch(
      updateSubscription({
        subscriptionId: userState.subscription.id,
        products: productsResult,
        _csrf: csrfState,
      })
    )
  }

  const onClickPrimarybtn = () => {
    if (!hasStripeSubscription) return setShowBillingProviderModal(true)

    switch (itemsState) {
      case 'readOnly':
        setItemsState('editableState')
        break

      case 'editableState':
        setItemsState('reviewState')
        break

      case 'reviewState':
        onUpdateSubscription()
        break
    }
  }

  const getPrimaryBtnProps = () => {
    switch (itemsState) {
      case 'readOnly':
        return {
          icon: <EditIcon width="20" color="#fff" />,
          iconDirection: 'left',
          label: i18next.t('subscription.update.products.changeProductsBtn'),
        }

      case 'editableState':
        return {
          icon: <ArrowIcon width="12" color="#fff" />,
          iconDirection: 'right',
          label: i18next.t('subscription.update.products.reviewBtn'),
        }

      case 'reviewState':
        return {
          icon: <CardIcon width="22" color="#fff" />,
          iconDirection: 'left',
          label: i18next.t(
            'subscription.update.products.updateSubscriptionBtn'
          ),
        }
    }
  }

  const getTotalValues = () => {
    return {
      current: products.reduce(
        (total, item) => total + item.originalQuantity * item.value,
        0
      ),
      new: products.reduce(
        (total, item) => total + item.quantity * item.value,
        0
      ),
    }
  }

  const primaryBtnProps = getPrimaryBtnProps()
  const totalValues = getTotalValues()

  const isQuantityChanged = Object.values(editedProducts).some(
    item => item === true
  )

  const showNewCost =
    itemsState === 'editableState' || itemsState === 'reviewState'

  const transitionClasses = 'transition-all duration-200 ease-out'

  return (
    <Page pageTitle={i18next.t('pageTitles.yourSubscription')}>
      <PlanSubsection />

      <Subsection title={i18next.t('subscription.update.products.title')}>
        <div id="upgrade-container" className="xl:flex text-ink">
          <div id="products-content" className="basis-9/12 p-8">
            {products.map(item => (
              <div
                key={item.id}
                className={`lg:flex items-center mb-8 pb-8 border-b last:border-b-0 last:mb-0 last:pb-0 transition ease-out duration-100`}>
                <div id="left-content" className="basis-1/2 md:mr-20">
                  <div className="flex mb-2">
                    {item.icons.map(icon => (
                      <img key={uuidv4()} src={icon} className="h-5 mr-4" />
                    ))}
                  </div>
                  <div className="font-medium mb-2">{item.title}</div>
                  <div className="text-sm font-light text-sideral-900">
                    {item.description}
                  </div>
                </div>

                <div id="right-content" className="basis-1/2 mt-6 lg:mt-0">
                  {getItemStateContent(item)}
                </div>
              </div>
            ))}
          </div>
          <div
            id="action-content"
            className="flex flex-col basis-3/12 bg-astral-50 border-t xl:border-l xl:border-t-0 p-6 pt-8">
            <div
              className={`${transitionClasses} duration-300 ${
                itemsState === 'reviewState'
                  ? 'opacity-1 mt-0 mb-8 xl:mb-0 border-b pb-6 xl:border-b-0 xl:pb-0'
                  : 'opacity-0 -mt-4'
              }`}>
              <div className="text-sm">
                {i18next.t('subscription.update.products.subscriptionHelper')}
              </div>
            </div>

            <div className="mt-auto">
              {hasStripeSubscription && (
                <div className="relative font-medium">
                  <div
                    className={`xl:absolute bottom-0 xl:border-t w-full ${transitionClasses} ${
                      showNewCost && 'mb-8 xl:mb-20'
                    }`}>
                    <div
                      className={`pt-5 ${transitionClasses} ${
                        showNewCost ? 'text-xs' : 'text-sm'
                      }`}>
                      {i18next.t(
                        'subscription.update.products.currentSubscriptionCost'
                      )}
                    </div>
                    <div
                      className={`${transitionClasses} ${
                        showNewCost ? 'text-xl mt-1' : 'text-3xl'
                      }`}>
                      {convertToCurrency(totalValues.current / 100, currency)}
                    </div>
                  </div>

                  <div
                    className={`mb-10 ${transitionClasses} ${
                      !showNewCost ? 'opacity-0' : 'opacity-1'
                    }`}>
                    <div className="text-sm">
                      {i18next.t(
                        'subscription.update.products.newSubscriptionCost'
                      )}
                    </div>
                    <div className="text-3xl">
                      {isQuantityChanged
                        ? convertToCurrency(totalValues.new / 100, currency)
                        : '—'}
                    </div>
                  </div>
                </div>
              )}

              <div className="sm:flex gap-2 xl:block">
                <div className="w-full">
                  <PrimaryButton
                    text={primaryBtnProps.label}
                    size="full"
                    margin="mb-2"
                    onClick={onClickPrimarybtn}
                    icon={primaryBtnProps.icon}
                    iconDirection={primaryBtnProps.iconDirection}
                    disabled={
                      itemsState === 'editableState' && !isQuantityChanged
                    }
                  />
                </div>
                <div className="w-full">
                  <PrimaryButton
                    text={i18next.t('subscription.update.products.discardBtn')}
                    theme="blue-outline"
                    size="full"
                    onClick={resetUI}
                    disabled={itemsState === 'readOnly'}
                  />
                </div>
              </div>
            </div>
          </div>

          {showBillingProviderModal && (
            <BillingProviderModal setModal={setShowBillingProviderModal} />
          )}
        </div>
      </Subsection>
    </Page>
  )
}
