import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import {DefaultModal} from 'components/partials/DefaultModal'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import {useDispatch, useSelector} from 'react-redux'
import {inviteSupplier, loading} from 'actions'
import {isLoading} from 'utils'

export default function SupplierInviteModal({supplierId, setModal}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)
  const inviteSupplierDoneState = useSelector(state => state.inviteSupplierDone)

  const [form, setForm] = useState({
    email: '',
  })

  const handleInputChange = e => {
    setForm({...form, email: e.target.value})
  }

  const handleSubmit = e => {
    e.preventDefault()

    dispatch(loading({inviteSupplier: true}))
    dispatch(
      inviteSupplier({
        supplierId,
        email: form.email,
        _csrf: csrfState,
      })
    )
  }

  useEffect(() => {
    if (inviteSupplierDoneState) setModal(false)
  }, [inviteSupplierDoneState])

  return (
    <form onSubmit={handleSubmit}>
      <DefaultModal
        id="inviteSupplierModal"
        title={i18next.t('suppliers.detail.invitation.title')}
        body={
          <div className="text-sm text-gray-700 leading-6 mb-8">
            <PrimaryInput
              text={i18next.t('suppliers.detail.invitation.label')}
              placeholder={i18next.t('suppliers.detail.invitation.placeholder')}
              name="email"
              type="text"
              value={form.email}
              onChange={handleInputChange}
              autofocus
              required
            />
          </div>
        }
        primaryBtnText={i18next.t('buttons.invite')}
        secondayBtnText={i18next.t('buttons.cancel')}
        onClickSecondaryBtn={() => setModal(false)}
        primaryButtonType="submit"
        primaryButtonLoading={isLoading(loadingState.inviteSupplier, false)}
        onCloseModal={() => setModal(false)}
      />
    </form>
  )
}

SupplierInviteModal.propTypes = {
  supplierId: PropTypes.string,
  setModal: PropTypes.func,
}
