import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {i18next} from 'translate/i18n'
import {useNavigate} from 'react-router-dom'
import {v4 as uuidv4} from 'uuid'

import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import DnsIcon from 'res/icons/dnsIcon'
import Timestamp from 'components/partials/Timestamp'

export default function RecordsTable({data, domainId}) {
  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      {
        header: i18next.t('tables.name'),
        accessorKey: 'name',
      },
      {
        header: i18next.t('tables.type'),
        accessorKey: 'type',
        width: 50,
      },
      {
        header: i18next.t('tables.value'),
        accessorKey: 'data',
        cell: cell => {
          const value = cell.getValue()

          if (typeof value === 'object') {
            if (Array.isArray(value)) {
              return value.map((value, index) => (
                <span key={uuidv4()}>
                  {typeof value === 'string' ? value : JSON.stringify(value)}
                  {index < value.length - 1 ? ', ' : ''}
                </span>
              ))
            }

            return JSON.stringify(value)
          }

          return value
        },
      },
      {
        header: i18next.t('tables.seen'),
        accessorKey: 'updatedAt',
        width: 50,
        cell: cell => {
          const moment =
            cell.row.original.lastNmapScan ||
            cell.row.original.checkedAt ||
            cell.row.original.updatedAt

          return <Timestamp date={new Date(moment)} fromNow />
        },
        sortingFn: (a, b) => {
          return new Date(a.original.updatedAt) - new Date(b.original.updatedAt)
        },
      },
    ],
    []
  )

  return (
    <>
      <PrimaryTableV8
        data={data}
        columns={columns}
        emptyStateWithoutFilter={
          <GenericEmptyState
            icon={<DnsIcon width="55" color="#90A4AE" />}
            title={i18next.t('titles.noResultsFound')}
            body={i18next.t('dns.noRecords')}
            buttonText={i18next.t('buttons.details')}
            linkPath={`/dns/domain/${domainId}`}
            bgColor={'bg-astral-50'}
            margin="m-2"
          />
        }
        hideSearch
        hidePagination
      />
      <SecondaryButton
        text={i18next.t('buttons.details')}
        size="sm"
        onClick={() => {
          navigate(`/dns/domain/${domainId}`)
        }}
        margin="mt-4 text-center"
        direction="right"
      />
    </>
  )
}

RecordsTable.propTypes = {
  data: PropTypes.array,
  domainId: PropTypes.string,
}
