import React, {useEffect, useState} from 'react'
import i18next from 'i18next'
import {useSelector, useDispatch} from 'react-redux'

import images from 'res'
import config from 'config'
import {sideral300, sky500} from 'constant'
import {viewFindingsByCategory} from 'utils'

import AddDomainModal from './AddDomainModal'
import DomainsTable from './domains/DomainsTable'
import WhatsImportant from './WhatsImportant'

import GenericEmptyState from 'components/partials/GenericEmptyState'
import ScoreBox from 'components/partials/ScoreBox'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import StatsBox from 'components/partials/StatsBox'
import TableWrapper from 'components/partials/tables/TableWrapper'

import {
  clearError,
  fetchDnsFeed,
  fetchDnsStats,
  fetchDomains,
  fetchNameServers,
  toggleRestrictionModal,
} from 'actions'
import {Page} from 'components/partials/Page'
import PlanUtils from 'utils/plan'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import {getFilterFromUrl} from 'utils/filters'
import {getRequestParams} from 'utils/pagination'

export default function DNS() {
  const company = useSelector(state => state.user?.company)
  const dnsFeed = useSelector(state => state.dnsFeed)
  const domains = useSelector(state => state.domains)
  const nameServers = useSelector(state => state.nameServers)
  const statsDns = useSelector(state => state.statsDns)
  const success = useSelector(state => state.success)

  const dispatch = useDispatch()

  const planUtils = PlanUtils()

  const [feed, setFeed] = useState([])
  const [fetchingFeed, setFetchingFeed] = useState(true)
  const [filterValue, setFilterValue] = useState('')
  const [modalVisibility, setModalVisibility] = useState('hidden')
  const [selectedRegistrar, setSelectedRegistrar] = useState()
  const [sortByRef, setSortByRef] = useState()

  const pageSize = 10
  const pageIndex = 0

  const prepareFilterArray = filterItems => {
    return filterItems && filterItems.length
      ? filterItems.map(filterItems => btoa(encodeURI(filterItems.value)))
      : []
  }

  const handleGenerateCSV = () => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'downloadCsvBtn', showModal: true})
      )

    const url = new URL(`${config.SERVER_URL}/dns/domains`)

    url.search = new URLSearchParams(
      getRequestParams({
        format: 'csv',
        pageIndex: 0,
        filterValue,
        sortBy: sortByRef,
        filterOptions: {
          registrarFilter: prepareFilterArray(selectedRegistrar),
        },
      })
    ).toString()

    window.location.href = url
  }

  const domainsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            accessor: 'score',
            disableFilters: true,
            hidden: true,
          },
          {
            accessor: 'hostname',
            disableFilters: true,
          },
          {
            accessor: 'findingsCount',
            disableFilters: true,
          },
          {
            accessor: 'actionButtons',
            disableFilters: true,
          },
          {
            accessor: 'chevron',
            disableGlobalFilter: true,
            disableFilters: true,
          },
          {
            Header: 'Registrar',
            accessor: 'registrar',
            hidden: true,
          },
        ],
      },
    ],
    []
  )

  const nameServerColumns = React.useMemo(
    () => [
      {
        accessorKey: 'nameServer',
        header: i18next.t('dns.nameServersTable.nameServers'),
        cell: cell => {
          try {
            const parsedArray = JSON.parse(cell.getValue())
            if (Array.isArray(parsedArray)) return parsedArray.join(', ')
          } catch (err) {
            return cell.getValue()
          }
        },
      },
      {
        accessorKey: 'totalNaked',
        header: i18next.t('dns.nameServersTable.nakedDomainsCount'),
      },
      {
        accessorKey: 'totalSubdomains',
        header: i18next.t('dns.nameServersTable.subdomainsCount'),
      },
    ],
    []
  )

  const toggleModal = () => {
    dispatch(clearError())

    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'addDomainBtn', showModal: true})
      )

    modalVisibility === 'hidden'
      ? setModalVisibility('block')
      : setModalVisibility('hidden')
  }

  useEffect(() => {
    setFetchingFeed(true)

    const registrarFilter = getFilterFromUrl('registrar', location)

    dispatch(
      fetchDomains({
        filterOptions: {
          registrarFilter: [btoa(encodeURI(registrarFilter))],
        },
        pageIndex,
        limit: pageSize,
      })
    )
    dispatch(fetchDnsStats(company.id))
    dispatch(fetchNameServers())

    if (!planUtils.hasPlanRestriction()) {
      dispatch(fetchDnsFeed())
    }
  }, [])

  useEffect(() => {
    if (dnsFeed) {
      const arr = [...dnsFeed.findings]
      setFeed(arr)
      setFetchingFeed(false)
    }
  }, [dnsFeed])

  useEffect(() => {
    if (success && modalVisibility !== 'hidden') setModalVisibility('hidden')
  }, [success])

  useEffect(() => {
    if (!dnsFeed || !nameServers || !domains) return

    const params = new URLSearchParams(window.location.search)

    if (
      params.has('registrar') ||
      window.location.hash.includes('#domains-table')
    ) {
      document.getElementById('domains-table').scrollIntoView()
    }
  }, [dnsFeed, nameServers, domains])

  return (
    <Page
      pageTitle={i18next.t('pageTitles.dns')}
      button={
        <SecondaryButton
          testId="add-domain-btn"
          text={i18next.t('buttons.addNewDomain')}
          onClick={toggleModal}
          crownIcon={planUtils.hasPlanRestriction()}
          plusIcon
        />
      }>
      {/* FIRST SECTION */}
      <div className="lg:flex lg:flex-wrap w-full">
        <AddDomainModal
          modalVisibility={modalVisibility}
          setModalVisibility={setModalVisibility}
        />

        {statsDns && company && (
          <div className="grid md:grid-cols-2 xl:grid-cols-7 gap-4 w-full">
            <StatsBox
              classContainer="md:col-span-2 xl:col-span-3"
              slots={2}
              title={`/${i18next.t('misc.assets')}`}
              label={`${i18next.t('dns.domains')}`}
              data={statsDns.domains || 0}
              secondaryLabel={i18next.t('dns.subdomains')}
              secondaryData={statsDns.subdomains || 0}
            />

            <StatsBox
              classContainer="xl:col-span-2"
              slots={1}
              title={`/${i18next.t('misc.findings')}`}
              data={statsDns.findings || 0}
              link={{
                text: i18next.t('buttons.viewAll'),
                onClick: () => viewFindingsByCategory('dns'),
              }}
              size="xs"
            />

            <ScoreBox
              classContainer="xl:col-span-2"
              score={statsDns && statsDns.scores}
            />
          </div>
        )}

        <WhatsImportant feed={feed || []} fetching={fetchingFeed} />

        <TableWrapper
          title={i18next.t('dns.nameServersTable.title')}
          loaded={nameServers}
          margin="mb-8"
          table={
            <PrimaryTableV8
              columns={nameServerColumns}
              data={nameServers}
              pageSize={10}
              pageNumber={0}
              detailPath={row => `/dns/nameserver/${row.original.nameServerId}`}
              emptyStateWithFilter={
                <GenericEmptyState
                  icon={<images.DnsIcon width="55" color={sideral300} />}
                  title={i18next.t('tables.nothingFoundWithSearch')}
                  body={i18next.t('tables.searchSomethingElse')}
                  margin={'m-2 mt-4'}
                />
              }
              emptyStateWithoutFilter={
                <GenericEmptyState
                  icon={<images.DnsIcon width="55" color={sideral300} />}
                  title={i18next.t('tables.nothingFound')}
                  body={i18next.t('tables.waitAFewMinutes')}
                  margin={'m-2 mt-4'}
                />
              }
            />
          }
        />

        <TableWrapper
          id="domains-table"
          loaded={domains}
          title={i18next.t('dns.organizationDomains')}
          button={
            <button
              className="ml-auto"
              onClick={handleGenerateCSV}
              title={i18next.t('buttons.downloadCSV')}>
              <images.DownloadIcon height="24" width="24" color={sky500} />
            </button>
          }
          table={
            <DomainsTable
              data={domains?.data}
              columns={domainsColumns}
              fetchData={props =>
                dispatch(
                  fetchDomains({
                    ...props,
                    limit: pageSize,
                  })
                )
              }
              filterValue={filterValue}
              prepareFilterArray={prepareFilterArray}
              setFilterValue={setFilterValue}
              selectedRegistrar={selectedRegistrar}
              setSelectedRegistrar={setSelectedRegistrar}
              setSortByRef={setSortByRef}
              total={domains?.total}
              pageSize={pageSize}
              pageCount={domains?.pages}
              pageIndex={pageIndex}
              emptyStateWithFilter={
                <GenericEmptyState
                  icon={<images.DnsIcon width="55" color={sideral300} />}
                  title={i18next.t('tables.nothingFoundWithSearch')}
                  body={i18next.t('tables.searchSomethingElse')}
                  margin={'m-2 mt-4'}
                />
              }
              emptyStateWithoutFilter={
                <GenericEmptyState
                  icon={<images.DnsIcon width="55" color={sideral300} />}
                  title={i18next.t('tables.nothingFound')}
                  body={i18next.t('tables.waitAFewMinutes')}
                  margin={'m-2 mt-4'}
                />
              }
            />
          }
        />
      </div>
    </Page>
  )
}
