import PropTypes from 'prop-types'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import questionMark from 'res/icons/questionMark.svg'
import PlanUtils from 'utils/plan'
import PlaceholderContent from '../PlaceholderContent'
import Spinner from '../Spinner'

export default function Subsection({
  id,
  title,
  children,
  content, // TO DO: remove content. It's better pass the content as 'children'. This makes the code easier to read
  helpPath,
  button,
  width,
  margin,
  loading,
  loadingMargin,
  isPremiumFeature,
  hide,
}) {
  const navigate = useNavigate()

  const showPlaceholderContent =
    isPremiumFeature && PlanUtils().hasPlanRestriction()

  const shouldHide = loading ? false : hide

  return (
    !shouldHide && (
      <section
        id={id}
        className={`rounded-lg border border-sideral-100 bg-white text-astral-900 ${
          width ? width : 'w-full'
        } ${margin ? margin : ''}`}>
        <header className="relative rounded-lg rounded-b-none border-sideral-100 border-b bg-astral-50 flex px-4 py-5">
          <div className="flex items-center dm-mono text-sideral-700 uppercase flex-1 font-medium leading-none">
            <div>{title}</div>
            {helpPath && (
              <div
                onClick={() => navigate(`#/help/${helpPath}`)}
                className="cursor-pointer">
                <img src={questionMark} className="w-4 h-4 opacity-75 ml-2" />
              </div>
            )}
            {button}
          </div>
        </header>
        <div className="h-full">
          {showPlaceholderContent ? (
            <PlaceholderContent sectionId={id} />
          ) : loading ? (
            <div className="flex items-center justify-center h-full">
              <div className={loadingMargin}>
                <Spinner />
              </div>
            </div>
          ) : (
            children || content
          )}
        </div>
      </section>
    )
  )
}

Subsection.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  content: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helpPath: PropTypes.string,
  button: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  margin: PropTypes.string,
  loadingMargin: PropTypes.string,
  width: PropTypes.string,
  loading: PropTypes.bool,
  isPremiumFeature: PropTypes.bool,
  hide: PropTypes.bool,
}
