import React, {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {useParams} from 'react-router-dom'

import {useSelector, useDispatch} from 'react-redux'
import {v4 as uuidv4} from 'uuid'
import {i18next} from 'translate/i18n'
import {getPropByLocale, urlContainsWildcard} from 'utils'
import PlanUtils from 'utils/plan'

import {
  borderStyle,
  sideral300,
  sky500,
  statusList,
  summer900,
  tooltipStyle,
} from 'constant'
import {fetchWebApp, fetchWebAppsFindings} from 'actions'

import images from 'res'
import ExternalLink from 'res/icons/externalLink'
import InfoIcon from 'res/icons/infoIcon'
import WebIcon from 'res/icons/webIcon'

import BackButton from 'components/partials/buttons/BackButton'
import ContentBox from 'components/partials/ContentBox'
import EntryPointsTable from './EntryPointsTable'
import ScoreBadge from 'components/partials/ScoreBadge'
import Spinner from 'components/partials/Spinner'
import {Page} from 'components/partials/Page'
import Timestamp from 'components/partials/Timestamp'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import {
  getSeverityColor,
  getSeverityLabel,
  getSeverityString,
} from 'utils/score'

const WebAppDetail = () => {
  const webAppState = useSelector(state => state.webApp)
  const webAppFindingsState = useSelector(state => state.webAppsFindings)

  const dispatch = useDispatch()
  const params = useParams()

  const [webApp, setWebApp] = useState({})
  const [fetching, setFetching] = useState(false)

  const planUtils = PlanUtils()

  useEffect(() => {
    if (webAppState && webAppState.id === params.id) {
      const webApp = {...webAppState}

      webApp.fullUrl = webApp.url

      if (webApp.final.query) {
        webApp.params = new URLSearchParams(webApp.final.query)
        webApp.fullUrl += webApp.final.query
      }

      setWebApp(webApp)
      setFetching(false)
    }

    if (!webAppState && !fetching) {
      dispatch(
        fetchWebApp({
          version: 2,
          webAppId: params.id,
        })
      )
    }
  }, [webAppState])

  useEffect(() => {
    if (params.id) {
      dispatch(
        fetchWebApp({
          version: 2,
          webAppId: params.id,
        })
      )

      if (!planUtils.hasPlanRestriction()) {
        dispatch(
          fetchWebAppsFindings({
            webAppId: params.id,
          })
        )
      }

      setFetching(true)
    }
  }, [params.id, dispatch])

  return (
    <Page pageTitle={i18next.t('pageTitles.web')}>
      <div>
        <WebAppOverview
          webApp={webApp}
          webAppFindings={webAppFindingsState}
          setWebApp={setWebApp}
        />
      </div>
    </Page>
  )
}

const WebAppOverview = ({webApp, webAppFindings}) => {
  return (
    <>
      {webApp && Object.keys(webApp).length ? (
        <>
          <section className="mb-6">
            <div
              className={`${borderStyle} relative bg-white border-b rounded-lg pt-5 text-sm`}>
              {webApp.status === 'idle' ? (
                <div className="tooltip absolute top-0 right-0 rounded-md mt-5 mr-5">
                  <figure className="cursor-pointer">
                    <images.AttentionSignal color={summer900} height="16" />
                  </figure>
                  <span
                    className={`${tooltipStyle.default} w-56 transform -translate-y-full -translate-x-full mt-1 -ml-1`}>
                    {i18next.t('web.errors.webAppNotResponding')}
                  </span>
                </div>
              ) : (
                <></>
              )}
              <div className="flex px-8">
                <figure className="-ml-1">
                  <WebIcon height="60" width="60" color={sideral300} />
                </figure>
                <div className="flex flex-wrap ml-4">
                  <BackButton fallbackLink={'/web'} />

                  <div className="w-full flex pt-3">
                    <div className="mt-[3px]">
                      <ScoreBadge letter={webApp.score && webApp.score.grade} />
                    </div>
                    <div className="text-sideral-700 font-medium text-lg break-all">
                      {webApp.url}
                    </div>

                    {urlContainsWildcard(webApp.url) ? (
                      <div className="tooltip mt-1 ml-1 mb-6">
                        <InfoIcon width="20" color={sky500} />
                        <div className={`${tooltipStyle.default} ml-8 -mt-5`}>
                          {i18next.t('web.wildcardTooltip')}
                        </div>
                      </div>
                    ) : (
                      <div className="pt-2 pl-2 mb-3">
                        <a
                          href={webApp.fullUrl}
                          rel="noreferrer"
                          target="_blank"
                          className="hover:text-black mr-2">
                          <ExternalLink width="12" color={sky500} />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {webApp && webApp.params ? (
                <div className="flex w-full">
                  <section
                    className={`${
                      webApp && webApp.status && webApp.status.idle
                        ? 'border-summer-900 rounded-tl-none'
                        : 'border-sideral-100'
                    } bg-white text-black rounded-b-lg border-t w-full overflow-hidden`}>
                    <div className="bg-white rounded-lg relative">
                      <div className="text-sideral-700 text-sm">
                        <div className="bg-astral-50 text-xs text-center p-1 border-b font-medium tracking-wide">
                          {i18next.t('web.urlQueryString')}
                        </div>
                        <table className="query-string-table w-full">
                          <thead className="text-xs">
                            <tr className="border-b">
                              <th className="border-r rounded-t-lg text-left bg-astral-50 py-1 p-4">
                                {i18next.t('web.url.key')}
                              </th>
                              <th className="rounded-t-lg bg-astral-50 text-left py-1 px-4">
                                {i18next.t('web.url.value')}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {webApp &&
                              (() => {
                                const elements = []
                                webApp.params.forEach((value, key) => {
                                  elements.push(
                                    <tr
                                      className="even:bg-astral-50"
                                      key={uuidv4()}>
                                      <td className="py-1 px-4 break-all border-r w-2/12">
                                        {key}
                                      </td>
                                      <td className="py-1 px-4 break-all w-7/12">
                                        {value}
                                      </td>
                                    </tr>
                                  )
                                })
                                return elements
                              })()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                </div>
              ) : (
                <></>
              )}
            </div>
          </section>

          <ContentBox
            header={i18next.t('web.details')}
            content={<Details webApp={webApp} />}
            margin="mb-6"
          />

          {webApp && webApp.webServices && webApp.webServices.length ? (
            <ContentBox
              header={i18next.t('web.entryPoints')}
              content={<EntryPoints webApp={webApp} />}
              margin="mb-6"
            />
          ) : (
            <></>
          )}

          <ContentBox
            id="findingsTable"
            header={i18next.t('misc.findings')}
            content={<Findings webAppFindings={webAppFindings} />}
            margin="mb-6"
            isPremiumFeature
          />
        </>
      ) : (
        <Spinner />
      )}
    </>
  )
}

const Details = ({webApp}) => {
  const getFingerprints = fingerprints => {
    if (!fingerprints || !Array.isArray(fingerprints) || !fingerprints.length)
      return '–'

    return fingerprints.map(({vendor, product, version}) => {
      const arr = [vendor, product, version]
      const result = arr.filter(item => item)

      if (!result.length) return

      return (
        <div
          key={uuidv4()}
          className="rounded-full inline-flex justify-center border border-gray-300 bg-gray-100 hover:bg-gray-200 p-px mb-1 mx-1">
          <span className="text-xs px-2">{result.join(' : ')}</span>
        </div>
      )
    })
  }

  return (
    <section>
      <div className="bg-white rounded-lg relative text-sideral-700 text-sm py-3">
        <div className="rounded-lg flex">
          <div className="w-8/12 text-sideral-700">
            <div className="py-1">
              <span className="font-semibold mr-2">
                {i18next.t('web.statusCode')}
              </span>
              {(webApp &&
                webApp.webServices &&
                webApp.webServices[0] &&
                webApp.webServices[0].data &&
                webApp.webServices[0].data.statusCode) ||
                '–'}
            </div>
            <div className="py-1">
              <span className="font-semibold mr-2">
                {i18next.t('Fingerprints')}:
              </span>
              {webApp && webApp.fingerprints && webApp.fingerprints.length
                ? getFingerprints(webApp.fingerprints)
                : '–'}
            </div>
            <div className="py-1 hidden">
              <span className="font-semibold mr-2">
                {i18next.t('web.title')}
              </span>
              {(webApp &&
                webApp.webServices &&
                webApp.webServices[0] &&
                webApp.webServices[0].data &&
                webApp.webServices[0].data.title) ||
                '–'}
            </div>
          </div>
          <div className="w-4/12 text-sideral-700">
            <div className="py-1">
              <span className="font-semibold mr-2">
                {i18next.t('web.firstSeen')}
              </span>
              <Timestamp format="LLL" date={webApp?.createdAt} />
            </div>
            <div className="py-1">
              <span className="font-semibold mr-2">
                {i18next.t('web.lastSeen')}
              </span>
              <Timestamp format="LLL" date={webApp?.latestCheck} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const EntryPoints = ({webApp}) => {
  const [pageSize, setPageSize] = useState(10)

  const entryPointsColumns = useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: 'From',
            accessor: 'original',
          },
        ],
      },
    ],
    []
  )

  return (
    <section>
      <div className="bg-white rounded-lg relative">
        <div className="text-xs">
          <div className="rounded-lg py-2">
            <EntryPointsTable
              columns={entryPointsColumns}
              data={webApp.webServices.slice(0, pageSize)}
              pageNumber={0}
              pageSize={100}
              defaultSorted={[
                {
                  id: 'createdAt',
                  desc: true,
                },
              ]}
            />
            {webApp.webServices.length > pageSize ? (
              <div className="dm-mono text-sky-900 hover:text-sky-800 text-center text-sm underline cursor-pointer py-2">
                <span
                  className="underline cursor-pointer"
                  onClick={() => {
                    setPageSize(pageSize + 10)
                  }}>
                  {i18next.t('misc.loadMore') + ' '}
                  {webApp.webServices.length - pageSize >= 10
                    ? '(10)'
                    : `(${webApp.webServices.length - pageSize})`}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

const Findings = ({webAppFindings}) => {
  const findingsColumns = useMemo(
    () => [
      {
        header: i18next.t('tables.title'),
        accessorKey: 'title',
        minSize: 400,
        cell: cell => (
          <>
            <span
              className={`${getSeverityColor(
                cell.row.original.severity
              )} severity-indicator inline-block flex-shrink-0 mr-2`}></span>

            <span className="truncate">
              {getPropByLocale(cell.row.original.title)}
            </span>
          </>
        ),
      },
      {
        header: 'status',
        accessorKey: 'status',
        cell: cell => statusList[cell.getValue()] || cell.getValue(),
      },
      {
        header: i18next.t('tables.severity'),
        accessorKey: 'severity',
        cell: cell => {
          const value = cell.getValue()

          if (typeof value === 'number') {
            return getSeverityLabel(getSeverityString(value))
          }

          return value
        },
      },
      {
        header: i18next.t('tables.lastCheck'),
        accessorKey: 'checkedAt',
        cell: cell => <Timestamp date={new Date(cell.getValue())} fromNow />,
        sortingFn: (a, b) => {
          return new Date(a.original.checkedAt) - new Date(b.original.checkedAt)
        },
      },
    ],

    []
  )

  if (!webAppFindings) return <></>

  return (
    <section>
      <div className="bg-white rounded-lg relative">
        <div className="text-xs">
          <div className="rounded-lg py-4">
            <PrimaryTableV8
              columns={findingsColumns}
              data={webAppFindings}
              defaultSorting={[
                {
                  id: 'severity',
                  desc: true,
                },
              ]}
              hideSearch
            />
          </div>
        </div>
      </div>
    </section>
  )
}

Details.propTypes = {
  webApp: PropTypes.object,
}

EntryPoints.propTypes = {
  webApp: PropTypes.object,
}

Findings.propTypes = {
  webAppFindings: PropTypes.array,
}

WebAppOverview.propTypes = {
  webApp: PropTypes.object,
  webAppFindings: PropTypes.array,
  setWebApp: PropTypes.func,
}

export default WebAppDetail
