import React from 'react'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router-dom'
import i18next from 'i18next'

import images from 'res'
import keyIcon from './../../../res/icons/keyIcon.svg'
import SectionAdvantages from 'components/partials/SectionAdvantages'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'

export default function ApiKeysEmptyState({
  hasAccessToFeature,
  handleDisplayAddModal,
}) {
  const navigate = useNavigate()

  const onClickActionBtn = () => {
    if (hasAccessToFeature) return handleDisplayAddModal()

    navigate('/subscription')
  }

  return (
    <div className="w-full flex-wrap text-center border border-sideral-100 font-medium rounded-md p-4 my-8">
      <SectionAdvantages
        headerIcon={<img className="w-16" src={keyIcon} />}
        headerTitle={i18next.t('settings.apiKeys.callTitle')}
        advantagesItems={[
          i18next.t('settings.apiKeys.advantages.firstItem'),
          i18next.t('settings.apiKeys.advantages.secondItem'),
          i18next.t('settings.apiKeys.advantages.thirdItem'),
        ]}
        actionButtons={
          <>
            <PrimaryButton
              text={
                hasAccessToFeature
                  ? i18next.t('settings.apiKeys.generateApiKeyBtn')
                  : i18next.t('buttons.buyNow')
              }
              onClick={onClickActionBtn}
              margin="mr-2"
            />

            <PrimaryButton
              text={i18next.t('settings.apiKeys.accessDocsBtn')}
              iconClass="icofont-external-link"
              iconDirection="right"
              theme="blue-outline"
              onClick={() =>
                window.open('https://api-docs.unxpose.com', '_blank')
              }
            />
          </>
        }
        rightSideImage={
          <img className="w-[70%] m-auto" src={images.APIKeysTable} />
        }
      />
    </div>
  )
}

ApiKeysEmptyState.propTypes = {
  hasAccessToFeature: PropTypes.bool,
  handleDisplayAddModal: PropTypes.func,
}
