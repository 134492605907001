import React from 'react'
import {useCheckout} from '@stripe/react-stripe-js'
import CheckoutContainer from './CheckoutContainer'

export default function Checkout() {
  const {lineItems} = useCheckout()

  return (
    <CheckoutContainer id="stripe-checkout-container" products={lineItems} />
  )
}
