import React from 'react'
import i18next from 'i18next'
import {getPropByLocale} from 'utils'
import {statusList} from 'constant'
import Timestamp from 'components/partials/Timestamp'
import {
  getSeverityColor,
  getSeverityLabel,
  getSeverityString,
} from 'utils/score'

const getCustomTimestamp = value => {
  return value && value !== 'N/A' && value !== 'not_supported' ? (
    <Timestamp date={new Date(value)} fromNow />
  ) : (
    '—'
  )
}

const isEncrypted = value => {
  return !value
    ? i18next.t('cloud.aws.ebsNotEncrypted')
    : i18next.t('cloud.aws.ebsEncrypted')
}

export const findingsColumns = [
  {
    header: i18next.t('tables.title'),
    accessorKey: 'title',
    meta: {align: 'left'},
    minSize: 400,
    cell: cell => (
      <div className="flex items-baseline">
        <div
          className={`${getSeverityColor(
            getSeverityString(cell.row.original.severity)
          )} severity-indicator inline-flex flex-shrink-0 mr-3`}
        />
        <div>{getPropByLocale(cell.row.original.title)}</div>
      </div>
    ),
  },
  {
    header: 'ARN',
    accessorKey: 'arn',
    minSize: 300,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    size: 60,
    cell: cell => statusList[cell.getValue()],
  },
  {
    header: i18next.t('tables.severity'),
    accessorKey: 'severity',
    size: 50,
    cell: cell => getSeverityLabel(getSeverityString(cell.getValue())),
  },
  {
    header: i18next.t('tables.seen'),
    accessorKey: 'id',
    size: 50,
    cell: cell => {
      const moment =
        cell.row.original.lastNmapScan ||
        cell.row.original.checkedAt ||
        cell.row.original.updatedAt

      return getCustomTimestamp(moment)
    },
    sortingFn: (a, b) => {
      return new Date(a.original.updatedAt) - new Date(b.original.updatedAt)
    },
  },
]

export const cloudtrailTrailsColumns = [
  {
    header: i18next.t('tables.name'),
    accessorKey: 'data.Name',
  },
  {
    header: i18next.t('Trail ARN'),
    accessorKey: 'data.TrailARN',
  },
  {
    header: i18next.t('Home region'),
    accessorKey: 'data.HomeRegion',
  },
]

export const iamPoliciesColumns = [
  {
    header: i18next.t('tables.name'),
    accessorKey: 'data.PolicyName',
  },
  {
    header: i18next.t('tables.attachmentCount'),
    accessorKey: 'data.AttachmentCount',
  },
  {
    header: i18next.t('tables.dateCreated'),
    accessorKey: 'data.CreateDate',
    cell: cell => getCustomTimestamp(cell.getValue()),
  },
]

export const iamUsersColumns = [
  {
    header: i18next.t('tables.username'),
    accessorKey: 'data.user',
  },
  {
    header: 'MFA',
    accessorKey: 'data.mfa_active',
    cell: cell =>
      !cell.getValue()
        ? i18next.t('cloud.aws.mfaNotEnabled')
        : i18next.t('cloud.aws.mfaEnabled'),
  },
  {
    header: i18next.t('tables.accessKeyAge'),
    accessorKey: 'accessKeyAge',
    cell: cell =>
      cell.getValue() ? <Timestamp date={cell.getValue()} fromNow /> : 'N/A',
  },
  {
    header: i18next.t('tables.passwordAge'),
    accessorKey: 'data.password_last_changed',
    cell: cell => getCustomTimestamp(cell.getValue()),
  },
  {
    header: i18next.t('tables.lastActivity'),
    accessorKey: 'lastActivity',
    cell: cell => <Timestamp date={cell.getValue()} fromNow />,
  },
  {
    header: i18next.t('tables.dateCreated'),
    accessorKey: 'data.user_creation_time',
    cell: cell => getCustomTimestamp(cell.getValue()),
  },
]

export const s3Columns = [
  {
    header: i18next.t('tables.bucketName'),
    accessorKey: 'data.Name',
  },
  {
    header: i18next.t('tables.access'),
    accessorKey: 'data.access',
  },
  {
    header: i18next.t('tables.region'),
    accessorKey: 'data.LocationConstraint',
  },
  {
    header: i18next.t('tables.dateCreated'),
    accessorKey: 'data.CreationDate',
    cell: cell => getCustomTimestamp(cell.getValue()),
    sortingFn: (a, b) => {
      return new Date(a.values.creationDate) - new Date(b.values.creationDate)
    },
  },
]

export const rdsInstancesColumns = [
  {
    header: i18next.t('tables.instanceId'),
    accessorKey: 'data.DBInstanceIdentifier',
  },
  {
    header: i18next.t('tables.dbName'),
    accessorKey: 'data.DBName',
  },
  {
    header: i18next.t('tables.instanceClass'),
    accessorKey: 'data.DBInstanceClass',
  },
  {
    header: i18next.t('tables.instanceStatus'),
    accessorKey: 'data.DBInstanceStatus',
  },
  {
    header: i18next.t('tables.dateCreated'),
    accessorKey: 'data.InstanceCreateTime',
    cell: cell => getCustomTimestamp(cell.getValue()),
  },
]

export const rdsSnapshotsColumns = [
  {
    header: i18next.t('ARN'),
    accessorKey: 'data.DBSnapshotArn',
  },
  {
    header: i18next.t('Port'),
    accessorKey: 'data.Port',
  },
  {
    header: i18next.t('Engine'),
    accessorKey: 'data.Engine',
  },
  {
    header: i18next.t('Encrypted'),
    accessorKey: 'data.Encrypted',
    cell: cell => isEncrypted(cell.getValue()),
  },
  {
    header: i18next.t('tables.dateCreated'),
    accessorKey: 'data.SnapshotCreateTime',
    cell: cell => getCustomTimestamp(cell.getValue()),
  },
]

export const kmsKeysColumns = [
  {
    header: 'Key ID',
    accessorKey: 'data.KeyId',
  },
  {
    header: 'Key ARN',
    accessorKey: 'data.KeyArn',
  },
]

export const ec2AmisColumns = [
  {
    header: i18next.t('tables.name'),
    accessorKey: 'data.Name',
  },

  {
    header: 'Image ID',
    accessorKey: 'data.ImageId',
  },
  {
    header: 'Public',
    accessorKey: 'data.Public',
    cell: cell =>
      !cell.getValue() ? i18next.t('tables.false') : i18next.t('tables.true'),
  },
  {
    header: 'Platform details',
    accessorKey: 'data.PlatformDetails',
  },
]

export const ec2InstancesColumns = [
  {
    header: i18next.t('tables.instanceId'),
    accessorKey: 'instanceId',
  },
  {
    header: i18next.t('tables.name'),
    accessorKey: 'name',
  },
  {
    header: i18next.t('tables.publicDns'),
    accessorKey: 'publicDnsName',
  },
  {
    header: i18next.t('tables.status'),
    accessorKey: 'machineState',
  },
  {
    header: i18next.t('tables.instanceType'),
    accessorKey: 'instanceType',
  },
  {
    header: i18next.t('tables.launchTime'),
    accessorKey: 'launchTime',
    cell: cell => getCustomTimestamp(cell.getValue()),
  },
]

export const ec2SecurityGroupsColumns = [
  {
    header: 'Group name',
    accessorKey: 'data.GroupName',
  },
  {
    header: 'Group ID',
    accessorKey: 'data.GroupId',
  },
  {
    header: 'Owner ID',
    accessorKey: 'data.OwnerId',
  },
  {
    header: 'Description',
    accessorKey: 'data.Description',
  },
]

export const ec2VpcsColumns = [
  {
    header: 'Name',
    accessorKey: 'data.name',
  },
  {
    header: 'VPC ID',
    accessorKey: 'data.VpcId',
  },
  {
    header: 'CIDR block',
    accessorKey: 'data.CidrBlock',
  },
  {
    header: 'Is default',
    accessorKey: 'data.IsDefault',
    cell: cell =>
      !cell.getValue()
        ? i18next.t('cloud.aws.vpcIsNotDefault')
        : i18next.t('cloud.aws.vpcIsDefault'),
  },
]

export const ebsColumns = [
  {
    header: i18next.t('tables.volumeId'),
    accessorKey: 'data.VolumeId',
  },
  {
    header: i18next.t('tables.encrypted'),
    accessorKey: 'data.Encrypted',
    cell: cell => isEncrypted(cell.getValue()),
  },
  {
    header: i18next.t('tables.volumeType'),
    accessorKey: 'data.VolumeType',
  },
  {
    header: i18next.t('tables.createTime'),
    accessorKey: 'data.CreateTime',
    cell: cell => getCustomTimestamp(cell.getValue()),
  },
]

export const efsColumns = [
  {
    header: i18next.t('tables.name'),
    accessorKey: 'data.Name',
  },
  {
    header: i18next.t('tables.encrypted'),
    accessorKey: 'data.Encrypted',
    cell: cell => isEncrypted(cell.getValue()),
  },
  {
    header: i18next.t('File System ID'),
    accessorKey: 'data.FileSystemId',
  },
]

export const route53Columns = [
  {
    header: i18next.t('tables.domainName'),
    accessorKey: 'data.DomainName',
  },
  {
    header: i18next.t('tables.autoRenew'),
    accessorKey: 'data.AutoRenew',
    cell: cell =>
      !cell.getValue()
        ? i18next.t('cloud.aws.route53AutoRenewNotEnabled')
        : i18next.t('cloud.aws.route53AutoRenewEnabled'),
  },
  {
    header: i18next.t('tables.transferLock'),
    accessorKey: 'data.TransferLock',
    cell: cell =>
      !cell.getValue()
        ? i18next.t('cloud.aws.route53PrivateZoneFalse')
        : i18next.t('cloud.aws.route53PrivateZoneTrue'),
  },
  {
    header: i18next.t('tables.expiry'),
    accessorKey: 'data.Expiry',
    cell: cell => getCustomTimestamp(cell.getValue()),
  },
]

export const route53HostedZonesColumns = [
  {
    header: 'ID',
    accessorKey: 'data.Id',
  },
  {
    header: i18next.t('tables.name'),
    accessorKey: 'data.Name',
  },
  {
    header: i18next.t('tables.privateZone'),
    accessorKey: 'data.Config.PrivateZone',
    cell: cell =>
      !cell.getValue()
        ? i18next.t('cloud.aws.route53PrivateZoneFalse')
        : i18next.t('cloud.aws.route53PrivateZoneTrue'),
  },
  {
    header: i18next.t('tables.resourceRecordSetCount'),
    accessorKey: 'data.ResourceRecordSetCount',
  },
]
