import React from 'react'
import i18next from 'i18next'
import {getPropByLocale} from 'utils'
import {statusList} from 'constant'
import Timestamp from 'components/partials/Timestamp'
import {
  getSeverityColor,
  getSeverityLabel,
  getSeverityString,
} from 'utils/score'

const getCustomTimestamp = value => {
  return value && value !== 'N/A' && value !== 'not_supported' ? (
    <Timestamp date={new Date(value)} fromNow />
  ) : (
    '—'
  )
}

export const bucketsColumns = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Storage class',
    accessorKey: 'data.storageClass',
  },
  {
    header: 'Location',
    accessorKey: 'data.location',
  },
  {
    header: 'Location type',
    accessorKey: 'data.locationType',
  },
  {
    header: 'Date created',
    accessorKey: 'data.timeCreated',
    cell: cell => getCustomTimestamp(cell.getValue()),
  },
]

export const instancesColumns = [
  {
    header: 'Name',
    accessorKey: 'data.name',
  },
  {
    header: 'Zone',
    accessorKey: 'data.zone',
  },
  {
    header: 'Status',
    accessorKey: 'data.status',
  },
  {
    header: 'Machine type',
    accessorKey: 'data.machineType',
  },
  {
    header: 'Date created',
    accessorKey: 'createdAt',
    cell: cell => getCustomTimestamp(cell.getValue()),
  },
]

export const kmsColumns = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Purpose',
    accessorKey: 'data.purpose',
  },
  {
    header: 'Protection level',
    accessorKey: 'data.primary.protectionLevel',
  },
  {
    header: 'Date created',
    accessorKey: 'data.createTime',
    cell: cell => getCustomTimestamp(cell.getValue()),
  },
]

export const apiKeysColumns = [
  {
    header: i18next.t('tables.displayName'),
    accessorKey: 'data.displayName',
    size: 80,
  },
  {
    header: i18next.t('tables.name'),
    accessorKey: 'data.name',
    size: 80,
  },
]

export const cloudRunServicesColumns = [
  {
    header: i18next.t('tables.name'),
    accessorKey: 'data.name',
    size: 80,
  },
  {
    header: i18next.t('tables.creator'),
    accessorKey: 'data.creator',
    size: 80,
  },
]

export const principalsColumns = [
  {
    header: i18next.t('tables.type'),
    accessorKey: 'type',
    size: 80,
  },
  {
    header: i18next.t('tables.name'),
    accessorKey: 'data.displayName',
    size: 80,
  },
  {
    header: 'Email',
    accessorKey: 'email',
  },
]

export const findingsColumns = [
  {
    header: i18next.t('tables.title'),
    accessorKey: 'title',
    meta: {align: 'left'},
    minSize: 600,
    cell: cell => (
      <div className="flex items-baseline">
        <div
          className={`${getSeverityColor(
            getSeverityString(cell.row.original.severity)
          )} severity-indicator inline-flex flex-shrink-0 mr-3`}
        />
        <div>{getPropByLocale(cell.row.original.title)}</div>
      </div>
    ),
  },
  {
    header: i18next.t('tables.resourceName'),
    accessorKey: 'name',
    minSize: 400,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: cell => statusList[cell.getValue()],
  },
  {
    header: i18next.t('tables.severity'),
    accessorKey: 'severity',
    cell: cell => getSeverityLabel(getSeverityString(cell.getValue())),
  },
  {
    header: i18next.t('tables.seen'),
    accessorKey: 'id',
    cell: cell => {
      const moment =
        cell.row.original.lastNmapScan ||
        cell.row.original.checkedAt ||
        cell.row.original.updatedAt

      return getCustomTimestamp(moment)
    },
    sortingFn: (a, b) => {
      return new Date(a.original.updatedAt) - new Date(b.original.updatedAt)
    },
  },
]

export const serviceAccountKeysColumns = [
  {
    header: 'Name',
    accessorKey: 'data.name',
  },
  {
    header: 'Key algorithm',
    accessorKey: 'data.keyAlgorithm',
  },
  {
    header: 'Key type',
    accessorKey: 'data.keyType',
  },
  {
    header: 'Valid after',
    accessorKey: 'data.validAfterTime',
    cell: cell => <Timestamp date={new Date(cell.getValue())} format="LLL" />,
  },
  {
    header: 'Valid before',
    accessorKey: 'data.validBeforeTime',
    cell: cell => <Timestamp date={new Date(cell.getValue())} format="LLL" />,
  },
]

export const sqlInstanceColumns = [
  {
    header: 'Name',
    accessorKey: 'data.name',
  },
  {
    header: 'Version',
    accessorKey: 'data.databaseVersion',
  },
  {
    header: 'Region',
    accessorKey: 'data.region',
  },
  {
    header: 'Project',
    accessorKey: 'data.project',
  },
  {
    header: 'Date created',
    accessorKey: 'data.createTime',
    cell: cell => getCustomTimestamp(cell.getValue()),
  },
]
