import React from 'react'
import PropTypes from 'prop-types'

export default function PrimaryInput(props) {
  const margin = props.margin || 'mb-3'
  const padding = props.button ? 'p-3 pr-10' : 'p-3'

  return (
    <div className={`relative ${margin}`}>
      <label className="block tracking-wide text-sideral-700 text-sm font-medium mb-1">
        {props.text}
        {props.required &&
          props.text &&
          !props.text.endsWith('*') &&
          !props.hideAsterisk && <span className="text-red-500 ml-1">*</span>}
      </label>
      <input
        data-testid={props.testId}
        className={`${
          props.hasError
            ? 'border-red-500 focus:border-red-500'
            : 'border-sideral-100 focus:border-sky-500'
        } ${props.icon && 'pl-10'}
        ${props.readOnly && 'bg-astral-100 focus:bg-astral-100'}
        appearance-none block w-full text-ink border rounded roboto leading-tight focus:outline-none focus:bg-white ${
          props.className
        } ${padding}`}
        type={props.type}
        placeholder={props.placeholder}
        name={props.name}
        required={props.required}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        minLength={props.minLength}
        maxLength={props.maxLength}
        pattern={props.pattern}
        ref={props.reference || null}
        autoComplete={props.autoComplete}
        autoFocus={props.autofocus}
        onFocus={props.onFocus}
        readOnly={props.readOnly}
        onBlur={props.onBlur}
        onKeyDown={props.onKeyDown}
      />
      {props.button && (
        <div className="absolute top-1/2 right-0 mx-4">{props.button}</div>
      )}
      {props.hasError && props.errorMsg && (
        <div className="text-xs text-red-500 mt-1">{props.errorMsg}</div>
      )}
      {props.icon}
    </div>
  )
}

PrimaryInput.propTypes = {
  testId: PropTypes.string,
  autoComplete: PropTypes.string,
  autofocus: PropTypes.bool,
  onFocus: PropTypes.func,
  text: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  icon: PropTypes.object,
  type: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMsg: PropTypes.string,
  disabled: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  pattern: PropTypes.string,
  margin: PropTypes.string,
  reference: PropTypes.object,
  hideAsterisk: PropTypes.bool,
  button: PropTypes.object,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
}
