import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {Trans} from 'react-i18next'
import {convertToCurrency} from '../../subscriptionUtils'

export default function ReadOnlyState({item, currency, hideCost}) {
  return (
    <div className="flex gap-10 items-start justify-around">
      {item.isOnOffItem ? (
        <div id="current-state" className="basis-1/2 text-center">
          <div className="font-medium text-sm mb-4">
            {i18next.t('subscription.update.products.included')}
          </div>
          <div className="text-xl">
            {item.quantity === 1 ? i18next.t('misc.yes') : i18next.t('misc.no')}
          </div>
        </div>
      ) : (
        <div id="current-quantity" className="basis-1/2 text-center">
          <div className="font-medium text-sm mb-4">
            {i18next.t('subscription.update.products.currentQty')}
          </div>
          <div className="text-xl">
            {item.quantity === 'UNLIMITED' ? (
              <i className="icofont-infinite text-2xl" />
            ) : (
              <div>
                <div>{item.quantity}</div>
                {item.subQuantity && item.quantity > 0 && (
                  <div className="inline-block bg-astral-100 border rounded text-xs px-1">
                    {
                      <Trans
                        i18nKey={item.i18nLimitKey}
                        values={{limit: item.subQuantity * item.quantity}}
                      />
                    }
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {!hideCost && (
        <div id="current-cost" className="basis-1/2 text-center">
          <div className="font-medium text-sm mb-4 text-nowrap">
            {i18next.t('subscription.update.products.currentCost')}
          </div>
          <div className="text-xl">
            {convertToCurrency((item.value * item.quantity) / 100, currency)}
          </div>
        </div>
      )}
    </div>
  )
}

ReadOnlyState.propTypes = {
  item: PropTypes.object,
  currency: PropTypes.string,
  hideCost: PropTypes.bool,
}
