import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {convertToCurrency} from '../../subscriptionUtils'
import ArrowIcon from 'res/icons/ArrowIcon'

export default function ReviewState({item, currency}) {
  return (
    <div className="flex items-start">
      {item.isOnOffItem ? (
        <div id="new-state" className="basis-1/2 text-center">
          <div className="font-medium text-sm mb-4">
            {i18next.t('subscription.update.products.newState')}
          </div>
          <div className="flex items-center justify-center">
            <div className="opacity-[.3]">
              {item.originalQuantity > item.quantity
                ? i18next.t('misc.yes')
                : i18next.t('misc.no')}
            </div>

            <div className="mx-4">
              <ArrowIcon width="10" color="#212121" />
            </div>

            <div>
              {item.originalQuantity < item.quantity
                ? i18next.t('misc.yes')
                : i18next.t('misc.no')}
            </div>
          </div>
        </div>
      ) : (
        <div id="new-quantity" className="basis-1/2 text-center">
          <div className="font-medium text-sm mb-4">
            {i18next.t('subscription.update.products.newQty')}
          </div>
          <div className="flex items-center justify-center text-xl">
            <div className="opacity-[.3]">{item.originalQuantity}</div>

            <div className="mx-4">
              <ArrowIcon width="10" color="#212121" />
            </div>

            <div>{item.quantity}</div>
          </div>
        </div>
      )}

      <div id="new-cost" className="basis-1/2">
        <div className="font-medium text-sm mb-4">
          {i18next.t('subscription.update.products.newCost')}
        </div>

        <div className="flex items-center text-xl">
          <div className="opacity-[.3]">
            {convertToCurrency(
              (item.value * item.originalQuantity) / 100,
              currency
            )}
          </div>

          <div className="mx-4">
            <ArrowIcon width="10" color="#212121" />
          </div>

          <div>
            {convertToCurrency((item.value * item.quantity) / 100, currency)}
          </div>
        </div>
      </div>
    </div>
  )
}

ReviewState.propTypes = {
  item: PropTypes.object,
  currency: PropTypes.string,
}
