import React from 'react'
import i18next from 'i18next'
import StripeIcon from 'res/icons/stripeIcon.svg'

export default function PaymentHeader() {
  return (
    <div id="payment-header">
      <div className="flex items-center mb-1">
        <div className="font-medium text-lg mr-[6px]">
          {i18next.t('subscription.checkout.paymentVia')}
        </div>
        <img src={StripeIcon} className="h-5" />
      </div>
      <div className="text-xs">
        {i18next.t('subscription.checkout.paymentViaDescription')}
      </div>
    </div>
  )
}
