import React, {useState} from 'react'

import i18next from 'i18next'
import {convertToCurrency} from '../subscriptionUtils'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import {toast} from 'react-toastify'
import ArrowIcon from 'res/icons/ArrowIcon'
import TrashIcon from 'res/icons/trashIcon'
import {red700, sky900} from 'constant'
import {useCheckout} from '@stripe/react-stripe-js'
import {useSelector} from 'react-redux'

export default function CheckoutFooter() {
  const {
    discountAmounts,
    currency,
    recurring,
    applyPromotionCode,
    removePromotionCode,
  } = useCheckout()

  const userState = useSelector(state => state.user)

  const [coupon, setCoupon] = useState('')

  const stripeTrialCompleted = userState.company?.stripeTrialCompleted

  const amountSubtotal = recurring.dueNext.amountSubtotal / 100
  const amountDiscount = recurring.dueNext.amountDiscount / 100
  const amountTotal = amountSubtotal - amountDiscount

  const handleApplyCupom = e => {
    e.preventDefault()

    if (!coupon) return

    applyPromotionCode(coupon).then(result => {
      if (result.type === 'success') {
        toast.success(i18next.t('subscription.checkout.messages.couponApplied'))
      } else {
        toast.error(i18next.t('subscription.checkout.messages.couponError'))
      }
    })
  }

  const handleRemoveCupom = e => {
    e.preventDefault()

    removePromotionCode().then(result => {
      if (result.type === 'success') {
        toast.info(i18next.t('subscription.checkout.messages.couponRemoved'))
        setCoupon('')
      } else {
        toast.error(
          i18next.t('subscription.checkout.messages.couponRemoveError')
        )
      }
    })
  }

  const hasAppliedCoupon = discountAmounts.length > 0

  return (
    <div className="sm:flex justify-between">
      <div className="sm:w-64 mb-10 sm:mb-0">
        <form onSubmit={handleApplyCupom}>
          <PrimaryInput
            text={i18next.t('subscription.checkout.cartFooter.coupon')}
            value={coupon}
            onChange={e => setCoupon(e.target.value)}
            button={
              <button
                onClick={
                  !hasAppliedCoupon ? handleApplyCupom : handleRemoveCupom
                }
                type="submit">
                {!hasAppliedCoupon ? (
                  <ArrowIcon color={sky900} width="12" direction="right" />
                ) : (
                  <TrashIcon color={red700} width="20" />
                )}
              </button>
            }
            r
            readOnly={hasAppliedCoupon}
            margin="0"
          />
        </form>
      </div>

      <div className="flex flex-col gap-6 sm:w-64">
        <div id="subtotal" className="flex items-center justify-between">
          <div className="text-sideral-700 text-sm">
            {i18next.t('subscription.checkout.cartFooter.subtotal')}
          </div>
          <div className="font-medium">
            {convertToCurrency(amountSubtotal, currency)}
          </div>
        </div>

        {hasAppliedCoupon && (
          <div id="coupon" className="flex items-center justify-between">
            <div>
              <div className="text-sideral-700 text-sm">
                {i18next.t('subscription.checkout.cartFooter.coupon')}
              </div>

              <div className="flex items-center bg-green-700 text-white text-xs px-2 mt-1 rounded">
                <i className="icofont-sale-discount mr-1" />
                <div>{discountAmounts[0].displayName}</div>
              </div>
            </div>

            <div className="font-medium text-green-800">
              {convertToCurrency(-amountDiscount, currency)}
            </div>
          </div>
        )}

        {!stripeTrialCompleted && (
          <div
            id="total-after-trial"
            className="flex items-center justify-between">
            <div className="text-sideral-700 text-sm">
              {i18next.t('subscription.checkout.cartFooter.afterTrial')}
            </div>
            <div className="font-medium text-right">
              <div>{convertToCurrency(amountTotal, currency)}</div>
              <div className="text-sm">
                {i18next.t('subscription.checkout.cartFooter.perMonth')}
              </div>
            </div>
          </div>
        )}

        <div className="border-t" />

        <div id="total-now" className="flex items-center justify-between">
          <div className="text-sideral-700 text-sm">
            {i18next.t('subscription.checkout.cartFooter.total')}
          </div>
          <div className="font-medium text-3xl">
            {stripeTrialCompleted
              ? convertToCurrency(amountTotal, currency)
              : convertToCurrency(0, currency)}
          </div>
        </div>
      </div>
    </div>
  )
}
