import React, {useState, useMemo, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {i18next} from 'translate/i18n'
import {v4 as uuidv4} from 'uuid'

import images from 'res'
import {mars700, sky500, tooltipStyle} from 'constant'
import {
  fetchAzureBlobContainers,
  fetchAzureDisks,
  fetchAzureStorageAccounts,
  fetchAzureVmInstances,
  fetchAzureFindingsByResource,
  fetchAzureSqlDatabases,
  fetchAzureSqlServers,
  fetchAzurePostgresqlServers,
  fetchAzureMysqlServers,
} from 'actions'
import {
  blobContainersColumns,
  disksColumns,
  findingsColumns,
  mysqlServersColumns,
  postgresqlServersColumns,
  resourceGroupsColumns,
  sqlDatabasesColumns,
  sqlServersColumns,
  storageAccountsColumns,
  vmInstancesColumns,
} from './columns'
import {extractResourceGroup, viewFindingsByCategory} from 'utils'

import Spinner from 'components/partials/Spinner'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import GenericEmptyState from 'components/partials/GenericEmptyState'

export default function AssetsItems(props) {
  const azureStatsState = useSelector(state => state.azureStats)
  const azureFindingsByResourceState = useSelector(
    state => state.azureFindingsByResource
  )
  const azureBlobContainersState = useSelector(
    state => state.azureBlobContainers
  )
  const azureDisksState = useSelector(state => state.azureDisks)
  const azureMysqlServersState = useSelector(state => state.azureMysqlServers)
  const azurePostgresqlServersState = useSelector(
    state => state.azurePostgresqlServers
  )
  const azureResourceGroupsState = useSelector(
    state => state.azureResourceGroups
  )
  const azureSqlDatabasesState = useSelector(state => state.azureSqlDatabases)
  const azureSqlServersState = useSelector(state => state.azureSqlServers)
  const azureStorageAccountsState = useSelector(
    state => state.azureStorageAccounts
  )
  const azureVmInstancesState = useSelector(state => state.azureVmInstances)

  const dispatch = useDispatch()

  const [openAccordion, setOpenAccordion] = useState('')
  const [tabs, setTabs] = useState({})

  const [blobContainers, setBlobContainers] = useState([])
  const [mysqlServers, setMysqlServers] = useState([])
  const [postgresqlServers, setPostgresqlServers] = useState([])
  const [sqlDatabases, setSqlDatabases] = useState([])
  const [sqlServers, setSqlServers] = useState([])
  const [storageAccounts, setStorageAccounts] = useState([])
  const [vmInstances, setVmInstances] = useState([])

  const toggleAccordion = resourceName => {
    openAccordion === resourceName
      ? setOpenAccordion('')
      : setOpenAccordion(resourceName)
  }

  const handleTab = (eventTarget, resourceName) => {
    if (eventTarget === tabs[resourceName]) return

    setTabs({...tabs, [resourceName]: eventTarget})
  }

  const latestCheckFailed = resourceName => {
    let service

    switch (resourceName) {
      case 'vms':
      case 'disks':
        service = 'compute'
        break
      case 'storageAccounts':
      case 'blobContainers':
        service = 'storage'
        break
      case 'sqlServers':
      case 'sqlDatabases':
      case 'mysqlServers':
      case 'postgresqlServers':
        service = 'database'
        break
    }

    return (
      azureStatsState &&
      azureStatsState.latestCheck &&
      azureStatsState.latestCheck.logs &&
      azureStatsState.latestCheck.logs[service] &&
      azureStatsState.latestCheck.logs[service] !== 'ok'
    )
  }

  const resourceIsLoading = resourceName => {
    return (
      !azureFindingsByResourceState ||
      !azureFindingsByResourceState[resourceName]
    )
  }

  function fetchAssets(resourceName) {
    switch (resourceName) {
      case 'blobContainers':
        !azureBlobContainersState &&
          dispatch(fetchAzureBlobContainers(props.tenantId))
        break
      case 'disks':
        !azureDisksState && dispatch(fetchAzureDisks(props.tenantId))
        break
      case 'mysqlServers':
        !azureMysqlServersState &&
          dispatch(fetchAzureMysqlServers(props.tenantId))
        break
      case 'postgresqlServers':
        !azurePostgresqlServersState &&
          dispatch(fetchAzurePostgresqlServers(props.tenantId))
        break
      case 'sqlDatabases':
        !azureSqlDatabasesState &&
          dispatch(fetchAzureSqlDatabases(props.tenantId))
        break
      case 'sqlServers':
        !azureSqlServersState && dispatch(fetchAzureSqlServers(props.tenantId))
        break
      case 'storageAccounts':
        !azureStorageAccountsState &&
          dispatch(fetchAzureStorageAccounts(props.tenantId))
        break
      case 'vms':
        !azureVmInstancesState &&
          dispatch(fetchAzureVmInstances(props.tenantId))
        break
      default:
        return
    }
  }

  function fetchFindings(resourceName) {
    if (
      !azureFindingsByResourceState ||
      (azureFindingsByResourceState &&
        !azureFindingsByResourceState[resourceName])
    ) {
      dispatch(
        fetchAzureFindingsByResource({
          resourceName: resourceName,
          tenantId: props.tenantId,
        })
      )
    }
  }

  function handleRowClick(resourceName) {
    fetchFindings(resourceName)
    fetchAssets(resourceName)
    handleTab('findings', resourceName)
    toggleAccordion(resourceName)
  }

  function getFindingsData(resourceName) {
    return (
      azureFindingsByResourceState && azureFindingsByResourceState[resourceName]
    )
  }

  const innerTableProps = {
    blobContainers: {
      data: blobContainers,
      columns: useMemo(() => blobContainersColumns, []),
    },
    disks: {
      data: azureDisksState,
      columns: useMemo(() => disksColumns, []),
    },
    mysqlServers: {
      data: mysqlServers,
      columns: useMemo(() => mysqlServersColumns, []),
    },
    postgresqlServers: {
      data: postgresqlServers,
      columns: useMemo(() => postgresqlServersColumns, []),
    },
    resourceGroups: {
      data: azureResourceGroupsState,
      columns: useMemo(() => resourceGroupsColumns, []),
    },
    sqlDatabases: {
      data: sqlDatabases,
      columns: useMemo(() => sqlDatabasesColumns, []),
    },
    sqlServers: {
      data: sqlServers,
      columns: useMemo(() => sqlServersColumns, []),
    },
    storageAccounts: {
      data: storageAccounts,
      columns: useMemo(() => storageAccountsColumns, []),
    },
    vms: {
      data: vmInstances,
      columns: useMemo(() => vmInstancesColumns, []),
    },
    findings: {columns: useMemo(() => findingsColumns, [])},
  }

  useEffect(() => {
    if (!azureBlobContainersState) return

    const arr = azureBlobContainersState
    arr.map(
      item => (item.resourceGroup = extractResourceGroup(item.resourceGroupId))
    )

    setBlobContainers(arr)
  }, [azureBlobContainersState])

  useEffect(() => {
    if (!azurePostgresqlServersState) return

    const arr = azurePostgresqlServersState
    arr.map(
      item => (item.resourceGroup = extractResourceGroup(item.resourceGroupId))
    )

    setPostgresqlServers(arr)
  }, [azurePostgresqlServersState])

  useEffect(() => {
    if (!azureMysqlServersState) return

    const arr = azureMysqlServersState
    arr.map(
      item => (item.resourceGroup = extractResourceGroup(item.resourceGroupId))
    )

    setMysqlServers(arr)
  }, [azureMysqlServersState])

  useEffect(() => {
    if (!azureSqlDatabasesState) return

    const arr = azureSqlDatabasesState
    arr.map(
      item => (item.resourceGroup = extractResourceGroup(item.resourceGroupId))
    )

    setSqlDatabases(arr)
  }, [azureSqlDatabasesState])

  useEffect(() => {
    if (!azureSqlServersState) return

    const arr = azureSqlServersState
    arr.map(
      item => (item.resourceGroup = extractResourceGroup(item.resourceGroupId))
    )

    setSqlServers(arr)
  }, [azureSqlServersState])

  useEffect(() => {
    if (!azureStorageAccountsState) return

    const arr = azureStorageAccountsState
    arr.map(
      item => (item.resourceGroup = extractResourceGroup(item.resourceGroupId))
    )

    setStorageAccounts(arr)
  }, [azureStorageAccountsState])

  useEffect(() => {
    if (!azureVmInstancesState) return

    const arr = azureVmInstancesState
    arr.map(
      item => (item.resourceGroup = extractResourceGroup(item.resourceGroupId))
    )

    setVmInstances(arr)
  }, [azureVmInstancesState])

  return props.data.map(item => (
    <div key={uuidv4()} className="my-2 rounded-lg border-2 border-sideral-50">
      <div
        onClick={() => handleRowClick(item.resourceName)}
        className="flex items-center justify-between w-full p-4">
        <div
          id="name"
          className="basis-1/3 uppercase flex cursor-pointer text-left text-sideral-700 font-medium text-sm items-center">
          {item.name}
        </div>

        <div
          id="findingsCount"
          className="basis-1/3 flex cursor-pointer items-center text-sm">
          <figure className="pr-1">
            <images.FindingIcon width="20" />
          </figure>
          {typeof item.findings === 'number' ? item.findings : '-'}
          <div className="ml-1">
            {item.findings > 1
              ? i18next.t('misc.findings').toLowerCase()
              : i18next.t('misc.finding').toLowerCase()}
          </div>
        </div>

        <div
          id="chevron"
          className="flex cursor-pointer items-center w-20 px-4">
          {latestCheckFailed(item.resourceName) && (
            <figure className="mr-4">
              <images.WarningIcon width="20" color={mars700} />
            </figure>
          )}
          <figure className="ml-auto">
            <images.Chevron
              width="12"
              color={sky500}
              direction={openAccordion === item.asset ? 'up' : 'down'}
            />
          </figure>
        </div>
      </div>

      {openAccordion === item.resourceName &&
        (resourceIsLoading(item.resourceName) ? (
          <div className="w-full -mt-4">
            <Spinner />
          </div>
        ) : (
          <div
            className="relative bg-astral-50 border-t-2 border-sideral-50 w-full p-4"
            onClick={e => e.stopPropagation()}>
            <div className="flex text-sky-500 text-sm mb-4">
              <Tab
                value="findings"
                tabs={tabs}
                handleTab={handleTab}
                text={i18next.t('misc.findings')}
                resourceName={item.resourceName}
              />
              <Tab
                value="assets"
                tabs={tabs}
                handleTab={handleTab}
                text={i18next.t('misc.assets')}
                resourceName={item.resourceName}
              />
            </div>

            {latestCheckFailed(item.resourceName) && <CheckFailed />}

            {tabs[item.resourceName] === 'findings' && (
              <PrimaryTableV8
                data={getFindingsData(item.resourceName)}
                columns={innerTableProps.findings.columns}
                detailPath={row => `/azure/finding/${row.original.id}`}
                emptyStateWithFilter={
                  <GenericEmptyState
                    icon={<images.FindingIcon width="55" color="#90A4AE" />}
                    title={i18next.t('tables.nothingFoundWithSearch')}
                    body={i18next.t('tables.searchSomethingElse')}
                    buttonText={i18next.t('buttons.viewAllFindings')}
                    linkPath={viewFindingsByCategory('cloud')}
                    bgColor="bg-astral-50"
                    margin="m-2"
                  />
                }
                emptyStateWithoutFilter={
                  <GenericEmptyState
                    icon={<images.FindingIcon width="55" color="#90A4AE" />}
                    title={i18next.t('titles.goodNews')}
                    body={i18next.t('cloud.noFindingsInService')}
                    buttonText={i18next.t('buttons.viewAllFindings')}
                    linkPath={viewFindingsByCategory('cloud')}
                    bgColor="bg-astral-50"
                    margin="m-2"
                  />
                }
              />
            )}

            {tabs[item.resourceName] === 'assets' && (
              <PrimaryTableV8
                data={innerTableProps[item.resourceName].data}
                columns={innerTableProps[item.resourceName].columns}
                emptyStateWithFilter={
                  <GenericEmptyState
                    icon={<images.CloudIcon width="55" color="#90A4AE" />}
                    title={i18next.t('tables.nothingFoundWithSearch')}
                    body={i18next.t('tables.searchSomethingElse')}
                    bgColor="bg-astral-50"
                    margin="m-2"
                  />
                }
                emptyStateWithoutFilter={
                  <GenericEmptyState
                    icon={<images.CloudIcon width="55" color="#90A4AE" />}
                    title={i18next.t('titles.noResultsFound')}
                    body={i18next.t('cloud.noAssets')}
                    bgColor="bg-astral-50"
                    margin="m-2"
                  />
                }
              />
            )}
          </div>
        ))}
    </div>
  ))
}

const Tab = ({tabs, handleTab, value, text, resourceName}) => {
  return (
    <button
      value={value}
      className={`${
        tabs[resourceName] === value
          ? ' border-sky-500 text-sky-900'
          : 'border-transparent'
      } border-b-2 uppercase cursor-pointer outline-none mr-5 pb-2`}
      onClick={e => {
        handleTab(e.target.value, resourceName)
      }}>
      {text}
    </button>
  )
}

const CheckFailed = () => {
  return (
    <Link
      to="/cloud/azure/integration"
      className="tooltip flex items-center absolute top-0 right-0 rounded font-light text-xs mt-4 mr-4 z-20">
      <images.WarningIcon width="20" color={mars700} />
      <span className="underline text-mars-700 ml-1">
        {i18next.t('errors.latestCheckFailed')}
      </span>
      <span
        className={`${tooltipStyle.danger} flex items-center top-0 left-0 mt-6 h-12`}>
        {i18next.t('errors.checkPermissions')}
      </span>
    </Link>
  )
}

AssetsItems.propTypes = {
  defaultSorted: PropTypes.array,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  tenantId: PropTypes.string,
}

Tab.propTypes = {
  tabs: PropTypes.object,
  handleTab: PropTypes.func,
  text: PropTypes.string,
  value: PropTypes.string,
  resourceName: PropTypes.string,
}
